<template>
  <mcma-card title="Interlocuteur technique" icon="administration" class="mcma-card-in-tab" :inline="true">
    <template #describe>
      N.B : Si l'interlocuteur souhaité n'apparaît pas dans la liste, veuillez vérifier que celui-ci possède toutes les
      informations requises (prénom, nom, email, téléphone).

      <div class="informations mt12" v-if="membre.interlocuteurTechnique && hasChange(form.getFieldsValue())">
        <mcma-icon
          type="info-circle"
          color="primary"
          name="L'interlocuteur technique pour ce site est différent de l'interlocuteur technique général du membre"
          :faIcon="true"
        />
      </div>
    </template>
    <template #headerRightSide>
      <mcma-button
        name="Vérifier mes informations interlocuteurs"
        color="primary"
        leftIcon="check-circle"
        :isFaIcon="true"
        @click="goTo('interlocuteurs')"
      />
    </template>
    <a-form :form="form" v-if="form.getFieldsValue()">
      <a-row type="flex" :gutter="12">
        <mcma-field
          :colSpan="16"
          type="select"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="form.getFieldValue('selectedInterlocuteurId')"
          fieldName="selectedInterlocuteurId"
          disableValue="Sélectionner un interlocuteur"
          @change="selectedInterlocuteurIdChange"
          label="interlocuteurs"
        >
          <a-select-option v-for="(interlocuteur, index) in interlocuteurs" :key="index" :value="interlocuteur.id">
            {{ interlocuteur.friendly }}
          </a-select-option>
        </mcma-field>
        <a-col :span="8" class="align-column-end">
          <mcma-button
            class="grow-2"
            name="Ajouter un nouvel interlocuteur"
            color="primary"
            leftIcon="user-plus"
            :isFaIcon="true"
            @click="setInterlocuteurCreateModal(true)"
          />
        </a-col>
      </a-row>
      <a-row type="flex" :gutter="12">
        <mcma-field
          type="select"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="fonction"
          :label="$t('membreInfos.fonction')"
          disableValue="Sélectionnez une fonction"
          :defaultValue="form.getFieldValue('fonction')"
          getFlow="name::services"
          getPanel="name::Interlocuteurs"
          getField="type::fonction"
          :required="true && isDeletedStatus"
          :requiredMessage="`Merci de renseigner sa fonction`"
          :disabled="true"
        />
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="form.getFieldValue('prenom')"
          fieldName="prenom"
          :label="$t('membreInfos.firstName')"
          :required="true && isDeletedStatus"
          :disabled="true"
          rule="excludeSpecial"
        />
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="form.getFieldValue('nom')"
          fieldName="nom"
          :label="$t('membreInfos.lastName')"
          :required="true && isDeletedStatus"
          :disabled="true"
          rule="excludeSpecial"
        />
        <mcma-field
          type="select"
          :form="form"
          :wasSubmit="wasSubmit"
          getFlow="name::services"
          getPanel="name::Interlocuteurs"
          getField="type::civility"
          :disabled="true"
        />

        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="form.getFieldValue('mail')"
          fieldName="mail"
          :label="$t('membreInfos.mail')"
          :required="true && isDeletedStatus"
          :disabled="true"
          rule="isEmail"
        />
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="form.getFieldValue('telephone')"
          fieldName="telephone"
          :label="$t('membreInfos.phone')"
          :extraValidationFields="['mobile']"
          :required="(!form.getFieldValue('mobile') || form.getFieldValue('mobile').length <= 0) && isDeletedStatus"
          :disabled="true"
          rule="isPhone"
        />
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="form.getFieldValue('mobile')"
          fieldName="mobile"
          :label="$t('membreInfos.mobile')"
          :extraValidationFields="['telephone']"
          :required="
            (!form.getFieldValue('telephone') || form.getFieldValue('telephone').length <= 0) && isDeletedStatus
          "
          :disabled="true"
          rule="isMobilePhone"
        />
      </a-row>
      <a-row type="flex" :gutter="12">
        <a-col :span="24">
          <div class="button-group-inline">
            <mcma-button
              name="Valider et passer à l'étape suivante"
              class="ml12"
              color="success"
              leftIcon="save"
              :isFaIcon="true"
              @click="submit"
            />
          </div>
        </a-col>
      </a-row>
    </a-form>
    <interlocuteur-create-modal
      :visible="showInterlocuteurCreateModal"
      from="site"
      @onCancel="setInterlocuteurCreateModal"
      @onOk="pushNewInterlocuteurFromModal"
    />
  </mcma-card>
</template>

<script>
import Axios from "@/util/Axios"
import _ from "lodash"
import { pick } from "@/util/Helpers"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaField from "@/components/mcma/common/McmaField"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaIcon from "@/components/mcma/common/McmaIcon"
import InterlocuteurCreateModal from "@/components/mcma/steps/interlocuteurs/InterlocuteurCreateModal"
import { mapState } from "vuex"

export default {
  name: "SiteInterlocuteurForm",
  components: {
    McmaCard,
    McmaField,
    McmaButton,
    McmaIcon,
    InterlocuteurCreateModal,
  },
  props: {
    site: Object,
    isDeletedStatus: Boolean,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      wasSubmit: false,
      interlocuteurs: [],
      originalSelectedInterlocuteur: {},
      showInterlocuteurCreateModal: false,
    }
  },
  computed: {
    ...mapState(["membre", "operation"]),
    getCapitalize() {
      return (value) => {
        return this.$options.filters.capitalize(value)
      }
    },
    hasChange() {
      return (currentValues) => {
        if (
          this.membre.interlocuteurTechnique &&
          this.membre.interlocuteurTechnique.id &&
          currentValues &&
          currentValues.mail
        ) {
          return (
            this.membre.interlocuteurTechnique.fonction !== currentValues.fonction ||
            this.membre.interlocuteurTechnique.prenom !== currentValues.prenom ||
            this.membre.interlocuteurTechnique.nom !== currentValues.nom ||
            this.membre.interlocuteurTechnique.mail !== currentValues.mail ||
            this.membre.interlocuteurTechnique.telephone !== currentValues.telephone ||
            this.membre.interlocuteurTechnique.mobile !== currentValues.mobile ||
            this.membre.interlocuteurTechnique.civility !== currentValues.civility
          )
        } else {
          return false
        }
      }
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    setInterlocuteurCreateModal(show) {
      this.showInterlocuteurCreateModal = !!show
    },
    pushNewInterlocuteurFromModal(interlocuteur) {
      this.pushInterlocuteur(interlocuteur)
      this.originalSelectedInterlocuteur = this.interlocuteurs.find((inter) => inter.id === interlocuteur.id)
      this.setFieldsValue(this.originalSelectedInterlocuteur)
      this.setFieldsValue({
        selectedInterlocuteurId: this.originalSelectedInterlocuteur.id,
      })
      this.setInterlocuteurCreateModal()
    },
    async initialize(reset) {
      this.interlocuteurs = []
      this.originalSelectedInterlocuteur = {}

      try {
        await this.prepareInterlocuteurs()
        const siteInterlocuteur = await this.prepareInterlocuteurBySite()
        const membreInterlocuteur = await this.prepareInterlocuteurByMembre()
        this.originalSelectedInterlocuteur = siteInterlocuteur || membreInterlocuteur || {}
      } catch (e) {
        console.error(e)
      }

      if (this.originalSelectedInterlocuteur && this.originalSelectedInterlocuteur.id) {
        this.setFieldsValue(this.originalSelectedInterlocuteur)
        this.setFieldsValue({
          selectedInterlocuteurId: this.originalSelectedInterlocuteur.id,
        })
      } else {
        this.resetForm()
      }
      if (reset) {
        this.$emit("reset")
      }
    },
    prepareInterlocuteurBySite() {
      const interId = this.site && this.site.interlocuteurId ? this.site.interlocuteurId : null
      if (interId) {
        return Axios("get", "api/interlocuteurs/" + interId)
          .then((response) => {
            const siteInterlocuteur = _.find(this.interlocuteurs, ["id", response.data.id])
            if (siteInterlocuteur) {
              return Promise.resolve(siteInterlocuteur)
            } else {
              const interToPush = this.pushInterlocuteur(response.data)
              return Promise.resolve(interToPush)
            }
          })
          .catch(() => {
            return Promise.resolve(null)
          })
      }
      return Promise.resolve(null)
    },
    prepareInterlocuteurs() {
      Axios("get", "api/membres/" + this.membre.id + "/interlocuteurs")
        .then((response) => {
          const interlocuteurs = response.data._embedded.interlocuteurs || []
          interlocuteurs.forEach((inter) => {
            this.pushInterlocuteur(inter)
          })

          return interlocuteurs
        })
        .catch((error) => {
          console.log(
            `%c prepareInterlocuteurs error`,
            "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
            error
          )
          return null
        })
    },
    pushInterlocuteur(inter) {
      if (inter.role === "TECHNIQUE" || inter.role === "AUTRE") {
        const interToPush = pick(inter, [
          "id",
          "fonction",
          "role",
          "nom",
          "prenom",
          "telephone",
          "mobile",
          "mail",
          "civility",
        ])
        interToPush.friendly = `${inter.nom.toUpperCase()} ${_.capitalize(inter.prenom)}`

        this.interlocuteurs.push(interToPush)
        this.interlocuteurs = _.uniqBy(this.interlocuteurs, "id")
        return interToPush
      }
      return null
    },
    prepareInterlocuteurByMembre() {
      const interT = this.membre.interlocuteurTechnique || {}
      let interToReturn = null
      let interToPush = null
      if (interT && interT.nom && interT.prenom && (interT.telephone || interT.mobile) && interT.id) {
        interToPush = this.pushInterlocuteur(interT)
        interToReturn = interToPush
      }
      return Axios("get", "api/membres/" + this.membre.id + "/interlocuteurs")
        .then((response) => {
          for (const interT of response.data._embedded.interlocuteurs) {
            if (
              interT.nom &&
              interT.prenom &&
              interT.telephone &&
              interT.id &&
              !_.find(this.interlocuteurs, ["id", interT.id]) &&
              (interT.role === "TECHNIQUE" || interT.role === "AUTRE")
            ) {
              this.pushInterlocuteur(interT)
              interToReturn = interToReturn && interToReturn.id ? interToReturn : interToPush
            }
          }
        })
        .catch(() => {
          return Promise.resolve(null)
        })
        .finally(() => {
          return Promise.resolve(interToReturn)
        })
    },
    selectedInterlocuteurIdChange(interId) {
      this.originalSelectedInterlocuteur = this.interlocuteurs.find((inter) => inter.id === interId)
      this.setFieldsValue(this.originalSelectedInterlocuteur)
    },
    setFieldsValue(object) {
      const keys = Object.keys(object)
      keys.forEach((key) => {
        this.form.getFieldValue(key)
      })
      this.$nextTick(() => {
        this.form.setFieldsValue(object)
      })
    },
    resetForm() {
      this.setFieldsValue({
        fonction: undefined,
        prenom: undefined,
        nom: undefined,
        mail: undefined,
        civility: undefined,
        telephone: undefined,
        mobile: undefined,
        selectedInterlocuteurId: undefined,
      })
    },
    submit() {
      const keys = ["fonction", "prenom", "nom", "mail", "civility", "telephone", "mobile"]
      this.form.validateFields(keys, (errors, fieldsValue) => {
        this.wasSubmit = true
        if (errors) {
          //triggers error until siteEditLocalisation
          this.$emit("saveWithErrors")
          /*this.$notification.error({
            message: "Vous avez des erreurs",
            description: `Veuillez remplir tous les champs comprenant des erreurs dans le formulaire de l'interlocuteur`,
          })*/
          this.$alert("Vous n'avez pas complété tous les champs obligatoires marqués par une astérisque rouge. Veuillez tous les remplir afin de valider le formulaire.","Erreur",'error')
        } else {
          console.log("Add interlocuteur")
          if (!this.form.getFieldValue("selectedInterlocuteurId")) {
            if (!this.isDeletedStatus) {
              this.$emit("save", null)
            } else {
              console.log("pas d'interlocuteur")
              Axios("post", "api/interlocuteurs", {
                removed: false,
                membre: process.env.VUE_APP_API_URL + "/api/membres/" + this.membre.id,
                fonction: fieldsValue.fonction,
                prenom: fieldsValue.prenom,
                nom: fieldsValue.nom,
                mail: fieldsValue.mail,
                civility: fieldsValue.civility,
                telephone: fieldsValue.telephone,
                mobile: fieldsValue.mobile,
                operationId: this.operation.id,
                role: "TECHNIQUE",
              })
                .then((response) => {
                  this.$notification.success({
                    message: "Interlocuteur technique ajouté",
                    description: `${fieldsValue.prenom} ${fieldsValue.nom} à bien été ajouter à vos interlocuteurs techniques.`,
                  })
                  const interToPush = this.pushInterlocuteur(response.data)
                  this.setFieldsValue({
                    selectedInterlocuteurId: interToPush.id,
                  })
                  console.log("response.data", response.data)
                  this.$emit("save", response.data.id)
                })
                .catch((error) => {
                  console.error(error)
                  if (error.response.status === 409) {
                    this.$notification.warning({
                      message: "L'email interlocuteur existe deja",
                      description:
                        "L'email de l'interlocuteur que vous essayer de creer est deja assigné à un autre interlocuteur.",
                    })
                  } else {
                    this.$notification.error({
                      message: "Erreur d'ajout d'interlocuteur",
                      description: `${this.getCapitalize(fieldsValue.prenom)} ${this.getCapitalize(
                        fieldsValue.nom
                      )} n'as pas pu étre ajouté à vos interlocuteurs techniques.`,
                    })
                  }
                })
            }
          } else {
            console.log("interlocuteur exist")
            this.$emit("save", fieldsValue.id)
          }
        }
      })
    },
    getSelectedInterlocuteurId() {
      return this.form.getFieldValue("selectedInterlocuteurId")
    },
    goTo(routeName) {
      this.$router.push({
        name: routeName,
      })
    },
  },
}
</script>

<style lang="scss"></style>
