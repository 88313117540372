<template>
  <main>
    <div class="container">
      <section class="row">
        <article class="card">
          <div class="card-content">
            <span class="card-title"> Liste de vos périmètres de facturation </span>
            <div class="progress">
              <div class="determinate" :style="{ width: progression * 100 + 1 + '%' }"></div>
            </div>
            <blockquote>
              <div v-if="progression > 0">
                Votre progression actuelle pour cette étape est de
                {{ $n(progression, "percent") }}.
              </div>
              <br />
              <span class="chip white-text deep-orange">
                <i class="material-icons">link</i>
                Sites non reliés
                {{ membre.siteNotGrouped }}
              </span>
              <span class="chip white-text lime">
                <i class="material-icons">link</i>
                Sites reliés
                {{ membre.statSite.size - membre.siteNotGrouped }}
              </span>
              <br />
              <span v-if="progression !== 1 || !membre.siteNotGrouped">
                Pour finir cette étape, vous devez :
                <br />
                1- compléter les informations de chacun de vos périmètres de facturation
                <br />
                2- vérifier d'avoir bien associé l'ensemble de vos sites sur vos périmètres de facturation
                <br />
              </span>
              <br /><br />
            </blockquote>
            <span class="progression-finish" v-if="progression === 1">
              Cette étape est teCette étape est terminée. <br />
              Pour savoir ce qu'il vous reste à faire :
              <router-link :to="{ name: 'home' }" class="waves-effect waves-light btn blue darken-2">
                rendez-vous sur la page d'accueil
              </router-link>
            </span>
          </div>
          <center class="card-action" v-if="membre.status !== 'EXPORTED' && membre.status !== 'APPROVED'">
            <router-link
              :to="{
                name: 'groupements-detail',
                params: { groupementId: null, action: 'new' },
              }"
              class="waves-effect waves-light btn blue darken-2"
            >
              Ajouter un périmètre de facturation
            </router-link>
          </center>
        </article>
      </section>
    </div>
    <section class="row">
      <article class="col s12" id="validGroupGrid">
        <place-holder :notReady="!groupements">
          <grid
            v-if="groupements"
            :rows="groupements"
            :columns="columns"
            :search="true"
            :columnHeader="columnHeader"
            :columnsNoSearch="['Actions']"
            :columnsListFilter="columnsListFilter"
          >
            <template slot="state" slot-scope="props">
              <router-link
                class="chip white-text deep-orange progression validGroupAction tutorialNoBtn"
                v-if="props.row.state === 'TO_COMPLETE'"
                :to="{ name: 'groupement', params: { id: props.row.id } }"
                tag="span"
              >
                <i class="material-icons">edit</i>
                À&nbsp;completer
              </router-link>
              <router-link
                class="chip white-text deep-orange progression validGroupAction tutorialNoBtn"
                v-else-if="props.row.state === 'TO_CERTIFY' && membre.siteNotGrouped > 0"
                :to="{ name: 'groupements-link', params: { id: props.row.id } }"
                tag="span"
              >
                <i class="material-icons">edit</i>
                Vérifier l'association de vos sites
              </router-link>
              <router-link
                class="chip white-text amber progression validGroupAction tutorialNoBtn"
                v-else-if="props.row.state === 'TO_CERTIFY' && membre.siteNotGrouped === 0"
                :to="{ name: 'groupement', params: { id: props.row.id } }"
                tag="span"
              >
                <i class="material-icons">send</i>
                À&nbsp;certifier
              </router-link>
              <router-link
                class="chip white-text lime progression"
                v-else
                :to="{ name: 'groupement', params: { id: props.row.id } }"
                tag="span"
              >
                <i class="material-icons">check</i>
                Certifié
              </router-link>
            </template>
            <template slot="Actions" slot-scope="props">
              <center>
                <router-link
                  :to="{
                    name: 'groupements-link',
                    params: { id: props.row.id },
                  }"
                  class="chip white-text grey darken-2 progression validGroupAction tutorialNoBtn"
                  tag="span"
                >
                  Associer vos sites à une facture
                </router-link>
                <router-link
                  :to="{ name: 'groupement', params: { id: props.row.id } }"
                  class="tutorialNoBtn"
                  v-tooltip.auto="'Modifier le périmètre de facuration'"
                  id="editGroupment"
                >
                  <i class="material-icons">edit</i>
                </router-link>

                <router-link
                  :to="{
                    name: 'groupements-link',
                    params: { id: props.row.id },
                  }"
                  class="tutorialNoBtn"
                  v-tooltip.auto="'Associer des sites en masse'"
                  id="validGroupSites"
                >
                  <i class="material-icons cyan-text">link</i>
                </router-link>

                <router-link
                  :to="{
                    name: 'groupement',
                    params: { id: props.row.id },
                    query: { action: 'duplique' },
                  }"
                  v-tooltip.auto="'Dupliquer le périmètre'"
                  v-if="membre.status !== 'EXPORTED' && membre.status !== 'APPROVED'"
                >
                  <i class="material-icons blue-grey-text">control_point_duplicate</i>
                </router-link>

                <a
                  href
                  v-tooltip.auto="'Supprimer le périmètre'"
                  @click.prevent="remove(props.row)"
                  v-if="props.row.state !== 'CERTIFIED'"
                >
                  <i class="material-icons deep-orange-text">remove</i>
                </a>
              </center>
            </template>
          </grid>
        </place-holder>
      </article>
    </section>
  </main>
</template>

<script>
import Mixin from "../Mixin"
import Axios from "@/util/Axios"
import { mapState, mapMutations, mapActions } from "vuex"
import Grid from "@/components/common/Grid"
import _ from "lodash"
import Materialize from "materialize-css"
import PlaceHolder from "@/components/common/PlaceHolder"

export default {
  name: "groupements",
  mixins: [Mixin],
  components: {
    Grid,
    PlaceHolder,
  },
  data() {
    return {
      flow: "FACTURATION",
      groupements: null,
      columns: ["nom", "commune", "budget", "siret", "sitesSize", "state", "Actions"],
      columnHeader: {
        nom: "Nom",
        commune: "Commune",
        budget: "Nom du budget affecté",
        siret: "Siret",
        sitesSize: "Nombre de sites rattachés",
        state: "Statut",
      },
      columnsListFilter: {
        state: {
          TO_COMPLETE: "À completer",
          TO_CERTIFY: "Complété",
          CERTIFIED: "Certifié",
        },
      },
      nbTotal: 0,
      nb: 0,
      groupementSuppr: null,
    }
  },
  computed: {
    ...mapState(["membre", "user", "operation"]),
    progression() {
      if (this.groupements) {
        let progress = 0
        progress += 1 * (this.membre.progressionsGroupement.CERTIFIED || 0)
        progress += 0.5 * (this.membre.progressionsGroupement.TO_CERTIFY || 0)
        progress -= 0.1 * (this.membre.siteNotGrouped || 0)
        if (progress < 0) progress = 0
        if (progress > this.groupements.length) progress = this.groupements.length
        return Math.trunc(progress / this.groupements.length)
      }
      return 0
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    ...mapActions(["refreshStore"]),
    initialize() {
      this.refreshStore()
      Axios("get", "api/membres/" + this.membre.id + "/groupements").then((response) => {
        this.groupements = _.filter(response.data._embedded.groupements, {
          removed: false,
        })
      })
      this.refreshStore()
    },
    remove(groupement) {
      if (confirm('Voulez-vous vraiment supprimer de façon irréversible le périmètre "' + groupement.nom + '" ?')) {
        this.groupementSuppr = groupement
        Axios("get", "api/groupements/" + this.groupementSuppr.id + "/sites").then((response) => {
          let sites = _.filter(
            _.flatMap(response.data._embedded, (value) => value),
            { removed: false }
          )
          this.nbTotal = sites.length
          if (this.nbTotal) {
            this.nb = 0
            for (var key in sites) {
              Axios("patch", "api/sites/" + sites[key].id, {
                groupement: null,
              }).then((response) => {
                this.nb++
              })
            }
          } else {
            this.deleteGroupement()
          }
        })
      }
    },
    deleteGroupement() {
      Axios("delete", "api/groupements/" + this.groupementSuppr.id)
        .then((reponse) => {
          this.initialize()
          Materialize.toast("Le périmètre a bien été supprimé", 4000, "lime")
          this.nbTotal = 0
          this.nb = 0
          this.groupementSuppr = null
        })
        .catch((error) => {
          console.error(error)
          Materialize.toast("Erreur lors de la suppression", 4000, "amber white-text")
        })
    },
  },
  watch: {
    nb() {
      if (this.nbTotal && this.nbTotal === this.nb) {
        this.deleteGroupement()
      }
    },
  },
  created() {
    this.setBreadcrumbAdd({
      name: "Périmètres de facturation",
      action: () => {
        this.$router.push({
          name: "groupements",
        })
      },
    })
    this.initialize()
    this.$nextTick(() => {
      this.initialize()
    })
  },
}
</script>

<style lang="scss" scoped>
.progression {
  cursor: pointer;
}
.progression-finish {
  color: red;
}
</style>
