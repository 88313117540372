<template>
  <a-form :form="form">
    <mcma-card>
      <a-row type="flex" :gutter="12" class="mt-0 mb-0">
        <mcma-field
          :colSpan="24"
          type="switch"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="bioGaz"
          getFlow="name::sites"
          getPanel="name::Données techniques (gaz)"
          getField="type::bioGaz"
          :defaultValue="site.bioGaz"
          :switchColors="['green', 'red']"
        />
        <mcma-field
          :colSpan="24"
          type="select"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="bioGazValue"
          getFlow="name::sites"
          getPanel="name::Données techniques (gaz)"
          getField="type::bioGazList"
          :disabled="activeENRUnique"
          :defaultValue="defaultValueEnr || 0"
        />
      </a-row>
    </mcma-card>
    <mcma-card title="Option Tarifaire, Profil et CAR" class="mcma-card-in-tab">
      <a-row type="flex" :gutter="12" class="mt25">
        <mcma-button
          v-show="!site.added"
          name=" Actualiser mes données techniques"
          color="primary"
          leftIcon="undo-alt"
          :isFaIcon="true"
          @click="apiFournisseur()"
        />
      </a-row>
      <mcma-spin-box :spinning="loading" :tip="'Chargement en cours'">
        <a-row type="flex" :gutter="12" v-if="showCarcTech">
          <a-col :span="12">
            <div class="field-block-title">Caractéristiques techniques</div>
            <a-row type="flex" :gutter="12" class="mtb4">
              <mcma-field
                :colSpan="24"
                type="select"
                :form="form"
                :wasSubmit="wasSubmit"
                fieldName="optionTarifaire"
                getFlow="name::sites"
                getPanel="name::Données techniques (gaz)"
                getField="type::optionTarifaire"
                :defaultValue="site.optionTarifaire"
                :disabled="site.optionTarifaire != null"
              />
              <mcma-field
                :colSpan="24"
                type="select"
                :form="form"
                :wasSubmit="wasSubmit"
                fieldName="profil"
                getFlow="name::sites"
                getPanel="name::Données techniques (gaz)"
                getField="type::profil"
                :defaultValue="site.profil"
                :disabled="site.profil != null"
              />
            </a-row>
            <a-row type="flex" :gutter="12" class="mtb4">
              <mcma-field
                :colSpan="form.getFieldValue('optionTarifaire') === 'T_4' ? 12 : 24"
                type="input"
                :form="form"
                :wasSubmit="wasSubmit"
                fieldName="car"
                :defaultValue="site.car != null ? site.car : null"
                getFlow="name::sites"
                getPanel="name::Données techniques (gaz)"
                getField="type::car"
                :disabled="site.car != null"
              />
              <mcma-field
                v-if="form.getFieldValue('optionTarifaire') === 'T_4'"
                :colSpan="12"
                type="input"
                :form="form"
                :wasSubmit="wasSubmit"
                fieldName="cja"
                :defaultValue="site.cja ? site.cja.toString() : null"
                label="CJA (MWh)"
                :required="true"
                :disabled="site.cja !== null"
                numberOnly="decimal"
              />
            </a-row>
          </a-col>
        </a-row>
        <a-row type="flex" :gutter="12" class="mt0">
          <mcma-field
            :colSpan="12"
            type="input"
            :form="form"
            getFlow="name::sites"
            getPanel="name::Données techniques (gaz)"
            getField="type::matriculeCompteurGaz"
            :wasSubmit="wasSubmit"
            fieldName="matriculeCompteur"
            :defaultValue="site.matriculeCompteur"
            :disabled="site.matriculeCompteur !== null"
          />
        </a-row>
        <a-row>
          <mcma-field
            :colSpan="12"
            type="select"
            :form="form"
            getFlow="name::sites"
            getPanel="name::Données techniques (gaz)"
            getField="type::modeReleve"
            :wasSubmit="wasSubmit"
            :defaultValue="site.modeReleve"
            fieldName="modeReleve"
            :disabled="site.modeReleve !== null"
          />
        </a-row>
      </mcma-spin-box>
      <a-row type="flex" :gutter="12">
        <a-col :span="24">
          <div class="button-group-inline">
            <mcma-button
              name="Valider les informations"
              class="ml12"
              color="success"
              leftIcon="save"
              :isFaIcon="true"
              @click="submit"
            />
          </div>
        </a-col>
      </a-row>
    </mcma-card>
  </a-form>
</template>

<script>
import Axios from "@/util/Axios"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaField from "@/components/mcma/common/McmaField"
import { mapState } from "vuex"
import { getDynamiqueFields } from "@/util/Helpers/DynamiqueFields"
import { RV_RESULT, RV_GAZ } from "./RG_GAZ"
import Materialize from "materialize-css"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"

export default {
  name: "etape_2_gaz",
  props: ["site"],
  components: {
    McmaCard,
    McmaField,
    McmaButton,
    McmaSpinBox,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      wasSubmit: false,
      loading: false,
    }
  },
  computed: {
    ...mapState(["operation", "membre"]),
    canUpdate() {
      return (fieldsValue) => {
        return !!(
          fieldsValue.optionTarifaire &&
          fieldsValue.profil &&
          fieldsValue.car &&
          (fieldsValue.optionTarifaire === "T_4" ? fieldsValue.cja : true)
        )
      }
    },
    showCarcTech() {
      return this.showCarcTechM()
    },

    activeENRUnique() {
      const enrUniqueSite = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Localisation",
        "type::enrUniqueSite"
      )
      return enrUniqueSite ? enrUniqueSite.displayed : false
    },
    defaultValueEnr() {
      let enr = 0
      enr = this.activeENRUnique && this.membre.enrUniqueSite ? this.membre.enrUniqueSite : this.site.bioGazValue
      return enr
    },
  },
  methods: {
    showCarcTechM() {
      const typeOption = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (gaz)",
        "type::optionTarifaire"
      )
      const typeProfil = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (gaz)",
        "type::profil"
      )
      const typeCar = getDynamiqueFields(this.operation, "name::sites", "name::Données techniques (gaz)", "type::car")
      return typeOption && typeProfil && typeCar
        ? typeOption.displayed || typeProfil.displayed || typeCar.displayed
        : true
    },
    initialize() {
      this.form.getFieldDecorator("bioGaz")
      this.form.getFieldDecorator("pce")
      this.form.getFieldDecorator("optionTarifaire")
      this.form.getFieldDecorator("profil")
      this.form.getFieldDecorator("car")
      this.form.getFieldDecorator("cja")
      this.form.getFieldDecorator("commentaire")
      this.form.setFieldsValue({
        bioGaz: this.site.bioGaz,
        optionTarifaire: this.site.optionTarifaire,
        profil: this.site.profil,
        car: this.site.car ? this.site.car.toString() : null,
        cja: this.site.cja ? this.site.cja.toString() : null,
        commentaire: this.site.commentaire,
        matriculeCompteur: this.site.matriculeCompteur,
        modeReleve: this.site.modeReleve,
      })
    },
    submit() {
      this.form.validateFields((errors, fieldsValue) => {
        if (!this.showCarcTechM()) {
          this.process(fieldsValue)
        } else {
          if (errors) {
            this.$notification.warning({
              message: "Formulaire invalide",
              description: `Veuillez-vous assurez de remplir tous les champs requis.`,
            })
          } else {
            this.validate(fieldsValue)
          }
        }
      })
    },
    validate(fieldsValue) {
      const errors = []
      const derogables = []
      let valid = true

      for (const rv in RV_GAZ) {
        const result = RV_GAZ[rv](fieldsValue.optionTarifaire, fieldsValue.profil)
        if (result.state === RV_RESULT.KO) {
          errors.push(result.message)
          valid = false
        } else if (result.state === RV_RESULT.DEROGABLE) {
          derogables.push(result.message)
        }
      }

      if (valid && derogables.length) {
        let message = "Les paramètres de votre site ne respectent pas les règles suivantes :\n\n"
        for (const derogable of derogables) {
          message += "  - " + derogable + "\n"
        }
        message += "\nVoulez-vous vraiment déroger à ces règles?"

        this.confirmDerogation(message, fieldsValue)
      } else if (!valid && errors.length) {
        this.$notification.warning({
          message: "Formulaire invalide",
          description: `Veuillez-vous assurez de remplir tous les champs requis.`,
        })
      } else {
        this.process(fieldsValue)
      }
    },
    apiFournisseur() {
      this.loading = true
      Axios("get", "fournisseur/point/gaz/" + this.site.rae)
        .then((response) => {
          this.loading = false
          let responseApi = response.data
          this.form.setFieldsValue({
            car: responseApi.car !== null ? responseApi.car.toString() : this.site.car.toString(),
            optionTarifaire:
              responseApi.optionTarifaire !== null ? responseApi.optionTarifaire : this.site.optionTarifaire,
            profil: responseApi.profil !== null ? responseApi.profil : this.site.profil,
            modeReleve: responseApi.modeReleve !== null ? responseApi.modeReleve : this.site.modeReleve,
            matriculeCompteur: responseApi.matricule !== null ? responseApi.matricule : this.site.matriculeCompteur,
          })
          Materialize.toast("Mise à jour des données gaz", 4000, "lime white-text")
        })
        .catch((response) => {
          this.loading = false
          console.error(response)
          Materialize.toast(
            "La référence saisie n’est pas reconnue par Enedis et GRDF, veuillez vérifier la référence ou préciser le nom du gestionnaire de réseau",
            4000,
            "amber white-text"
          )
        })
    },

    confirmDerogation(message, fieldsValue) {
      const that = this
      this.$confirm({
        title: `Déroger à la règle ?`,
        content: message,
        okText: "Oui",
        okType: "success",
        cancelText: "Non",
        onOk() {
          that.process(fieldsValue)
        },
        onCancel() {
          console.log(
            `%c Cancel dérogation rule`,
            "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
          )
          that.$notification.warning({
            message: "Correction des règles",
            description: `Veuillez corriger les règles non respecté pour finir l'operation correctement.`,
          })
        },
      })
    },
    process(fieldsValue) {
      Axios("patch", "api/sites/" + this.site.id, {
        ongletParametre: true,
        bioGaz: fieldsValue.bioGaz,
        bioGazValue: fieldsValue.bioGazValue,
        pce: fieldsValue.pce,
        optionTarifaire: fieldsValue.optionTarifaire,
        profil: fieldsValue.profil,
        car: fieldsValue.car,
        cja: fieldsValue.optionTarifaire === "T_4" ? fieldsValue.cja : null,
        commentaire: fieldsValue.commentaire,
        multiSite: fieldsValue.optionTarifaire !== "T_3",
        matriculeCompteur: fieldsValue.matriculeCompteur,
        modeReleve: fieldsValue.modeReleve,
      })
        .then((response) => {
          this.$emit("save", response.data.id)
          this.$emit("update", response.data)
          this.$notification.success({
            message: "Paramètres sauvegardées !",
            description: `Les paramètres ont bien été sauvegardées`,
          })
        })
        .catch((error) => {
          console.error(error)
          this.$notification.error({
            message: "Erreur serveur",
            description: "Une erreur server est survenue, veuillez contacter un administrateur.",
          })
        })
    },
  },
  watch: {
    site() {
      this.initialize()
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style lang="scss"></style>
