<template>
  <main class="row">
    <section
      class="col s12 m6"
      v-for="(tuto, index) in tutorials"
      :key="index"
      v-if="
        ((!tuto.admin && user.role === 'CLIENT') ||
          user.role === 'SUPER_ADMIN' ||
          user.role === 'ADMIN' ||
          user.role === 'COORDINATEUR' ||
          user.role === 'COORDINATEUR_AVANCE') &&
        (tuto.flow ? getFlow.includes(tuto.flow) : true)
      "
    >
      <article class="card hoverable pointer" @click="goToTuto(tuto)">
        <div class="card-content">
          <span class="card-title">{{ tuto.title }}</span>
          <blockquote>{{ tuto.description }}</blockquote>
        </div>
      </article>
    </section>
  </main>
</template>

<script>
import { mapMutations, mapActions, mapState, mapGetters } from "vuex"

export default {
  name: "tutorials",
  data() {
    return {
      tutorials: [
        {
          name: "validSite",
          title: "Valider l'ensemble de mes sites",
          description:
            "La validation des sites est l'un des objectifs à atteindre afin de pouvoir certifier un membre. Découvrez comment faire étape par étape.",
          link: "home",
          admin: false,
          memberRequired: true,
          flow: "PERIMETRE",
        },
        {
          name: "validGroup",
          title: "Valider l'ensemble de mes périmètres de facturation",
          description:
            "L'association de 100% des sites à des périmètres de facturation aisni que la validation de ceux-ci sont des objectifs à atteindre afin de pouvoir certifier un membre. Découvrez comment faire étape par étape.",
          link: "home",
          admin: false,
          memberRequired: true,
          flow: "FACTURATION",
        },
        {
          name: "validInterlocutor",
          title: "Ajouter et valider un interlocuteur",
          description: "Afin de pouvoir certifier un membre. Découvrez comment faire étape par étape.",
          link: "home",
          admin: false,
          memberRequired: true,
          flow: "SERVICES",
        },
        {
          name: "validService",
          title: "Ajouter et valider un service",
          description:
            "Afin de pouvoir certifier un membre, il est nécessaire que celui-ci possède au moins un service. Découvrez comment faire étape par étape.",
          link: "home",
          admin: false,
          memberRequired: true,
          flow: "SERVICES",
        },
        {
          name: "uploadBill",
          title: "Déposer une facture",
          description:
            "Tout au long du processus de certification d'un membre, il vous est possible de déposer des factures. Découvrez comment faire étape par étape.",
          link: "home",
          admin: false,
          memberRequired: true,
          flow: "DOCUMENTS",
        },
        {
          name: "signDocument",
          title: "Signer une pièce",
          description:
            "Tout au long du processus de certification d'un membre, il vous est possible de télécharger des pièces pour les signer. Découvrez comment faire étape par étape.",
          link: "home",
          admin: false,
          memberRequired: true,
          flow: "DOCUMENTS",
        },
        {
          name: "addUser",
          title: "Ajouter un utilisateur",
          description: "Vous avez besoin d'ajouter un utilisateur ? Découvrez comment faire étape par étape.",
          link: "home",
          admin: true,
          memberRequired: false,
        },
        {
          name: "editUserLinks",
          title: "Associer des membres à un utilisateur",
          description:
            "Vous avez besoin d'associer des membres à un utilisateur ? Découvrez comment faire étape par étape.",
          link: "home",
          admin: true,
          memberRequired: false,
        },
        {
          name: "addMember",
          title: "Ajouter un membre",
          description: "Vous avez besoin d'ajouter un membre ? Découvrez comment faire étape par étape.",
          link: "home",
          admin: true,
          memberRequired: false,
        },
        {
          name: "editMemberLinks",
          title: "Associer des sites à un membre",
          description: "Vous avez besoin d'associer des sites à un membre ? Découvrez comment faire étape par étape.",
          link: "home",
          admin: true,
          memberRequired: false,
          flow: "PERIMETRE",
        },
        {
          name: "addDocument",
          title: "Ajouter une pièce",
          description: "Vous avez besoin d'ajouter une pièce à signer ? Découvrez comment faire étape par étape.",
          link: "home",
          admin: true,
          memberRequired: false,
          flow: "DOCUMENTS",
        },
      ],
    }
  },
  computed: {
    ...mapState(["user", "membre", "intro", "operation"]),
    ...mapGetters(["getFlow"]),
  },
  methods: {
    ...mapMutations(["setCurrentTutorial", "setTutorialIsPlaying", "setBreadcrumbAdd", "setState"]),
    ...mapActions(["tutoAddSteps", "tutoReset"]),
    goToTuto(tuto) {
      this.setTutorialIsPlaying(true)
      this.$router.push({
        name: tuto.link,
      })
      this.setTutorialIsPlaying(false)
      this.$nextTick(() => {
        setTimeout(() => {
          this.setCurrentTutorial(tuto.name)
          if (tuto.memberRequired) {
            this.$nextTick(() => {
              if (
                (!this.membre || this.membre === null) &&
                (this.user.role === "SUPER_ADMIN" ||
                  this.user.role === "ADMIN" ||
                  this.user.role === "COORDINATEUR" ||
                  this.user.role === "COORDINATEUR_AVANCE")
              ) {
                this.tutoReset("adminToMember")
              } else if (!this.membre || this.membre === null) {
                this.tutoReset("requiresMember")
              }
            })
          } else if (this.membre && this.membre !== null) {
            this.setState({
              stateName: "membre",
              value: null,
            })
          }
        }, 500)
      })
    },
  },
  created() {
    this.setBreadcrumbAdd({
      name: "M'accompagner",
      action: () => {
        this.$router.push({
          name: "tutorials",
        })
      },
    })
  },
}
</script>

<style lang="scss" scoped>
.card-title {
  font-weight: 400;
}
</style>
