<template>
  <main>
    <place-holder :notReady="!groupement">
      <section class="container" v-if="groupement">
        <div class="row">
          <div class="col s12">
            <article class="card">
              <div class="card-content">
                <span class="card-title">
                  Gestion des rattachements entre les Périmètres de facturation et leurs Sites
                </span>
                <blockquote>
                  Rattacher unitairement ou massivement vos sites au périmètre de facturation "{{ groupement.nom }}" via
                  le bouton
                  <a class="chip green" v-tooltip.auto="'Ajouter au périmètre de facturation'"
                    ><i class="material-icons small white-text">send</i></a
                  >.<br />
                  Détacher les unitairement ou massivement grace au bouton
                  <a class="chip red" v-tooltip.auto="'Supprimer du périmètre de facturation'"
                    ><i class="material-icons white-text">delete</i></a
                  >
                </blockquote>
                <div v-if="groupement.monoSite && membre.status !== 'EXPORTED' && membre.status !== 'APPROVED'">
                  <i class="material-icons">warning</i> Ce périmètre de facturation contient un site configuré en
                  "facture monosite". Vous ne pouvez donc pas lui affecter d'autres sites tant que cela est le cas.
                </div>
              </div>
              <center class="card-action" v-if="membre.status !== 'EXPORTED' && membre.status !== 'APPROVED'">
                <button
                  type="button"
                  class="waves-effect waves-light btn light-green"
                  @click="submit"
                  id="validGroupValidSites"
                >
                  <i class="material-icons">save</i>
                  Valider
                </button>
                <button type="button" class="waves-effect waves-light btn blue darken-2" @click="initialize">
                  <i class="material-icons">remove</i>
                  Réinitialiser
                </button>
              </center>
            </article>
          </div>
        </div>
      </section>
    </place-holder>
    <section class="row">
      <article class="col s6" id="validGroupSitesToAdd">
        <place-holder :notReady="!sites">
          <grid
            v-if="sites"
            name="Sites disponibles"
            :rows="sites"
            :columns="columns"
            :search="true"
            :columnHeader="columnHeader"
            :columnsNoSearch="['Actions']"
            :columnsListFilter="columnsListFilter"
            :multiSelect="!groupement.monoSite && membre.status !== 'EXPORTED' && membre.status !== 'APPROVED'"
            @select="(value) => (sitesSelected = value)"
          >
            <template slot="header">
              <a
                href
                @click.prevent="add(sitesSelected)"
                class="waves-effect waves-light btn light-green"
                v-if="!groupement.monoSite && membre.status !== 'EXPORTED' && membre.status !== 'APPROVED'"
                id="validGroupSiteCheck"
              >
                <i class="material-icons">send</i>
                Rattacher en une fois les sites sélectionnés ci-dessous au périmètre de facturation.
              </a>
            </template>
            <template slot="multiSite" slot-scope="props">
              <span v-if="props.row.multiSite"> Oui </span>
              <span v-else> Non </span>
              <i class="material-icons pointer" @click="toggleMono(props.row)">edit</i>
            </template>
            <template slot="state" slot-scope="props">
              <router-link
                :to="{ name: 'site', params: { id: props.row.id } }"
                class="chip white-text deep-orange"
                v-if="props.row.state === 'TO_COMPLETE'"
              >
                <i class="material-icons">edit</i>
              </router-link>
              <router-link
                :to="{ name: 'site', params: { id: props.row.id } }"
                class="chip white-text amber"
                v-else-if="props.row.state === 'TO_CERTIFY'"
              >
                <i class="material-icons">send</i>
              </router-link>
              <router-link :to="{ name: 'site', params: { id: props.row.id } }" class="chip white-text lime" v-else>
                <i class="material-icons">check</i>
              </router-link>
            </template>
            <center slot="Actions" slot-scope="props">
              <a
                href
                @click.prevent="add([props.row])"
                class="validGroupSiteAdd chip green"
                v-tooltip.auto="'Ajouter au périmètre de facturation'"
              >
                <i class="material-icons small white-text">send</i>
              </a>
            </center>
          </grid>
        </place-holder>
      </article>
      <article class="col s6" id="validGroupSitesToRemove">
        <place-holder :notReady="!sitesGrouped">
          <grid
            v-if="sitesGrouped"
            name="Sites associés à ce périmètre de facturation"
            :rows="sitesGrouped"
            :columns="columnsGrouped"
            :search="true"
            :columnHeader="columnHeader"
            :columnsNoSearch="['Actions']"
            :columnsListFilter="columnsListFilter"
            :multiSelect="membre.status !== 'EXPORTED' && membre.status !== 'APPROVED'"
            @select="(value) => (sitesGroupedSelected = value)"
          >
            <template slot="header">
              <a
                href
                @click.prevent="remove(sitesGroupedSelected)"
                class="waves-effect waves-light btn deep-orange"
                v-if="membre.status !== 'EXPORTED' && membre.status !== 'APPROVED'"
                id="validGroupSiteRemCheck"
              >
                <i class="material-icons">delete</i>
                Détacher en une fois les sites sélectionnés ci-dessous.
              </a>
            </template>
            <template slot="multiSite" slot-scope="props">
              <span v-if="props.row.multiSite"> Oui </span>
              <span v-else> Non </span>
              <i class="material-icons pointer" @click="toggleMono(props.row)">edit</i>
            </template>
            <template slot="state" slot-scope="props">
              <router-link
                :to="{ name: 'site', params: { id: props.row.id } }"
                class="chip white-text deep-orange"
                v-if="props.row.state === 'TO_COMPLETE'"
              >
                <i class="material-icons">edit</i>
              </router-link>
              <router-link
                :to="{ name: 'site', params: { id: props.row.id } }"
                class="chip white-text amber"
                v-else-if="props.row.state === 'TO_CERTIFY'"
              >
                <i class="material-icons">send</i>
              </router-link>
              <router-link :to="{ name: 'site', params: { id: props.row.id } }" class="chip white-text lime" v-else>
                <i class="material-icons">check</i>
              </router-link>
            </template>
            <template slot="Actions" slot-scope="props">
              <center>
                <a
                  href
                  @click.prevent="remove([props.row])"
                  class="validGroupSiteRem chip red"
                  v-tooltip.auto="'Supprimer du périmètre de facturation'"
                >
                  <i class="material-icons white-text">delete</i>
                </a>
              </center>
            </template>
          </grid>
        </place-holder>
      </article>
    </section>
  </main>
</template>

<script>
import Mixin from "../Mixin"
import Axios from "@/util/Axios"
import { mapState, mapMutations } from "vuex"
import Grid from "@/components/common/Grid"
import _ from "lodash"
import Materialize from "materialize-css"
import PlaceHolder from "@/components/common/PlaceHolder"

export default {
  name: "groupement-link",
  mixins: [Mixin],
  components: {
    Grid,
    PlaceHolder,
  },
  data() {
    return {
      flow: "FACTURATION",
      groupement: null,
      sitesGroupedInitial: null,
      sitesGrouped: null,
      sitesGroupedSelected: [],
      sites: null,
      sitesSelected: [],
      columnHeader: {
        nom: "Nom",
        rae: "RAE",
        // multiSite: 'Multisite',
        state: "Progression",
        groupementNom: "Périmètre",
      },
      columnsListFilter: {
        state: {
          TO_COMPLETE: "À completer",
          TO_CERTIFY: "Complété",
          CERTIFIED: "Exporté",
        },
      },
    }
  },
  computed: {
    ...mapState(["membre", "user", "operation"]),
    columns() {
      if (this.groupement.monoSite || this.membre.status === "EXPORTED" || this.membre.status === "APPROVED") {
        return ["nom", "rae", "multiSite", "groupementNom", "state"]
      }
      return ["nom", "rae", "multiSite", "groupementNom", "state", "Actions"]
    },
    columnsGrouped() {
      if (this.membre.status === "EXPORTED" || this.membre.status === "APPROVED") {
        return ["nom", "rae", "multiSite", "state"]
      }
      return ["nom", "rae", "multiSite", "state", "Actions"]
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    add(sites) {
      sites.forEach((site) => {
        site.selected = false
      })
      this.sites = [..._.pullAll(this.sites, sites)]
      this.sitesGrouped.push(...sites)
      if (sites === this.sitesSelected) {
        this.sitesSelected = []
      } else {
        this.sitesSelected = [..._.pullAll(this.sitesSelected, sites)]
      }
    },
    remove(sites) {
      sites.forEach((site) => {
        site.selected = false
      })
      this.sitesGrouped = [..._.pullAll(this.sitesGrouped, sites)]
      this.sites.push(...sites)
      if (sites === this.sitesGroupedSelected) {
        this.sitesGroupedSelected = []
      } else {
        this.sitesSelected = [..._.pullAll(this.sitesGroupedSelected, sites)]
      }
    },
    initialize() {
      this.groupement = null
      this.sites = null
      this.sitesGrouped = null
      Axios("get", "api/groupements/" + this.$route.params.id).then((response) => {
        this.groupement = response.data
        this.setBreadcrumbAdd({
          name: "Liens pour : " + this.groupement.nom || this.groupement.siret,
          action: () => {},
        })
      })
      Axios("get", "api/membres/" + this.membre.id + "/sites").then((response) => {
        this.sites = _.filter(
          _.flatMap(response.data._embedded, (value) => value),
          { removed: false }
        )
        this.recadrage()
      })
      Axios("get", "api/groupements/" + this.$route.params.id + "/sites").then((response) => {
        this.sitesGrouped = _.filter(
          _.flatMap(response.data._embedded, (value) => value),
          { removed: false }
        )
        this.sitesGroupedInitial = _.map(this.sitesGrouped, (site) => {
          return site.id
        })
        this.recadrage()
      })
    },
    recadrage() {
      if (this.sites && this.sitesGrouped) {
        this.sites = _.differenceBy(this.sites, this.sitesGrouped, "id")
      }
    },
    submit() {
      let valid = true
      if (this.sitesGrouped.length > 1) {
        for (let key in this.sitesGrouped) {
          if (!this.sitesGrouped[key].multiSite) {
            valid = false
            break
          }
        }
      }

      if (valid) {
        for (let key in this.sites) {
          const site = this.sites[key]
          if (this.sitesGroupedInitial.includes(site.id)) {
            Axios("patch", "api/sites/" + site.id, {
              groupement: null,
            })
          }
        }
        for (let key in this.sitesGrouped) {
          const site = this.sitesGrouped[key]
          if (!this.sitesGroupedInitial.includes(site.id)) {
            Axios("patch", "api/sites/" + site.id, {
              groupement: process.env.VUE_APP_API_URL + "/api/groupements/" + this.groupement.id,
            })
          }
        }
        Materialize.toast("Liaison sites / facturation sauvegardée", 4000, "lime")
        this.$router.push({
          name: "groupements",
        })
      } else {
        Materialize.toast(
          "Vous ne pouvez pas lier un site à mono facturation avec d'autres sites. Vous devez créer un périmètre de facturation spécifique pour chacun de vos sites à facturation mono-site",
          20000,
          "deep-orange"
        )
      }
    },
    toggleMono(site) {
      console.log(site)
      if (
        window.confirm(
          "Voulez vous vraiment passer le site " + site.nom + " en " + (site.multiSite ? "monosite" : "multisite") + "?"
        )
      ) {
        Axios("patch", "api/sites/" + site.id, {
          multiSite: !site.multiSite,
        })
          .then((response) => {
            Materialize.toast("Site modifié", 4000, "lime")
            this.initialize()
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style lang="scss" scoped>
.material-icons.pointer {
  transition: 0.3s;
  border-radius: 15px;
  padding: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}
</style>
