import { render, staticRenderFns } from "./AddAdmin.vue?vue&type=template&id=f4dbdabe&scoped=true"
import script from "./AddAdmin.vue?vue&type=script&lang=js"
export * from "./AddAdmin.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4dbdabe",
  null
  
)

export default component.exports