<template>
  <main class="container" v-if="membre">
    <section v-if="canCertify" class="card">
      <article class="card-content">
        <div class="card-title">Validation du membre "{{ membre.nom }}"</div>
        <blockquote>
          Êtes-vous sur de vouloir valider les informations de ce membre ?
          <br />
          Vous ne pourrez plus modifier ni ajouter des sites, périmètres de facturation et interlocuteurs, via cette
          application après validation.
        </blockquote>
      </article>
      <center class="card-action">
        <button type="button" class="waves-effect waves-light btn light-green" @click="validate">
          Oui, je valide ce membre
        </button>
        <router-link type="button" class="waves-effect waves-light btn blue darken-2" :to="{ name: 'sites' }">
          Non, je ne veux pas encore valider ce membre
        </router-link>
      </center>
    </section>
    <section v-else class="card-panel white-text deep-orange">Ce membre ne peut pas être validé.</section>
  </main>
</template>
<script>
import { mapState, mapMutations } from "vuex"

export default {
  name: "validation",
  computed: {
    ...mapState(["membre", "user", "operation"]),
    canCertify() {
      if (this.membre.progression === 1) {
        if (this.membre.status === "IN_PROGRESS") {
          return true
        } else if (this.user.role !== "CLIENT" && this.membre.status === "INITIALISED") {
          return true
        }
      }
      return false
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
  },
  created() {
    this.setBreadcrumbAdd({
      name: "Validation du membre",
      action: () => {},
    })
    this.validate()
  },
}
</script>

<style lang="scss" scoped></style>
