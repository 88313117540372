<template>
  <a-config-provider :locale="french">
    <section class="app" :class="{ notConnected: !connected }">
      <app-header v-if="connected" />

      <router-view v-if="connected" />
      <login v-else />
    </section>
  </a-config-provider>
</template>

<script>
import AppHeader from "@/components/mcma/header/Header"
import Login from "@/components/layout/Login"
import { mapState, mapMutations, mapActions } from "vuex"
import tutorials from "@/assets/tutorials"
import _ from "lodash"

import french from "ant-design-vue/lib/locale-provider/fr_FR"
import moment from "moment"
import "moment/locale/fr"

moment.locale("fr")

export default {
  name: "app",
  components: {
    AppHeader,
    Login,
  },
  data() {
    return {
      french,
      introIsChanging: false,
    }
  },
  computed: {
    ...mapState(["connected", "currentTutorial", "intro", "user", "tutorialIsPlaying"]),
  },
  methods: {
    ...mapMutations(["setIntro", "setCurrentTutorial", "setTutorialIsPlaying"]),
    ...mapActions(["tutoNextStep", "setAppWindowsParams"]),
  },
  watch: {
    currentTutorial(value) {
      this.$nextTick(() => {
        setTimeout(() => {
          if (tutorials[_.camelCase("get-" + value)]) {
            this.setIntro(this.$intro())
            this.intro
              .setOptions({
                nextLabel: "Suivant",
                prevLabel: "Précédent",
                skipLabel: "Annuler",
                doneLabel: "Terminer",
                scrollTo: "tooltip",
                keyboardNavigation: false,
                showStepNumbers: false,
                steps: tutorials[_.camelCase("get-" + value)]() ? [...tutorials[_.camelCase("get-" + value)]()] : [],
              })
              .onbeforechange((element) => {
                if (element.classList.value.includes("tutoDone")) {
                  const currentTuto = this.currentTutorial
                  const tutoClass = element.classList.value.match(/tutoDone\w*/)[0]
                  this.intro.setOption("doneLabel", tutoClass.match(/tutoDone(.*?)ToRoute/)[1])
                  this.intro.oncomplete(() => {
                    this.$router.push({
                      name: _.camelCase(tutoClass.match(/ToRoute(.*?)$/)[1]),
                    })
                    this.tutoNextStep(currentTuto)
                  })
                }
              })
              .onafterchange((element) => {
                if (element.classList.value.includes("tutorialNoBtn")) {
                  document.getElementsByClassName("introjs-skipbutton")[0].style.display = "none"
                  document.getElementsByClassName("introjs-bullets")[0].style.display = "none"
                  if (document.getElementsByClassName("introjs-prevbutton")[0]) {
                    document.getElementsByClassName("introjs-prevbutton")[0].style.display = "none"
                    document.getElementsByClassName("introjs-nextbutton")[0].style.display = "none"
                  }
                } else {
                  document.getElementsByClassName("introjs-skipbutton")[0].style.display = "inline-block"
                  document.getElementsByClassName("introjs-bullets")[0].style.display = "block"
                  if (document.getElementsByClassName("introjs-prevbutton")[0]) {
                    document.getElementsByClassName("introjs-prevbutton")[0].style.display = "inline-block"
                    document.getElementsByClassName("introjs-nextbutton")[0].style.display = "inline-block"
                  }
                }
                if (element.classList.value.includes("tutorialNoOverlay")) {
                  document.getElementsByClassName("introjs-helperLayer")[0].style.display = "none"
                  for (const overlay of document.getElementsByClassName("introjs-overlay")) {
                    overlay.classList.add("noOverlay")
                    overlay.style.display = "none"
                  }
                  document.getElementsByClassName("introjs-overlay")[1].style.display = "none"
                } else {
                  for (const overlay of document.getElementsByClassName("introjs-overlay")) {
                    overlay.classList.remove("noOverlay")
                    overlay.style.display = "block"
                  }
                  document.getElementsByClassName("introjs-helperLayer")[0].style.display = "block"
                }
                if (
                  this.intro._options.steps[this.intro._currentStep].tooltipClass &&
                  this.intro._options.steps[this.intro._currentStep].tooltipClass.includes("tutoExpects")
                ) {
                  const route =
                    this.intro._options.steps[this.intro._currentStep].tooltipClass.match(/tutoExpects_(.*?)$/)[1]
                  if (this.$route.name !== route) {
                    this.intro.exit(true)
                    this.setCurrentTutorial("lost")
                  }
                }
              })
              .start()
            this.intro.onexit(() => {
              if (!this.tutorialIsPlaying) {
                this.setCurrentTutorial(null)
              }
            })
          }
        }, 200)
      })
    },
    "$route.name"() {
      this.$nextTick(() => {
        if (!this.tutorialIsPlaying && this.currentTutorial && this.currentTutorial !== null) {
          this.$nextTick(() => {
            this.tutoNextStep()
          })
        }
      })
    },
  },
  created() {
    this.setAppWindowsParams()
    window.addEventListener("resize", this.setAppWindowsParams)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setAppWindowsParams)
  },
}
</script>

<style lang="scss">
body {
  background-color: #f5f5f5;
}
main {
  padding-top: 35px !important;
  overflow-y: scroll;
  height: calc(100vh - 130px);
}
.material-icons {
  vertical-align: middle;
}
.notConnected {
  height: 100vh;
}

/*@media screen and (max-width: 1280px) {*/
/*  body {*/
/*    zoom: 0.9;*/
/*    -ms-zoom: 0.9;*/
/*    -webkit-zoom: 0.9;*/
/*  }*/
/*}*/
/*@media screen and (max-width: 1080px) {*/
/*  body {*/
/*    zoom: 0.8;*/
/*    -ms-zoom: 0.8;*/
/*    -webkit-zoom: 0.8;*/
/*  }*/
/*}*/

.noOverlay {
  display: none;
}

// scss
// examples of overriding sass variables
$ag-cell-horizontal-padding: 5px;
$ag-tool-panel-background-color: #fff;
$ag-primary-color: #ea6919;
$ag-accent-color: #2196f3;

@import "~@ag-grid-enterprise/all-modules/dist/styles/ag-grid.scss";
@import "~@ag-grid-enterprise/all-modules/dist/styles/ag-theme-material/sass/ag-theme-material.scss";
</style>
