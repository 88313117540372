<template>
  <div class="mcma-table">
    <div class="header">
      <div class="selected-sentence" :class="{ show: hasSelected }" v-if="dataSource.length > 0">
        <template>{{ selectedSitesSentence }}</template>
      </div>
    </div>
    <a-table
      :rowSelection="rowSelection"
      :columns="getColumns"
      :dataSource="dataSource"
      :scroll="{ x: true }"
      :pagination="{
        defaultPageSize: 5,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '50', '100'],
      }"
      @change="onChange"
      :locale="getLocale"
      bordered
    >
      <div
        slot="filterDropdown"
        slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        style="padding: 8px"
      >
        <a-input
          v-ant-ref="(c) => (searchInput[column.dataIndex] = c)"
          :placeholder="`Recherche par ${column.title.toLowerCase()}`"
          :value="selectedKeys[0]"
          @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(column.dataIndex, selectedKeys, confirm)"
          style="width: 188px; margin-bottom: 8px; display: block"
        />
        <a-button
          type="primary"
          @click="() => handleSearch(column.dataIndex, selectedKeys, confirm)"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
        ></a-button>
        <a-button
          @click="() => handleReset(column.dataIndex, clearFilters)"
          icon="sync"
          size="small"
          style="width: 90px"
        ></a-button>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :class="{ 'blue-color': filtered }" />
      <template slot="nom" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="nom" :text="text" />
      </template>
      <template slot="rae" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="rae" :text="text" />
      </template>
      <template slot="codeSite" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="codeSite" :text="text" />
      </template>
      <template slot="codeGroupement" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="codeGroupement" :text="text" />
      </template>
      <template slot="adresse" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="adresse" :text="text" />
      </template>
      <template slot="commune" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="commune" :text="text" />
      </template>
      <template slot="interlocuteur" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="interlocuteur" :text="text" />
      </template>
      <div slot="fluide" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" :isGenericField="true" fieldName="fluide" :text="text" />
      </div>
      <div slot="status" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" :isGenericField="true" fieldName="status" :text="text" />
      </div>
      <div slot="actions" slot-scope="props">
        <div class="cell-actions">
          <mcma-button
            :name="getStateByValue(props.state).text"
            :color="getStateByValue(props.state).classColor"
            :leftImage="getStateByValue(props.state).whiteIcon"
            @click="goTo({ name: 'sites-detail', params: { siteId: props.id } })"
          />
        </div>
      </div>
    </a-table>

    <mcma-code> sites: {{ sites }} dataSource: {{ dataSource }} </mcma-code>
  </div>
</template>

<script>
import Vue from "vue"
import McmaIcon from "@/components/mcma/common/McmaIcon"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaSearchTableFilter from "@/components/mcma/common/McmaSearchTableFilter"
import McmaCode from "@/components/mcma/common/McmaCode"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import Constants from "@/util/Constants"
import Axios from "@/util/Axios"
import _ from "lodash"
import { accorization } from "@/util/Helpers"
import { getDynamiqueFields } from "@/util/Helpers/DynamiqueFields"

Vue.component("site-empty-without-filter", {
  template: `<a-empty image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original">
                <span slot="description"> Aucun site </span>
              </a-empty>`,
})

Vue.component("site-empty-with-filter", {
  template: `<a-empty image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original">
                <span slot="description"> Aucun site ne correspond au filtre renseigné </span>
              </a-empty>`,
})

export default {
  name: "SitesListTable",
  props: {
    sites: Array,
  },
  components: {
    McmaIcon,
    McmaButton,
    McmaSearchTableFilter,
    McmaCode,
  },
  data() {
    return {
      selectedRowKeys: [],
      oldSelectedRowKeys: [],
      dataSource: [],
      loading: false,
      filters: {},
      searchText: {
        nom: "",
        rae: "",
        codeSite: "",
        codeGroupement: "",
        adresse: "",
        commune: "",
        interlocuteur: "",
      },
      searchInput: {
        nom: null,
        rae: null,
        codeSite: null,
        codeGroupement: "",
        adresse: null,
        commune: null,
        interlocuteur: "",
      },
      columns: [
        {
          title: "Nom",
          dataIndex: "nom",
          key: "nom",
          fixed: "left",
          width: 150,
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "nom",
          },
          onFilter: (value, record) =>
            record.nom ? record.nom.toString().toLowerCase().includes(value.toLowerCase()) : null,
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput["nom"].focus()
              }, 0)
            }
          },
        },
        {
          title: "RAE",
          dataIndex: "rae",
          key: "rae",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "rae",
          },
          onFilter: (value, record) =>
            record.rae ? record.rae.toString().toLowerCase().includes(value.toLowerCase()) : null,
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput["rae"].focus()
              }, 0)
            }
          },
        },
        {
          title: "Fluide",
          dataIndex: "fluide",
          filters: [
            {
              text: Constants.FLUIDE.ELEC.text,
              value: Constants.FLUIDE.ELEC.text,
            },
            {
              text: Constants.FLUIDE.GAZ.text,
              value: Constants.FLUIDE.GAZ.text,
            },
          ],
          onFilter: (value, record) => record.fluide === value,
          scopedSlots: { customRender: "fluide" },
        },
        {
          title: "Statut",
          dataIndex: "status",
          filters: [
            {
              text: Constants.SITE_STATUS.NOUVEAU.text,
              value: Constants.SITE_STATUS.NOUVEAU.text,
            },
            {
              text: Constants.SITE_STATUS.ACTIF.text,
              value: Constants.SITE_STATUS.ACTIF.text,
            },
          ],
          onFilter: (value, record) => record.status === value,
          scopedSlots: { customRender: "status" },
        },
        {
          title: "Segment/Option tarifaire",
          dataIndex: "tarif",
          key: "tarif",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "codeSite",
          },
          onFilter: (value, record) =>
            record.tarif ? record.tarif.toString().toLowerCase().includes(value.toLowerCase()) : null,
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput["tarif"].focus()
              }, 0)
            }
          },
        },
        {
          title: "Adresse",
          dataIndex: "adresse",
          key: "adresse",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "adresse",
          },
          onFilter: (value, record) =>
            record.adresse ? record.adresse.toString().toLowerCase().includes(value.toLowerCase()) : null,
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput["adresse"].focus()
              }, 0)
            }
          },
        },
        {
          title: "Commune",
          dataIndex: "commune",
          key: "commune",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "commune",
          },
          onFilter: (value, record) =>
            record.commune ? record.commune.toString().toLowerCase().includes(value.toLowerCase()) : null,
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput["commune"].focus()
              }, 0)
            }
          },
        },
        {
          title: "Interlocuteur technique",
          dataIndex: "interlocuteur",
          key: "interlocuteur",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "interlocuteur",
          },
          onFilter: (value, record) =>
            record.interlocuteur ? record.interlocuteur.toString().toLowerCase().includes(value.toLowerCase()) : null,
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput["interlocuteur"].focus()
              }, 0)
            }
          },
        },
        {
          title: "Actions",
          fixed: "right",
          key: "actions",
          width: 185,
          filters: [
            {
              text: Constants.STATUS.TO_COMPLETE.text,
              value: Constants.STATUS.TO_COMPLETE.text,
            },
            {
              text: Constants.STATUS.TO_CERTIFY.text,
              value: Constants.STATUS.TO_CERTIFY.text,
            },
            {
              text: Constants.STATUS.CERTIFIED.text,
              value: Constants.STATUS.CERTIFIED.text,
            },
          ],
          onFilter: (value, record) => this.getStateByValue(record.state).text === value,
          scopedSlots: { customRender: "actions" },
        },
      ],
      states: [
        {
          text: Constants.STATUS.TO_COMPLETE.text,
          value: Constants.STATUS.TO_COMPLETE.value,
          whiteIcon: Constants.STATUS.TO_COMPLETE.whiteIcon,
          classColor: Constants.STATUS.TO_COMPLETE.classColor,
        },
        {
          text: Constants.STATUS.TO_CERTIFY.text,
          value: Constants.STATUS.TO_CERTIFY.value,
          whiteIcon: Constants.STATUS.TO_CERTIFY.whiteIcon,
          classColor: Constants.STATUS.TO_CERTIFY.classColor,
        },
        {
          text: Constants.STATUS.COMPLETED.text,
          value: Constants.STATUS.COMPLETED.value,
          whiteIcon: Constants.STATUS.COMPLETED.whiteIcon,
          classColor: Constants.STATUS.COMPLETED.classColor,
        },
        {
          text: Constants.STATUS.CERTIFIED.text,
          value: Constants.STATUS.CERTIFIED.value,
          whiteIcon: Constants.STATUS.CERTIFIED.whiteIcon,
          classColor: Constants.STATUS.CERTIFIED.classColor,
        },
      ],
    }
  },
  computed: {
    ...mapState(["membre", "user", "currentTutorial", "operation"]),
    ...mapGetters(["getFlow"]),
    getStateByValue() {
      return (value) => {
        return this.states.find((state) => state.value === value)
      }
    },
    superAdmin() {
      return (member) => {
        return member.status === "EXPORTED" && (this.user.role === "ADMIN" || this.user.role === "SUPER_ADMIN")
      }
    },
    getLocale() {
      return {
        emptyText: this.dataSource.length === 0 ? <site-empty-without-filter /> : <site-empty-with-filter />,
      }
    },
    selectedSitesSentence() {
      return accorization(this.oldSelectedRowKeys.length, "site sélèctionné", true, "aucun site sélèctionné")
    },
    getColumns() {
      return this.columns
    },
    hasSelected() {
      return this.selectedRowKeys.length > 0
    },
    getProgressionByState() {
      return (member, state) => {
        let progression = 0
        if (this.getFlow.includes("PERIMETRE")) {
          progression += member.statSite[state] ? member.statSite[state] : 0
        }
        if (this.getFlow.includes("FACTURATION")) {
          progression += member.statGroupement[state] ? member.statGroupement[state] : 0
        }
        if (this.getFlow.includes("SERVICES")) {
          const upper = _.snakeCase(state).toUpperCase()
          progression += member.statService.state === upper ? 1 : 0
          progression += member.statInterlocuteur.state === upper ? 1 : 0
        }
        return progression.toString()
      }
    },
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onSelectAll: this.onSelectAll,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: [
          {
            key: "select-to-complete",
            text: `Sélectionner tous les sites ${Constants.STATUS.TO_COMPLETE.text.toLowerCase()}`,
            onSelect: () => {
              this.selectedByState("TO_COMPLETE")
            },
          },
          {
            key: "select-complete",
            text: `Sélectionner tous les sites ${Constants.STATUS.TO_CERTIFY.text.toLowerCase()}`,
            onSelect: () => {
              this.selectedByState("TO_CERTIFY")
            },
          },
          {
            key: "select-certified",
            text: `Sélectionner tous les sites ${Constants.STATUS.CERTIFIED.text.toLowerCase()}`,
            onSelect: () => {
              this.selectedByState("CERTIFIED")
            },
          },
        ],
        onSelection: this.onSelection,
      }
    },
  },
  watch: {
    sites: {
      deep: true,
      handler(sites) {
        console.log(
          `%c sites`,
          "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
          sites
        )
        this.generateDataSource(sites)
      },
    },
    selectedRowKeys(newValue, oldValue) {
      this.oldSelectedRowKeys = newValue.length === 0 ? [...oldValue] : [...newValue]
    },
  },
  mounted() {
    this.generateDataSource(this.sites)
  },
  methods: {
    ...mapActions(["refreshStore", "tutoGoesTo"]),
    ...mapMutations(["updateCurrentMembre"]),
    mail(type, member) {
      this.$emit("sendMail", { type: type, member: member })
    },
    action(type, all, member) {
      this.$emit(type, { all: all, member: member })
    },
    goTo(route) {
      if (typeof route === "string") {
        this.$router.push({ name: route })
      } else if (typeof route === "object") {
        this.$router.push(route)
      }
    },
    updateSelectRowsOnFilterChange() {
      const dataSource = this.getDataSourceFiltered()
      this.selectedRowKeys = this.selectedRowKeys.filter((key) => {
        return dataSource.find((data) => {
          return key === data.key
        })
      })
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
      const sitesList = this.dataSource.filter((data) => selectedRowKeys.includes(data.key)).map((data) => data.key)
      this.$emit("selectedSitesChanged", sitesList)
    },
    onChange(pagination, filters, sorter) {
      this.filters = filters
      this.updateSelectRowsOnFilterChange()
    },
    generateDataSource(sites) {
      this.dataSource = []
      if (!!sites && sites.length > 0) {
        sites.forEach((site, index) => {
          this.dataSource.push({
            key: index,
            id: site.id,
            nom: site.nom,
            rae: site.rae,
            fluide: this.$getValueByKey(site.fluide),
            status: this.$getValueByKey(site.status),
            tarif: site.optionTarifaire || site.segment,
            adresse: site.adresse,
            commune: site.commune,
            state: this.getState(site),
            interlocuteur: site.interlocuteurPrenom + " " + site.interlocuteurNom,
          })
        })
        this.dataSource.sort((a, b) => b.id - a.id)
      }
    },
    getState(site) {
      let dataRequired = []
      const enr = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::enr"
      )
      let dataFulled = {
        codePostal: site.codePostal,
        fluide: site.fluide,
        status: site.status,
        statutSite: site.statutSite,
        dateActivation: site.dateActivation,
        distributeur: site.distributeur,
        rae: site.rae,
        interlocuteurPrenom: site.interlocuteurPrenom,
        interlocuteurNom: site.interlocuteurNom,
      }
      let dataTechGaz = {
        optionTarifaire: site.optionTarifaire,
        car: site.car,
        profil: site.profil,
        modeReleve: site.modeReleve,
      }
      //enr not in dataElec cuz we verify after if he is required
      let dataTechElec = {
        segment: site.segment,
        fta: site.fta,
        ps: site.ps,
      }
      if (site.fluide === "ELEC") {
        //we check here if enr should be include in dataRequired
        if (enr.displayed === true ) {
          Object.assign(dataFulled, site.enr)
        }
        Object.assign(dataFulled, dataTechElec)
      } else if (site.fluide === "GAZ") {
        Object.assign(dataFulled, dataTechGaz)
      }
      console.log(dataFulled)
      // On passe par statut_site et non plus status (GRD)
      if (site.statutSite === "A transférer vers un autre membre" || site.statutSite === "Ce site est à supprimer de la bascule") {
        // cas particulier: les champs ne sont pas à valider
        console.log("Mise à jour de l'état des statuts supprimés")
        Axios("patch", "/api/sites/" + site.id, { ongletIdentification: 1, ongletParametre: 1 }).catch((error) =>
          console.log(error)
        )
        return Constants.STATUS.COMPLETED.value
      } else {
        for (const key in dataFulled) {
          if (dataFulled[key] == null || dataFulled[key] === "") {
            dataRequired.push(dataFulled[key])
            console.log(site.nom + " data required is " + key + ": " + dataFulled[key])
          }
        }
        if (dataRequired.length > 0) {
          console.log("dataRequired number is" + dataRequired.length)
          Axios("patch", "/api/sites/" + site.id, { ongletIdentification: 0, ongletParametre: 1 }).catch((error) =>
            console.log(error)
          )
          return Constants.STATUS.TO_COMPLETE.value
        } else if (dataRequired.length === 0) {
          Axios("patch", "/api/sites/" + site.id, { ongletIdentification: 1, ongletParametre: 1 }).catch((error) =>
            console.log(error)
          )
          return Constants.STATUS.COMPLETED.value
        }
      }
    },
    getDataSourceFiltered() {
      const toCheck = ["status", "fluide", "state"]
      let dataSourceFiltered = [...this.dataSource].sort((a, b) => b.id - a.id)
      toCheck.forEach((key) => {
        if (this.filters[key] && this.filters[key].length > 0) {
          dataSourceFiltered = dataSourceFiltered.filter((data) => this.filters[key].includes(data[key]))
        }
      })
      for (let key in this.searchInput) {
        if (this.filters[key] && this.filters[key].length > 0) {
          dataSourceFiltered = dataSourceFiltered.filter((data) =>
            data[key] ? data[key].toString().toLowerCase().includes(this.searchText[key].toLowerCase()) : null
          )
        }
      }
      return dataSourceFiltered
    },
    selectedByState(state) {
      let dataSourceFiltered = this.getDataSourceFiltered()
      dataSourceFiltered = dataSourceFiltered.filter((data) => data.state === state).map((data) => data.key)
      this.selectedRowKeys = dataSourceFiltered
    },
    onSelectAll(selected) {
      let dataSourceFiltered = []
      if (selected) {
        dataSourceFiltered = this.getDataSourceFiltered()
      }
      this.selectedRowKeys = dataSourceFiltered.map((data) => data.key)
      this.onSelectChange(this.selectedRowKeys)
    },
    handleSearch(fieldName, selectedKeys, confirm) {
      confirm()
      this.searchText[fieldName] = selectedKeys[0]
    },

    handleReset(fieldName, clearFilters) {
      clearFilters()
      this.searchText[fieldName] = ""
    },
  },
}
</script>

<style lang="scss"></style>
