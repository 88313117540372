var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-spin',{attrs:{"spinning":_vm.spinning,"tip":_vm.tip,"delay":_vm.delay,"wrapperClassName":'mcma-spin-box ' +
      _vm.wrapperClassName +
      (_vm.noTransparence ? ' no-transparence' : '') +
      (_vm.size ? ` size-${_vm.size}` : '') +
      (_vm.full ? ' full' : '')}},[_c('a-icon',{style:({
        fontSize: _vm.size + 'px',
        height: _vm.size + 'px',
        width: _vm.size + 'px',
      }),attrs:{"slot":"indicator","type":"loading","spin":""},slot:"indicator"}),_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }