<template>
  <section>
    <i class="material-icons" :class="focus ? 'isFocused' : 'notFocused'">search</i>
    <input
      type="text"
      placeholder="Rechercher"
      @focusin="focus = true"
      @focusout="focus = false"
      v-model="searchValue"
      @keyup.enter="$emit('search')"
    />
    <i class="material-icons removeBtn" @click="remove">close</i>
    <i class="material-icons searchBtn" @click="$emit('search')">arrow_forward</i>
  </section>
</template>

<script>
export default {
  name: "search",
  props: ["value"],
  data() {
    return {
      focus: false,
    }
  },
  computed: {
    searchValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
  },
  methods:{
    remove(){
      this.searchValue = ""
      this.$emit('search')
    },
  }
}
</script>

<style lang="scss" scoped>
section {
  position: relative;
  width: 30%;
  min-width: 250px;
}

i {
  position: absolute;
  &:first-of-type {
    line-height: 45px;
    left: 10px;
    transition: all 0.3s;
    pointer-events: none;
    &.isFocused {
      color: black;
    }
    &.notFocused {
      color: rgba(0, 0, 0, 0.4);
    }
  }
  &.searchBtn {
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 500px;
    padding: 3px;
    margin: 7.5px;
    top: 0;
    right: -75px;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
  &.removeBtn {
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 500px;
    padding: 3px;
    margin: 7.5px;
    top: 0;
    right: -50px;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
}

input[type="text"] {
  padding-left: 35px !important;
  padding-right: 40px !important;
  margin: 0 !important;
  border: 0 !important;
  background: rgba(255, 255, 255, 0.6) !important;
  border-radius: 500px !important;
  &:focus {
    border-bottom: 0 !important;
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1) !important;
    background: white !important;
  }
  &:hover:not(:focus) {
    background: rgba(0, 0, 0, 0.02) !important;
  }
}
</style>
