<template>
  <main>
    <section class="container">
      <div class="card">
        <div class="card-content">
          <span class="card-title"> Services </span>
          <blockquote>
            Afin de faciliter nos échanges avec vos fournisseurs et pour un meillieur suivi de l'execution du contrat
            énergie,
            <br />
            cette interface vous permet de renseigner les informations relatifs aux services de vos fournisseurs :
            <br />
            <br />
            - Le lien internet de la plateforme du fournisseur
            <br />
            - Votre Identifiant
            <br />
            - Votre Mot de passe
            <br />
            <br />
            Il vous est demandé de déclarer au moins un service.
            <br /><br />
            Votre état actuelle pour cette étape est
            <br /><br />

            <article v-if="membre.statService === 'TO_COMPLETE'">
              <span class="chip white-text deep-orange progression validServiceState">
                <i class="material-icons">edit</i>
                À&nbsp;completer
              </span>
            </article>
          </blockquote>
        </div>
        <center class="card-action" id="validServiceFinal">
          <template
            v-if="membre.status !== 'EXPORTED' && membre.status !== 'APPROVED' && membre.statService !== 'CERTIFIED'"
          >
            <router-link
              :to="{ name: 'service', params: { id: 'new' } }"
              class="waves-effect waves-light btn blue darken-2 tutorialNoBtn"
              id="validServiceAdd"
            >
              Ajouter un nouveau service
            </router-link>
          </template>
        </center>
      </div>
    </section>
    <section class="row">
      <article class="col s12" id="validServiceGrid">
        <place-holder :notReady="!services">
          <grid
            v-if="services"
            name="Liste des services"
            :rows="services"
            :columns="columns"
            :search="true"
            :columnHeader="columnHeader"
            :columnsNoSearch="['action']"
          >
            <template slot="action" slot-scope="props">
              <router-link
                :to="{ name: 'service', params: { id: props.row.id } }"
                class="validServiceEdit"
                v-tooltip.auto="'Editer le service'"
              >
                <i class="material-icons amber-text">edit</i>
              </router-link>

              <a href @click.prevent="remove(props.row)" v-tooltip.auto="'Supprimer le service'">
                <i class="material-icons deep-orange-text">remove</i>
              </a>
            </template>
          </grid>
        </place-holder>
      </article>
    </section>
  </main>
</template>

<script>
import Mixin from "../Mixin"
import Axios from "@/util/Axios"
import Grid from "@/components/common/Grid"
import { mapState, mapMutations, mapActions } from "vuex"
import Materialize from "materialize-css"
import PlaceHolder from "@/components/common/PlaceHolder"

export default {
  name: "services",
  mixins: [Mixin],
  components: {
    Grid,
    PlaceHolder,
  },
  data() {
    return {
      flow: "SERVICES",
      services: null,
    }
  },
  computed: {
    ...mapState(["membre", "user", "operation"]),
    columns() {
      if (this.membre && this.membre.statService === "CERTIFIED") {
        return ["url", "identifiant"]
      }
      return ["url", "identifiant", "action"]
    },
    columnHeader() {
      return {
        url: "Url",
        identifiant: "Identifiant",
        action: "Actions",
      }
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    ...mapActions(["refreshStore"]),
    initialize() {
      Axios("get", "api/membres/" + this.membre.id + "/services").then((response) => {
        this.services = response.data._embedded.services
      })
      this.refreshStore()
    },
    remove(service) {
      if (
        confirm(
          'Voulez-vous vraiment supprimer de façon irréversible le service pour "' +
            service.url +
            " " +
            service.identifiant +
            '" ?'
        )
      ) {
        Axios("delete", "api/services/" + service.id)
          .then((reponse) => {
            this.initialize()
            Materialize.toast("Le service a bien été supprimé", 4000, "lime")
          })
          .catch((error) => {
            console.error(error)
            Materialize.toast("Erreur lors de la suppression", 4000, "amber white-text")
          })
      }
    },
  },
  created() {
    this.setBreadcrumbAdd({
      name: "Services",
      action: () => {
        this.$router.push({
          name: "services",
        })
      },
    })
    this.initialize()
  },
}
</script>

<style lang="scss" scoped></style>
