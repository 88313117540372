<template>
  <section>
    <!-- <pre>
      membre : {{membre | pretty}} 
      <br>
      membreStats : {{membreStats | pretty}} 
      <br>
      loading : {{loading | pretty}} 
    </pre>
    -->
    <div class="container">
      <mcma-spin-box :spinning="loading">
        <a-row type="flex" :gutter="12">
          <a-col :span="24">
            <explain />
          </a-col>
        </a-row>
        <a-row v-if="membre && operation && !loading" type="flex" :gutter="12">
          <a-col :span="18">
            <actual-progress />
          </a-col>
          <a-col :span="6">
            <timer />
            <!-- <general-info @completed="displayGeneralInfo" /> -->
          </a-col>
        </a-row>
        <a-row type="flex" :gutter="12">
          <a-col :span="24">
            <mcma-divider />
          </a-col>
        </a-row>
        <!-- debbug l'ouverture du workflow -->
        <!-- 
        <pre>
          generalInfoNeeded : {{generalInfoNeeded}}
          <br>
          superUnlock : {{superUnlock}}
          <br>
          activ GeneralInfoBox : {{!superUnlock && generalInfoNeeded}}
        </pre> 
        -->
        <a-row v-if="membre && operation && !loading" type="flex" :gutter="12">
          <a-col :span="24" style="height: 200px" v-if="!superUnlock && infosNeeded">
            <mcma-card-button
              name="Démarrer mon parcours"
              color="success"
              :uppercase="true"
              @click="openModal = true"
            />
            <general-info v-model="openModal" />
          </a-col>
          <a-col :span="24" v-else>
            <mcma-flows v-if="membre" />
          </a-col>
        </a-row>
      </mcma-spin-box>
    </div>
    <mcma-konami-unlock @unlock="setSuperUnlock" />
  </section>
</template>

<script>
import Explain from "@/components/mcma/steps/components/Explain"
import Timer from "@/components/mcma/steps/components/Timer.vue"
import ActualProgress from "@/components/mcma/steps/components/ActualProgress"
import GeneralInfo from "@/components/mcma/steps/components/GeneralInfo.vue"
import GeneralInfoMessageBox from "@/components/mcma/steps/components/GeneralInfoMessageBox"
import McmaDivider from "@/components/mcma/common/McmaDivider"
import McmaFlows from "@/components/mcma/steps/components/Flows.vue"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import McmaKonamiUnlock from "@/components/mcma/common/McmaKonamiUnlock"
import McmaCardButton from "@/components/mcma/common/McmaCardButton.vue"
import { pushLogServer } from "@/util/Helpers/DynamiqueFields"
import { mapState, mapMutations, mapActions } from "vuex"
import Axios from "@/util/Axios"

export default {
  name: "steps",
  components: {
    Explain,
    Timer,
    ActualProgress,
    GeneralInfo,
    GeneralInfoMessageBox,
    McmaSpinBox,
    McmaDivider,
    McmaFlows,
    McmaKonamiUnlock,
    McmaCardButton,
  },
  data() {
    return {
      groupements: null,
      loading: true,
      superUnlock: false,
      openModal: false,
    }
  },
  computed: {
    ...mapState(["user", "membre", "operation", "confOperation"]),
    // ...mapGetters(["getFlow"])
    infosNeeded() {
      const needed = !(
        this.membre &&
        this.membre.businessName &&
        this.membre.ape &&
        this.membre.siret &&
        this.membre.nomVoie &&
        this.membre.postalCode &&
        this.membre.city &&
        this.membre.interlocuteurFacture &&
        this.membre.interlocuteurFacture.fonction &&
        this.membre.interlocuteurFacture.prenom &&
        this.membre.interlocuteurFacture.nom &&
        this.membre.interlocuteurFacture.mail &&
        (this.membre.interlocuteurFacture.telephone || this.membre.interlocuteurFacture.mobile) &&
        this.membre.interlocuteurTechnique &&
        this.membre.interlocuteurTechnique.fonction &&
        this.membre.interlocuteurTechnique.prenom &&
        this.membre.interlocuteurTechnique.nom &&
        this.membre.interlocuteurTechnique.mail &&
        (this.membre.interlocuteurTechnique.telephone || this.membre.interlocuteurTechnique.mobile)
      )
      return needed
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd", "setOperation", "setState"]),
    ...mapActions(["requestMembresStats", "requestMembreStats", "requestMembreByMembreId"]),
    setSuperUnlock() {
      if (this.user.role === "SUPER_ADMIN" || this.user.role === "ADMIN" || this.user.role === "COORDINATEUR_AVANCE") {
        this.superUnlock = true
        this.$scrollToBottom()
        this.$notification.success({
          message: "Étapes débloquées",
          description: `Les étapes ont été débloqués !`,
        })
      }
    },
    displayGeneralInfo(needed) {
      console.log("> displayGeneralInfo : %O", needed)
      this.generalInfoNeeded = needed
    },
    setNewOperation() {
      console.group("setNewOperation")
      if (this.membre && this.membre.id) {
        Axios("get", "api/membres/" + this.membre.id + "/operation").then((response) => {
          // TODO gérer le cas ou un membre est dans plusieurs oprération
          console.log("> response.data : %O", response.data._embedded.operations[0])
          this.setOperation(response.data._embedded.operations[0])
        })
      }
      console.groupEnd()
    },
    async initialise() {
      console.group("initialise@Step")
      console.log("membre : %O", this.membre)
      console.log("operation : %O", this.operation)
      console.log("this.$router : %O", this.$router)
      console.log("this.$router.history.current.params.membreId : %O", this.$router.history.current.params.membreId)
      // console.log("this.membreId : %O", this.membreId);
      this.loading = true
      if (!this.membre) {
        // dans le cas où l'on vient de l'opération
        try {
          await this.requestMembreByMembreId(this.$router.history.current.params.membreId)
        } catch (error) {
          pushLogServer(
            "ERROR",
            "Erreur serveur lors de la recherche du membre en venant de l'opération : " + this.user.email
          )
          console.log(error)
        }
      } else {
        // dans utilisateur CLIENT qui arrive directement sur le STEP
        try {
          await this.requestMembreStats(this.$router.history.current.params.membreId)
        } catch (error) {
          pushLogServer(
            "ERROR",
            "Erreur serveur lors de la recherche du membre en venant directement du membre : " + this.user.email
          )
          console.log(error)
        }
      }
      this.setBreadcrumbAdd({
        name: this.membre.nom || this.user.email,
        action: () => {
          this.$router.push({
            name: "steps",
            params: {
              operationId: this.operation.id,
              membreId: this.membre.id,
            },
          })
        },
      })

      if (!this.membre || this.membre.interlocuteurFacture === null || !this.membre.interlocuteurFacture) {
        let final = { ...this.membre }
        final.interlocuteurFacture = {
          fonction: null,
          civility: null,
          prenom: null,
          nom: null,
          mail: null,
          telephone: null,
          mobile: null,
        }
        this.setState({
          stateName: "membre",
          value: final,
        })
      }

      if (!this.membre || this.membre.interlocuteurTechnique === null || !this.membre.interlocuteurTechnique) {
        let final = { ...this.membre }
        final.interlocuteurTechnique = {
          fonction: null,
          civility: null,
          prenom: null,
          nom: null,
          mail: null,
          telephone: null,
          mobile: null,
        }
        this.setState({
          stateName: "membre",
          value: final,
        })
      }

      if (!this.membre || this.membre.interlocuteurTresorerie === null || !this.membre.interlocuteurTresorerie) {
        let final = { ...this.membre }
        final.interlocuteurTresorerie = {
          fonction: null,
          civility: null,
          prenom: null,
          nom: null,
          mail: null,
          telephone: null,
          mobile: null,
        }
        this.setState({
          stateName: "membre",
          value: final,
        })
      }

      this.loading = false
      this.setUserFirstConnectOk()
      /*
      setTimeout(() => {
              this.loading = false;
            }, 60000);
      */
      console.groupEnd()
    },
    setUserFirstConnectOk() {
      console.group("setUserFirstConnectOk")
      console.log("this.user.firstConnectOk : %O", this.user.firstConnectOk)
      if (this.user.firstConnectOk === null) {
        console.log("> setUserFirstConnectOk patch user")
        Axios("patch", "api/users/" + this.user.id, {
          firstConnectOk: Date.now(),
        })
          .then((response) => {
            pushLogServer("INFO", "Connexion FirstConnect Ok pour : " + this.user.email)
            console.log("> setUserFirstConnectOk respeonse : %O", response)
            console.log("> First connect utilisateur Ok")
          })
          .catch((error) => {
            console.log("> First connect utilisateur Ko")
            console.error(error)
            pushLogServer("ERROR", "Erreur lors du set first connect  : " + this.user.email)
            pushLogServer("ERROR", "Erreur lors du set first connect : " + error)
            if (error.response.status === 400) {
              this.error =
                "Erreur le serveur n'a pas répondu suite à votre accès au membre. Merci de retenter une connexion"
            } else {
              this.error =
                "Erreur le serveur n'a pas répondu suite à votre accès au membre. Merci de retenter une connexion"
            }
          })
      }
      console.groupEnd()
    },
  },
  watch: {
    membre: {
      handler() {
        // this.initialise();
      },
      deep: true,
    },
  },
  created() {
    this.initialise()
  },
}
</script>

<style lang="scss"></style>
