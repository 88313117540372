<template>
    <McmaImportWorkflow :steps="steps" :operationId="getOperationId"></McmaImportWorkflow>
</template>

<script>
import McmaImportWorkflow from "@/components/mcma/common/McmaImportWorkflow.vue";
import { mapMutations } from "vuex"
export default {
    name: "MembresImportWorkflow",
    components: {
        McmaImportWorkflow,
    },
    data() {
        return {
            // Steps qui composent notre wkflow d'import, pour un ajout, il suffit d'ajouter une ligne avec un pipename existant côté mage..
            // Généraliser le composant, puis passer cette var en props pour appeler le composant où on veut dans l'app avec diff workflow ;)
            steps: [
                { key: 'insertMembers', label: 'Insérer des membres', order: 0, pipename: 'wp_insert_members' },
                { key: 'linkUserMembre', label: 'Lier des utilisateurs à leurs membres', order: 1, pipename: 'wp_link_user_membre' },
                { key: 'linkPdlMembre', label: 'Lier des PDLs à leurs membres', order: 2, pipename: 'wp_link_pdl_membre' }
            ]
        }
    },
    created() {
        this.initialize()
    },
    computed: {
        getOperationId() {
            if(Object.keys(this.$route.params).includes('operationId')){
                return this.$route.params.operationId
            }
            return null
        },
    },

    methods: {
        ...mapMutations(["setBreadcrumbAdd"]),

        initialize() {
            this.setBreadcrumbAdd({
                name: "Import en Masse de membres",
                action: () => {
                    this.$router.push({
                        name: "members-generic-import",
                    })
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped></style>