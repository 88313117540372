import Etape1 from "./Etape_1"
import Etape2Elec from "./Etape_2_Elec"
import Etape2Gaz from "./Etape_2_Gaz"

export default function (confOperation, flow, user, site) {
  const etapes = [Etape1]
  console.log("site : %O", site)
  console.log("confOperation : %O", confOperation)
  console.log("user.role : %O", user.role)
  if (site.id) {
    if (site.fluide === "ELEC") {
      etapes.push(Etape2Elec)
    } else if (site.fluide === "GAZ") {
      etapes.push(Etape2Gaz)
    }
  }

  return etapes
}
