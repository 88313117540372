<template>
    <mcma-import-work-flow :steps="steps" :operationId="getOperationId" :membreId="getMembreId"></mcma-import-work-flow>
</template>

<script>
import McmaImportWorkFlow from "@/components/mcma/common/McmaImportWorkflow.vue"
import { mapMutations } from "vuex"
export default {
    name: "UsersImportWorkflow",
    components: {
        McmaImportWorkFlow,
    },
    data() {
        return {
            // Steps qui composent notre wkflow d'import, pour un ajout, il suffit d'ajouter une ligne avec un pipename existant côté mage..
            // Généraliser le composant, puis passer cette var en props pour appeler le composant où on veut dans l'app avec diff workflow ;)
            steps: [
                { key: 'insertUsers', label: 'Insérer des utilisateurs', order: 0, pipename: 'wp_insert_users' },
                { key: 'linkUserMembre', label: 'Lier des utilisateurs à leurs membres', order: 1, pipename: 'wp_link_user_membre' }
            ]
        }
    },
    created() {
        console.log("operationId / membreId", this.getOperationId, " / ", this.getMembreId)
        this.initialize()
    },
    computed: {
        getOperationId() {
            if(Object.keys(this.$route.params).includes('operationId')){
                return this.$route.params.operationId
            }
            return null
        },
        getMembreId() {
            if(Object.keys(this.$route.params).includes('membreId')){
                return this.$route.params.membreId
            }
            return null
        },

    },

    methods: {
        ...mapMutations(["setBreadcrumbAdd"]),

        initialize() {
            this.setBreadcrumbAdd({
                name: "Import en Masse d'utilisateurs",
                action: () => {
                    this.$router.push({
                        name: "users-generic-import",
                    })
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped></style>