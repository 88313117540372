<template>
  <mcma-card :title="getTitle" icon="edit_site" class="mcma-card-site-detail-header" :inline="true">
    <template #describe>
      <span v-if="site.state === Constants.STATUS.COMPLETED.value">
        Vous avez validé les deux premiers onglets au moins une fois.
      </span>
      <span v-else-if="site.state === Constants.STATUS.TO_COMPLETE_MASSE.value">
        Modifier en masse vos sites directement dans la grille de saisie ci-dessous.
      </span>
    </template>
    <template #headerRightSide>
      <mcma-button v-if="site.added && site.id" name="Supprimer" color="error" @click="remove()" />
    </template>
    <slot></slot>
  </mcma-card>
</template>

<script>
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaCard from "@/components/mcma/common/McmaCard"
import Axios from "@/util/Axios"
import Constants from "@/util/Constants"

export default {
  name: "SitesDetailCardHeader",
  props: ["site", "membre"],
  components: {
    McmaCard,
    McmaButton,
  },
  computed: {
    Constants() {
      return Constants
    },
    getTitle() {
      if (this.site.id) {
        return `Edition du site ${this.site.nom}`
      } else if (this.site.masse) {
        return "Edition en masse des sites"
      }
      return "Création d'un nouveau site"
    },
  },
  methods: {
    goTo(route) {
      if (typeof route === "string") {
        this.$router.push({ name: route })
      } else if (typeof route === "object") {
        this.$router.push(route)
      }
    },
    remove() {
      const that = this
      that.$confirm({
        title: `Supprimer le site`,
        content: `Voulez-vous vraiment supprimer de façon irréversible le site ${this.site.nom} ?`,
        okText: "Oui",
        okType: "success",
        cancelText: "Non",
        onOk() {
          Axios("delete", "api/sites/" + that.site.id)
            .then((reponse) => {
              that.$notification.success({
                message: `Site supprimé`,
                description: `Le site à bien été supprimé`,
              })
              that.$router.push({
                name: "sites",
              })
            })
            .catch((error) => {
              console.error(error)
              that.$notification.error({
                message: `Erreur de suppression`,
                description: `Erreur lors de la suppression du site ${this.site.nom}`,
              })
            })
        },
        onCancel() {
          console.log(
            `%c Cancel Delete Site`,
            "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
          )
        },
      })
    },
  },
}
</script>

<style lang="scss"></style>
