<template>
  <!-- icon : sites-->
  <mcma-card
    title="Liste de vos périmètres de facturation"
    icon="noIcon"
    class="mcma-card-groupements-header"
    :inline="true"
  >
    <template #describe>
      <span>Votre progression actuelle pour cette étape est de</span>
    </template>
    <template #headerRightSide>
      <a-progress
        :strokeColor="progression === 100 ? successColor : primaryColor"
        :percent="progression"
        :format="() => progression + '%'"
        :strokeWidth="22"
      />
    </template>
    <div
      v-if="getCurrentFlow && getCurrentFlow.describeInFlow"
      class="html-render-content sub-describe"
      v-html="getCurrentFlow.describeInFlow"
      :class="{ 'can-edit': $isAdmin() }"
      @click="setEditorModal('groupements', 'describeInFlow')"
    />
    <mcma-card class="mcma-card-groupements-header-body">
      <div class="button-group flex-column mtb12">
        <mcma-button
          :name="`Nombre de sites restant à rattacher à un périmètre de facturation`"
          class="mb12"
          color="orange"
          leftIcon="link"
          :value="membreStats.statSite.notGrouped"
          :isFaIcon="true"
        />
        <mcma-button
          :name="`Nombre de sites déjà rattachés à un périmètre de facturation`"
          color="success"
          :value="membreStats.statSite.mustBeGroup - membreStats.statSite.notGrouped"
          leftIcon="link"
          :isFaIcon="true"
          class="ml12"
        />
      </div>
      <mcma-divider class="mt12 mb24" />
      <div v-if="progression !== 100 || !membre.siteNotGrouped">
        <div class="sentence">
          <p class="principal-message inline">Pour finir cette étape, vous devez :</p>
        </div>
        <ul>
          <li v-for="(instruction, index) in instructions" :key="index">
            <div class="number">
              <span>
                {{ index + 1 }}
              </span>
            </div>
            <p class="instruction">
              <span>
                {{ instruction }}
              </span>
            </p>
          </li>
        </ul>
      </div>
      <div v-if="progression === 100">
        <div class="sentence">
          <p class="principal-message inline">
            Cette étape est terminée. <br />
            Pour savoir ce qu'il vous reste à faire :
          </p>
          <mcma-button name="Rendez-vous sur la page d'accueil" class="mt24" color="success" @click="goBack()" />
        </div>

        <mcma-divider class="mt20" />
      </div>
      <div class="button-group">
        <mcma-button
          v-if="membre.status !== 'EXPORTED' && membre.status !== 'APPROVED'"
          name="Créer un lot de factures individuelles"
          class="mt24 mr12"
          @click="
            goTo({
              name: 'groupements-detail',
              params: {
                groupementId: null,
                action: 'new',
                type: 'FACTURES_INDIVIDUELLES',
              },
            })
          "
        />
        <mcma-button
          v-if="membre.status !== 'EXPORTED' && membre.status !== 'APPROVED'"
          name="Créer une facture groupée"
          class="mt24 mr12"
          @click="
            goTo({
              name: 'groupements-detail',
              params: {
                groupementId: null,
                action: 'new',
                type: 'FACTURE_GROUPEE',
              },
            })
          "
        />
      </div>
    </mcma-card>
    <slot></slot>
    <mcma-editor-modal
      v-if="$isAdmin()"
      :visible="showEditor"
      :flowName="flowName"
      :flowProp="flowProp"
      @onCancel="setEditorModal"
      @onSave="editorHasMakeChange"
    />
    <mcma-end-step-modal :visible="progression === 100 && openEndStepModal === true" name="Facturation" step="groupements" @close="closeEndStepModal" />
  </mcma-card>
</template>

<script>
import Constants from "@/util/Constants"
import { mapState } from "vuex"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaDivider from "@/components/mcma/common/McmaDivider"
import McmaEditorModal from "@/components/mcma/common/McmaEditorModal"
import McmaEndStepModal from "@/components/mcma/common/McmaEndStepModal"
export default {
  name: "GroupementsHeader",
  props: {
    groupements: Array,
  },
  components: {
    McmaCard,
    McmaSpinBox,
    McmaButton,
    McmaDivider,
    McmaEditorModal,
    McmaEndStepModal,
  },
  data() {
    return {
      primaryColor: "#1890ff",
      successColor: "#4ECB74",
      instructions: [
        "Compléter les informations de chacun de vos périmètres de facturation.",
        "Vérifier d'avoir bien associé l'ensemble de vos sites sur vos périmètres de facturation.",
      ],
      showEditor: false,
      flowName: null,
      flowProp: null,
      param: null,
      openEndStepModal: true,
    }
  },
  computed: {
    ...mapState(["membre", "membreStats", "operation"]),
    progression() {
      console.group("progression@GrouepementsHeader")
      console.log("this.groupements : %O", this.groupements)
      console.log("this.membre : %O", this.membre)
      console.groupEnd()
      if (this.groupements) {
        let progress = 0
        const groupementCertified = this.groupements.filter(
          (g) => g.state === Constants.STATUS.COMPLETED.value || g.state === Constants.STATUS.CERTIFIED.value
        )
        const groupementToComplete = this.groupements.filter((g) => g.state === Constants.STATUS.TO_COMPLETE.value)
        progress += 1 * (groupementCertified.length || 0)
        progress += 0.8 * (groupementToComplete.length || 0)
        progress -= 0.1 * (this.membre.siteNotGrouped || 0)
        if (progress < 0) progress = 0
        if (progress > this.groupements.length) progress = this.groupements.length
        return progress > 0 ? Math.trunc((progress / this.groupements.length) * 100) : 0
      }
      return 0
    },
    getCurrentFlow() {
      return this.operation.flows.find((item) => item.name === "groupements")
    },
  },
  methods: {
    closeEndStepModal(){
      this.openEndStepModal = false
    },
    setEditorModal(flowName, flowProp) {
      this.showEditor = !!(flowName && flowProp)
      if (flowName && flowProp) {
        this.flowName = flowName
        this.flowProp = flowProp
      } else {
        setTimeout(() => {
          this.flowName = flowName
          this.flowProp = flowProp
        }, 300)
      }
    },
    editorHasMakeChange() {
      this.setEditorModal()
    },
    goTo(route) {
      if (typeof route === "string") {
        this.$router.push({ name: route })
      } else if (typeof route === "object") {
        this.$router.push(route)
      }
    },
    goBack() {
      // this.setBreadcrumb("goBack");
      this.$router.push({
        name: "steps",
      })
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-card-groupements-header {
  .headerRightSide {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  .card-body {
    .mcma-card-groupements-header-body {
      padding-top: 0;
      .card-body {
        padding-top: 0;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
      }
      .sentence {
        display: flex;
        flex-flow: column nowrap;
        text-align: center;
        align-items: center;
        .principal-message {
          display: flex;
          flex-flow: column nowrap;
          font-size: 16px;
          color: var(--text-color, $text-color);
          font-weight: 400;
          strong {
            color: var(--primary-color, $primary-color);
          }
          &.inline {
            display: inline;
          }
          &.with-bottom {
            margin-top: var(--padding, $padding);
            margin-bottom: calc(var(--padding, $padding) * 2);
          }
          .mcma-icon {
            align-self: center;
            background: var(--success-color, $success-color);
            border-radius: 4px;
            padding: 8px 15px 7px 15px;
            margin: 20px 0;
            font-weight: 900;
            img {
              height: 22px;
            }
          }
        }
        .secondary-message {
          font-size: 18px;
          font-weight: 900;
          background: var(--secondary-color, $current-color);
          color: $white;
          border-radius: 4px;
          padding: 20px;
          margin-top: var(--padding, $padding);
          position: relative;
          &:before {
            content: "";
            position: absolute;
            top: calc(var(--padding, $padding) * -1);
            left: calc(var(--padding, $padding) * -1);
            width: calc(100% + (var(--padding, $padding) * 2) * 2);
            height: 1px;
            background-image: linear-gradient(to right, $border-color-base 66%, rgba($border-color-base, 0) 0%);
            background-position: top;
            background-size: 15px 1px;
            background-repeat: repeat-x;
          }
          &.done {
            background: var(--success-color, $success-color);
          }
          &.pending {
            background: var(--secondary-color, $current-color);
          }
        }
      }
      ul {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        height: 100%;
        li {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;
          margin-top: 15px;
          .number {
            display: flex;
            min-width: 24px;
            width: 24px;
            height: 24px;
            margin: 0 12px;
            border-radius: 5px;
            box-shadow: $box-shadow;
            span {
              margin: auto;
              font-weight: 900;
              color: $blueLagon;
              font-size: 15px;
              line-height: 15px;
            }
          }
          .instruction {
            span {
              color: var(--text-color, $text-color);
            }
          }
        }
      }
    }
  }
  .ant-progress {
    .ant-progress-outer {
      width: calc(100% - 60px);
    }
    .ant-progress-text {
      font-size: 22px;
      margin-left: 25px;
      line-height: 25px;
      text-align: right;
      font-weight: 300;
      color: var(--text-color, $text-color);
    }
    &.ant-progress-status-success {
      .ant-progress-text {
        i {
          color: var(--success-color, $success-color);
          svg {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
  .button-group {
    display: flex;
    flex-flow: row nowrap;
  }
}
</style>
