import Etape1 from "@/components/mcma/steps/sites/Etapes/Etape_1/Etape_1"
import Etape2Elec from "@/components/mcma/steps/sites/Etapes/Etape_2_Elec/Etape_2_Elec"
import Etape2Gaz from "@/components/mcma/steps/sites/Etapes/Etape_2_Gaz/Etape_2_Gaz"

export default function (confOperation, flow, user, site) {
  const etapes = [Etape1]
  console.log("site : %O", site)
  console.log("confOperation : %O", confOperation)
  console.log("user.role : %O", user.role)
  if (site.id) {
    // if (confOperation !== 'CARREFOUR' || user.role !== 'CLIENT') {
    if (site.fluide === "ELEC") {
      etapes.push(Etape2Elec)
    } else if (site.fluide === "GAZ") {
      etapes.push(Etape2Gaz)
    }
    // }
  }

  return etapes
}
