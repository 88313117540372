<template>
  <section>
    <mcma-spin-box :spinning="loading" :tip="loadingMessage" :size="80" :full="true">
      <div class="header-block mt10">
        <h5>
          {{ operation.id ? "Édition de l'opération " + operation.name : "Nouvelle opération" }}
        </h5>
        <div class="sub-header-block">
          <i
            class="material-icons operationBtn validation"
            v-tooltip.auto="'Enregistrer l\'opération'"
            @click="confirmSaveOperation"
            >check
          </i>
          <i
            class="material-icons operationBtn reset"
            v-tooltip.auto="'Annuler toutes les modifications'"
            @click="confirmInitialize"
            >replay
          </i>
          <i
            class="material-icons actionBtn pointer"
            v-tooltip.auto="'Se connecter à l\'opération'"
            :class="{ disabled: !operation.id }"
            @click="$parent.goTo(operation)"
            >home
          </i>
          <div
            v-for="tab in Tabs"
            :key="tab.name"
            class="editorTab"
            :class="currentTab === tab.name ? 'activeTab' : ''"
            @click="currentTab = tab.name"
          >
            {{ $t("super_admin.editor." + tab.name) }}
          </div>
          <mcma-button
            class="go-right"
            name="Importer depuis une autre opération"
            color="primary"
            leftIcon="file-import"
            :isFaIcon="true"
            @click="openImportOperationModal"
          />
          <mcma-button
            class="last-item"
            color="purple"
            leftIcon="cog"
            :isFaIcon="true"
            @click="openAdminSettingModal"
          />
        </div>
      </div>
      <component :is="currentTab" class="tabContent" v-model="operation" />
    </mcma-spin-box>

    <mcma-import-operation-modal
      :visible="showImportOperation"
      :operation="operation"
      @cancel="closeImportOperationModal"
      @save="saveImportOperationModal"
    />
    <mcma-admin-setting-modal
      :visible="showAdminSetting"
      :operation="operation"
      @cancel="closeAdminSettingModal"
      @save="saveAdminSettingModal"
    />
  </section>
</template>

<script>
import _ from "lodash"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import McmaImportOperationModal from "@/components/mcma/common/McmaImportOperationModal"
import McmaAdminSettingModal from "@/components/mcma/common/McmaAdminSettingModal"
import Axios from "@/util/Axios"
import { mapMutations, mapState } from "vuex"
import operations from "../../operations.json"
import { Tabs } from "./Tabs"

export default {
  name: "editor",
  components: {
    ...Tabs,
    McmaSpinBox,
    McmaButton,
    McmaImportOperationModal,
    McmaAdminSettingModal,
  },
  data() {
    return {
      currentTab: "general",
      operation: {},
      originalOperation: {},
      loading: true,
      loadingMessage: "Chargement de l'opération...",
      showImportOperation: false,
      showAdminSetting: false,
    }
  },
  computed: {
    ...mapState(["user"]),
    Tabs() {
      var tabsFilter = Tabs
      console.log("Tags : %O", tabsFilter)
      if (this.user.role !== "SUPER_ADMIN" && this.user.role !== "ADMIN") {
        delete tabsFilter.Membres
        delete tabsFilter.Flow
      }
      return tabsFilter
    },
    operationNotSaved: {
      get() {
        return this.$store.state.operationNotSaved
      },
      set(value) {
        console.group("setOperationNotSaved@Editor")
        console.log("> Value : %O", value)
        this.setOperationNotSaved(value)
        console.groupEnd()
      },
    },
  },
  methods: {
    ...mapMutations([
      "setOperationNotSaved",
      "setBreadcrumbAdd",
      "setOperation",
      "setOperationEditing",
      "removeLastbreadcrumb",
    ]),
    initialize() {
      return this.getOperationbyId(this.$route.params.operationId)
        .then((operation) => {
          if (!operation) {
            this.originalOperation = this.$cloneDeep(operations.empty)
            this.operation = this.$cloneDeep(operations.empty)
          }
          this.loading = false
          this.setOperation(this.operation)
          return this.operation
        })
        .catch((error) => {
          this.loading = false
          console.error("error", error)
          return error
        })
    },
    openImportOperationModal() {
      this.showImportOperation = true
    },
    closeImportOperationModal() {
      this.showImportOperation = false
    },
    saveImportOperationModal() {
      this.showImportOperation = false
      this.initialize()
    },
    openAdminSettingModal() {
      this.showAdminSetting = true
    },
    closeAdminSettingModal() {
      this.showAdminSetting = false
    },
    saveAdminSettingModal() {
      this.showAdminSetting = false
    },
    confirmInitialize() {
      const that = this
      that.$confirm({
        title: `Annuler les modifications ?`,
        content: `Voulez-vous vraiment annuler toutes vos modifications ?`,
        okText: "Oui",
        okType: "success",
        cancelText: "Non",
        onOk() {
          that.initialize()
        },
      })
    },
    getOperationbyId(operationId) {
      if (!operationId) {
        return Promise.resolve(null)
      }
      return Axios("get", "api/operations/" + operationId)
        .then((response) => {
          this.originalOperation = this.$cloneDeep(response.data)
          this.operation = this.$cloneDeep(response.data)

          return Axios("get", "api/operations/" + operationId + "/mails")
            .then((response) => {
              console.log("this.originalOperation.mails : %O", response.data._embedded.mails)

              this.originalOperation.mails = this.$cloneDeep(response.data._embedded.mails)

              this.operation.mails = response.data._embedded.mails
              let mailFixedOrdered = _.sortBy(this.operation.mails, "id")
              this.operation.mails = mailFixedOrdered

              return this.operation
            })
            .catch((error) => {
              this.$notification.error({
                message: `Erreur récuperation operation mail`,
                description: `Une erreur est survenue lors de la recupération de la configuration d'email de l'operation.`,
              })
              return error
            })
        })
        .catch((error) => {
          this.$notification.error({
            message: `Erreur récuperation de l'operation`,
            description: `Une erreur est survenue lors de la recupération de l'operation.`,
          })
          return error
        })
    },
    confirm(dialog, action) {
      if (window.confirm(dialog) && action) {
        action()
      }
    },
    confirmSaveOperation() {
      const that = this
      that.$confirm({
        title: `Enregistrer l'opération ?`,
        content: `Voulez-vous vraiment enregistrer cette opération ?`,
        okText: "Oui",
        okType: "success",
        cancelText: "Non",
        onOk() {
          if (
            !that.operation.name ||
            !that.operation.groupement ||
            !that.operation.contactLastName ||
            !that.operation.contactFirstName ||
            !that.operation.contactMail ||
            !that.operation.exportMail ||
            !that.operation.dateDebut ||
            !that.operation.dateFin ||
            !that.operation.dateBascule ||
            !that.operation.dateFinContrat
          ) {
            that.$notification.error({
              message: `Impossible d'ajouter l'opération`,
              description: `Des champs requis n'ont pas été saisis`,
            })
            return
          }

          that.loadingMessage = "Nous sauvegardons vos modifications..."
          that.loading = true
          that.saveOperation()
        },
      })
    },
    saveOperation() {
      if (this.originalOperation) {
        this.$deleteKeyRecursive(this.operation, "_links", true)

        if (this.operation)
          if (this.operation && this.operation.id != null) {
            return Axios("post", "operation/save", this.operation)
              .then((response) => {
                this.$notification.success({
                  message: `Opération modifiée`,
                  description: `L'opération à bien été modifiée.`,
                })

                return this.initialize()
              })
              .catch((error) => {
                this.loading = false
                console.error("error", error)
                this.$notification.success({
                  message: `Erreur de modification`,
                  description: `Une erreur est survenue lors de la modification de l'operation.`,
                })
                return error
              })
          } else {
            delete this.operation.progression
            delete this.operation.membres
            return Axios("post", "operation/save", this.operation)
              .then(() => {
                this.$notification.success({
                  message: `Opération ajoutée`,
                  description: `L'opération à bien été ajoutée.`,
                })
                return this.initialize()
              })
              .catch((error) => {
                this.loading = false
                console.error("error", error)
                this.$notification.error({
                  message: `Erreur d'ajout de l'operation`,
                  description: `Une erreur est survenue lors de l'ajout de l'operation.`,
                })
                return error
              })
          }
      }
    },
    hasChanged() {
      console.group("hasChanged@Editor@Operation")
      console.log("this.originalOperation : %O", this.originalOperation)
      console.log("this.operation : %O", this.operation)

      if (
        JSON.stringify(this.originalOperation) !== JSON.stringify(this.operation) &&
        JSON.stringify(this.operation) !== JSON.stringify(operations.empty)
      ) {
        this.operationNotSaved = true
      } else {
        this.operationNotSaved = false
      }
      console.log("this.operationNotSaved : %O", this.operationNotSaved)
      console.log("this.originalOperation : %O", this.originalOperation)
      console.log("this.operation : %O", this.operation)
      console.groupEnd()
    },
  },
  watch: {
    operation: {
      handler() {
        this.hasChanged()
      },
      deep: true,
    },
  },
  async created() {
    this.$scrollToTop()
    let name = "Nouvelle Opération"
    try {
      await this.initialize()
      if (this.operation.id) {
        name = `Edition de l'Opération : ${this.operation.name}`
      }
    } catch (e) {
      console.error(e)
    }
    this.removeLastbreadcrumb()
    this.setBreadcrumbAdd({
      name: name,
      action: () => {},
    })

    this.hasChanged()
  },
}
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

section {
  .header-block {
    display: flex;
    flex-flow: column nowrap;
    .sub-header-block {
      display: flex;
      flex-flow: row nowrap;
      height: 30px;
      .go-right {
        margin: -10px -23px 0 auto;
        transform: scale(0.85);
      }
      .last-item {
        margin: -11px 18px 0 0;
        transform: scale(0.78);
      }
    }
  }
  & .editorTab {
    display: inline-block;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px 5px 0 0;
    transition: 0.3s;
    &:hover:not(.activeTab) {
      background: rgba(255, 255, 255, 0.5);
    }
    &.activeTab {
      background: white;
    }
  }
  & .tabContent {
    padding: 10px;
    background: white;
    margin-right: 20px;
    border-radius: 5px;
  }
  & .operationBtn {
    transition: 0.3s;
    padding: 3px;
    border-radius: 15px;
    cursor: pointer;
    &:not(:hover) {
      &.validation {
        color: #8bc34a;
      }
      &.reset {
        color: #1976d2;
      }
    }
    &:hover {
      color: white;
      &.validation {
        background: #8bc34a;
      }
      &.reset {
        background: #1976d2;
      }
    }
  }
}

i.disabled {
  color: $disabled-color;
  cursor: pointer;
}
</style>
