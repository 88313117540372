<template>
  <main class="container" v-if="site">
    <section v-if="site.state !== Constants.STATUS.CERTIFIED.value">
      <div class="row">
        <div class="col s12">
          <article class="card">
            <div class="card-image" id="validSiteMenu">
              <div class="row valign-wrapper">
                <center class="col s1">
                  <ul class="pagination">
                    <li
                      class="waves-effect"
                      @click="gotTo(etapeActualIndex - 1)"
                      :class="{ disabled: !etapeActualIndex }"
                      v-if="site.id"
                    >
                      <a href @click.prevent>
                        <i class="material-icons"> chevron_left </i>
                      </a>
                    </li>
                  </ul>
                </center>
                <center class="col s10">
                  <span
                    v-for="(etape, index) in etapes"
                    @click="gotTo(index)"
                    :key="index"
                    class="etape chip amber white-text"
                    :class="{
                      selected: etapeActualIndex === index,
                      lime: isCompleted(index),
                    }"
                  >
                    <i v-if="isCompleted(index)" class="material-icons">send</i>
                    <i v-else class="material-icons">edit</i>
                    {{ $t("site." + etape.name + ".name") }}
                  </span>
                </center>
                <center class="col s1">
                  <ul class="pagination">
                    <li
                      class="waves-effect"
                      @click="gotTo(etapeActualIndex + 1)"
                      :class="{
                        disabled: etapeActualIndex === etapes.length - 1,
                      }"
                      v-if="site.id"
                    >
                      <a href @click.prevent>
                        <i class="material-icons"> chevron_right </i>
                      </a>
                    </li>
                  </ul>
                </center>
              </div>
            </div>
            <div class="card-content">
              <span class="card-title" v-if="site.id"> Edition du site "{{ site.nom }}" </span>
              <span class="card-title" v-else-if="site.masse"> Edition en masse des sites </span>
              <span class="card-title" v-else> Création d'un nouveau site </span>
              <blockquote v-if="site.state === Constants.STATUS.TO_COMPLETE_MASSE.value">
                Modifier en masse vos sites directement dans la grille de saisie ci-dessous.
              </blockquote>
            </div>
            <center class="card-action" id="validSiteFinal">
              <a
                href
                class="waves-effect waves-light btn deep-orange"
                @click.prevent="remove"
                v-if="site.added && site.id"
              >
                <i class="material-icons">remove</i>
                Supprimer
              </a>
            </center>
          </article>
        </div>
      </div>
      <component :is="etapeActual" :site="site" @save="initialize" id="validSiteForm"></component>
    </section>
    <section v-else class="card-panel white-text deep-orange">
      Ce site ne peux pas être modifié car il a déjà été envoyé en validation.
    </section>
  </main>
</template>

<script>
import Mixin from "../Mixin"
import getEtapes from "./Etapes"
import masse from "./Masse"
import Axios from "@/util/Axios"
import { mapState, mapMutations, mapGetters } from "vuex"
import Materialize from "materialize-css"
import Constants from "@/util/Constants"

export default {
  name: "site",
  mixins: [Mixin],
  data() {
    return {
      flow: "PERIMETRE",
      site: null,
      etapeActualIndex: 0,
    }
  },
  computed: {
    ...mapState(["operation", "membre", "user"]),
    ...mapGetters(["getFlow"]),
    Constants() {
      return Constants
    },
    etapes() {
      return getEtapes(this.operation, this.getFlow, this.user, this.site)
    },
    etapeActual() {
      if (!this.site.masse) {
        return this.etapes[this.etapeActualIndex]
      } else {
        return masse
      }
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    gotTo(etape) {
      if (etape >= 0 && etape <= this.etapes.length - 1) {
        this.etapeActualIndex = etape
      }
    },
    initialize(id) {
      Axios("get", "api/sites/" + id)
        .then((response) => {
          this.site = response.data
          this.setBreadcrumbAdd({
            name: "Edition de : " + this.site.nom,
            action: () => {},
          })
        })
        .catch((error) => {
          console.error("error", error)
        })
    },
    getColorationOnglet(index) {
      const fields = ["ongletIdentification", "ongletParametre", "ongletFacturation"]
      if (this.site[fields[index]]) {
        return "light-green-text"
      }
      return "deep-orange-text"
    },
    isCompleted(index) {
      const fields = ["ongletIdentification", "ongletParametre", "ongletFacturation"]
      return this.site[fields[index]]
    },
    remove() {
      if (confirm('Voulez-vous vraiment supprimer de façon irréversible le site "' + this.site.nom + '" ?')) {
        Axios("delete", "api/sites/" + this.site.id)
          .then((reponse) => {
            Materialize.toast("Le site a bien été supprimé", 4000, "lime")
            this.$router.push({
              name: "sites",
            })
          })
          .catch((error) => {
            console.error(error)
            Materialize.toast("Erreur lors de la suppression", 4000, "amber white-text")
          })
      }
    },
  },
  created() {
    switch (this.$route.params.id) {
      case "new":
        this.site = {
          new: true,
          state: "TO_COMPLETE",
          status: "NOUVEAU",
          added: true,
        }
        this.setBreadcrumbAdd({
          name: "Création",
          action: () => {},
        })
        break
      case "masse":
        this.site = {
          new: false,
          state: "TO_COMPLETE_MASSE",
          status: "NOUVEAU",
          added: false,
          masse: true,
        }
        this.setBreadcrumbAdd({
          name: "Gestion en masse des sites",
          action: () => {},
        })
        break
      default:
        this.initialize(this.$route.params.id)
    }
  },
}
</script>

<style lang="scss" scoped>
.row {
  margin-bottom: 0px;
}

.etape {
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.selected {
  border: 2px solid #6c6d70;
}

.card-image {
  padding-top: 10px;
}
</style>
