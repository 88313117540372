<template>
  <section>
    <div class="container">
      <a-row type="flex" :gutter="12">
        <a-col :span="24">
          <sites-header :sites="sites" @updateSite="getSites" :progression="progression">
            <mcma-spin-box :spinning="loading">
              <sites-list-table :sites="sites" @updateSite="getSites" />
            </mcma-spin-box>
          </sites-header>
        </a-col>
      </a-row>
    </div>
  </section>
</template>

<script>
import Axios from "@/util/Axios"
import GuardiansMixin from "@/components/mcma/steps/guardians/GuardiansMixin"
import SitesHeader from "@/components/mcma/steps/sites/components/SitesHeader/SitesHeader"
import SitesListTable from "@/components/mcma/steps/sites/components/SitesListTable"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import { mapState, mapMutations, mapActions } from "vuex"
import _ from "lodash"
import Constants from "@/util/Constants"
export default {
  name: "Sites",
  mixins: [GuardiansMixin],
  components: {
    SitesHeader,
    SitesListTable,
    McmaSpinBox,
  },
  data() {
    return {
      flow: "PERIMETRE",
      sites: null,
      columns: ["nom", "rae", "fluide", "status", "codeSite", "adresse", "commune", "state"],
      columnHeader: {
        nom: "Nom",
        status: "Statut",
        codeSite: "Code Groupement",
        adresse: "Adresse",
        commune: "Commune",
        state: "Action",
        fluide: "Fluide",
        rae: "RAE",
      },
      columnsListFilter: {
        state: {
          TO_COMPLETE: "À completer",
          TO_CERTIFY: "À certifier",
          CERTIFIED: "Certifié",
        },
      },
      loading: true,
    }
  },
  computed: {
    ...mapState(["membre", "user", "operation"]),
    progression() {
      
      if (this.sites && this.membre) {
        let progress = 0
        const siteToComplete = this.sites.filter((site) => site.state === Constants.STATUS.TO_COMPLETE.value)
        const siteCompleted = this.sites.filter(
          (site) => site.state === Constants.STATUS.COMPLETED.value || site.state === Constants.STATUS.CERTIFIED.value
        )
        progress += 0.5 * (siteToComplete.length || 0)
        progress += 1 * (siteCompleted.length || 0)
        return progress > 0 ? Math.trunc((progress / this.sites.length) * 100) : 0
      }
      return 0
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd", "setState"]),
    ...mapActions(["refreshStore"]),
    initialize(loadLoopState) {
      this.loading = true
      this.sites = null
      this.setBreadcrumbAdd({
        name: "Sites",
        action: () => {
          this.$router.push({
            name: "sites",
          })
        },
      })
      this.getSites(loadLoopState)
    },
    getSites(loadLoopState) {
      return Axios("get", "api/membres/" + this.membre.id + "/sites").then(async (response) => {
        this.sites = _.flatMap(response.data._embedded, (value) => value)
        if (loadLoopState && (await this.$loopStateCanChangeToCompleted(this.operation, this.sites, "sites"))) {
          this.initialize()
        }
        this.loading = false
        this.refreshStore()
      })
    },
    resetSiteEditing() {
      this.setState({
        stateName: "siteEditing",
        value: null,
      })
    },
  },
  created() {
    this.initialize(true)
    this.resetSiteEditing()
  },
}
</script>

<style lang="scss" scoped>
.progression {
  cursor: pointer;
}
.action {
  min-width: 150px;
}
</style>
