<template>
  <main>
    <div class="container">
      <section class="row">
        <article class="card">
          <div class="card-content">
            <span class="card-title"> Liste de vos interlocuteurs </span>
            <blockquote>
              Cette interface vous permet de renseigner les interlocuteurs qui auront accès au service Espace Client.
              <br />
              Il vous est demandé de déclarer au moins un contact qui sera gestionnaire de la plateforme.
              <br /><br />
              Votre état actuel pour cette étape est
              <br /><br />
              <div v-if="membreStats.statInterlocuteur.state === 'TO_COMPLETE'">
                <span class="chip white-text deep-orange progression validInterlocutorState">
                  <i class="material-icons">edit</i>
                  À&nbsp;completer
                </span>
                <br /><br />
                <!--                Vous devez avoir au moins un interlocuteur dont le rôle est-->
                <!--                GESTIONNAIRE.-->
              </div>
              <div v-else-if="membreStats.statInterlocuteur.state === 'COMPLETED'">
                <span class="chip white-text amber progression validInterlocutorState">
                  <i class="material-icons">send</i>
                  À&nbsp;certifier
                </span>
                <br /><br />
                Vous pouvez desormais certifier vos interlocuteurs.
              </div>
              <div v-else>
                <span class="chip white-text lime progression validInterlocutorState">
                  <i class="material-icons">check</i>
                  Certifié
                </span>
                <br /><br />
                Vous avez certifié vos interlocuteurs, vous ne pouvez donc plus en ajouter ni les modifier.
                <br />
                Cette étape est terminée. <br />
                Pour savoir ce qu'il vous reste à faire :
                <router-link :to="{ name: 'home' }" class="waves-effect waves-light btn blue darken-2">
                  rendez-vous sur la page d'accueil </router-link
                >.
              </div>
            </blockquote>
          </div>
          <center class="card-action" id="validInterlocutorFinal">
            <template v-if="membreStats.status !== 'EXPORTED' && membreStats.status !== 'APPROVED'">
              <router-link
                :to="{ name: 'interlocuteur', params: { id: 'new' } }"
                class="waves-effect waves-light btn blue darken-2 tutorialNoBtn"
                v-if="membreStats.statInterlocuteur.state !== 'CERTIFIED'"
                id="validInterlocutorAdd"
              >
                Ajouter un nouvel interlocuteur
              </router-link>

              <router-link
                class="waves-effect waves-light pulse btn light-green"
                :to="{ name: 'validation-interlocuteurs' }"
                v-if="membreStats.statInterlocuteur.state === 'COMPLETED'"
              >
                Certifier les interlocuteurs
              </router-link>
            </template>
          </center>
        </article>
      </section>
    </div>
    <section class="row">
      <article class="col s12" id="validInterlocutorGrid">
        <place-holder :notReady="!interlocuteurs">
          <grid
            v-if="interlocuteurs"
            :rows="interlocuteurs"
            :columns="columns"
            :search="true"
            :columnHeader="columnHeader"
            :columnsNoSearch="['action']"
          >
            <template slot="action" slot-scope="props">
              <!--
                <router-link
                :to="{ name: 'interlocuteur', params: { id: props.row.id } }"
                class="validInterlocutorEdit"
                v-tooltip.auto="'Editer l\'interlocuteur'"
                v-if="
                  props.row.id !== membreStats.interlocuteurFacture.id &&
                    props.row.id !== membreStats.interlocuteurTechnique.id
                "
              >
              -->
              <router-link
                :to="{ name: 'interlocuteur', params: { id: props.row.id } }"
                class="validInterlocutorEdit"
                v-tooltip.auto="'Editer l\'interlocuteur'"
              >
                <i class="material-icons amber-text">edit</i>
              </router-link>
              <a href @click.prevent="remove(props.row)" v-tooltip.auto="'Supprimer l\'interlocuteur'">
                <i class="material-icons deep-orange-text">remove</i>
              </a>
            </template>
          </grid>
        </place-holder>
      </article>
    </section>
  </main>
</template>

<script>
import Mixin from "../Mixin"
import Axios from "@/util/Axios"
import Grid from "@/components/common/Grid"
import { mapState, mapMutations, mapActions } from "vuex"
import _ from "lodash"
import Materialize from "materialize-css"
import PlaceHolder from "@/components/common/PlaceHolder"

export default {
  name: "interlocuteurs",
  mixins: [Mixin],
  components: {
    Grid,
    PlaceHolder,
  },
  data() {
    return {
      flow: "SERVICES",
      interlocuteurs: null,
      columnHeader: {
        nom: "Nom",
        prenom: "Prénom",
        fonction: "Fonction",
        mail: "Mail",
        telephone: "Téléphone",
        role: "Rôle",
        action: "Actions",
      },
    }
  },
  computed: {
    ...mapState(["membreStats", "operation", "user"]),
    columns() {
      if (this.membreStats && this.membreStats.statInterlocuteur.state === "CERTIFIED") {
        return ["nom", "prenom", "fonction", "mail", "telephone", "role"]
      }
      return ["nom", "prenom", "fonction", "mail", "telephone", "role", "action"]
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    ...mapActions(["refreshStore"]),
    initialize() {
      console.group("initialize@FlowInterlocuteur")
      console.log("this.membreStats.id : %O", this.membreStats.id)
      this.interlocuteurs = null
      Axios("get", "api/membres/" + this.membreStats.id + "/interlocuteurs").then((response) => {
        this.interlocuteurs = _.filter(response.data._embedded.interlocuteurs, {
          removed: false,
        })
      })
      // this.refreshStore();
      console.groupEnd()
    },
    remove(interlocuteur) {
      if (
        confirm(
          "Voulez-vous vraiment supprimer de façon irréversible l'interlocuteur \"" +
            interlocuteur.nom +
            " " +
            interlocuteur.prenom +
            '" ?'
        )
      ) {
        Axios("delete", "api/interlocuteurs/" + interlocuteur.id)
          .then((reponse) => {
            this.initialize()
            Materialize.toast("L'interlocuteur a bien été supprimé", 4000, "lime")
          })
          .catch((error) => {
            console.error(error)
            Materialize.toast("Erreur lors de la suppression", 4000, "amber white-text")
          })
      }
    },
  },
  created() {
    this.setBreadcrumbAdd({
      name: "Interlocuteurs",
      action: () => {
        this.$router.push({
          name: "interlocuteurs",
        })
      },
    })
    this.initialize()
    /*
    this.$nextTick(() => {
      this.initialize();
    });
    */
  },
}
</script>

<style lang="scss" scoped></style>
