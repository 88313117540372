<template>
  <mcma-card title="Liste des mails" icon="administration" class="mcma-card-emails-tracker-header">
    <template #describe>
      <p v-if="user.role === 'SUPER_ADMIN' || user.role === 'ADMIN' || user.role === 'COORDINATEUR_AVANCE'">
        Cette interface vous permets de voir le retour serveur des mails transmis aux utilisteurs via la plateforme.
        <br />
        Il est important de noté qu'il faut un certain délai entre l'émission du mail et son retour serveur. <br />
        Si l'ensemble des mails que vous avez envoyé tout de suite n'apparaissent merci de revenir un peu plus tard.
        <br />
      </p>
    </template>

    <mcma-card class="mcma-card-emails-tracker-header-body">
      <div class="sentence">
        <div class="principal-message" v-if="user.role === 'SUPER_ADMIN' || user.role === 'ADMIN'">
          <h3 class="title-block">Dans quel cas agir ?</h3>
          <ul>
            <li>
              <span class="point-title error-color">Bloqué, Rebondissement dur :</span>
              <span class="point-describe">
                l'adresse mail est erronée ou inexistante. Il est important pour la qualité d'envoi et la réussite de
                l'opération de rapidement prendre contact avec le coordonnateur ou l'entité membre pour corriger
                l'adresse mail. Une fois la bonne adresse en main vous pouvez cliquer sur l'action
                <mcma-icon type="edit" color="primary" leftIcon="edit_white" :full="true" />
                pour modifier l'adresse de votre correspondant.
              </span>
            </li>
            <li>
              <span class="point-title orange-color">Rebondissement doux, SPAM, UNSUB :</span>
              <span class="point-describe">
                Il est probable que la boite mail de votre correspondant soit saturée, qu'une erreur technique soit
                survenue, ou que le mail ai été considéré comme un spam. Il est souhaitable de prendre contact avec le
                destinataire avant de retenter l'envoi de mail.
              </span>
            </li>
          </ul>
        </div>
      </div>
    </mcma-card>
    <slot></slot>
  </mcma-card>
</template>

<script>
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaIcon from "@/components/mcma/common/McmaIcon"
import McmaDivider from "@/components/mcma/common/McmaDivider"
import { mapState } from "vuex"

export default {
  name: "EmailsTrackerHeader",
  props: {
    emailsTracker: Array,
  },
  components: {
    McmaCard,
    McmaIcon,
    McmaButton,
    McmaDivider,
  },
  computed: {
    ...mapState(["user"]),
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-card-emails-tracker-header {
  .headerRightSide {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  .card-body {
    // position: relative;
    // margin-top: 24px;
    // &:before{
    // content: "";
    // position: absolute;
    // top: 0;
    // left: calc(var(--padding, $padding) * -1);
    // width: calc(100% + (var(--padding, $padding) * 2) * 2);
    // height: 1px;
    // background-image: linear-gradient(to right, $border-color-base 66%, rgba($border-color-base,0) 0%);
    // background-position: top;
    // background-size: 15px 1px;
    // background-repeat: repeat-x;
    // }
    .mcma-card-emails-tracker-header-body {
      padding-top: 0;
      .card-body {
        padding-top: 0;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
      }
      .sentence {
        display: flex;
        flex-flow: column nowrap;
        text-align: left;
        align-items: center;
        .principal-message {
          display: flex;
          flex-flow: column nowrap;
          font-size: 14px;
          color: var(--text-color, $text-color);
          font-weight: 400;
          .title-block {
            padding-bottom: var(--padding, $padding);
            font-size: 20px;
            font-weight: 900;
            color: var(--primary-color, $primary-color);
          }
          ul {
            margin-bottom: 0;
            li {
              padding-bottom: var(--padding, $padding);
              &:last-child {
                padding-bottom: 0;
              }
              .point-title {
                font-weight: 900;
              }
              .point-describe {
              }
              .mcma-icon {
                display: inline-flex;
                img {
                }
              }
            }
          }
          strong {
            color: var(--primary-color, $primary-color);
          }
          &.inline {
            display: inline;
          }
          &.with-bottom {
            margin-top: var(--padding, $padding);
            margin-bottom: calc(var(--padding, $padding) * 2);
          }
        }
        .secondary-message {
          font-size: 18px;
          font-weight: 900;
          background: var(--secondary-color, $current-color);
          color: $white;
          border-radius: 4px;
          padding: 20px;
          margin-top: var(--padding, $padding);
          position: relative;
          &:before {
            content: "";
            position: absolute;
            top: calc(var(--padding, $padding) * -1);
            left: calc(var(--padding, $padding) * -1);
            width: calc(100% + (var(--padding, $padding) * 2) * 2);
            height: 1px;
            background-image: linear-gradient(to right, $border-color-base 66%, rgba($border-color-base, 0) 0%);
            background-position: top;
            background-size: 15px 1px;
            background-repeat: repeat-x;
          }
          &.done {
            background: var(--success-color, $success-color);
          }
          &.pending {
            background: var(--secondary-color, $current-color);
          }
        }
      }
    }
  }
  .ant-progress {
    .ant-progress-outer {
      width: calc(100% - 60px);
    }
    .ant-progress-text {
      font-size: 22px;
      margin-left: 25px;
      line-height: 25px;
      text-align: right;
      font-weight: 300;
      color: var(--text-color, $text-color);
    }
    &.ant-progress-status-success {
      .ant-progress-text {
        i {
          color: var(--success-color, $success-color);
          svg {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
  .button-group {
    display: flex;
    flex-flow: row nowrap;
  }
}
</style>
