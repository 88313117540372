<template>
  <a-modal :width="800" v-model="visible" :maskClosable="false" class="mcma-admin-setting-modal" :title="`Etape terminée`">
    <div class="container-list">
      <p class="message">Vous avez atteint 100% de l'étape {{ name }}, souhaitez-vous passer à la prochaine étape du parcours ?</p>
      <div class="inline-buttons">
        <mcma-button :name="'Continuer sur l\'étape '+name" color="red" class="mt24" @click="handleCancel" />
        <mcma-button name="Passer à l'étape suivante" color="orange" class="mt24" @click="handleOk" />
      </div>
    </div>
  </a-modal>
</template>
<script>
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaField from "@/components/mcma/common/McmaField"
import { mapMutations, mapState, mapGetters } from "vuex"
export default {
  name: "McmaEndStepModal",
  props: {
    visible: Boolean,
    name: String,
    param: String,
    step: String,
  },
  components: {
    McmaButton,
    McmaField,
  },
  computed:{
      ...mapState(["membre", "membreStats", "user", "operation"]),
  },
  methods: {
      ...mapMutations(["setState"]), 
      ...mapGetters(["getFlow"]),
     getNextFlowName() {
      let actualOrderFlow = this.operation.flows.find((item) => item.name === this.step).ordre
      let nameNextFlow
      if (actualOrderFlow +1 <= this.getFlow().length + 1) {
        nameNextFlow = this.operation.flows.find(
          (item) => item.ordre === actualOrderFlow + 1 && item.name !== "general"
        ).name
        if (nameNextFlow === "services") {
          nameNextFlow = "interlocuteurs"
        } else if (nameNextFlow === "documents-download" || nameNextFlow === "piecetelechargement") {
          nameNextFlow = "documents"
          this.param = "download"
        } else if (nameNextFlow === "documents-upload" || nameNextFlow === "piecedepot") {
          nameNextFlow = "documents"
          this.param = "upload"
        }
      } else {
        nameNextFlow = "steps"
      }
      return nameNextFlow
    },
    handleCancel() {
      this.$emit("close")
    },
    handleOk() {
      this.goTo(this.getNextFlowName(), this.param)
    },
    goTo(route, param) {
      if (this.name === "interlocuteurs") {
        this.setState({
          stateName: "interlocuteur",
          value: {},
        })
      }

      if (typeof route === "string") {
        this.$router.push({ name: route, params: { action: param } })
      } else if (typeof route === "object") {
        this.$router.push(route)
      }
    },
  },
}
</script>
<style scoped>
.message{
  font-size: 18px;
  font-weight: bold;
}
</style>
