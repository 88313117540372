<template>
  <mcma-card title="Progression Actuelle" icon="actual_progress" class="mcma-card-actual-progress" :inline="true">
    <template #headerRightSide>
      <a-progress strokeColor="#1890ff" :percent="progression" :format="() => progression + '%'" :strokeWidth="22" />
    </template>
    <mcma-card
      class="mcma-card-actual-progress-body"
      v-if="membreStats && membreStats.status !== Constants.STATUS.APPROVED.value"
    >
      <div v-if="validatedParcours">
        <p class="principal-message inline">
          <mcma-button name="Valider mon parcours" color="success" @click="openModal"> </mcma-button>
        </p>
      </div>
      <div v-else>
        <p class="principal-message inline">
          <mcma-button name="Contrôler mon parcours" color="blue" @click="openModal"> </mcma-button>
        </p>
      </div>
      <mcma-validate-parcours-modal
        :visible="verifyParcours"
        @save="save"
        @close="close"
      ></mcma-validate-parcours-modal>
    </mcma-card>
    <mcma-card
      v-if="membreStats && membreStats.status === Constants.STATUS.APPROVED.value"
      class="mcma-card-actual-progress-body"
    >
      <div class="sentence">
        <p class="principal-message inline">
          Vous avez rempli toutes les <strong>informations necessaires</strong>, Merci !
        </p>
      </div>

      <div class="sentence">
        <p class="principal-message with-bottom">
          Nous vous remercions d'avoir entièrement validé vos données. Elles sont maintenant
          <mcma-icon
            leftImage="certified_white"
            color="white"
            :coloriseText="true"
            text-transform="capitalize"
            name="certifiées"
          />
          et transmises à l'équipe en charge de votre groupement.
        </p>
        <p class="secondary-message done">Vous n'avez plus d'action à réaliser.</p>
      </div>
    </mcma-card>
  </mcma-card>
</template>

<script>
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaIcon from "@/components/mcma/common/McmaIcon"
import { mapState } from "vuex"
import Constants from "@/util/Constants"
import McmaValidateParcoursModal from "./McmaValidateParcoursModal"

export default {
  name: "ActualProgress",
  components: {
    McmaCard,
    McmaIcon,
    McmaButton,
    McmaValidateParcoursModal,
  },
  data() {
    return {
      verifyParcours: false,
      parcoursFinish: false,
      validatedParcours: false,
    }
  },
  created() {
    if (
      this.membreStats.statSite.toComplete === 0 &&
      this.membreStats.statGroupement.progress === 1 &&
      this.membreStats.statInterlocuteur.progress === 1 &&
      this.membreStats.statGroupement.link === 1
    ) {
      this.validatedParcours = true
    } else {
      this.validatedParcours = false
    }
  },
  computed: {
    ...mapState(["membre", "membreStats", "user", "operation", "confOperation"]),
    Constants() {
      return Constants
    },
    progression() {
      if (this.membreStats) {
        return Math.trunc((this.membreStats.progress || 0) * 100)
      }
      return 0
    },
  },
  methods: {
    goTo(routeName) {
      this.$router.push({
        name: routeName,
      })
    },
    openModal() {
      this.verifyParcours = true
    },
    close() {
      this.verifyParcours = false
    },
    save() {
      this.parcoursFinish = true
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-card-actual-progress {
  .headerRightSide {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  .card-body {
    .mcma-card-actual-progress-body {
      padding-top: 0;
      .card-body {
        padding-top: 0;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
      }
      .sentence {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        text-align: center;
        .principal-message {
          display: flex;
          flex-flow: column nowrap;
          font-size: 16px;
          color: var(--text-color, $text-color);
          font-weight: 400;
          strong {
            color: var(--primary-color, $primary-color);
          }
          &.inline {
            display: inline;
          }
          &.with-bottom {
            margin-top: var(--padding, $padding);
            margin-bottom: calc(var(--padding, $padding) * 2);
          }
          .mcma-icon {
            align-self: center;
            background: var(--success-color, $success-color);
            border-radius: 4px;
            padding: 8px 15px 7px 15px;
            margin: 20px 0;
            font-weight: 900;
            img {
              height: 22px;
            }
          }
        }
        .secondary-message {
          font-size: 18px;
          font-weight: 900;
          background: var(--secondary-color, $current-color);
          color: $white;
          border-radius: 4px;
          padding: 20px;
          margin-top: var(--padding, $padding);
          position: relative;
          &:before {
            content: "";
            position: absolute;
            top: calc(var(--padding, $padding) * -1);
            left: calc(var(--padding, $padding) * -1);
            width: calc(100% + (var(--padding, $padding) * 2) * 2);
            height: 1px;
            background-image: linear-gradient(to right, $border-color-base 66%, rgba($border-color-base, 0) 0%);
            background-position: top;
            background-size: 15px 1px;
            background-repeat: repeat-x;
          }
          &.done {
            background: var(--success-color, $success-color);
          }
          &.pending {
            background: var(--secondary-color, $current-color);
          }
        }
      }
    }
  }
  .ant-progress {
    .ant-progress-outer {
      width: calc(100% - 60px);
    }
    .ant-progress-text {
      font-size: 22px;
      margin-left: 25px;
      line-height: 25px;
      text-align: right;
      font-weight: 300;
      color: var(--text-color, $text-color);
    }
    &.ant-progress-status-success {
      .ant-progress-text {
        i {
          color: var(--success-color, $success-color);
          svg {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
}
</style>
