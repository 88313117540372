import { render, staticRenderFns } from "./GeneralInfo.vue?vue&type=template&id=ba3a6100"
import script from "./GeneralInfo.vue?vue&type=script&lang=js"
export * from "./GeneralInfo.vue?vue&type=script&lang=js"
import style0 from "./GeneralInfo.vue?vue&type=style&index=0&id=ba3a6100&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports