<template>
  <mcma-card title="administration" icon="administration" class="mcma-card-administration fit-height">
    <template #describe>
      Bienvenue dans l'espace d'administration de l'operation
      {{ operation.name }}. Ici, vous pouvez superviser l'avancement des saisies de vos membres.
    </template>
  </mcma-card>
</template>

<script>
import McmaCard from "@/components/mcma/common/McmaCard.vue"

export default {
  name: "AdministrationInformations",
  props: {
    operation: Object,
  },
  components: {
    McmaCard,
  },
}
</script>

<style lang="scss">
.mcma-card-administration {
  height: 100%;
  min-width: 300px;
}
</style>
