<template>
  <a-form :form="form">
    <mcma-card v-if="showTypeSite" title="Type de site" class="mcma-card-in-tab transparent-bg">
      <a-row type="flex" :gutter="12" class="mt-0 mb-0">
        <mcma-field
          :colSpan="24"
          type="radio"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="typeSite"
          getFlow="name::sites"
          getPanel="name::Données techniques (électricité)"
          getField="type::typeSite"
          :defaultValue="site.typeSite"
        />
      </a-row>
    </mcma-card>

    <a-row type="flex" :gutter="12" class="mt-0 mb-0">
      <mcma-field
        :colSpan="12"
        type="radio"
        :form="form"
        :wasSubmit="wasSubmit"
        fieldName="typeTcfe"
        getFlow="name::sites"
        getPanel="name::Données techniques (électricité)"
        getField="type::typeTcfe"
        :defaultValue="site.typeTcfe"
      />
      <mcma-field
        :colSpan="12"
        type="switch"
        :form="form"
        :wasSubmit="wasSubmit"
        fieldName="confirmeTcfe"
        getFlow="name::sites"
        getPanel="name::Données techniques (électricité)"
        getField="type::confirmeTcfe"
        :defaultValue="site.confirmeTcfe"
      />
      <mcma-field
        v-if="form.getFieldValue('confirmeTcfe')"
        :colSpan="12"
        type="input"
        :form="form"
        :wasSubmit="wasSubmit"
        :disabled="!form.getFieldValue('confirmeTcfe')"
        fieldName="codeApeSite"
        getFlow="name::sites"
        getPanel="name::Données techniques (électricité)"
        getField="type::codeApeSite"
        :required="form.getFieldValue('confirmeTcfe')"
        :defaultValue="site.codeApeSite"
        rule="isApe"
      />
    </a-row>

    <a-row type="flex" :gutter="12" class="mt-0 mb-0">
      <mcma-field
        :colSpan="6"
        type="input"
        :form="form"
        :wasSubmit="wasSubmit"
        extraLabel="A renseigner uniquement en cas de taux réduit (indiquer la valeur applicable) - joindre le CERFA 14318 dans l'étape 'Dépôt de pièces' en fin de parcours"
        fieldName="exonerationCspe"
        getFlow="name::sites"
        getPanel="name::Données techniques (électricité)"
        getField="type::exonerationCspe"
        :defaultValue="site.exonerationCspe"
        numberOnly="decimal"
      />
    </a-row>

    <a-row type="flex" :gutter="12" class="mt-0 mb-0">
      <mcma-field
        :colSpan="24"
        type="switch"
        :form="form"
        :wasSubmit="wasSubmit"
        fieldName="exonerationCee"
        getFlow="name::sites"
        getPanel="name::Données techniques (électricité)"
        getField="type::exonerationCee"
      />
      <mcma-field
        v-if="form.getFieldValue('exonerationCee')"
        :colSpan="12"
        type="input"
        :form="form"
        :wasSubmit="wasSubmit"
        :disabled="!form.getFieldValue('exonerationCee')"
        fieldName="codeNafSite"
        getFlow="name::sites"
        getPanel="name::Données techniques (électricité)"
        getField="type::codeNafSite"
        :required="form.getFieldValue('exonerationCee')"
        :defaultValue="site.codeNafSite"
        rule="isNaf"
      />
    </a-row>

    <mcma-card v-if="showENR" title="Énergie renouvelable" class="mcma-card-in-tab transparent-bg" :inline="true">
      <a-row type="flex" :gutter="12" class="mt-0 mb-0">
        <mcma-field
          :colSpan="24"
          type="select"
          :form="form"
          :wasSubmit="wasSubmit"
          getFlow="name::sites"
          getPanel="name::Données techniques (électricité)"
          getField="type::enr"
          @change="resetHve"
          :defaultValue="defaultValueEnr || 0"
          :required="true"
        >
          <template #information class="flex-column">
            <div
              v-if="getSitesFlow && getSitesFlow.enrDescribe"
              class="html-render-content"
              v-html="getSitesFlow.enrDescribe"
              :class="{ 'can-edit': $isAdmin() }"
              @click="setEditorModal('sites', 'enrDescribe')"
            />
            <span class="action" @click="openEnrInfosModal">Cliquer ici</span>
            pour en savoir plus.
          </template>
        </mcma-field>
        <mcma-field
          type="switch"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="hve"
          getFlow="name::sites"
          getPanel="name::Données techniques (électricité)"
          getField="type::hve"
          :disabled="form.getFieldValue('enr') !== '100'"
          :defaultValue="site.hve"
        />
      </a-row>
    </mcma-card>
    <mcma-card>
      <a-row type="flex" :gutter="12" class="mt25">
        <mcma-button
          v-show="!site.added"
          name=" Actualiser mes données techniques"
          color="primary"
          leftIcon="undo-alt"
          :isFaIcon="true"
          @click="apiFournisseur()"
        />
      </a-row>
      <mcma-spin-box :spinning="loading" :tip="'Chargement en cours'">
        <a-row type="flex" :gutter="12" class="mt25">
          <a-col :span="12" v-if="!site.added">
            <div class="field-block-title">Caractéristiques techniques actuelles</div>
            <a-row type="flex" :gutter="12" class="mtb4">
              <mcma-field
                type="select"
                :required="true"
                :form="form"
                :wasSubmit="wasSubmit"
                :class="{ mb12: !form.getFieldValue('segment') }"
                @change="onUpdateSegment($event)"
                disableValue="Sélectionnez un segment"
                getFlow="name::sites"
                getPanel="name::Données techniques (électricité)"
                getField="type::segment"
                :defaultValue="site.segment"
                :disabledOptions="!site.added && site.segment && (site.segment === 'C_2' || site.segment === 'C_3')"
                :disabled="site.segment != null && site.segment !== 'C_0' && site.segment !== 'A_PRECISER'"
                extraLabel="Le segment choisi dans cette section devra correspondre à la typologie de segment de la facture associée à ce site. Exemple: Un site de segment C_3 ne peut pas être lié à une facture de typologie de segment C5."
              />
            </a-row>

            <a-row type="flex" :gutter="12" class="mtb4" v-if="showFTA && form.getFieldValue('segment') && ftas">
              <mcma-field
                :colSpan="12"
                type="select"
                :form="form"
                :wasSubmit="wasSubmit"
                fieldName="fta"
                @change="onUpdateFta($event)"
                getFlow="name::sites"
                getPanel="name::Données techniques (électricité)"
                getField="type::fta"
                :defaultValue="site.fta"
                :required="ftaRequired"
                :disabled="site.fta != null && site.fta !== 'A_PRECISER' && segmentUpdated === false"
              >
                <a-select-option v-for="(fta, index) in ftas" :key="index" :value="fta">{{
                  $getValueByKey(fta)
                }}</a-select-option>
              </mcma-field>
            </a-row>

            <a-row
              type="flex"
              :gutter="12"
              class="mtb4"
              v-if="showPS && showFTA && form.getFieldValue('segment') && puissances && puissances.length > 0"
            >
              <a-col :span="24">
                <ul class="red-text" v-if="invalidPs">
                  Des règles ne sont pas respectés :
                  <li v-for="error in invalidPs" :key="error">{{ error }}</li>
                </ul>
              </a-col>
            </a-row>
            <a-row
              type="flex"
              :gutter="12"
              class="mtb4"
              v-if="showPS && showFTA && form.getFieldValue('segment') && puissances && puissances.length > 0"
            >
              <div class="flex-column">
                <label class="mcma-label">Puissance(s)</label>
                <span v-if="getPsRule(form.getFieldValue('fta')) === 'isInt'">Doit contenir un entier à 6 chiffres maximum</span>
                <span v-if="getPsRule(form.getFieldValue('fta')) === 'isFloat'"
                  >Doit contenir un nombre décimal à 6 chiffres maximum et 2 décimales maximum</span
                >
                <div>
                  <mcma-field
                    v-for="puissance in puissances"
                    :key="puissance"
                    :colSpan="8"
                    type="input"
                    :form="form"
                    :wasSubmit="wasSubmit"
                    @change="inputPs($event, puissance)"
                    :fieldName="`ps-${puissance}`"
                    :defaultValue="defaultValuePS(puissance)"
                    :label="`${puissance} ${
                      form.getFieldValue('segment') === 'C_2' || form.getFieldValue('segment') === 'C_3'
                        ? '(kW)'
                        : '(kVA)'
                    }`"
                    :required="true"
                    :rule="getPsRule(form.getFieldValue('fta'))"
                  />
                </div>
              </div>
            </a-row>
          </a-col>
        </a-row>
        <a-row type="flex" :gutter="12" class="mt0">
          <mcma-field
            :colSpan="12"
            type="input"
            :form="form"
            getFlow="name::sites"
            getPanel="name::Données techniques (électricité)"
            getField="type::matriculeCompteurElec"
            :wasSubmit="wasSubmit"
            fieldName="matriculeCompteur"
            :disabled="site.matriculeCompteur != null"
            :defaultValue="site.matriculeCompteur"
          />
        </a-row>
        <a-row type="flex" :gutter="12" class="mt0">
          <mcma-field
            v-if="showStructureComptage"
            :colSpan="12"
            type="select"
            :form="form"
            getFlow="name::sites"
            getPanel="name::Données techniques (électricité)"
            getField="type::structureComptage"
            :wasSubmit="wasSubmit"
            fieldName="structureComptage"
            :disabled="site.structureComptage != null"
            :defaultValue="site.structureComptage"
          />
        </a-row>
        <a-row type="flex" :gutter="12" class="mt0">
          <mcma-field
            :colSpan="12"
            type="select"
            :form="form"
            v-if="showContextUtilisation"
            getFlow="name::sites"
            getPanel="name::Données techniques (électricité)"
            getField="type::contexteUtilisation"
            :wasSubmit="wasSubmit"
            fieldName="contexteUtilisation"
            :disabled="site.contexteUtilisation != null"
            :defaultValue="site.contexteUtilisation"
            :required="site.segment === 'C_5'"
          />
        </a-row>
        <a-row type="flex" :gutter="12" class="mt0">
          <mcma-field
            :colSpan="12"
            type="select"
            :form="form"
            getFlow="name::sites"
            getPanel="name::Données techniques (électricité)"
            getField="type::typeCompteur"
            :wasSubmit="wasSubmit"
            fieldName="typeCompteur"
            :disabled="site.typeCompteur != null"
            :defaultValue="site.typeCompteur"
          />
        </a-row>
        <a-row>
          <mcma-field
            :colSpan="12"
            type="select"
            :form="form"
            :wasSubmit="wasSubmit"
            fieldName="statutCompteur"
            label="Statut compteur"
            :disabled="site.statutCompteur !== null"
            :defaultValue="site.statutCompteur"
          />
        </a-row>
      </mcma-spin-box>
      <a-row type="flex" :gutter="12">
        <a-col :span="24">
          <div class="button-group-inline">
            <mcma-button
              name="Réinitialiser"
              color="primary"
              leftIcon="undo-alt"
              :isFaIcon="true"
              @click="initialize"
            />
            <mcma-button
              name="Valider les informations"
              class="ml12"
              color="success"
              leftIcon="save"
              :isFaIcon="true"
              @click="submit"
            />
          </div>
        </a-col>
      </a-row>
    </mcma-card>
    <mcma-editor-modal
      v-if="$isAdmin()"
      :visible="showEditor"
      :flowName="flowName"
      :flowProp="flowProp"
      @onCancel="setEditorModal"
      @onSave="editorHasMakeChange"
    />

    <mcma-enr-infos-modal
      :visible="openEnrInfos"
      :htmlRender="getSitesFlow && getSitesFlow.enrModalDescribe ? getSitesFlow.enrModalDescribe : null"
      @setEditorModal="setEditorModal('sites', 'enrModalDescribe')"
      @close="closeEnrInfos"
    />
  </a-form>
</template>

<script>
import Axios from "@/util/Axios"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaField from "@/components/mcma/common/McmaField"
import McmaEditorModal from "@/components/mcma/common/McmaEditorModal"
import McmaEnrInfosModal from "@/components/mcma/common/McmaEnrInfosModal"
import { getDynamiqueFields } from "@/util/Helpers/DynamiqueFields"
import { pick } from "@/util/Helpers"
import { mapState } from "vuex"
import { Ftas, Segments } from "./Puissances"
import { RA_RESULT, RA_TURPE, RV_RESULT, RV_TURPE } from "./RG_TURPE"
import Materialize from "materialize-css"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"

export default {
  name: "etape_2_elec",
  props: ["site"],
  components: {
    McmaCard,
    McmaField,
    McmaButton,
    McmaEditorModal,
    McmaEnrInfosModal,
    McmaSpinBox,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      wasSubmit: false,
      openEnrInfos: false,
      ftas: [],
      puissances: [],
      invalidPs: false,
      showEditor: false,
      flowName: null,
      flowProp: null,
      responseApi: null,
      puissanceFromApi: null,
      loading: false,
      showContextUtilisation: false,
      showStructureComptage: false,
      ftaRequired: true,
      segmentUpdated: false,
    }
  },
  computed: {
    ...mapState(["operation", "membre"]),
    getPsRule() {
      return (fta) => {
        if (fta === "BTINF_LU_BASE") return "isFloat"
        return "isInt"
      }
    },
    showTypeSite() {
      const typeSite = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::typeSite"
      )
      return typeSite ? typeSite.displayed : false
    },
    showENR() {
      const enr = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::enr"
      )
      return enr ? enr.displayed : false
    },
    activeENRUnique() {
      const enrUniqueSite = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::enrUniqueSite"
      )
      return enrUniqueSite ? enrUniqueSite.displayed : false
    },
    showFTA() {
      const fta = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::fta"
      )
      return fta ? fta.displayed : false
    },
    showPS() {
      const power = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::power"
      )
      return power ? power.displayed : false
    },
    showSegment() {
      const segment = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::segment"
      )
      return segment ? segment.displayed : false
    },
    defaultValueEnr() {
      let enr = 0
      enr = this.activeENRUnique && this.membre.enrUniqueSite ? this.membre.enrUniqueSite : this.site.enr
      return enr
    },
    getSitesFlow() {
      return this.operation.flows.find((flow) => flow.name === "sites")
    },
    psInitial() {
      return this.site.psInitial ? JSON.parse(this.site.psInitial) : {}
    },
    canUpdate() {
      return (fieldsValue) => {
        const ps = this.getAllPsAvailable(fieldsValue, true)
        for (let key in ps) {
          if (!ps[key] || ps[key] === "0") {
            return false
          }
        }
      }
    },
    defaultValuePS() {
      return (puissance) => {
        if (puissance === "PTE/PM") {
          return this.site.fta === "BTSUP_LU" && !this.psInitial["PTE/PM"]
            ? this.psInitial["HPH"]
            : this.psInitial["PTE/PM"]
        }
        const sitePs = this.site.ps ? JSON.parse(this.site.ps) : {}
        const dataElecFromApi = this.puissanceFromApi !== null ? this.puissanceFromApi : {}
        return dataElecFromApi[puissance] || sitePs[puissance] || this.psInitial[puissance] || "0"
      }
    },
  },
  methods: {
    initialize() {
      this.puissances = []
      let editSiteElec = pick(this.site, [
        "enr",
        "typeSite",
        "hve",
        "typeTcfe",
        "exonerationCspe",
        "exonerationCee",
        "segment",
        "fta",
        "commentaire",
        "confirmeTcfe",
        "codeApeSite",
        "codeNafSite",
      ])
      this.$nextTick(() => {
        editSiteElec.exonerationCspe = this.integerToString(editSiteElec.exonerationCspe)
        editSiteElec = this.$mergeObject(this.form.getFieldsValue(), editSiteElec)
        this.form.setFieldsValue(editSiteElec)
        this.initSegment()
      })
      if (this.site != null) {
        if (this.site.fta != null) {
          if (this.site.fta.includes("LU")) {
            this.showContextUtilisation = true
          }
        }
        if (this.site.segment === "C_5") {
          this.showStructureComptage = true
        }
      }
    },
    ftaRequiredHandler(statutCompteur) {
      if (statutCompteur !== null && statutCompteur === "RESILIATION") {
        this.ftaRequired = false
      }
      return this.ftaRequired
    },
    apiFournisseur() {
      this.loading = true
      Axios("get", "fournisseur/point/elec/" + this.site.rae)
        .then((response) => {
          this.loading = false
          let responseApi = response.data
          this.puissanceFromApi = {
            HPH: responseApi.puissanceHPH,
            HCH: responseApi.puissanceHCH,
            HCE: responseApi.puissanceHCB,
            HPE: responseApi.puissanceHPB,
            HC: responseApi.puissanceHC,
            HP: responseApi.puissanceHP,
            BASE: responseApi.puissanceBase
          }

          for (const [key, value] of Object.entries(this.puissanceFromApi)) {
            this.defaultValuePS(key)
          }

          this.form.setFieldsValue({
            typeCompteur: responseApi.typeCompteur !== null ? responseApi.typeCompteur : this.site.typeCompteur,
            matriculeCompteur:
              responseApi.matriculeCompteur !== null ? responseApi.matriculeCompteur : this.site.matriculeCompteur,
            structureComptage:
              responseApi.structureComptage !== null
                ? responseApi.structureComptage.toUpperCase()
                : this.site.structureComptage,
            contexteUtilisation:
              responseApi.contexteUtilisation !== null
                ? responseApi.contexteUtilisation
                : this.site.contexteUtilisation,
            ftaInitial: responseApi.fta !== null ? responseApi.fta : this.site.ftaInitial,
            fta: responseApi.fta !== null ? responseApi.fta : this.site.fta,
            segmentInitial: responseApi.segment !== null ? responseApi.segment : this.site.segment,
            segment: responseApi.segment !== null ? responseApi.segment : this.site.segment,
            statutCompteur: responseApi.statut !== null ? responseApi.statut : "Pas de statut",
          })
          this.ftaRequiredHandler(responseApi.statut)

          if (responseApi.segment !== null) {
            this.onUpdateSegment(responseApi.segment)
          }
          if (responseApi.fta !== null) {
            this.onUpdateFta(responseApi.fta)
          }
          Materialize.toast("Récupération des données fournisseur: OK", 4000, "lime white-text")
        })
        .catch((error) => {
          this.loading = false
          console.error("error api:" + error)
          Materialize.toast(
            "La référence saisie n’est pas reconnue par Enedis et GRDF, veuillez vérifier la référence ou préciser le nom du gestionnaire de réseau",
            4000,
            "amber white-text"
          )
        })
    },
    integerToString(currentField) {
      currentField = currentField ? currentField.toString() : "0"
    },
    resetHve() {
      this.$nextTick(() => {
        this.form.setFieldsValue({
          hve: false,
        })
      })
    },
    setEditorModal(flowName, flowProp) {
      console.group("setEditorModal@Etape2_Elec")
      console.log("flowName : %O", flowName)
      console.log("flowProp : %O", flowProp)
      this.showEditor = !!(flowName && flowProp)
      if (flowName && flowProp) {
        this.flowName = flowName
        this.flowProp = flowProp
      } else {
        setTimeout(() => {
          this.flowName = flowName
          this.flowProp = flowProp
        }, 300)
      }
      console.groupEnd()
    },
    editorHasMakeChange() {
      this.setEditorModal()
    },
    submit() {
      let valid = true
      this.form.validateFields((errors, fieldsValue) => {
        if (errors) {
          // canUpdate utile?
          this.$notification.warning({
            message: "Formulaire invalide",
            description: `Veuillez-vous assurez de remplir tous les champs requis.`,
          })
        } else {
          const currentFta = Ftas[fieldsValue.fta] || {}
          fieldsValue.ps = this.getAllPsAvailable(fieldsValue, true)
          if (currentFta.puissances && currentFta.puissances.length > 0) {
            for (let key in fieldsValue.ps) {
              if (!currentFta.puissances.includes(key)) {
                delete fieldsValue.ps[key]
              }
            }
          }

          valid = this.validatePs(fieldsValue) ? valid : false

          if (valid) {
            Axios("patch", "api/sites/" + this.site.id, {
              ongletParametre: true,
              enr: fieldsValue.enr,
              typeSite: fieldsValue.typeSite,
              typeTcfe: fieldsValue.typeTcfe,
              confirmeTcfe: fieldsValue.confirmeTcfe,
              codeApeSite: fieldsValue.codeApeSite,
              exonerationCspe: fieldsValue.exonerationCspe,
              exonerationCee: fieldsValue.exonerationCee,
              codeNafSite: fieldsValue.codeNafSite,
              hve: fieldsValue.hve,
              segment: fieldsValue.segment,
              fta: fieldsValue.fta,
              ps: fieldsValue.ps ? JSON.stringify(fieldsValue.ps) : fieldsValue.ps,
              commentaire: fieldsValue.commentaire,
              multiSite: true,
              matriculeCompteur: fieldsValue.matriculeCompteur,
              structureComptage: fieldsValue.structureComptage,
              contexteUtilisation: fieldsValue.contexteUtilisation,
              typeCompteur: fieldsValue.typeCompteur,
              statutCompteur: fieldsValue.statutCompteur,
            })
              .then((response) => {
                this.$emit("update", response.data)
                this.$notification.success({
                  message: "Paramètres sauvegardées !",
                  description: `Les paramètres ont bien été sauvegardées`,
                })
              })
              .catch((error) => {
                console.error(error)
                this.$notification.error({
                  message: "Erreur serveur",
                  description: "Une erreur server est survenue, veuillez contacter un administrateur.",
                })
              })
          } else {
            this.$notification.warning({
              message: "Formulaire invalide",
              description: `Veuillez-vous assurez de remplir tous les champs requis.`,
            })
          }
        }
      })
    },
    validatePs(fieldsValue) {
      this.invalidPs = false

      const errors = []
      const derogables = []
      let valid = true

      for (const rv in RV_TURPE) {
        const result = RV_TURPE[rv](
          fieldsValue.typeSite === "ECLAIRAGE_PUBLIC",
          fieldsValue.segment,
          fieldsValue.fta,
          fieldsValue.ps
        )
        if (result.state === RV_RESULT.KO) {
          errors.push(result.message)
          valid = false
        } else if (result.state === RV_RESULT.DEROGABLE) {
          derogables.push(result.message)
        }
      }

      if (valid && derogables.length) {
        let message = "Les puissances indiquées pour votre site ne respectent pas les règles suivantes :\n"
        for (const derogable of derogables) {
          message += "  - " + derogable + "\n"
        }
        message += "Voulez-vous vraiment déroger à ces règles?"

        if (!confirm(message)) {
          return false
        }
      } else if (!valid && errors.length) {
        this.invalidPs = errors
        return false
      }
      return true
    },

    inputPs(value, item) {
      let adjust = value
      for (const ra in RA_TURPE) {
        const result = RA_TURPE[ra](
          this.form.getFieldValue("typeSite") === "ECLAIRAGE_PUBLIC",
          this.form.getFieldValue("segment"),
          this.form.getFieldValue("fta"),
          adjust
        )
        if (result.state === RA_RESULT.ADJUSTED) {
          adjust = result.value
        }
      }
      if (adjust !== value) {
        this.form.setFieldsValue({
          [item]: adjust,
        })
      }
    },
    initSegment() {
      const segment = this.form.getFieldValue("segment")
      const dynSegment = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::segment",
        segment
      )
      if (segment && dynSegment && dynSegment.lockedValues.includes(segment || dynSegment.value)) {
        this.loadFtas(segment)
        const fta = this.form.getFieldValue("fta") || this.site.fta
        this.puissances = fta ? Ftas[fta].puissances : undefined
      } else {
        const toReset = {
          segment: undefined,
          fta: undefined,
        }
        const ps = this.getAllPsAvailable()
        ps.forEach((key) => {
          toReset[key] = undefined
        })
        this.form.setFieldsValue(toReset)
      }
    },
    getAllPsAvailable(fieldsValues, getHasObject) {
      if (!fieldsValues) {
        fieldsValues = this.form.getFieldsValue()
      }
      const fieldsValuesKeys = Object.keys(fieldsValues) || []
      const psKeys = fieldsValuesKeys.filter((item) => item.startsWith("ps-"))
      if (getHasObject) {
        const psObject = {}
        psKeys.forEach((key) => {
          const keyParse = key.replace("ps-", "")
          psObject[keyParse] = fieldsValues[key]
        })
        return psObject
      }
      return psKeys
    },
    loadFtas(segment) {
      const segmentObject = Segments[segment.toUpperCase()] || {}
      const ftasBySegment = segmentObject.fta || []
      const dynFta = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::fta",
        this.form.getFieldValue("fta")
      )
      const lockedValues = dynFta.lockedValues || []
      this.ftas = ftasBySegment.filter(function (item) {
        return lockedValues.includes(item.name)
      })
      this.ftas = this.ftas.map((fta) => fta.name)
      if (this.ftas.length > 0) {
        if (!this.ftas.includes(this.site.fta)) {
          this.emptyForm()
        }
      } else {
        this.emptyForm()
      }
    },
    emptyForm() {
      this.puissances = []
      const toReset = { fta: undefined }
      const ps = this.getAllPsAvailable()
      ps.forEach((key) => {
        toReset[key] = undefined
      })
      this.form.setFieldsValue(toReset)
    },
    onUpdateSegment(segment) {
      this.segmentUpdated = true
      this.loadFtas(segment)
      if (segment === "C_5") {
        this.showStructureComptage = true
      } else {
        this.showStructureComptage = false
      }
    },
    onUpdateFta(fta) {
      this.puissances = Ftas[fta].puissances
      if (fta != null) {
        if (fta.includes("LU")) {
          this.showContextUtilisation = true
        } else {
          this.showContextUtilisation = false
        }
      }
    },
    closeEnrInfos() {
      this.openEnrInfos = false
    },
    openEnrInfosModal() {
      this.openEnrInfos = true
    },
  },
  watch: {
    site() {
      this.initialize()
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.error-content {
  display: flex;
  width: 100%;
  height: 50px;
  background: #f15921;
  margin: 10px 6px;
  border-radius: 4px;
  .error {
    margin: auto;
    color: white;
    font-weight: 900;
  }
}
</style>
