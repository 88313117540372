<template>
  <main>
    <section>
      <form
        class="card"
        @submit.prevent="submit"
        v-if="groupement.state !== Constants.STATUS.CERTIFIED.value || $route.params.action === 'duplique'"
        id="validGroupForm"
      >
        <div class="card-content">
          <span class="card-title">
            <span v-if="$route.params.action === 'duplique'">
              Duplication du périmètre de facturation : {{ groupement.nom }}
            </span>
            <span v-else-if="groupement.id"> Édition du périmètre de facturation : {{ groupement.nom }} </span>
            <span v-else> Nouveau périmètre de facturation </span>
          </span>

          <div class="row">
            <article class="input-field col s6">
              <input id="nom" type="text" required v-model="nom" />
              <label :class="{ active: nom }" for="nom">
                <font-awesome-icon icon="asterisk" class="red-text" />
                Nom du périmètre de facturation
              </label>
            </article>

            <budget-name class="col s6" v-model="budget" />

            <article class="input-field col s6">
              <input id="raisonSocialPayeur" type="text" required v-model="raisonSocialPayeur" />
              <label :class="{ active: raisonSocialPayeur }" for="raisonSocialPayeur">
                <font-awesome-icon icon="asterisk" class="red-text" />
                Raison sociale du gestionnaire de la facture
              </label>
            </article>

            <article class="input-field col s6">
              <input id="adresseFacturation" type="text" required v-model="adresseFacturation" />
              <label :class="{ active: adresseFacturation }" for="adresseFacturation">
                <font-awesome-icon icon="asterisk" class="red-text" />
                Adresse d’envoi de la facture
              </label>
            </article>

            <code-postal class="col s6" v-model="codePostal" />

            <article class="input-field col s6">
              <input id="commune" type="text" required v-model="commune" />
              <label :class="{ active: commune }" for="commune">
                <font-awesome-icon icon="asterisk" class="red-text" />
                Commune
              </label>
            </article>

            <label
              >Sélectionner un interlocuteur en charge du traitement/suivi de la facture :
              <br />
              N.B : Si l'interlocuteur souhaité n'apparaît pas dans la liste, veuillez vérifier que celui-ci possède
              toutes les informations requises (prénom, nom, email, téléphone).
              <router-link
                :to="{ name: 'interlocuteurs' }"
                class="waves-effect waves-light btn blue darken-2 tutorialNoBtn"
                id="validInterlocutorBtn"
              >
                Vérifier mes informations interlocuteurs
              </router-link>
            </label>
            <select class="browser-default" v-model="currentInterlocuteur">
              <option v-for="(interlocuteur, index) in interlocuteurs" :key="index" :value="interlocuteur">
                {{ interlocuteur ? interlocuteur.friendly : " + Ajouter un nouvel interlocuteur" }}
              </option>
            </select>

            <article class="input-field col s6">
              <input
                id="interlocuteurPayeurNom"
                type="text"
                required
                v-model="interlocuteurPayeurNom"
                :disabled="currentInterlocuteur"
              />
              <label :class="{ active: interlocuteurPayeurNom }" for="interlocuteurPayeurNom">
                <font-awesome-icon icon="asterisk" class="red-text" />
                Interlocuteur payeur (nom)
              </label>
            </article>

            <article class="input-field col s6">
              <input
                id="interlocuteurPayeurPrenom"
                type="text"
                required
                v-model="interlocuteurPayeurPrenom"
                :disabled="currentInterlocuteur"
              />
              <label :class="{ active: interlocuteurPayeurPrenom }" for="interlocuteurPayeurPrenom">
                <font-awesome-icon icon="asterisk" class="red-text" />
                Interlocuteur payeur (prénom)
              </label>
            </article>

            <telephone class="col s6" v-model="telephonePayeur" :disabled="currentInterlocuteur" />

            <email class="col s6" v-model="emailPayeur" :disabled="currentInterlocuteur" />

            <siret class="col s6" v-model="siret" />

            <iban class="col s6" :context="sepaContext" v-model="iban" />

            <generic-field
              v-model="moyenPaiement"
              defaultLabel="Moyen de paiement"
              defaultDisabledValue="Sélectionnez un mode de paiement"
              getFlow="name::groupements"
              getPanel="name::groupements"
              getField="type::moyenPaiement"
              class="col s6"
            />

            <generic-field
              v-model="rythmeFacturation"
              defaultLabel="Rythme de facturation"
              defaultDisabledValue="Sélectionnez un rythme de facturation"
              getFlow="name::groupements"
              getPanel="name::groupements"
              getField="type::rythmeFacturation"
              class="col s6"
            />

            <generic-field
              v-model="delaiPaiement"
              defaultLabel="Délai de paiement"
              defaultDisabledValue="Sélectionnez un délai de paiement"
              getFlow="name::groupements"
              getPanel="name::groupements"
              getField="type::delaiPaiement"
              class="col s6"
            />

            <sepa v-if="sepaDisplay" class="col s12" :context="sepaContext" v-model="sepa" />

            <code-identification-individuelle
              class="col s6"
              :context="sepaContext"
              v-model="codeIdentificationIndividuelle"
            />

            <num-convention-tripartite class="col s6" v-model="numConventionTripartite" />

            <div class="row" v-if="chorus">
              <article class="input-field col s6">
                <input
                  id="engagementJuridique"
                  type="text"
                  :required="chorus && chorus.gestionEngagement"
                  v-model="engagementJuridique"
                />
                <label :class="{ active: engagementJuridique }" for="engagementJuridique">
                  <font-awesome-icon icon="asterisk" class="red-text" v-if="chorus && chorus.gestionEngagement" />
                  Engagement juridique le cas échéant
                </label>
              </article>

              <article class="input-field col s6">
                <input
                  id="codeServiceExecutant"
                  type="text"
                  :required="chorus && chorus.gestionService"
                  v-model="codeServiceExecutant"
                />
                <label :class="{ active: codeServiceExecutant }" for="codeServiceExecutant">
                  <font-awesome-icon icon="asterisk" class="red-text" v-if="chorus && chorus.gestionService" />
                  Code service exécutant le cas échéant
                </label>
              </article>
            </div>
            <div class="row">
              <generic-field
                v-if="!chorus"
                v-model="papier"
                getFlow="name::groupements"
                getPanel="name::groupements"
                getField="type::papier"
                :reverseToogleValue="true"
              />
              <generic-field
                v-if="!chorus"
                v-model="optionExcel"
                getFlow="name::groupements"
                getPanel="name::groupements"
                getField="type::optionExcel"
              />
              <generic-field
                v-if="!chorus"
                v-model="dematXml"
                getFlow="name::groupements"
                getPanel="name::groupements"
                getField="type::dematXml"
              />
            </div>
          </div>
        </div>
        <center class="card-action">
          <button
            type="submit"
            class="waves-effect waves-light btn light-green tutoDoneRetourToRouteGroupements"
            id="validGroupValid"
          >
            <i class="material-icons">save</i>
            Valider
          </button>
          <button type="button" class="waves-effect waves-light btn blue darken-2" @click="initialize">
            <i class="material-icons">remove</i>
            Réinitialiser
          </button>
        </center>
      </form>
      <section v-else class="card-panel white-text deep-orange">
        Ce périmètre de facturation ne peux pas être modifié car il a déjà été envoyé en validation.
      </section>
    </section>
  </main>
</template>

<script>
import Axios from "@/util/Axios"
import GroupementsDetailCardHeader from "@/components/mcma/steps/groupements/components/GroupementsDetailCardHeader"
import GroupementsEdit from "@/components/mcma/steps/groupements/components/GroupementsEdit"
import { mapState, mapMutations } from "vuex"
import MixinValidator from "@/util/Mixin/Validator"
import Materialize from "materialize-css"
import {
  DelaiPaiement,
  MoyenPaiement,
  Sepa,
  CodeIdentificationIndividuelle,
  NumConventionTripartite,
  Iban,
  CodePostal,
  Email,
  Telephone,
  Siret,
  BudgetName,
  RythmeFacturation,
} from "@/components/common/Fields"
import GenericField from "@/components/common/Fields/GenericField"
import _ from "lodash"
import Constants from "@/util/Constants"

export default {
  name: "groupement-form",
  mixins: [MixinValidator],
  components: {
    GroupementsEdit,
    GroupementsDetailCardHeader,
    DelaiPaiement,
    MoyenPaiement,
    Sepa,
    CodeIdentificationIndividuelle,
    NumConventionTripartite,
    Iban,
    CodePostal,
    Email,
    Telephone,
    Siret,
    BudgetName,
    GenericField,
    RythmeFacturation,
  },
  props: ["groupement"],
  data() {
    return {
      nom: null,
      budget: null,
      raisonSocialPayeur: null,
      adresseFacturation: null,
      codePostal: null,
      commune: null,
      interlocuteurPayeurNom: null,
      interlocuteurPayeurPrenom: null,
      telephonePayeur: null,
      emailPayeur: null,
      siret: null,
      iban: null,
      moyenPaiement: null,
      delaiPaiement: null,
      codeIdentificationIndividuelle: null,
      numConventionTripartite: null,
      engagementJuridique: null,
      codeServiceExecutant: null,
      papier: false,
      sepa: null,
      rythmeFacturation: null,
      optionExcel: false,
      dematXml: false,
      chorus: null,
      interlocuteurs: [],
      currentInterlocuteur: null,
    }
  },
  computed: {
    ...mapState(["membre", "operation"]),
    Constants() {
      return Constants
    },
    sepaContext() {
      return {
        id: this.groupement.id,
        nom: this.nom,
        moyenPaiement: this.moyenPaiement,
        sepa: this.groupement.sepa,
      }
    },
    sepaDisplay: {
      get() {
        console.group("sepaDisplay")
        if (this.operation && this.operation.flows) {
          const flow = _.find(this.operation.flows, ["name", "groupements"])
          console.log(">>>> flow : %O", flow)
          if (flow && flow.panels) {
            const panel = _.find(flow.panels, ["name", "groupements"])
            console.log(">>>> panel : %O", panel)
            if (panel && panel.fields) {
              let field = _.find(panel.fields, ["type", "moyenPaiement"])
              console.log(">>>> field : %O", field)
              if (field) {
                console.log(">>>> field.lockedValues : %O", field.lockedValues)
                let lockedValues = field.lockedValues
                let dis = lockedValues.find(function (element) {
                  console.log(">>>> element : %O", element)
                  return element === "Prélèvement"
                })
                return dis
              }
            }
          }
        }
        console.groupEnd()
        return true
      },
    },
    state() {
      let state = "COMPLETED"
      if (this.moyenPaiement === "PRELEVEMENT" && !(this.sepa.check && (this.sepa.file || this.groupement.sepa))) {
        state = "TO_COMPLETE"
      }
      return state
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    initialize() {
      console.log("> this.groupement : %O", this.groupement)
      this.nom = this.groupement.nom
      this.budget = this.groupement.budget
      this.raisonSocialPayeur = this.groupement.raisonSocialPayeur
      this.adresseFacturation = this.groupement.adresseFacturation
      this.codePostal = this.groupement.codePostal
      this.commune = this.groupement.commune
      this.interlocuteurPayeurNom = this.groupement.interlocuteurPayeurNom
      this.interlocuteurPayeurPrenom = this.groupement.interlocuteurPayeurPrenom
      this.telephonePayeur = this.groupement.telephonePayeur
      this.emailPayeur = this.groupement.emailPayeur
      this.siret = this.groupement.siret
      this.iban = this.groupement.iban
      this.moyenPaiement = this.groupement.moyenPaiement
      this.delaiPaiement = this.groupement.delaiPaiement
      this.codeIdentificationIndividuelle = this.groupement.codeIdentificationIndividuelle
      this.numConventionTripartite = this.groupement.numConventionTripartite
      this.engagementJuridique = this.groupement.engagementJuridique
      this.codeServiceExecutant = this.groupement.codeServiceExecutant
      this.rythmeFacturation = this.groupement.rythmeFacturation
      this.optionExcel = this.groupement.optionExcel
      this.dematXml = this.groupement.dematXml
      this.papier = this.groupement.id ? this.groupement.papier : true
      this.sepa = {
        file: null,
        check: this.groupement.sepa,
      }
      this.interlocuteurs = [null]
      this.currentInterlocuteur = null
      if (
        this.membre.interlocuteurFacture &&
        this.membre.interlocuteurFacture.nom &&
        this.membre.interlocuteurFacture.prenom &&
        (this.membre.interlocuteurFacture.telephone || this.membre.interlocuteurFacture.mobile) &&
        this.membre.interlocuteurFacture.mail
      ) {
        this.interlocuteurs.push({
          nom: this.membre.interlocuteurFacture.nom,
          prenom: this.membre.interlocuteurFacture.prenom,
          telephone:
            this.membre.interlocuteurFacture.telephone.length > 0
              ? this.membre.interlocuteurFacture.telephone
              : this.membre.interlocuteurFacture.mobile,
          mail: this.membre.interlocuteurFacture.mail,
          friendly:
            this.membre.interlocuteurFacture.nom.toUpperCase() +
            " " +
            _.capitalize(this.membre.interlocuteurFacture.prenom),
        })
        this.currentInterlocuteur = {
          nom: this.membre.interlocuteurFacture.nom,
          prenom: this.membre.interlocuteurFacture.prenom,
          telephone:
            this.membre.interlocuteurFacture.telephone.length > 0
              ? this.membre.interlocuteurFacture.telephone
              : this.membre.interlocuteurFacture.mobile,
          mail: this.membre.interlocuteurFacture.mail,
          friendly:
            this.membre.interlocuteurFacture.nom.toUpperCase() +
            " " +
            _.capitalize(this.membre.interlocuteurFacture.prenom),
        }
      }
      if (
        this.groupement &&
        this.groupement.interlocuteurPayeurNom &&
        this.groupement.interlocuteurPayeurPrenom &&
        this.groupement.telephonePayeur &&
        this.groupement.emailPayeur
      ) {
        this.interlocuteurs.push({
          nom: this.groupement.interlocuteurPayeurNom,
          prenom: this.groupement.interlocuteurPayeurPrenom,
          telephone: this.groupement.telephonePayeur,
          mail: this.groupement.emailPayeur,
          friendly:
            this.groupement.interlocuteurPayeurNom.toUpperCase() +
            " " +
            _.capitalize(this.groupement.interlocuteurPayeurPrenom),
        })
        this.currentInterlocuteur = {
          nom: this.groupement.interlocuteurPayeurNom,
          prenom: this.groupement.interlocuteurPayeurPrenom,
          telephone: this.groupement.telephonePayeur,
          mail: this.groupement.emailPayeur,
          friendly:
            this.groupement.interlocuteurPayeurNom.toUpperCase() +
            " " +
            _.capitalize(this.groupement.interlocuteurPayeurPrenom),
        }
      }
      Axios("get", "api/membres/" + this.membre.id + "/interlocuteurs").then((response) => {
        for (const interlocuteur of response.data._embedded.interlocuteurs) {
          console.log(interlocuteur)
          console.log({
            nom: interlocuteur.nom,
            prenom: interlocuteur.prenom,
            telephone: interlocuteur.telephone,
            mail: interlocuteur.mail,
            friendly: interlocuteur.nom.toUpperCase() + " " + _.capitalize(interlocuteur.prenom),
          })
          if (
            interlocuteur.nom &&
            interlocuteur.prenom &&
            interlocuteur.telephone &&
            interlocuteur.mail &&
            !_.find(this.interlocuteurs, ["mail", interlocuteur.mail]) &&
            (interlocuteur.role === "GESTIONNAIRE" ||
              interlocuteur.role === "FACTURATION" ||
              interlocuteur.role === "MEMBRE")
          ) {
            this.interlocuteurs.push({
              nom: interlocuteur.nom,
              prenom: interlocuteur.prenom,
              telephone: interlocuteur.telephone,
              mail: interlocuteur.mail,
              friendly: interlocuteur.nom.toUpperCase() + " " + _.capitalize(interlocuteur.prenom),
            })
            console.log("this.interlocuteurs : %O", this.interlocuteurs)
          }
        }
      })
    },
    submit() {
      if (!this.currentInterlocuteur) {
        Axios("post", "api/interlocuteurs", {
          nom: this.interlocuteurPayeurNom,
          prenom: this.interlocuteurPayeurPrenom,
          telephone: this.telephonePayeur,
          mail: this.emailPayeur,
          role: "FACTURATION",
          removed: false,
          membre: process.env.VUE_APP_API_URL + "/api/membres/" + this.membre.id,
          operationId: this.operation.id,
        })
          .then((response) => {
            Materialize.toast("Interlocuteur ajouté.", 4000, "lime")
          })
          .catch((error) => {
            console.error("[Add interlocutor] " + error)
          })
      }

      const groupement = {
        membre: process.env.VUE_APP_API_URL + "/api/membres/" + this.membre.id,
        nom: this.nom,
        budget: this.budget,
        raisonSocialPayeur: this.raisonSocialPayeur,
        adresseFacturation: this.adresseFacturation,
        codePostal: this.codePostal,
        commune: this.commune,
        interlocuteurPayeurNom: this.interlocuteurPayeurNom,
        interlocuteurPayeurPrenom: this.interlocuteurPayeurPrenom,
        telephonePayeur: this.telephonePayeur,
        emailPayeur: this.emailPayeur,
        siret: this.siret,
        iban: this.iban,
        moyenPaiement: this.moyenPaiement,
        delaiPaiement: this.delaiPaiement,
        codeIdentificationIndividuelle: this.codeIdentificationIndividuelle,
        numConventionTripartite: this.numConventionTripartite,
        engagementJuridique: this.engagementJuridique,
        codeServiceExecutant: this.codeServiceExecutant,
        state: this.state,
        papier: this.papier,
        sepa: this.sepa.check,
        operationId: this.operation.id,
        rythmeFacturation: this.rythmeFacturation,
        optionExcel: this.optionExcel,
        dematXml: this.dematXml,
      }
      console.log(">> Modif/Add groupement : %O", groupement)
      let request = null
      if (this.$route.query.action !== "duplique" && this.groupement.id) {
        request = Axios("patch", "api/groupements/" + this.groupement.id, groupement)
      } else {
        request = Axios("post", "api/groupements", groupement)
      }

      request
        .then((response) => {
          if (this.$route.query.action !== "duplique") {
            Materialize.toast("Le périmètre de facturation a bien été sauvegardé", 4000, "lime")
          } else {
            Materialize.toast("Le périmètre de facturation a bien été dupliqué", 4000, "lime")
          }

          if (this.sepa.check && this.sepa.file) {
            const formData = new FormData()
            formData.append("file", this.sepa.file)
            formData.append("membreId", this.membre.id)
            formData.append("groupement", groupement.nom)
            Axios("post", "/file/sepa/membre", formData, null, {
              "Content-Type": "multipart/form-data",
            })
              .then((reponse) => {
                Materialize.toast("SEPA signé !", 4000, "lime")
              })
              .catch((error) => {
                console.error(error)
                Materialize.toast("Erreur lors de la signature du SEPA", 4000, "amber white-text")
              })
          } else if (!this.sepa.check && this.groupement.sepa) {
            Axios("delete", "file/sepa?membreId=" + this.membre.id + "&groupement=" + this.groupement.nom)
              .then((reponse) => {
                Materialize.toast("SEPA supprimé !", 4000, "lime")
              })
              .catch((error) => {
                console.error(error)
                Materialize.toast("Erreur lors de la suppresion du SEPA", 4000, "amber white-text")
              })
          }

          this.$emit("save", response.data.id)
        })
        .catch((error) => {
          console.error(error)
          Materialize.toast("Erreur serveur", 4000, "deep-orange")
        })
    },
  },
  watch: {
    groupement() {
      this.initialize()
    },
    siret(val) {
      this.chorus = null
      if (val) {
        if (val.includes(" ")) {
          this.siret = val.split(" ").join("")
        }
        if (this.siret) {
          Axios(
            "get",
            "api/chorusAnnuaires/search/findByIdentifiantStartingWith?identifiant=" + this.siret.substring(0, 9)
          ).then((response) => {
            if (response.data._embedded.chorusAnnuaires.length) {
              for (let key in response.data._embedded.chorusAnnuaires) {
                if (response.data._embedded.chorusAnnuaires[key].identifiant === this.siret) {
                  this.chorus = response.data._embedded.chorusAnnuaires[key]
                  break
                }
              }
              if (!this.chorus) {
                this.chorus = {}
              }
              this.papier = false
            } else {
              this.chorus = null
              this.engagementJuridique = null
              this.codeServiceExecutant = null
            }
          })
        }
      }
    },
    moyenPaiement(val) {
      if (val === "PRELEVEMENT_BANQUE_DE_FRANCE" || val === "VIREMENT_SANS_MANDATEMENT") {
        this.codeIdentificationIndividuelle = this.siret
      }
    },
    currentInterlocuteur: {
      handler() {
        if (this.currentInterlocuteur) {
          this.interlocuteurPayeurNom = this.currentInterlocuteur.nom
          this.interlocuteurPayeurPrenom = this.currentInterlocuteur.prenom
          this.telephonePayeur = this.currentInterlocuteur.telephone
          this.emailPayeur = this.currentInterlocuteur.mail
        } else {
          this.interlocuteurPayeurNom = ""
          this.interlocuteurPayeurPrenom = ""
          this.telephonePayeur = ""
          this.emailPayeur = ""
        }
      },
      deep: true,
    },
  },
  created() {
    let msg = ""
    this.setBreadcrumbAdd({
      name: "Edition " + msg,
      action: () => {},
    })
    this.initialize()
  },
}
</script>

<style lang="scss" scoped></style>
