<template>
  <transition name="open-transition" tag="div">
    <a-row
      type="flex"
      :gutter="12"
      class="upload-card"
      :class="{ valid: valid }"
      v-show="groupementEditing.moyenPaiement === 'PRELEVEMENT'"
    >
      <a-col :span="12" class="left-side">
        <div class="label">
          <font-awesome-icon :icon="['fas', 'check']" v-if="valid" class="success-color" />
          SEPA
        </div>
        <div class="legende">
          Dans le cadre du prélèvement comme moyen de paiement, nous reviendrons vers vous pour l'obtention d'un SEPA
          afin de mettre en place un prélèvement bancaire automatique pour le paiement de vos factures.
        </div>
      </a-col>
      <a-col :span="12" class="right-side">
        <transition-group name="check-transition" tag="div" class="transition-block">
          <a-checkbox v-show="!check" :key="0" @change="checkChange"> J'accepte </a-checkbox>
          <!--
          <div class="group-buttons"
               :key="1"
               v-show="check">
            <div class="first-line">
              <mcma-button
                name="Télécharger le SEPA"
                color="purple"
                leftIcon="download"
                :isFaIcon="true"
                @click="download"
              />
              <mcma-button
                name="Déposer le SEPA SIGNE"
                color="success"
                leftIcon="upload"
                :isFaIcon="true"
                class="ml12"
                @click="$refs.documentUploaded.click()"
              />
            </div>
            <div class="second-line">
              <mcma-button
                v-if="groupement.sepa && groupement.id"
                class="mt12"
                name="Voir votre version signé"
                color="primary"
                leftIcon="eye"
                :fullwidth="true"
                :isFaIcon="true"
                @click="downloadSigned"
              />
            </div>
          </div>
          -->
        </transition-group>
        <input class="ninja" type="file" ref="documentUploaded" @change="processFile" />
        <a class="ninja" ref="downloadLink" />
      </a-col>
    </a-row>
  </transition>
</template>

<script>
import Axios from "@/util/Axios"
import McmaButton from "@/components/mcma/common/McmaButton"
import { mapState } from "vuex"

export default {
  name: "McmaSepa",
  props: {
    groupement: Object,
    groupementEditing: Object,
  },
  components: {
    McmaButton,
  },
  data() {
    return {
      check: false,
      file: null,
    }
  },
  computed: {
    ...mapState(["membre", "operation"]),
    valid() {
      return this.check && this.file
    },
  },
  watch: {
    valid(value) {
      if (value) {
        this.$emit("fileUpload", this.check, this.file)
      }
    },
  },
  methods: {
    initialize() {
      this.check = this.groupement.sepa
    },
    checkChange(e) {
      this.check = e.target.checked
    },
    download() {
      Axios("get", "file/sepa/download", null, {
        responseType: "blob",
      })
        .then((response) => {
          const link = this.$refs.downloadLink
          link.href = window.URL.createObjectURL(new Blob([response.data]))
          link.setAttribute("download", "SEPA.docx")
          link.click()
        })
        .catch((error) => {
          console.error(error)
          this.$message.error("Erreur lors du téléchargement")
        })
    },
    processFile(event) {
      const sizeMax = process.env.VUE_APP_UPLOAD_SIZE * 1024 * 1024
      const file = event.target.files[0]

      if (file.size > sizeMax) {
        this.$message.warning(`Le fichier dépasse la taille autorisé qui est de ${process.env.VUE_APP_UPLOAD_SIZE}Mo.`)
      } else {
        this.file = file
      }
    },
    downloadSigned() {
      Axios("get", "file/sepa/download?membreId=" + this.membre.id + "&groupementId=" + this.groupement.id, null, {
        responseType: "blob",
      })
        .then((response) => {
          const link = this.$refs.downloadLink
          link.href = window.URL.createObjectURL(new Blob([response.data]))
          link.setAttribute("download", this.groupementEditing.nom + " - SEPA.docx")
          link.click()
        })
        .catch((error) => {
          console.error(error)
          this.$message.error("Erreur lors du téléchargement")
        })
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.upload-card {
  padding: calc(var(--padding, $padding) * 2);
  transition: all 0.5s ease;
  background: hsla(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l), 0.1);
  overflow: hidden;
  &.valid {
    background: hsla(var(--success-color-h), var(--success-color-s), var(--success-color-l), 0.1);
    .left-side {
      .label {
        color: var(--success-color, $success-color);
        svg {
          opacity: 1;
        }
      }
    }
  }
  .left-side {
    .label {
      transition: color 0.5s ease;
      color: var(--primary-color, $primary-color);
      font-weight: 600;
      svg {
        transition: opacity 0.5s ease;
        opacity: 0;
      }
    }
    .legende {
      color: var(--text-color-secondary, $text-color-secondary);
    }
  }
  .right-side {
    display: flex;
    flex-flow: row nowrap;
    .transition-block {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 100%;
      .ant-checkbox-wrapper {
        position: absolute;
        margin: auto;
      }
      .group-buttons {
        display: flex;
        flex-flow: column nowrap;
        .first-line {
          display: flex;
          flex-flow: row nowrap;
        }
        .second-line {
        }
      }
    }
  }
}
.check-transition-enter-active {
  transition: all 0.5s ease;
}
.check-transition-leave-active {
  transition: all 0s ease;
}
.check-transition-leave-to,
.check-transition-enter {
  opacity: 0;
}

.open-transition-enter-active,
.open-transition-leave-active {
  transition: all 0.5s ease;
}
.open-transition-leave-to,
.open-transition-enter {
  opacity: 0;
  height: 0;
  padding: 0 calc(var(--padding, $padding) * 2);
}
</style>
