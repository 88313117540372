<template>
  <section v-if="site">
    <div class="container" v-if="site.state !== Constants.STATUS.CERTIFIED.value">
      <a-row type="flex" :gutter="12">
        <a-col :span="24">
          <sites-detail-card-header :site="site">
            <a-tabs
              :defaultActiveKey="0"
              :activeKey="etapeActualIndex"
              @change="changeTab"
              :class="{
                'hide-tabs': site.state === Constants.STATUS.TO_COMPLETE_MASSE.value,
              }"
            >
              <!--type="card"-->
              <a-tab-pane v-for="(etape, index) in etapes" :key="index">
                <span slot="tab">
                  <mcma-icon
                    :type="isCompleted(index) ? 'paper-plane' : 'edit'"
                    :faIcon="true"
                    :name="$t(`site.${etape.name}.name`)"
                  />
                </span>
                <component
                  :is="getEtapeByName(etape.name)"
                  :site="site"
                  @update="updateSite"
                  @save="initialize"
                ></component>
              </a-tab-pane>
            </a-tabs>
          </sites-detail-card-header>
        </a-col>
      </a-row>
    </div>
    <div class="container" v-else>
      <mcma-empty
        :inCard="true"
        image="in_validation"
        :center="true"
        :imageSize="300"
        message="Ce site ne peux pas être modifié car il a déjà été envoyé en validation."
      >
        <mcma-button name="Revenir aux sites" class="mt24" @click="goBack()" />
      </mcma-empty>
    </div>
  </section>
</template>

<script>
import Mixin from "@/components/flow/Mixin"
import getEtapes from "@/components/mcma/steps/sites/Etapes"
import masse from "@/components/mcma/steps/sites/Masse/Masse"
import Axios from "@/util/Axios"
import McmaEmpty from "@/components/mcma/common/McmaEmpty"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaIcon from "@/components/mcma/common/McmaIcon"
import SitesDetailCardHeader from "@/components/mcma/steps/sites/components/SitesDetailCardHeader"
import { mapState, mapMutations, mapGetters } from "vuex"
import Materialize from "materialize-css"
import Constants from "@/util/Constants"

export default {
  name: "SitesDetail",
  mixins: [Mixin],
  components: {
    McmaEmpty,
    McmaButton,
    McmaCard,
    McmaIcon,
    SitesDetailCardHeader,
  },
  data() {
    return {
      flow: "PERIMETRE",
      site: null,
      etapeActualIndex: 0,
    }
  },
  computed: {
    ...mapState(["operation", "membre", "user", "siteEditing"]),
    ...mapGetters(["getFlow"]),
    Constants() {
      return Constants
    },
    etapes() {
      return getEtapes(this.operation, this.getFlow, this.user, this.site)
    },
    getEtapeByName() {
      return (name) => {
        if (!this.site.masse) {
          return this.etapes.find((etape) => etape.name === name)
        } else {
          return masse
        }
      }
    },
    etapeActual() {
      if (!this.site.masse) {
        return this.etapes[this.etapeActualIndex]
      } else {
        return masse
      }
    },
  },
  mounted() {
    this.$root.$on("ROOT_updateSiteEditing", (site) => {
      this.updateSiteEditing(site)
    })
  },
  beforeDestroy() {
    this.$root.$off("ROOT_updateSiteEditing")
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd", "breadcrumbGoBack", "setState"]),
    changeTab(value){
      this.etapeActualIndex = value
    },
    updateSiteEditing(site) {
      if (!this.site.id && !this.site.masse) {
        this.setState({
          stateName: "siteEditing",
          value: site,
        })
      }
    },
    updateSite(site) {
      this.etapeActualIndex = this.etapeActualIndex +1
      this.site = site
      if (site.ongletParametre) {
        this.breadcrumbGoBack()
      } else {
        this.$scrollToTop()
      }
    },
    gotTo(etape) {
      if (etape >= 0 && etape <= this.etapes.length - 1) {
        this.etapeActualIndex = etape
      }
    },
    initialize(id) {
      Axios("get", "api/sites/" + id)
        .then((response) => {
          this.site = response.data
          this.setState({
            stateName: "siteEditing",
            value: null,
          })
          this.setBreadcrumbAdd({
            name: "Edition de " + this.site.nom,
            action: () => {
              this.$router.push({
                name: "sites-detail",
                params: {
                  operationId: this.operation.id,
                  membreId: this.membre.id,
                  siteId: this.site.id,
                  action: "edit",
                },
              })
            },
          })
        })
        .catch((error) => {
          console.error("error", error)
        })
    },
    getColorationOnglet(index) {
      const fields = ["ongletIdentification", "ongletParametre", "ongletFacturation"]
      if (this.site[fields[index]]) {
        return "light-green-text"
      }
      return "deep-orange-text"
    },
    isCompleted(index) {
      const fields = ["ongletIdentification", "ongletParametre", "ongletFacturation"]
      return this.site[fields[index]]
    },
    remove() {
      if (confirm('Voulez-vous vraiment supprimer de façon irréversible le site "' + this.site.nom + '" ?')) {
        Axios("delete", "api/sites/" + this.site.id)
          .then((reponse) => {
            Materialize.toast("Le site a bien été supprimé", 4000, "lime")
            this.$router.push({
              name: "sites",
            })
          })
          .catch((error) => {
            console.error(error)
            Materialize.toast("Erreur lors de la suppression", 4000, "amber white-text")
          })
      }
    },
    goBack() {
      this.$router.push({
        name: "sites",
      })
    },
  },
  created() {
    switch (this.$route.params.action) {
      case "new":
        this.site = {
          new: true,
          state: "TO_COMPLETE",
          status: "NOUVEAU",
          added: true,
        }
        this.site = { ...this.siteEditing }
        this.setBreadcrumbAdd({
          name: "Ajout d'un nouveau site",
          action: () => {
            this.$router.push({
              name: "sites-detail",
              params: {
                operationId: this.operation.id,
                membreId: this.membre.id,
                siteId: null,
                action: "new",
              },
            })
          },
        })
        break
      case "masse":
        this.site = {
          new: false,
          state: "TO_COMPLETE_MASSE",
          status: "NOUVEAU",
          added: false,
          masse: true,
        }
        this.setBreadcrumbAdd({
          name: "Gestion en masse des sites",
          action: () => {},
        })
        break
      default:
        this.initialize(this.$route.params.siteId)
    }
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.row {
  margin-bottom: 0px;
}

.etape {
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.selected {
  border: 2px solid #6c6d70;
}

.card-image {
  padding-top: 10px;
}

.ant-tabs {
  &.hide-tabs {
    .ant-tabs-bar {
      display: none;
    }
  }
}
</style>
