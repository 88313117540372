<template>
  <a-form :form="form">
    <div class="row-inline">
      <mcma-field
        :colSpan="12"
        type="input"
        :form="form"
        :wasSubmit="wasSubmit"
        fieldName="name"
        label="Nom"
        placeholder="Nom de la pièce"
        :required="true"
      />
      <mcma-field
        :colSpan="12"
        type="select"
        :form="form"
        :wasSubmit="wasSubmit"
        fieldName="type"
        label="Type"
        disableValue="Sélectionner le type de la piéce"
        :required="true"
      >
        <a-select-option v-for="(type, index) in documentsTypes" :key="index" :value="type.key">
          {{ type.value }}
        </a-select-option>
      </mcma-field>

      <mcma-field
        :colSpan="12"
        type="switch"
        :form="form"
        :wasSubmit="wasSubmit"
        :defaultValue="true"
        label="Dépôt"
        fieldName="mandatory_upload"
        flowIsAvailable="false"
        :defaultList="['Obligatoire', 'Facultatif']"
      />

      <div class="upload-box" :class="{ 'has-file': file }">
        <mcma-button
          name="Charger une piéce"
          :color="file ? 'success' : 'purple'"
          :leftIcon="file ? 'check' : 'upload'"
          :isFaIcon="true"
          @click="$refs.documentUploaded.click()"
        />
        <div class="file-name">
          {{ file ? file.name : "Aucune piéce" }}
        </div>
      </div>
    </div>
    <a-row type="flex" :gutter="12">
      <a-col :span="24">
        <div class="button-group-inline mt24">
          <mcma-button
            name="Annuler les modifications"
            color="primary"
            leftIcon="undo-alt"
            :isFaIcon="true"
            @click="initialize"
          />
          <mcma-button
            name="Ajouter la piéce"
            class="ml12"
            color="success"
            leftIcon="save"
            :isFaIcon="true"
            @click="submit"
          />
        </div>
      </a-col>
    </a-row>
    <input class="ninja" type="file" ref="documentUploaded" @change="processFile" />
  </a-form>
</template>

<script>
import Axios from "@/util/Axios"
import McmaField from "@/components/mcma/common/McmaField"
import McmaButton from "@/components/mcma/common/McmaButton"
import { mapState } from "vuex"

export default {
  name: "DocumentsManagerEdit",
  components: {
    McmaField,
    McmaButton,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      wasSubmit: false,
      file: null,
      headers: {
        authorization: "authorization-text",
      },
      documentsTypes: [
        {
          key: "MANDAT",
          value: "Mandat",
        },
        {
          key: "DELIBERATION",
          value: "Deliberation",
        },
        {
          key: "BORDEREAU",
          value: "Bordereau de prix unitaire",
        },
        {
          key: "CONTRAT",
          value: "Contrat cadre",
        },
        {
          key: "AUTRES",
          value: "Autres",
        },
      ],
    }
  },
  computed: {
    ...mapState({
      operation: "operation",
    }),
  },
  methods: {
    initialize() {
      this.resetFile()
      this.form.getFieldDecorator("name")
      this.form.getFieldDecorator("type")
      this.form.setFieldsValue({
        name: null,
        type: null,
      })
    },
    submit() {
      this.form.validateFields((errors, fieldsValue) => {
        this.wasSubmit = true
        if (errors) {
          this.$notification.warning({
            message: "Formulaire invalide",
            description: `Veuillez-vous assurez de remplir tous les champs requis.`,
          })
        } else if (!this.file) {
          this.$notification.warning({
            message: "Piéce non importé",
            description: `Veuillez importer la piéce à charger.`,
          })
        } else {
          const sizeMax = process.env.VUE_APP_UPLOAD_SIZE * 1024 * 1024
          if (this.file.size > sizeMax) {
            this.$notification.warning({
              message: `Fichier trop volumineux`,
              description: `Le fichier dépasse la taille autorisé qui est de ${process.env.VUE_APP_UPLOAD_SIZE}Mo.`,
            })
          } else {
            const formData = new FormData()
            formData.append(
              "piece",
              JSON.stringify({
                name: fieldsValue.name,
                type: fieldsValue.type,
                mandatory_upload: fieldsValue.mandatory_upload,
              })
            )
            formData.append("file", this.file)
            formData.append("operationId", this.operation.id)
            Axios("post", "/file/piece", formData, null, {
              "Content-Type": "multipart/form-data",
            })
              .then(() => {
                this.initialize()
                this.$notification.success({
                  message: `Piéce ajouté`,
                  description: `La piéce à bien été ajouté avec succés.`,
                })
              })
              .catch((error) => {
                console.error(error)
                if (error.response.status === 400) {
                  this.$notification.error({
                    message: `Existe deja`,
                    description: `Cette pièce existe déjà.`,
                  })
                } else {
                  this.$notification.error({
                    message: `Erreur lors de l'ajout`,
                    description: `Une erreur s'est produite lors de l'ajout de la piéce, veuillez contacter un administrateur.`,
                  })
                }
              })
          }
        }
      })
    },
    goToMembersManager() {
      this.initialize()
      this.$router.push({
        name: "members-manager",
      })
    },
    resetFile() {
      this.file = null
      const input = this.$refs.documentUploaded
      if (input && input.type) {
        input.type = "text"
        input.type = "file"
      }
    },
    processFile(event) {
      const sizeMax = process.env.VUE_APP_UPLOAD_SIZE * 1024 * 1024
      const file = event.target.files[0]

      if (file.size > sizeMax) {
        this.$message.warning(`Le fichier dépasse la taille autorisé qui est de ${process.env.VUE_APP_UPLOAD_SIZE}Mo.`)
      } else {
        this.file = file
      }
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.row-inline {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.upload-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 45vw;
  margin: 20px 0;
  padding-right: 10px;
  border-radius: 4px;
  background: rgba($grey, 0.1);
  border: 1px solid rgba($grey, 0.3);
  transition: all 0.3s ease;
  .file-name {
    color: $grey;
  }
  &.has-file {
    background: hsla(var(--success-color-h), var(--success-color-s), var(--success-color-l), 0.1);
    .file-name {
      color: var(--text-color, $text-color);
    }
  }
}
</style>
