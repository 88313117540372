var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flows"},[_c('a-steps',{attrs:{"current":_vm.current},scopedSlots:_vm._u([{key:"progressDot",fn:function({ index }){return _c('a-popover',{},[_c('template',{slot:"content"},[_c('span',[_vm._v("Etape "+_vm._s(index + 1))])]),_c('div',{staticClass:"bar-number",on:{"click":function($event){return _vm.goToSlide(index)}}},[(index == 0 || _vm.progression(index - 1) === 'COMPLETED' || _vm.progression(index - 1) === 'CERTIFIED')?_c('span',[_c('mcma-icon',{attrs:{"type":"check","color":"success","faIcon":true}})],1):_c('span',[_vm._v(" "+_vm._s(index + 1)+" ")])])],2)}}])},[_c('a-step',{attrs:{"title":'Mes données générales'}}),_vm._l((_vm.getFlow),function(flow,index){return _c('a-step',{key:'flow-' + index,attrs:{"title":_vm.getFlowByKey(flow).shortTitle}})})],2),_c('a-carousel',{ref:"carousel",staticClass:"flows-content",class:`prev-slide-number-${_vm.current - 1} next-slide-number-${_vm.current + 1}`,attrs:{"dots":false,"infinite":false,"beforeChange":_vm.changeStepTo}},[_c('flow-card',{key:_vm.index + 1,attrs:{"index":0,"title":"Mes données générales","buttons":[
        {
          title: 'Mes données générales',
          action: () => {
            _vm.openModal = true
          },
        },
      ],"state":"COMPLETED"}}),_vm._l((_vm.getFlow),function(flow,index){return _c('flow-card',{key:index + 1,attrs:{"index":index + 2,"title":_vm.getFlowByKey(flow).longTitle,"flowName":_vm.getFlowByKey(flow).name,"customDescribe":_vm.getFlowByKey(flow).customDescribe,"describe":_vm.getFlowByKey(flow).describe,"buttons":_vm.getFlowByKey(flow).buttons,"mycomment":_vm.stateComment(index),"state":_vm.progression(index)},on:{"refresh-flows":_vm.initFlows}})})],2),_c('general-info',{model:{value:(_vm.openModal),callback:function ($$v) {_vm.openModal=$$v},expression:"openModal"}}),_c('div',{staticClass:"custom-slick-arrow left-arrow",class:{ disabled: _vm.current <= 0 },style:({ bottom: `${_vm.heightOfFlows / 2 - _vm.carouselArrowPXSize / 2}px` }),on:{"click":function($event){$event.preventDefault();return _vm.goToSlide(_vm.current > 0 ? _vm.current - 1 : _vm.current)}}},[_c('mcma-icon',{attrs:{"type":"chevron-left","disabled":_vm.current <= 0,"color":"primary","faIcon":true}})],1),_c('div',{staticClass:"custom-slick-arrow right-arrow",class:{ disabled: _vm.current >= _vm.flows.length },style:({ bottom: `${_vm.heightOfFlows / 2 - _vm.carouselArrowPXSize / 2}px` }),on:{"click":function($event){$event.preventDefault();return _vm.goToSlide(_vm.current < _vm.flows.length ? _vm.current + 1 : _vm.current)}}},[_c('mcma-icon',{attrs:{"type":"chevron-right","disabled":_vm.current >= _vm.flows.length,"color":"primary","faIcon":true}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }