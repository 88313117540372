<template>
  <section>
    <label class="active" for="moyenPaiement">
      <font-awesome-icon icon="asterisk" class="red-text" />
      Moyen de paiement
    </label>
    <select id="moyenPaiement" class="browser-default" required v-model="field" :disabled="disabled">
      <option :value="null" disabled selected>Sélectionnez moyen de paiement</option>
      <option v-for="(friendly, key) in values" :value="key" :key="key">
        {{ friendly }}
      </option>
    </select>
  </section>
</template>

<script>
import Mixin from "./Mixin"
import _ from "lodash"

export default {
  name: "moyen-paiement",
  mixins: [Mixin],
  computed: {
    values() {
      if (["CARREFOUR"].includes(this.confOperation)) {
        return {
          PRELEVEMENT: "Prélèvement",
        }
      }
      return {
        COMPTE_COURANT: "Compte Courant",
        VIREMENT_SANS_MANDATEMENT: "Virement sans Mandatement",
        VIREMENT_AVEC_MANDATEMENT: "Virement avec Mandatement",
        VIREMENT: "Virement",
        CHEQUE: "Chèque",
        PRELEVEMENT: "Prélèvement",
        PRELEVEMENT_BANQUE_DE_FRANCE: "Prélèvement Banque de France",
        PRELEVEMENT_TRESORERIE: "Prélèvement trésorerie",
        TIP: "TIP",
      }
    },
    disabled() {
      return _.keys(this.values).length === 1
    },
  },
  created() {
    if (this.disabled) {
      this.field = _.keys(this.values)[0]
    }
  },
}
</script>

<style lang="scss" scoped></style>
