var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"text-editable-presentation"},[_c('ul',{staticClass:"collapsible",attrs:{"data-collapsible":"expandable"}},[_c('li',[_c('div',{staticClass:"collapsible-header"},[_vm._v("Edition des textes libres de présentation")]),_c('div',{staticClass:"collapsible-body"},[_c('table',[_c('tbody',[[_c('tr',[_c('td',[_c('div',[_vm._m(0),_c('vue-editor',{model:{value:(_vm.step.instruction),callback:function ($$v) {_vm.$set(_vm.step, "instruction", $$v)},expression:"step.instruction"}})],1)])]),_c('tr',[_c('td',[_c('div',[_vm._m(1),_c('vue-editor',{model:{value:(_vm.step.cardDescribe),callback:function ($$v) {_vm.$set(_vm.step, "cardDescribe", $$v)},expression:"step.cardDescribe"}})],1)])]),_c('tr',[_c('td',[_c('div',[_vm._m(2),_c('vue-editor',{model:{value:(_vm.step.describeInFlow),callback:function ($$v) {_vm.$set(_vm.step, "describeInFlow", $$v)},expression:"step.describeInFlow"}})],1)])]),(_vm.step.name === 'sites')?_c('tr',[_c('td',[_c('div',[_vm._m(3),_c('vue-editor',{model:{value:(_vm.step.sftapDescribe),callback:function ($$v) {_vm.$set(_vm.step, "sftapDescribe", $$v)},expression:"step.sftapDescribe"}})],1)])]):_vm._e(),(_vm.step.name === 'sites')?_c('tr',[_c('td',[_c('div',[_vm._m(4),_c('vue-editor',{model:{value:(_vm.step.sftapComment),callback:function ($$v) {_vm.$set(_vm.step, "sftapComment", $$v)},expression:"step.sftapComment"}})],1)])]):_vm._e(),(_vm.step.name === 'sites')?_c('tr',[_c('td',[_c('div',[_vm._m(5),_c('vue-editor',{model:{value:(_vm.step.enrDescribe),callback:function ($$v) {_vm.$set(_vm.step, "enrDescribe", $$v)},expression:"step.enrDescribe"}})],1)])]):_vm._e(),(_vm.step.name === 'sites')?_c('tr',[_c('td',[_c('div',[_vm._m(6),_c('vue-editor',{model:{value:(_vm.step.enrModalDescribe),callback:function ($$v) {_vm.$set(_vm.step, "enrModalDescribe", $$v)},expression:"step.enrModalDescribe"}})],1)])]):_vm._e()]],2)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('h5',[_vm._v("Text libre de présentation de l'instruction de l'étape")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('h5',{staticClass:"mt25"},[_vm._v("Text libre de présentation de la carte de l'étape")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('h5',{staticClass:"mt25"},[_vm._v("Text libre de présentation de l'étape adressé aux utilisateurs")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('h5',{staticClass:"mt25"},[_vm._v(" Text libre de présentation pour Segment, Formule tarifaire d'acheminement (FTA) et Puissances ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('h5',{staticClass:"mt25"},[_vm._v(" Aide au commentaire pour Segment, Formule tarifaire d'acheminement (FTA) et Puissances ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('h5',{staticClass:"mt25"},[_vm._v("Aide au commentaire pour ENR")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('h5',{staticClass:"mt25"},[_vm._v("Aide au commentaire pour ENR (Modal)")])])
}]

export { render, staticRenderFns }