<template>
  <a-form :form="form">
    <mcma-spin-box :spinning="loading" :tip="'Chargement en cours'">
      <mcma-card title="Localisation du site" class="mcma-card-in-tab">
        <template #describe>
          <span v-if="!site.id">
            Tant que les informations d'identification du site que vous voulez créer ne sont pas complétées, vous ne
            pouvez pas accéder aux autres onglets.
          </span>
          <span v-else>Vous pouvez modifier les informations d'identification du site autant que souhaité, le temps que
            celui-ci
            n'est pas certifié.</span>
        </template>
        <a-row type="flex" v-show="site.added == null" :gutter="12" class="mt25">
          <mcma-button name=" Actualiser mes données de site" color="primary" leftIcon="undo-alt" :isFaIcon="true"
            @click="apiFournisseur()" />
        </a-row>
        <a-row type="flex" :gutter="12">
          <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="nom" :defaultValue="site.nom"
            placeholder="Mon site n°5" getFlow="name::sites" getPanel="name::Localisation" getField="type::siteName"
            @change="updateSiteEditing(form.getFieldsValue())" rule="excludeSpecial" :maxlength="35" />
          <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="refSite" :defaultValue="site.refSite"
            label="Autre référence" @change="updateSiteEditing(form.getFieldsValue())" rule="excludeSpecial"
            :maxlength="30" />
          <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="codeSite"
            :defaultValue="site.codeSite" placeholder="123456789" :maxlength="20" getFlow="name::sites"
            getPanel="name::Localisation" getField="type::siteCode" rule="excludeSpecial"
            @change="updateSiteEditing(form.getFieldsValue())" />
        </a-row>
        <a-row type="flex" :gutter="12">
          <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="adresse" :defaultValue="site.adresse"
            placeholder="12 rue de la tour eiffel" getFlow="name::sites" getPanel="name::Localisation"
            getField="type::address" rule="excludeSpecial" @change="updateSiteEditing(form.getFieldsValue())" />

          <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="commune" :defaultValue="site.commune"
            placeholder="PARIS" :uppercase="true" getFlow="name::sites" getPanel="name::Localisation"
            getField="type::town" :maxlength="32" @change="updateSiteEditing(form.getFieldsValue())" />
          <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="codePostal"
            :defaultValue="site.codePostal" placeholder="92000" getFlow="name::sites" getPanel="name::Localisation"
            getField="type::postalCode" rule="isZipCode" :required="true && siteStateDeleted"
            @change="updateEld(form.getFieldsValue())" />
          <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="codeInsee" getFlow="name::sites"
            getPanel="name::Localisation" getField="type::codeInsee" :defaultValue="site.codeInsee" placeholder="12345"
            rule="isCodeInsee" @change="updateSiteEditing(form.getFieldsValue())" />
        </a-row>
      </mcma-card>
    </mcma-spin-box>
    <mcma-card title="Energie et Statut" class="mcma-card-in-tab">
      <a-row type="flex" :gutter="12">
        <mcma-field type="select" :form="form" :wasSubmit="wasSubmit" fieldName="fluide"
          disableValue="Sélectionnez un fluide" :extraLabel="!site.fluide ? 'non modifiable une fois sauvegardé' : null"
          getFlow="name::sites" getPanel="name::Localisation" getField="type::fluid"
          :required="true && siteStateDeleted" @change="updateEld(form.getFieldsValue())" />
        <!-- disable because TDE wants this info comes from import and not from users -->
        <mcma-field type="select" :form="form" :wasSubmit="wasSubmit" :disabled="true"
          :defaultValue="site.status ? site.status : 'NOUVEAU'" label="Etat actuel selon le GRD"
          disableValue="Sélectionnez un statut" getFlow="name::sites" getPanel="name::Localisation"
          getField="type::status" :required="true" @change="onChangeStatus($event)" />
        <mcma-field :colSpan="12" type="date" class="mt10" :form="form" :wasSubmit="wasSubmit"
          :defaultValue="site.dateActivation" fieldName="dateActivation" label="Sélectionnez la date d'activation"
          placeholder="date d'activation" :disableDateRange="[operation.dateBascule, operation.dateFinContrat]"
          getFlow="name::sites" getPanel="name::Localisation" getField="type::dateActivation"
          :required="true && siteStateDeleted" @change="updateSiteEditing(form.getFieldsValue())" />
        <mcma-field :colSpan="12" type="select" :form="form" getFlow="name::sites" getPanel="name::Localisation"
          getField="type::statutBascule" :wasSubmit="wasSubmit" fieldName="statutSite" label="Statut"
          extraLabel="Si ce site vous appartient, veuillez cliquer sur confirmer la bascule"
          :defaultValue="site.statutSite" @change="onChangeStatus($event)" />
        <template>
          <a-modal v-model="visibleMesValidation" :title="`Validation de la mise en service`" :maskClosable="false"
            class="mcma-mes-modal" :width="1000">
            <div class="container-list">
              <mcma-card>
                <h5 style="margin:10px;">
                  Votre site est actuellement résilié, confirmez vous que nous devons faire une Mise en Service ?
                </h5>
                <template>
                  <mcma-field
                    v-if="form.getFieldValue('statutSite') === 'Confirmer la bascule' && (form.getFieldValue('status') === 'RESILIATION' || form.getFieldValue('status') === 'EN_COURS_DE_RACCORDEMENT')"
                    type="switch" :form="form" getFlow="name::sites" getPanel="name::Localisation"
                    getField="type::validateMes" :wasSubmit="wasSubmit" fieldName="validateMes"
                    extraLabel=""
                    :defaultValue="site.validateMes" />
                </template>
                <div class="inline-buttons">
                  <mcma-button name="Valider les informations" color="primary" class="mt24" @click="visibleMesValidation = false" />
                </div>
              </mcma-card>
            </div>
          </a-modal>
        </template>

      </a-row>
    </mcma-card>
    <mcma-card title="Référence du site" class="mcma-card-in-tab">
      <a-row type="flex" :gutter="12">
        <mcma-field type="select" :form="form" :wasSubmit="wasSubmit" fieldName="distributeur"
          disableValue="Sélectionnez un distributeur" label="Distributeur"
          extraLabel="Ajouter préalablement l'énergie et le code postal" :required="true && siteStateDeleted"
          :disabled="getDistribution(form.getFieldsValue()).length === 0 || (site.id && !enabledDistributeur)"
          :defaultList="getDistribution(form.getFieldsValue())" defaultValue="" @change="
            updateSiteEditing(form.getFieldsValue())
          onDistributeurChange($event)
            " />
        <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" :defaultValue="site.rae" placeholder="123456789"
          :label="raeLabel(form)" :disabled="site.rae && site.id && !site.added" getFlow="name::sites"
          getPanel="name::Localisation" getField="type::rae" :extraLabel="getRaeExtraLabel(form)" :required="true"
          :rule="getRaeRule(form)" @change="updateSiteEditing(form.getFieldsValue())" />
      </a-row>
      <a-row type="flex" :gutter="24">
        <mcma-field v-if="showEldKey" type="input" :form="form" :wasSubmit="wasSubmit" :defaultValue="site.keyEld"
          fieldName="keyEld" getFlow="name::sites" getPanel="name::Localisation" getField="type::keyEld"
          @change="updateSiteEditing(form.getFieldsValue())" />
      </a-row>
    </mcma-card>
    <mcma-card>
      <a-row type="flex" :gutter="12">
        <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="codeGroupement"
          :defaultValue="site.codeGroupement" placeholder="123456789" :maxlength="30" getFlow="name::sites"
          getPanel="name::Localisation" getField="type::codeGroupement"
          :extraLabel="'*Ce champ facultatif est destiné à vous faciliter la préparation de vos périmètres de facturation.'"
          @change="updateSiteEditing(form.getFieldsValue())" />
        <mcma-field type="select" :form="form" :wasSubmit="wasSubmit" :defaultValue="site.codeImputation"
          label="Code imputation budgetaire" disableValue="Sélectionnez le code imputation budgetaire"
          getFlow="name::sites" getPanel="name::Localisation" getField="type::codeImputation"
          @change="updateSiteEditing(form.getFieldsValue())" />
      </a-row>
    </mcma-card>
  </a-form>
</template>

<script>
import { mapState } from "vuex"
import moment from "moment"
import Axios from "@/util/Axios"
import { pick } from "@/util/Helpers"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaField from "@/components/mcma/common/McmaField"
import McmaButton from "@/components/mcma/common/McmaButton"
import Distribution from "@/components/mcma/common/Fields/Jsons/Distribution.json"
import DistributionCode from "@/components/mcma/common/Fields/Jsons/DistributionCode.json"
import Materialize from "materialize-css"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import Constants from "@/util/Constants"
export default {
  name: "SiteEditLocalisation",
  data() {
    return {
      form: this.$form.createForm(this),
      wasSubmit: false,
      showEldKey: false,
      distribCodeSelected: null,
      siteStateDeleted: null,
      loading: false,
      enabledDistributeur: false,
      visibleMesValidation: false,
    }
  },
  components: {
    McmaCard,
    McmaField,
    McmaButton,
    McmaSpinBox,
  },
  props: {
    site: Object,
  },
  computed: {
    ...mapState(["membre", "operation"]),
    getDistribution() {
      return (fieldsValues) => {
        if (fieldsValues.fluide && fieldsValues.codePostal && fieldsValues.codePostal.length === 5) {
          const departmentCode = fieldsValues.codePostal.toString().substring(0, 2)
          if (!Distribution[fieldsValues.fluide][departmentCode]) {
            return [...Distribution[fieldsValues.fluide].default]
          }
          return [...Distribution[fieldsValues.fluide].default, ...Distribution[fieldsValues.fluide][departmentCode]]
        }
        return []
      }
    },
    getRaeRule() {
      return (form) => {
        if (this.raeLabel(form) === "RAE") {
          return "isRAE"
        } else if (this.raeLabel(form) === "ELD (Distributeur)") {
          return "isELD"
        } else if (this.raeLabel(form) === "PCE") {
          return "isPCE"
        } else if (this.raeLabel(form) === "PCE (Distributeur)") {
          return "isELD_PCE"
        }
        return null
      }
    },
    getRaeExtraLabel() {
      return (form) => {
        if (this.raeLabel(form) === "RAE") {
          return "Votre RAE composé de 14 chiffres est présent sur vos factures"
        }
        return null
      }
    },
    raeLabel() {
      return (form) => {
        const lastFluide = form.getFieldValue("fluide") || this.site.fluide
        const isEld =
          form.getFieldValue("distributeur") &&
          form.getFieldValue("distributeur") !== "ENEDIS" &&
          form.getFieldValue("distributeur") !== "GRDF"
        let result = ""
        if (lastFluide === "ELEC") {
          if (!isEld) {
            result = "RAE"
          } else {
            result = "ELD (Distributeur)"
          }
        } else if (lastFluide === "GAZ") {
          if (!isEld) {
            result = "PCE"
          } else {
            result = "RAE / PCE (Distributeur)"
          }
        }
        return result
      }
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    moment,
    updateEld(fieldsValue) {
      this.enabledDistributeur = true
      if (this.getDistribution(fieldsValue).length >= 1) {
        this.$nextTick(() => {
          if (!this.getDistribution(fieldsValue).includes(fieldsValue.distributeur)) {
            this.form.setFieldsValue({
              distributeur: this.getDistribution(fieldsValue)[0],
            })
          }
        })
      }
      this.updateSiteEditing(fieldsValue)
    },
    onDistributeurChange(value) {
      if (value && value !== "ENEDIS" && value !== "GRDF") {
        this.showEldKey = true
      } else {
        this.showEldKey = false
      }
      this.getDistributionCode(value)
    },
    getDistributionCode(value) {
      const departmentCode = this.form.getFieldValue("codePostal").toString().substring(0, 2)
      let listDistribCode = DistributionCode[this.form.getFieldValue("fluide")][departmentCode]

      if (listDistribCode.length > 1) {
        const indexDistributeur = Distribution[this.form.getFieldValue("fluide")][departmentCode].indexOf(value)
        this.distribCodeSelected = listDistribCode[indexDistributeur]
      } else {
        this.distribCodeSelected = listDistribCode.toString()
      }
    },
    onChangeStatus(statusValue) {
      console.log(statusValue)
      if (
        statusValue === "INEXISTANT" ||
        statusValue === "RESILIATION" ||
        statusValue === "REJETE" ||
        statusValue === "Ce site est à supprimer de la bascule" ||
        statusValue === "A transférer vers un autre membre"
      ) {
        this.siteStateDeleted = false
        this.$emit("deletedStatus", false)
      } else {
        this.siteStateDeleted = true
        this.$emit("deletedStatus", true)
      }

      if (this.form.getFieldValue('statutSite') === 'Confirmer la bascule' && (this.form.getFieldValue('status') === 'RESILIATION' || this.form.getFieldValue('status') === 'EN_COURS_DE_RACCORDEMENT')) {
        this.visibleMesValidation = true
      } else {
        this.visibleMesValidation = false
      }
    },
    deleteArrondissement(commune) {
      if (commune != null) {
        const paris = "PARIS"
        const marseille = "MARSEILLE"
        const lyon = "LYON"
        const town = commune.split(" ")
        if (town.includes(paris) || town.includes(marseille) || town.includes(lyon)) {
          if (town.includes(paris)) {
            commune = paris
          } else if (town.includes(marseille)) {
            commune = marseille
          } else if (town.includes(lyon)) {
            commune = lyon
          } else {
            commune = town
          }
          return commune
        }
        return commune
      }
    },
    updateSiteEditing(fieldsValue) {
      this.$root.$emit("ROOT_updateSiteEditing", fieldsValue)
    },
    apiFournisseur() {
      const rae = this.form.getFieldValue("rae")
      const fluide = this.form.getFieldValue("fluide")
      if (rae == null) {
        Materialize.toast("Veuillez remplir au préalable le RAE", 4000, "amber white-text")
      } else if (fluide == null) {
        Materialize.toast("Veuillez remplir au préalable l'énergie", 4000, "amber white-text")
      } else if (fluide === "ELEC") {
        this.loading = true
        Axios("get", "fournisseur/point/elec/" + rae)
          .then((response) => {
            this.loading = false
            let responseApi = response.data
            this.form.setFieldsValue({
              codePostal: responseApi.codePostal,
              commune: responseApi.commune,
              adresse: responseApi.adresse,
              codeInsee: responseApi.codeInsee,
              distributeur: "ENEDIS",
            })
            Materialize.toast("Récupération des données fournisseur: OK", 4000, "lime white-text")
          })
          .catch(() => {
            this.loading = false
            Materialize.toast(
              "La référence saisie n’est pas reconnue par Enedis et GRDF, veuillez vérifier la référence ou préciser le nom du gestionnaire de réseau",
              4000,
              "amber white-text"
            )
          })
      } else if (this.form.getFieldValue("fluide") === "GAZ") {
        this.loading = true
        Axios("get", "fournisseur/point/gaz/" + rae)
          .then((response) => {
            this.loading = false
            let responseApi = response.data
            this.form.setFieldsValue({
              codePostal: responseApi.codePostal !== null ? responseApi.codePostal : this.site.codePostal,
              commune: responseApi.commune !== null ? responseApi.commune : this.site.commune,
              adresse: responseApi.adresse !== null ? responseApi.adresse : this.site.adresse,
              distributeur: "GRDF",
            })
            Materialize.toast("Récupération des données fournisseur: OK", 4000, "lime white-text")
          })
          .catch(() => {
            this.loading = false
            Materialize.toast(
              "La référence saisie n’est pas reconnue par Enedis et GRDF, veuillez vérifier la référence ou préciser le nom du gestionnaire de réseau",
              4000,
              "amber white-text"
            )
          })
      }
    },
    initialize() {
      this.form.getFieldDecorator("nom")
      this.form.getFieldDecorator("codeSite")
      this.form.getFieldDecorator("rae")
      this.form.getFieldDecorator("fluide")
      this.form.getFieldDecorator("status")
      this.form.getFieldDecorator("refTDG")
      this.form.getFieldDecorator("refSite")
      this.form.getFieldDecorator("adresse")
      this.form.getFieldDecorator("codePostal")
      this.form.getFieldDecorator("commune")
      this.form.getFieldDecorator("codeImputation")
      this.form.getFieldDecorator("codeGroupement")
      this.form.getFieldDecorator("dateActivation")
      this.form.getFieldDecorator("dateResiliation")
      this.form.getFieldDecorator("codeInsee")
      this.form.getFieldDecorator("distributeur")
      let editSite = pick(this.site, [
        "nom",
        "codeSite",
        "rae",
        "fluide",
        "status",
        "refTDG",
        "refSite",
        "adresse",
        "codePostal",
        "commune",
        "codeImputation",
        "codeGroupement",
        "codeInsee",
        "distributeur",
        "keyEld",
      ])
      editSite = this.$mergeObject(this.form.getFieldsValue(), editSite)
      editSite.dateActivation = this.site.dateActivation
        ? moment(this.site.dateActivation)
        : moment(this.operation.dateBascule)
      editSite.dateResiliation = this.site.dateResiliation
        ? moment(this.site.dateResiliation)
        : moment(this.operation.dateFinContrat)
      this.$nextTick(() => {
        this.form.setFieldsValue(editSite)
      })

      if (this.site.distributeur && this.site.distributeur !== "ENEDIS" && this.site.distributeur !== "GRDF") {
        this.showEldKey = true
      }

      if (
        this.form.getFieldValue("status") === "INEXISTANT" ||
        this.form.getFieldValue("status") === "RESILIATION" ||
        this.form.getFieldValue("status") === "REJETE" ||
        this.form.getFieldValue("status") === "Ce site est à supprimer de la bascule" ||
        this.form.getFieldValue("status") === "A transférer vers un autre membre"
      ) {
        this.siteStateDeleted = false
        this.$emit("deletedStatus", false)
      } else {
        this.siteStateDeleted = true
        this.$emit("deletedStatus", true)
      }
    },
    save(site) {
      this.$emit("save", site)
    },
    triggerError() {
      this.form.validateFields((errors, fieldsValue) => {
        if (errors) {
          this.$alert(
            "Vous n'avez pas complété tous les champs obligatoires marqués par une astérisque rouge. Veuillez tous les remplir afin de valider le formulaire.",
            "Erreur",
            "error"
          )
        }
      })
    },
    submit(interlocuteurId) {
      console.log(
        `%c Site > Etape_1 > submit`,
        "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
      )
      this.form.validateFields((errors, fieldsValue) => {
        this.wasSubmit = true
        let siteActif = !(
          this.form.getFieldValue("status") === "INEXISTANT" ||
          this.form.getFieldValue("status") === "RESILIATION" ||
          this.form.getFieldValue("status") === "REJETE"
        )
        if (errors) {
          /* this.$notification.warning({
            message: "Formulaire invalide",
            description: `Veuillez-vous assurez de remplir tous les champs requis.`,
          })*/
          this.$alert(
            "Vous n'avez pas complété tous les champs obligatoires marqués par une astérisque rouge. Veuillez tous les remplir afin de valider le formulaire.",
            "Erreur",
            "error"
          )
        } else if (interlocuteurId === null && siteActif) {
          this.$notification.error({
            message: "Aucun interlocuteur n'existe",
            description: `Vous devez préalablement créer un interlocuteur technique pour ce site.`,
          })
        } else {
          Axios("get", "api/interlocuteurs/" + interlocuteurId)
            .then((response) => {
              this.proceed(response.data.id, fieldsValue)
            })
            .catch((error) => {
              console.error("error", error)
            })
            .catch((error) => {
              console.error("error", error)
            })
        }
      })
    },
    proceed(interlocuteurId, fieldsValue) {
      const siteDisabled = ["RESILIATION", "REJETE", "INEXISTANT"]
      const space = [" "]
      let adr = fieldsValue.adresse
      //check si le premier char est un espace
      if (space.includes(adr.substring(0, 1))) {
        while (space.includes(adr.substring(0, 1))) {
          adr = adr.substring(1)
        }
      }
      //check si le dernier char est un espace
      if (space.includes(adr.substring(adr.length - 1))) {
        while (space.includes(adr.substring(adr.length - 1))) {
          adr = adr.substring(0, adr.length - 1)
        }
      }
      //extinctionNUIT tjrs à 0 car pas exploité côté tde pour le moment.
      const site = {
        extinctionNuit: 0,
        groupement: null,
        ongletIdentification: true,
        membre: process.env.VUE_APP_API_URL + "/api/membres/" + this.membre.id,
        fluide: fieldsValue.fluide,
        nom: fieldsValue.nom == null ? "PAS DE NOM" : fieldsValue.nom,
        refSite: fieldsValue.refSite,
        codeSite: fieldsValue.codeSite,
        rae: fieldsValue.rae,
        adresse: adr,
        codePostal: fieldsValue.codePostal,
        commune: fieldsValue.commune !== null ? this.deleteArrondissement(fieldsValue.commune) : fieldsValue.commune,
        codeImputation: fieldsValue.codeImputation,
        codeGroupement: fieldsValue.codeGroupement,
        distributeur: fieldsValue.distributeur,
        distributeurCode: this.distribCodeSelected,
        status: fieldsValue.status,
        removed: siteDisabled.includes(fieldsValue.status) && fieldsValue.statutSite.toUpperCase() !== "Confirmer la bascule".toUpperCase() ? 1 : 0,
        dateActivation: +moment(fieldsValue.dateActivation),
        dateResiliation: +moment(fieldsValue.dateResiliation),
        codeInsee: fieldsValue.codeInsee,
        operationId: this.operation.id,
        interlocuteur: process.env.VUE_APP_API_URL + "/api/interlocuteurs/" + interlocuteurId,
        keyEld: fieldsValue.keyEld,
        statutSite: fieldsValue.statutSite,
        validateMes: fieldsValue.validateMes ? fieldsValue.validateMes : false
      }
      // IMPORTANT: GERER LIEN GROUPEMENT EN FCT SEGMENT
      if (site.codeGroupement !== null && site.codeGroupement !== "") {
        Axios(
          "get",
          "api/groupements/search/findByNomAndMembre?nom=" + site.codeGroupement + "&membreId=" + this.membre.id
        )
          .then((resp) => {
            if (resp.data != null) {
              console.log("groupement existant")
              if (
                this.site.id &&
                resp.data.typologieSegment &&
                resp.data.typologieSegment.split(",").includes(this.site.segment)
              ) {
                site.groupement = process.env.VUE_APP_API_URL + "/api/groupements/" + resp.data.id
              } else {
                this.$alert(
                  "Le lien avec le groupement de facture " +
                  resp.data.nom +
                  " n'a pas été établi dû à une incompatibilité de segment entre le segment de site et celui de la facture, ou bien car le site n'est pas encore crée (segment null)"
                )
              }
              this.saveSite(site, fieldsValue)
            }
          })
          .catch((error) => {
            console.log("errror", error)
            if (this.site.id) {
              console.log("groupement creation")
              let tabTypoSeg = ["C_1,C_2,C_3,C_4", "C_5", "T_1,T_2", "T_3", "A_PRECISER"]
              let segSiteToTypo = tabTypoSeg.filter((typo) => typo.split(",").includes(this.site.segment)).toString()
              const groupement = {
                membre: process.env.VUE_APP_API_URL + "/api/membres/" + this.membre.id,
                // Type de Facturation
                nom: fieldsValue.codeGroupement,
                typologieSegment: segSiteToTypo,
                // Moyen de paiment
                moyenPaiement: this.membre.moyenPaiement,
                // Option
                papier: false,
                optionExcel: false,
                dematXml: false,
                factureElectr: false,
                dispoEdi: false,
                // Etat
                state: Constants.STATUS.TO_COMPLETE.value,
                operationId: this.operation.id,
                removed: false,
              }
              Axios("post", "api/groupements", groupement)
                .then((response) => {
                  site.groupement = process.env.VUE_APP_API_URL + "/api/groupements/" + response.data.id
                  this.saveSite(site, fieldsValue)
                  this.$notification.success({
                    message: "Création groupement terminée",
                    description: "Une facture a bien été créer à partir du libellé de facture renseigné !",
                  })
                })
                .catch((error) => {
                  console.log(error)
                  this.$notification.error({
                    message: "Erreur",
                    description: "Erreur dans la création du périmètre de facturation",
                  })
                })
            } else {
              this.$alert(
                "Le lien avec le groupement de facture " +
                fieldsValue.codeGroupement +
                " n'a pas été établi car le site n'a pas encore de segment précisé."
              )
              this.saveSite(site, fieldsValue)
            }
          })
      } else {
        this.saveSite(site, fieldsValue)
      }
    },
    saveSite(site, fieldsValue) {
      if (this.site.id) {
        Axios("patch", "api/sites/" + this.site.id, site)
          .then((response) => {
            this.$notification.success({
              message: "Sauvegarde terminée",
              description: "Les informations d'identification ont bien été sauvegardées.",
            })
            this.$emit("update", response.data)
          })
          .catch((error) => {
            console.error(error)
            this.$notification.error({
              message: "Erreur serveur",
              description: "Une erreur server est survenue, veuillez contacter un administrateur.",
            })
          })
      } else {
        const bean = fieldsValue.fluide === "GAZ" ? "siteGazes" : "siteElecs"
        site.multiSite = true
        Axios("post", "api/" + bean, site)
          .then((response) => {
            this.$notification.success({
              message: "Sauvegarde terminée",
              description: "Les informations d'identification ont bien été sauvegardées.",
            })
            this.$emit("update", response.data)
          })
          .catch((error) => {
            console.error(error)
            if (error.response.status === 409) {
              this.$notification.warning({
                message: "Erreur d'RAE",
                description: "Ce RAE existe déjà pour un autre site.",
              })
            } else {
              this.$notification.error({
                message: "Erreur serveur",
                description: "Une erreur server est survenue, veuillez contacter un administrateur.",
              })
            }
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-mes-modal {
  font-family: $font-family !important;
  width: 800px !important;

  .ant-modal-content {
    .ant-modal-footer {
      display: none;
    }
  }

  .container-list {
    display: flex;
    flex-flow: column nowrap;
  }
}
</style>
