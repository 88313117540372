<template>
  <section class="textInput">
    <label>
      <font-awesome-icon v-if="required" icon="asterisk" class="red-text" />
      {{ label }}
    </label>
    <input
      type="date"
      v-model="date"
      :placeholder="placeholder"
      class="validate textInput"
      :required="required"
      ref="field"
    />
  </section>
</template>

<script>
import moment from "moment"

export default {
  name: "date-field",
  props: ["value", "label", "placeholder", "required"],
  computed: {
    date: {
      get() {
        return this.value ? moment(this.value, "x").format("YYYY-MM-DD") : new Date()
      },
      set(value) {
        let valid = false
        if (/^[1-9][0-9]{3}.{1}[0-9]{2}.{1}[0-9]{2}$/.test(value)) {
          const realDate = moment(value, "YYYY-MM-DD").format("x")
          if (realDate !== "Invalid date") {
            valid = true
            this.$emit("input", realDate)
          }
        }

        if (valid) {
          this.$refs.field.setCustomValidity("")
        } else {
          this.$refs.field.setCustomValidity("Mauvais format de date")
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
input::-webkit-inner-spin-button {
  display: none;
}
</style>
