<template>
  <mcma-card
    title="progression global"
    icon="progression_global"
    class="mcma-card-progress fit-height"
    :inline="true"
    :class="{ loading: loading }"
  >
    <template #describe>
      <div class="list">
        <div class="line">
          <div class="label">
            {{ timeIsOver ? "Terminée depuis" : "Temps restant" }}
          </div>
          <div class="value">
            <count-down :modeCountDown="true" @timeIsOver="setTimeIsOver" />
          </div>
        </div>
        <div class="line">
          <div class="label">
            {{ timeIsOver ? "Opération terminée le" : "Fin de l'opération" }}
          </div>
          <div class="value">
            <count-down />
          </div>
        </div>
      </div>
    </template>
    <template #headerRightSide>
      <div class="progress-global-block">
        <mcma-spin-box :spinning="loading" :noTransparence="true" :size="50">
          <a-progress
            type="circle"
            :strokeColor="progression === 100 ? successColor : primaryColor"
            :percent="progression"
          />
        </mcma-spin-box>
      </div>
    </template>
  </mcma-card>
</template>

<script>
import McmaCard from "@/components/mcma/common/McmaCard.vue"
import CountDown from "@/components/common/CountDown"
import _ from "lodash"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"

export default {
  name: "GlobalProgress",
  props: {
    membresStats: Array,
    loading: Boolean,
  },
  data() {
    return {
      timeIsOver: false,
      primaryColor: "#1890ff",
      successColor: "#4ECB74",
    }
  },
  components: {
    McmaCard,
    CountDown,
    McmaSpinBox,
  },
  computed: {
    progression() {
      if (this.membresStats) {
        const number =
          _.sumBy(this.membresStats, (membre) => {
            return membre.certified ? 1 : membre.progress
          }) / this.membresStats.length
        return Math.trunc(number * 100)
      }
      return 0
    },
  },
  methods: {
    setTimeIsOver() {
      this.timeIsOver = true
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-card-progress {
  height: 100%;
  min-width: 300px;
  .title-block {
    margin-top: 15px;
  }
  &.loading {
    .progress-global-block {
      .ant-progress {
        .ant-progress-text {
          opacity: 0;
        }
      }
    }
  }
  .describe {
    display: flex;
    flex-flow: row wrap;
    .list {
      .line {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 3px 0;
        min-width: 300px;
        width: 300px;
        color: var(--text-color, $text-color);
        .label {
          white-space: nowrap;
          font-size: 14px;
          font-weight: 400;
        }
        .value {
          white-space: nowrap;
          font-size: 14px;
          font-weight: 300;
        }
      }
    }
  }
  .progress-global-block {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    height: 100%;
    > div {
      margin: auto 0 auto auto;
      .ant-progress {
        .ant-progress-text {
          font-size: 1.5em;
          color: var(--heading-color, $heading-color) !important;
          .anticon-check {
            color: var(--success-color, $success-color);
            font-size: 35px;
          }
        }
      }
    }
  }
}
</style>
