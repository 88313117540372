<template>
  <a-modal v-model="visible" :title="`Validation du parcours`" :maskClosable="false" class="mcma-validation-modal"
    :width="1000">
    <div class="container-list">
      <mcma-card v-if="!validatedParcours" class="mcma-card-actual-progress-body">
        <h5>
          <p class="principal-message with-bottom">Validation impossible pour le(s) motif(s) suivant(s):</p>
        </h5>
        <br />
        <h6>
          <p v-if="membreStats.statSite.toComplete !== 0">
            Etape Site: au moins un des onglets 'Localisation' ou 'données techniques' n'est pas complété
          </p>
          <br />
        </h6>
        <h6>
          <p
            v-if="membreStats.statGroupement.link !== 1 || membreStats.statGroupement.size !== membreStats.statGroupement.completed || membreStats.statGroupement.toComplete !== 0">
            Etape Périmètre facturation: au moins une des données obligatoires du formulaire n'est pas renseigné ou un
            lien site/facture est manquant.
          </p>
          <br />
        </h6>
        <h6>
          <p v-if="membreStats.statInterlocuteur.progress !== 1">
            Etape Interlocuteur: au moins un des rôles suivant n'a pas été créé en tant qu'interlocuteur (GESTIONNAIRE /
            FACTURATION / SIGNATAIRE / ADMINSTRATEUR_ESPACE_CLIENT
          </p>
          <br />
        </h6>
        <h6>
          <p v-if="membreStats.statGroupement.link !== 1">
            Etape Périmètre facturation: au moins un des sites n'est pas associé à un périmètre de facturation
          </p>
        </h6>
        <br />
        <div class="align-row-end mtb12">
          <mcma-button name="J'ai compris" color="success" class="ok mr7" @click="close" />
        </div>
      </mcma-card>

      <mcma-card v-else>
        <h5 style="margin:10px;">
          Vous allez valider votre parcours, vos données ne pourront plus être modifiées et vous recevrez un
          e-mail contenant l'export de toute vos données.
        </h5>
        <template>
          <a-form :form="form">
            <a-row type="flex" :gutter="12">
              <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="nom" label="Nom du validateur"
                placeholder="DUPONT" :required="true" :uppercase="true" />
              <mcma-field type="input" :form="form" :wasSubmit="wasSubmit"  fieldName="prenom" label="Prénom du validateur"
                placeholder="John" :required="true" :capitalize="true" />
              <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="email"
                label="Email du validateur" placeholder="john@gmail.com" :required="true"
                :requiredMessage="`Merci de renseigner l'email du validateur`"
                rule="isEmail" />
            </a-row>
          </a-form>
        </template>
        <div class="inline-buttons">
          <mcma-button name="Annuler" color="orange" class="mt24" @click="close" />
          <mcma-button name="Valider les informations" color="primary" class="mt24"
            @click="handleOk" />
        </div>
      </mcma-card>
    </div>
  </a-modal>
</template>

<script>
import McmaField from "@/components/mcma/common/McmaField"
import McmaButton from "@/components/mcma/common/McmaButton"
import { mapState, mapActions } from "vuex"
import McmaIcon from "@/components/mcma/common/McmaIcon"
import McmaCard from "@/components/mcma/common/McmaCard"
import Axios from "@/util/Axios"
import Materialize from "materialize-css"

export default {
  name: "McmaValidateParcoursModal",
  props: {
    visible: Boolean,
  },
  components: {
    McmaField,
    McmaButton,
    McmaIcon,
    McmaCard,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      validatedParcours: false,
      wasSubmit: false,
    }
  },
  computed: {
    ...mapState(["operation", "membre", "membreStats"]),
  },
  created() {
    if (
      this.membreStats.statSite.toComplete === 0 &&
      this.membreStats.statGroupement.progress === 1 &&
      this.membreStats.statInterlocuteur.progress === 1 &&
      this.membreStats.statGroupement.link === 1
    ) {
      this.validatedParcours = true
    } else {
      this.validatedParcours = false
    }
  },
  methods: {
    ...mapActions(["tutoGoesTo", "requestMembresStats"]),
    handleOk() {
      this.form.validateFields((errors, fieldsValue) => {
        this.wasSubmit = true
        if (errors) {
          this.$notification.warning({
            message: "Formulaire invalide",
            description: `Veuillez-vous assurez de remplir les données requises à la validation du parcours.`,
          })
        } else {
          
          this.validate()
          this.$emit("save")
          this.close()
        }
      })
      
    },
    close() {
      this.$emit("close")
    },
    validate() {
      const callSite = Axios("get", "api/membres/" + this.membre.id + "/sites")
        .then((sites) => {
          let listeSites = []
          if (sites.data._embedded.siteGazes != null) {
            listeSites = sites.data._embedded.siteGazes
          } else if (sites.data._embedded.siteElecs != null) {
            listeSites = sites.data._embedded.siteElecs
          }
          listeSites.map((site) => {
            Axios("patch", "api/sites/" + site.id, {
              certified: true,
            })
          })
        })
        .catch((error) => console.log(error))

      const callGroupement = Axios("get", "api/membres/" + this.membre.id + "/groupements")
        .then((groupements) => {
          let listeGroupements = []
          listeGroupements = groupements.data._embedded.groupements
          listeGroupements.map((groupement) => {
            Axios("patch", "api/groupements/" + groupement.id, {
              state: "CERTIFIED",
            })
          })
        })
        .catch((error) => console.log(error))
      
      //put here form datas for validation trace
      const callMembre = Axios("patch", "api/membres/" + this.membre.id, {
        certified: true,
        serviceCertified: true,
        approuved: true,
        approuvedInterlocuteurs: true,
        emailValidateur: this.form.getFieldValue("email"),
        nomValidateur: this.form.getFieldValue("nom"),
        prenomValidateur: this.form.getFieldValue("prenom"),
        dateValidation: Date.now()
      }).catch((error) => console.log(error))

      Promise.all([callSite, callMembre, callGroupement])
        .then(() => {
          this.requestMembresStats()
          this.sendMailWithExportMembre()
          Materialize.toast("Toutes les données parcours sont validées", 4000, "lime white-text")
        })
        .catch((error) => console.log(error))
    },
    sendMailWithExportMembre() {
      if (this.membreStats && this.membreStats.userEmail !== null) {
        Axios("post", "export/membres/users?idOperation=" + this.operation.id, [this.membre]).catch((error) => {
          console.error(error)
          this.$notification.error({
            message: "Erreur lors de l'envoi de email",
            description: "erreur lors de l'envoi du mail avec export",
          })
        })
      }
    },
  },
}
</script>
<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-validation-modal {
  font-family: $font-family !important;
  width: 800px !important;

  .ant-modal-content {
    .ant-modal-footer {
      display: none;
    }
  }

  .container-list {
    display: flex;
    flex-flow: column nowrap;
  }
}
</style>
