<template>
    <div>
        <a-tooltip v-if="isIcon" @click="clickExport()" :placement="user.role === 'COORDINATEUR_AVANCE' || user.role === 'ADMIN' || user.role === 'SUPER_ADMIN'
            ? 'bottom'
            : 'top'
        ">
            <template slot="title">
                <span>{{title}}</span>
            </template>
            <div>
                <mcma-icon :class="classIcon" :type="typeIcon" :color="color" :full="full" :faIcon="faIcon" />
            </div>
        </a-tooltip>
        <a-row v-else :gutter="12" class="stretch">
            <a-col>
                <mcma-button v-if="mcmaButtonComponent" :name="title" color="primary" class="mt24" @click="clickExport" :loading="loading" :disabled="loading"/>
                <v-btn v-else small block color="green" :disabled="loading" :loading="loading" @click="clickExport()">
                    {{ title }}
                    <v-icon right> cloud_download </v-icon>
                </v-btn>
            </a-col>
            <a-col v-if="loading">
                <span>Merci de patienter le temps du traitement...</span>
            </a-col>
        </a-row>
        <a ref="downloadLink" class="ninja" />
    </div>
</template>

<script>
import { mapState } from "vuex"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import McmaCard from "@/components/mcma/common/McmaCard.vue"
import Axios from "@/util/Axios"
import McmaIcon from "@/components/mcma/common/McmaIcon"
import McmaButton from "@/components/mcma/common/McmaButton"
export default {
    name: 'McmaExportGenericMage',
    props: {
        pipeline: String,
        title: String,
        fileTitle: String,
        isIcon: Boolean,
        member: Object,
        mcmaButtonComponent: Boolean,
        classIcon: String,
        typeIcon: String, 
        color: String, 
        full: Boolean,
        faIcon: Boolean
    },
    components: {
        McmaButton,
        McmaIcon,
        McmaCard,
        McmaSpinBox,
    },

    data() {
        return {
            loading: false,
        }
    },
    computed: {
        ...mapState(["membre", "operation", "user"]),
        membreId() {
            return this.membre?.id || this.member?.id || null
        },
        operationId() {
            return this.operation?.id || null
        },
    },

    methods: {
        createFilename(){
        var date = new Date()
        var fileName =
          this.fileTitle+
            "_" +
          date.getFullYear() +
          date.getMonth() +
          date.getDay() +
          "_" +
          date.getHours() +
          date.getMinutes() +
          date.getSeconds() +
          ".xlsx"
          return fileName
        },  
        async clickExport() {
            console.log("on entre dans clickExport")
            this.loading = true
            try {
                var url = '/mage/export?pipeline=' + this.pipeline + '&tag=export'
                if (this.membreId !== undefined && this.membreId !== null) {
                    url = url + '&membreId=' + this.membreId
                }
                if (this.operationId !== undefined && this.operationId !== null) {
                    url = url + '&operationId=' + this.operationId
                }
                const res = await Axios("get", url)
                if (res.data.pipeline_run?.event_variables?.waika_path_s3_return) {

                    const waika_path_s3_return = res.data.pipeline_run.event_variables.waika_path_s3_return
                    console.log('waika_path_s3_return : %O', waika_path_s3_return)
                    const dataret = await Axios("get",
                        '/file/s3/download_from_path?path=' + waika_path_s3_return + '&bucket=store-waika&timeout=3600&isFile=true'
                        , null, {
                        responseType: "blob",
                    }).then((response) => {
                        const link = this.$refs.downloadLink
                        link.href = window.URL.createObjectURL(new Blob([response.data]))
                        link.setAttribute('download', this.createFilename())
                        link.click()
                        //this.$toast.success('Fichier téléchargé !')
                    })
                }
            } catch (error) {
                console.error(error)
                //this.$toast.error()
            } finally {
                this.loading = false
                console.log('download end');
            }
        },

    },
}
</script>

<style lang="scss" scoped>
.action {
    margin-bottom: 15px;
}

.obligatoire {
    color: rgb(255, 94, 0);
}

.preferable {
    color: rgb(100, 100, 252);
}
</style>