<template>
  <div class="mcma-table">
    <div class="header">
      <div class="selected-sentence" :class="{ show: hasSelected }" v-if="dataSource.length > 0">
        <template>
          {{ selectedSentence }}
        </template>
      </div>
    </div>
    <a-table
      :rowSelection="rowSelection"
      :columns="getColumns"
      :dataSource="dataSource"
      :scroll="{ x: true }"
      :pagination="{
        defaultPageSize: 5,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '50', '100'],
      }"
      @change="onChange"
      :locale="getLocale"
      bordered
    >
      <div
        slot="filterDropdown"
        slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        style="padding: 8px"
      >
        <a-input
          v-ant-ref="(c) => (searchInput[column.dataIndex] = c)"
          :placeholder="`Recherche par ${column.title.toLowerCase()}`"
          :value="selectedKeys[0]"
          @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(column.dataIndex, selectedKeys, confirm)"
          style="width: 188px; margin-bottom: 8px; display: block"
        />
        <a-button
          type="primary"
          @click="() => handleSearch(column.dataIndex, selectedKeys, confirm)"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
        >
        </a-button>
        <a-button
          @click="() => handleReset(column.dataIndex, clearFilters)"
          icon="sync"
          size="small"
          style="width: 90px"
        >
        </a-button>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :class="{ 'blue-color': filtered }" />
      <template slot="name" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="name" :text="text" />
      </template>
      <template slot="type" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="type" :text="text" />
      </template>
      <div slot="actions" slot-scope="props">
        <div class="cell-actions">
          <div class="actions-block">
            <div class="line">
              <div @click="download(props)">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Télécharger</span>
                  </template>
                  <div>
                    <mcma-icon type="download" color="purple" :faIcon="true" :full="true" />
                  </div>
                </a-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-table>
    <a class="ninja" ref="downloadLink" />
  </div>
</template>

<script>
import Vue from "vue"
import McmaIcon from "@/components/mcma/common/McmaIcon"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaSearchTableFilter from "@/components/mcma/common/McmaSearchTableFilter"
import { mapState } from "vuex"
import Axios from "@/util/Axios"
import { accorization } from "@/util/Helpers"
import Constants from "@/util/Constants"

Vue.component("document-empty-without-filter", {
  template: `<a-empty image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original">
                <span slot="description"> Aucune piéce </span>
              </a-empty>`,
})

Vue.component("document-empty-with-filter", {
  template: `<a-empty image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original">
                <span slot="description"> Aucune piéce ne correspond au filtre renseigné </span>
              </a-empty>`,
})

export default {
  name: "DocumentsManagerListTable",
  props: {
    documents: Array,
  },
  components: {
    McmaIcon,
    McmaButton,
    McmaSearchTableFilter,
  },
  data() {
    return {
      selectedRowKeys: [],
      oldSelectedRowKeys: [],
      dataSource: [],
      currentDoc: null,
      loading: false,
      filters: {},
      searchText: {
        name: "",
        type: "",
      },
      searchInput: {
        name: null,
        type: null,
      },
      columns: [
        {
          title: "Nom de la piece",
          dataIndex: "name",
          key: "name",
          fixed: "left",
          width: 200,
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "name",
          },
          onFilter: (value, record) =>
            record.name ? record.name.toString().toLowerCase().includes(value.toLowerCase()) : null,
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput["name"].focus()
              }, 0)
            }
          },
        },
        {
          title: "Type de piece",
          dataIndex: "type",
          key: "type",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "type",
          },
          onFilter: (value, record) =>
            record.type ? record.type.toString().toLowerCase().includes(value.toLowerCase()) : null,
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput["type"].focus()
              }, 0)
            }
          },
        },
        {
          title: "Dépôt obligatoire",
          dataIndex: "mandatory_upload",
          width: 200,
          filters: [
            {
              text: Constants.FILESTATUS.MANDATORY_UPLOAD.text,
              value: Constants.FILESTATUS.MANDATORY_UPLOAD.text,
            },
            {
              text: Constants.FILESTATUS.NOT_MANDATORY_UPLOAD.text,
              value: Constants.FILESTATUS.NOT_MANDATORY_UPLOAD.text,
            },
          ],
          onFilter: (value, record) => record.mandatory_upload === value,
          scopedSlots: { customRender: "mandatory_upload" },
        },
        {
          title: "Actions",
          fixed: "right",
          key: "actions",
          width: 100,
          scopedSlots: { customRender: "actions" },
        },
      ],
    }
  },
  computed: {
    ...mapState(["membre", "user", "currentTutorial", "operation"]),
    getLocale() {
      return {
        emptyText: this.dataSource.length === 0 ? <document-empty-without-filter /> : <document-empty-with-filter />,
      }
    },
    selectedSentence() {
      return accorization(this.oldSelectedRowKeys.length, "piéce sélèctionnée", true, "aucune piéce sélèctionnée")
    },
    getColumns() {
      return this.columns
    },
    hasSelected() {
      return this.selectedRowKeys.length > 0
    },
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onSelectAll: this.onSelectAll,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        onSelection: this.onSelection,
      }
    },
  },
  watch: {
    documents(newValue) {
      this.generateDataSource(newValue)
    },
    selectedRowKeys(newValue, oldValue) {
      this.oldSelectedRowKeys = newValue.length === 0 ? [...oldValue] : [...newValue]
    },
  },
  mounted() {
    this.generateDataSource(this.documents)
  },
  methods: {
    updateSelectRowsOnFilterChange() {
      const dataSource = this.getDataSourceFiltered()
      this.selectedRowKeys = this.selectedRowKeys.filter((key) => {
        return dataSource.find((data) => {
          return key === data.key
        })
      })
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
      const list = this.dataSource.filter((data) => selectedRowKeys.includes(data.key)).map((data) => data.key)
      this.$emit("selectedChanged", list)
    },
    onChange(pagination, filters, sorter) {
      this.filters = filters
      this.updateSelectRowsOnFilterChange()
    },
    generateDataSource(documents) {
      this.dataSource = []
      documents.forEach((doc, index) => {
        this.dataSource.push({
          key: index,
          id: doc.id,
          name: doc.name,
          type: doc.type,
          mandatory_upload: doc.mandatory_upload ? "Obligatoire" : "Facultatif",
          extension: doc.extension,
        })
      })
      this.dataSource.sort((a, b) => b.id - a.id)
    },
    getDataSourceFiltered() {
      let dataSourceFiltered = [...this.dataSource].sort((a, b) => b.id - a.id)
      for (let key in this.searchInput) {
        if (this.filters[key] && this.filters[key].length > 0) {
          dataSourceFiltered = dataSourceFiltered.filter((data) =>
            data[key] ? data[key].toString().toLowerCase().includes(this.searchText[key].toLowerCase()) : null
          )
        }
      }
      return dataSourceFiltered
    },
    onSelectAll(selected) {
      let dataSourceFiltered = []
      if (selected) {
        dataSourceFiltered = this.getDataSourceFiltered()
      }
      this.selectedRowKeys = dataSourceFiltered.map((data) => data.key)
      this.onSelectChange(this.selectedRowKeys)
    },
    handleSearch(fieldName, selectedKeys, confirm) {
      confirm()
      this.searchText[fieldName] = selectedKeys[0]
    },
    handleReset(fieldName, clearFilters) {
      clearFilters()
      this.searchText[fieldName] = ""
    },
    download(documents) {
      Axios("get", "file/piece/download?pieceId=" + documents.id + "&operationId=" + this.operation.id, null, {
        responseType: "blob",
      })
        .then((response) => {
          const link = this.$refs.downloadLink
          link.href = window.URL.createObjectURL(new Blob([response.data]))
          link.setAttribute("download", documents.name + "." + documents.extension)
          link.click()
          this.$message.success(`La piéce est en cours de télechargement.`)
        })
        .catch((error) => {
          console.error(error)
          this.$notification.error({
            message: "Erreur lors du téléchargement",
            description:
              "Une erreur est survenue lors du telechargement de la piece, veuillez contacter un administrateur.",
          })
        })
    },
  },
}
</script>

<style lang="scss"></style>
