<template>
  <section>
    <div class="container">
      <a-row type="flex" :gutter="12" v-if="users">
        <a-col :span="24">
          <users-header :users="users">
            <mcma-spin-box :spinning="loading">
              <users-list-table :users="users" @initialize="initialize" @selectedChanged="selectedChanged" />
            </mcma-spin-box>
          </users-header>
        </a-col>
      </a-row>
    </div>
  </section>
</template>

<script>
import Axios from "@/util/Axios"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import UsersHeader from "@/components/mcma/users/components/UsersHeader"
import UsersListTable from "@/components/mcma/users/components/UsersListTable"
import { mapActions, mapMutations, mapState } from "vuex"

export default {
  name: "UsersManager",
  components: {
    McmaSpinBox,
    UsersHeader,
    UsersListTable,
  },
  data() {
    return {
      loading: true,
      selected: null,
    }
  },
  computed: {
    ...mapState(["user", "operation", "users"]),
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    ...mapActions(["requestUsers"]),
    initialize() {
      if (this.selected === null && this.$route.params !== undefined && this.$route.params.id !== undefined) {
        Axios("get", "api/users/" + this.$route.params.id)
          .then((response) => {
            this.$notification.success({
              message: `Utilisateur prêt !`,
              description: `l'utilisateur est prêt pour être modifié.`,
            })
            console.log("> response : %O", response)
            this.selected = response.data
          })
          .catch((error) => {
            console.error(error)
            this.$notification.error({
              message: `Erreur!`,
              description: `Erreur lors du préchargement de l'utilisteur.`,
            })
          })
      }
      this.requestUsers().then(() => {
        this.loading = false
      })
      /*
      Axios(
        'get',
        'api/users'
      ).then(response => {
        const size = response.data.page.totalElements
        Axios(
          'get',
          'api/users?size=' + size
        ).then(response => {
          this.users = response.data._embedded.users
        })
      })
      */
    },
    submit(user) {
      if (this.selected) {
        Axios("patch", "api/users/" + this.selected.id, {
          ...user,
          welcomed: null,
          firstConnectOk: null,
          lastConnect: null,
          lastConnectAttempt: null,
        })
          .then((response) => {
            this.$notification.success({
              message: `Utilisateur modifié !`,
              description: `l'utilisateur à bien été modifié.`,
            })
            this.selected = null
            this.initialize()
          })
          .catch((error) => {
            console.error(error)
            this.$notification.error({
              message: `Erreur d'ajout utilisateur !`,
              description: `Une erreur est survenue lors de l'ajout de l'utilisateur.`,
            })
          })
      } else if (!this.invalidPassword) {
        Axios("post", "api/users", user)
          .then((response) => {
            this.$notification.success({
              message: `Utilisateur ajouté !`,
              description: `l'utilisateur à bien été ajouté.`,
            })
            // TODO add user operation_id dans la table operation_user...
            console.log("Add user response : %O", response.data)
            console.log("Add user response.data.id : %O", response.data.id)
            console.log("Add user response.data.id : %O", this.operation.id)
            Axios("post", "operation/addLinkUser?operation=" + this.operation.id + "&user=" + response.data.id)
              .then((response) => {
                this.$notification.success({
                  message: `Liaison sauvegardé !`,
                  description: `La liaison utilisateur-opération à bien été sauvegardée.`,
                })
              })
              .catch((error) => {
                console.error(error)
                this.$notification.error({
                  message: `Erreur d'ajout !`,
                  description: `Une erreur est survenue lors de l'ajout du lien entre l'utilisateur et l'opération !`,
                })
              })
            this.initialize()
          })
          .catch((error) => {
            console.error(error)
            this.$notification.error({
              message: `Erreur d'ajout !`,
              description: `Une erreur est survenue lors de l'ajout de l'utilisateur !`,
            })
          })
      }
    },
    selectedChanged(list) {
      console.log(
        `%c selectedChanged`,
        "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
        list
      )
    },
  },
  watch: {
    selected() {
      this.initialize()
    },
    $route(to, from) {
      console.log("to : %O", to)
      console.log("from : %O", from)
      // react to route changes...
    },
  },
  created() {
    // console.group('Users.created')
    // console.log("> This :%O", this)
    // console.log("> this.$root :%O", this.$root)
    this.setBreadcrumbAdd({
      name: "Gestion des utilisateurs",
      action: () => {
        this.$router.push({
          name: "users-manager",
          params: {
            operationId: this.operation.id,
          },
        })
      },
    })
    this.initialize()
    console.groupEnd()
  },
}
</script>

<style lang="scss" scoped></style>
