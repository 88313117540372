<template>
  <section>
    <div class="container">
      <a-row type="flex" :gutter="12" v-if="interlocuteurs">
        <a-col :span="24">
          <interlocuteurs-header :interlocuteurs="interlocuteurs">
            <mcma-spin-box :spinning="loading">
              <interlocuteurs-list-table
                :interlocuteurs="interlocuteurs"
                @initialize="initialize"
                @updateInterlocuteur="updateInterlocuteur"
                @selectedChanged="selectedChanged"
              />
            </mcma-spin-box>
          </interlocuteurs-header>
        </a-col>
      </a-row>
    </div>
  </section>
</template>

<script>
import GuardiansMixin from "@/components/mcma/steps/guardians/GuardiansMixin"
import Axios from "@/util/Axios"
import Grid from "@/components/common/Grid"
import InterlocuteursHeader from "@/components/mcma/steps/interlocuteurs/components/InterlocuteursHeader"
import InterlocuteursListTable from "@/components/mcma/steps/interlocuteurs/components/InterlocuteursListTable"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import { mapState, mapMutations, mapActions } from "vuex"
import _ from "lodash"
import PlaceHolder from "@/components/common/PlaceHolder"

export default {
  name: "interlocuteurs",
  mixins: [GuardiansMixin],
  components: {
    Grid,
    PlaceHolder,
    InterlocuteursHeader,
    InterlocuteursListTable,
    McmaSpinBox,
  },
  data() {
    return {
      flow: "SERVICES",
      interlocuteurs: null,
      loading: true,
    }
  },
  computed: {
    ...mapState(["membre", "operation", "user"]),
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd", "setState", "requestMembreStats"]),
    ...mapActions(["refreshStore", "requestMembreStats"]),
    goTo(route) {
      const interlocuteurId = route && route.params ? route.params.interlocuteurId : null
      const interlocuteur = this.interlocuteurs.find((g) => g.id === interlocuteurId)
      this.setState({
        stateName: "interlocuteur",
        value: interlocuteur || {},
      })
      if (typeof route === "string") {
        this.$router.push({ name: route })
      } else if (typeof route === "object") {
        this.$router.push(route)
      }
    },
    initialize() {
      console.group("initialize@FlowInterlocuteur")
      console.log("this.membre.id : %O", this.membre.id)
      this.loading = true
      this.interlocuteurs = null
      this.requestMembreStats(this.membre.id)
      Axios("get", "api/membres/" + this.membre.id + "/interlocuteurs").then((response) => {
        this.interlocuteurs = _.filter(response.data._embedded.interlocuteurs, { removed: false })
        this.loading = false
      })
      // this.refreshStore();
      console.groupEnd()
    },
    updateInterlocuteur(interlocuteurId, dataToUpdate) {
      console.log(
        `%c updateInterlocuteur`,
        "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
        interlocuteurId,
        dataToUpdate
      )
    },
    selectedChanged(list) {
      console.log(
        `%c selectedChanged`,
        "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
        list
      )
    },
  },
  created() {
    this.setBreadcrumbAdd({
      name: "Interlocuteurs",
      action: () => {
        this.$router.push({
          name: "interlocuteurs",
        })
      },
    })
    this.initialize()
  },
}
</script>

<style lang="scss" scoped></style>
