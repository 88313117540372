<template>
  <section>
    <div
      class="tab"
      :class="activeTab === index ? 'active' : ''"
      v-for="(mail, index) in operation.mails"
      :key="index"
      @click="activeTab = index"
      v-if="user.role === 'SUPER_ADMIN' || (user.role === 'ADMIN' && mail.name !== 'forgot')"
    >
      {{ $t("super_admin.editor.mailEditor." + mail.name) }}
    </div>
  
    <div class="mailContainer">
      <!-- <span>{{operation.mails[activeTab].name}}</span> -->
      <div>
        <span v-if="operation.mails[activeTab].name === 'footer'"
          ><h5>Ci-dessous, vous pouvez créer la signature qui sera utilise en pied de mail.</h5></span
        >
        <span v-else-if="operation.mails[activeTab].name === 'header'"
          ><h5>Ci-dessous, vous pouvez créer l'entête utilisé dans chacun des type de mails.</h5></span
        >
        <span v-else>
          <article class="col s6 right-align">
            <button
              class="waves-effect waves-light btn green"
              type="reset"
              @click="sendMailTest(operation.mails[activeTab].name)"
            >
              <i class="material-icons left"></i>
              Envoyer un mail de test à l'adresse ci-dessous
            </button>
            <button
              v-if="operation.mails[activeTab].name === 'welcome'"
              class="waves-effect waves-light btn orange"
              type="reset"
              @click="sendMailAdmin()"
            >
              <i class="material-icons left"></i>
              Envoyer le mail de bienvenue aux administrateurs
            </button>
          </article>
          <article class="input-field col s6 center-align">
            <i class="material-icons prefix">account_circle</i>
            <input id="mailTest" type="text" class="validate" required v-model="mailTest" />
            <label for="mailTest" class="active">Envoyer le mail de test à l'adresse ci-dessous :</label>
          </article>
        </span>
      </div>
      <article class="input-field col s6 center-align">
        <i class="material-icons prefix">assignment</i>
        <input id="mailObjet" type="text" class="validate" required v-model="operation.mails[activeTab].subject" />
        <label for="mailObjet" class="active">Objet du mail :</label>
      </article>
      <vue-editor v-model="operation.mails[activeTab].content" class="mailEditor" />
      <div>
        <ul v-if="operation.mails[activeTab].name !== 'forgot'" class="collection with-header">
          <li class="collection-header">
            <h5>Balises spéciales exploitables dans les mails</h5>
            <h6>
              pour ce faire il vous suffit d'ajouter un des termes ci-dessous dans votre mail en le mettant entre balise
              comme ceci :
            </h6>
            <h5><b>&lsaquo;nom_du_terme/&rsaquo;</b></h5>
          </li>
          <li class="collection-item"><b>nomDuGroupement</b> : nom du groupement défini dans l'onglet groupement</li>
          <li class="collection-item"><b>nomOperation</b> : nom de l'opération défini dans l'onglet groupement</li>
          <li class="collection-item"><b>nomContact</b> : Nom du contact défini dans l'onglet groupement</li>
          <li class="collection-item"><b>clientContactMail</b> : mail contact indiqué dans l'onglet groupement</li>
          <li class="collection-item">
            <b>fonctionContact</b> : Fonction du contact de l'opération défini dans l'onglet groupement
          </li>
          <li class="collection-item"><b>dateDebut</b> : date de début indiquée dans l'onglet groupement</li>
          <li class="collection-item"><b>dateFin</b> : date de fin indiquée dans l'onglet groupement</li>
          <li class="collection-item">
            <b>dateActivationContrat</b> : date d'activation des contrats indiquée dans l'onglet groupement
          </li>
          <li class="collection-item"><b>joursRestants</b> : nombre de jours restant avant la fin de l'opération</li>
          <li class="collection-item"><b>membres</b> : nom du membre courant dans le contexte d'envoi du mail</li>
          <li class="collection-item">
            <b>utilisateur</b> : Nom et Prenom de l'utilisateur courant dans le contexte d'envoi du mail
          </li>
          <li class="collection-item">
            <b>clientUrl</b> : adresse internet par laquelle le client se connecte à l'opération
          </li>
          <li class="collection-item"><b>motDePasse</b> : mot de passe de l'utilisateur</li>
          <li class="collection-item"><b>emailDestinataire</b> : email du destinataire</li>
        </ul>
        <ul v-else class="collection with-header">
          <li class="collection-header">
            <h5>Balises spéciales exploitables dans ce type de mail</h5>
            <h6 style="color: rgb(255, 0, 17)">
              Pour des raisons de parcours client, les balises spéciales pour ce type de mail sont uniquement :
            </h6>
            <h6 style="color: rgb(255, 0, 17)">
              Penser à bien vérifier qu'aucune autre balise ne soit présente et à ne pas mettre de text spécifique à une
              opération !
            </h6>
          </li>
          <li class="collection-item"><b>motDePasse</b> : mot de passe de l'utilisateur</li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import Axios from "@/util/Axios"
import Constants from "@/util/Constants"
import { VueEditor } from "vue2-editor"
import Materialize from "materialize-css"
import { mapState } from "vuex"

export default {
  name: "mails",
  props: ["value"],
  components: {
    VueEditor,
  },
  data() {
    return {
      activeTab: 0,
      mailTest: "",
    }
  },
  computed: {
    ...mapState(["user"]),
    operation: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
  },
  methods: {
    sendMailAdmin() {
      console.group("sendMailAdmin")
      console.log("> this.operation : %O", this.operation)
      console.groupEnd()
      Axios("post", "/mail/membre?idOperation=" + this.operation.id, {
        from: this.user,
        type: Constants.MAIL.BDD_WELCOME_ADMIN.value,
        to: [],
      })
        .then((response) => {
          Materialize.toast(
            "Les mails de Bienvenue ont bien été envoyés aux adminsitrateurs de l'opération",
            4000,
            "lime"
          )
        })
        .catch((error) => {
          console.error(error)
          Materialize.toast("Erreur lors de l'envoi pour au moins l'un des mails", 4000, "amber white-text")
        })
    },
    sendMailTest(type) {
      console.group("sendMailTest")
      console.log("> type : %O", type)
      console.log("> mailTest : %O", this.mailTest)
      console.log("> this.operation : %O", this.operation)
      console.groupEnd()
      if (!this.mailTest) {
        Materialize.toast("Merci de définir une adresse mail pour envoyer le mail test", 5000, "amber white-text")
      } else {
        // TODO : nettoyer la duplication de code
        // l'évènement devrait être traité dans index@Editor
        this.saveOperation(type)
        /*
        Axios(
          'get',
          '/mail/sendMailTest?email=' + this.mailTest + '&nameMail=' + type + '&idOperation=' + this.operation.id
        ).then(response => {
          Materialize.toast('Le mails de test de type ' + type + ' a été envoyé à : ' + this.mailTest, 4000, 'lime')
        }).catch(error => {
          console.error(error)
          Materialize.toast('Erreur lors de l\'envoi du mail de test', 4000, 'amber white-text')
        })
        */
      }
    },
    saveOperation(type) {
      // TODO : nettoyer la duplication de code
      // l'évènement devrait être traité dans index@Editor
      console.group("saveOperation@indexMail")
      console.log("this.operation1 : %O", this.operation)
      console.log("this.currentOperation : %O", this.currentOperation)
      if (this.operation) {
        if (this.operation) {
          delete this.operation._links
          // console.log('this.operation.flows : %O', this.operation.flows)
          if (this.operation.flows) {
            this.operation.flows.forEach((f) => {
              delete f._links
              // console.log('f.panels : %O', f.panels)
              if (f.panels) {
                f.panels.forEach((p) => {
                  delete p._links
                  // console.log('p.fields : %O', p.fields)
                  if (p.fields) {
                    p.fields.forEach((field) => {
                      delete field._links
                    })
                  }
                })
              }
            })
          }
          console.log("this.operation.mails : %O", this.operation.mails)
          if (this.operation.mails) {
            this.operation.mails.forEach((m) => {
              delete m._links
            })
          }
          console.log("this.operation2 : %O", this.operation)
          Axios("post", "operation/save", this.operation)
            .then((response) => {
              console.log("response.data : %O", response.data)
              Materialize.toast("Opération modifiée", 4000, "lime white-text")
              Axios(
                "get",
                "/mail/sendMailTest?email=" + this.mailTest + "&nameMail=" + type + "&idOperation=" + this.operation.id
              )
                .then((response) => {
                  Materialize.toast(
                    "Le mails de test de type " + type + " a été envoyé à : " + this.mailTest,
                    4000,
                    "lime"
                  )
                })
                .catch((error) => {
                  console.error(error)
                  Materialize.toast("Erreur lors de l'envoi du mail de test", 4000, "amber white-text")
                })
            })
            .catch((error) => {
              console.error("[Edit operation] " + error)
              Materialize.toast("Erreur lors du test de l'opération", 4000, "deep-orange white-text")
            })
        } else {
          delete this.operation._links
          delete this.operation.progression
          delete this.operation.membres
          console.log("this.operation.flows : %O", this.operation.flows)
          if (this.operation.flows) {
            this.operation.flows.forEach((f) => {
              delete f._links
              console.log("f.panels : %O", f.panels)
              if (f.panels) {
                f.panels.forEach((p) => {
                  delete p._links
                  console.log("p.fields : %O", p.fields)
                  if (p.fields) {
                    p.fields.forEach((field) => {
                      delete field._links
                    })
                  }
                })
              }
            })
          }
          Axios("post", "operation/save", this.operation)
            .then((response) => {
              Materialize.toast("Opération ajoutée", 4000, "lime white-text")
              Axios(
                "get",
                "/mail/sendMailTest?email=" + this.mailTest + "&nameMail=" + type + "&idOperation=" + this.operation.id
              )
                .then((response) => {
                  Materialize.toast(
                    "Le mails de test de type " + type + " a été envoyé à : " + this.mailTest,
                    4000,
                    "lime"
                  )
                })
                .catch((error) => {
                  console.error(error)
                  Materialize.toast("Erreur lors de l'envoi du mail de test", 4000, "amber white-text")
                })
            })
            .catch((error) => {
              console.error("[Add operation] " + error)
              Materialize.toast("Erreur lors de l'ajout de l'opération", 4000, "deep-orange white-text")
            })
        }
      }
      console.groupEnd()
    },
  },
}
</script>

<style lang="scss" scoped>
.tab {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  transition: 0.3s;
  &:not(.active) {
    background: #dbdbdb;
    border: 1px solid #cccccc;
    &:hover {
      background: #f5f5f5;
      border: 1px solid #f5f5f5;
    }
  }
  &.active {
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
  }
}

.mailContainer {
  border-radius: 0 5px 5px 5px;
  background: #f5f5f5;
  padding: 5px;
  & .mailEditor {
    background: white;
    border-radius: 5px;
    margin: 5px;
  }
}
</style>
