<template>
  <form class="card" @submit.prevent="submit">
    <section class="card-content">
      <span class="card-title"> Paramètres du site </span>
      <div class="row valign-wrapper">
        <article class="input-field col s6">
          <input id="pce" type="text" v-model="pce" required pattern="[0-9]{14}|GI[0-9]{6}" />
          <label class="active" for="pce">
            <font-awesome-icon icon="asterisk" class="red-text" />
            PCE
          </label>
        </article>

        <article class="switch col s6 center-align" v-if="bioGazField.displayed">
          <div class="chip" :class="{ 'light-green': bioGaz, red: !bioGaz }">
            <label class="white-text">
              <strong>
                Pourcentage de BioGaz : 0 %
                <input type="checkbox" v-model="bioGaz" />
                <span class="lever"></span>
                10 %
              </strong>
            </label>
          </div>
        </article>
      </div>
      <div class="row">
        <article class="col s6">
          <label class="col s6">Option Tarifaire Initial</label>
          <span class="col s6">
            {{ site.optionTarifaireInitial }}
          </span>
        </article>

        <!-- <article class="col s6">
          <label>
            <font-awesome-icon icon="asterisk" class="red-text"/>
            Option Tarifaire
          </label>
          <select class="browser-default" v-model="optionTarifaire" required>
            <option :value="null" disabled selected>Sélectionnez une option</option>
            <option v-for="item in OptionTarifaireEnum" :key="item" :value="item">{{item}}</option>
          </select>
        </article> -->
        <generic-field
          v-model="optionTarifaire"
          getFlow="name::sites"
          getPanel="name::Données techniques (gaz)"
          getField="type::optionTarifaire"
          class="col s6"
        />
      </div>
      <div class="row">
        <article class="col s6">
          <label class="col s6">Profil Initial</label>
          <span class="col s6">
            {{ site.profilInitial }}
          </span>
        </article>

        <!-- <article class="col s6">
          <label>
            <font-awesome-icon icon="asterisk" class="red-text"/>
            Profil
          </label>
          <select class="browser-default" v-model="profil" required>
            <option :value="null" disabled selected>Sélectionnez un profil</option>
            <option v-for="item in ProfilGazEnum" :key="item" :value="item">{{item}}</option>
          </select>
        </article> -->
        <generic-field
          v-model="profil"
          getFlow="name::sites"
          getPanel="name::Données techniques (gaz)"
          getField="type::profil"
          class="col s6"
        />
      </div>
      <div class="row">
        <article class="col s6">
          <label class="col s6">CAR (kWh) Initial</label>
          <span class="col s6">
            {{ site.carInitial }}
          </span>
        </article>

        <article class="input-field col s6">
          <input id="car" type="number" v-model.number="car" required min="0" step="1" />
          <label class="active" for="car">
            <font-awesome-icon icon="asterisk" class="red-text" />
            CAR (kWh)
          </label>
        </article>
      </div>
      <div class="row">
        <article class="input-field col s12" v-if="hasCja">
          <input id="cja" type="number" v-model.number="cja" required min="0" step="1" />
          <label class="active" for="cja">
            <font-awesome-icon icon="asterisk" class="red-text" />
            CJA (MWh)
          </label>
        </article>
      </div>

      <div class="row">
        <article class="input-field col s12">
          <textarea id="commentaire" class="materialize-textarea" v-model="commentaire"></textarea>
          <label for="commentaire" :class="{ active: commentaire }">Commentaires</label>
        </article>
      </div>
    </section>
    <section class="card-action center-align">
      <button type="submit" class="waves-effect waves-light btn light-green">
        <i class="material-icons">save</i>
        Valider
      </button>

      <button type="button" class="waves-effect waves-light btn blue darken-2" @click="initialize">
        <i class="material-icons">remove</i>
        Réinitialiser
      </button>
    </section>
  </form>
</template>

<script>
import Axios from "@/util/Axios"
import Materialize from "materialize-css"
import { OptionTarifaireEnum, ProfilGazEnum } from "./Enums"
import { RV_RESULT, RV_GAZ } from "./RG_GAZ"
// import _ from 'lodash'
import { mapState } from "vuex"
import GenericField from "@/components/common/Fields/GenericField"
import { getDynamiqueFields } from "@/util/Helpers/DynamiqueFields"

export default {
  name: "etape_2_gaz",
  props: ["site"],
  components: {
    GenericField,
  },
  data() {
    return {
      pce: null,
      optionTarifaire: null,
      car: null,
      profil: null,
      cja: null,
      bioGaz: null,
      commentaire: null,
    }
  },
  computed: {
    ...mapState(["operation"]),
    OptionTarifaireEnum() {
      return OptionTarifaireEnum
    },
    ProfilGazEnum() {
      return ProfilGazEnum
    },
    hasCja() {
      return this.optionTarifaire === "T4"
    },
    bioGazField: {
      get() {
        return getDynamiqueFields(
          this.operation,
          "name::sites",
          "name::Données techniques (gaz)",
          "type::bioGaz",
          this.bioGaz
        )
      },
      set(value) {
        this.bioGaz = value
      },
    },
  },
  methods: {
    initialize() {
      this.pce = this.site.pce
      this.optionTarifaire = this.site.optionTarifaire
      this.car = this.site.car
      this.profil = this.site.profil
      this.cja = this.site.cja
      this.bioGaz = this.site.bioGaz
      this.commentaire = this.site.commentaire
    },
    submit() {
      if (this.validate()) {
        Axios("patch", "api/sites/" + this.site.id, {
          ongletParametre: true,
          pce: this.pce,
          optionTarifaire: this.optionTarifaire,
          car: this.car,
          profil: this.profil,
          cja: this.hasCja ? this.cja : null,
          bioGaz: this.bioGaz,
          commentaire: this.commentaire,
        })
          .then((response) => {
            this.$emit("save", response.data.id)
            Materialize.toast("Les paramètres ont bien été sauvegardées", 4000, "lime")
          })
          .catch((error) => {
            console.error(error)
            Materialize.toast("Erreur serveur", 4000, "deep-orange")
          })
      } else {
        Materialize.toast("Formulaire invalide", 4000, "deep-orange")
      }
    },
    validate() {
      const errors = []
      const derogables = []
      let valid = true

      for (const rv in RV_GAZ) {
        const result = RV_GAZ[rv](this.optionTarifaire, this.profil)
        if (result.state === RV_RESULT.KO) {
          errors.push(result.message)
          valid = false
        } else if (result.state === RV_RESULT.DEROGABLE) {
          derogables.push(result.message)
        }
      }

      if (valid && derogables.length) {
        let message = "Les paramètres de votre site ne respectent pas les règles suivantes :\n"
        for (const derogable of derogables) {
          message += "  - " + derogable + "\n"
        }
        message += "Voulez-vous vraiment déroger à ces règles?"

        if (!confirm(message)) {
          return false
        }
      } else if (!valid && errors.length) {
        return false
      }
      return true
    },
  },
  watch: {
    site() {
      this.initialize()
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style lang="scss" scoped></style>
