var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mcma-button",class:{ 'has-value': _vm.getValue }},[(_vm.hasSlot())?_c('a-dropdown',{attrs:{"trigger":_vm.onClick ? ['click'] : ['hover']}},[_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[_vm._t("default")],2),_c('a-button',{staticClass:"mcma-button-content",class:'button-bb button-bb-' + _vm.color,attrs:{"type":_vm.color,"size":_vm.size,"shape":_vm.shape,"loading":_vm.loading,"disabled":_vm.disabled},on:{"click":function($event){$event.preventDefault();return _vm.action.apply(null, arguments)}}},[(_vm.leftIcon && !_vm.isFaIcon && !_vm.loading)?_c('a-icon',{staticClass:"leftIcon",attrs:{"type":_vm.leftIcon}}):(_vm.leftIcon && _vm.isFaIcon && !_vm.loading)?_c('font-awesome-icon',{class:_vm.getFaIconColor,attrs:{"icon":[_vm.regularIcon ? 'far' : 'fas', _vm.leftIcon]}}):(_vm.leftImage && !_vm.loading)?_c('img',{staticClass:"leftImage",attrs:{"src":_vm.getLeftImage,"alt":_vm.leftImage}}):_vm._e(),(_vm.leftImage)?_c('div',{staticClass:"triangle-separator"}):_vm._e(),_c('span',{staticClass:"name",class:{ uppercase: _vm.uppercase }},[_vm._v(" "+_vm._s(_vm.name)+" ")]),(_vm.isDropdown)?_c('a-icon',{staticClass:"rightIcon",attrs:{"type":"down"}}):(_vm.rightIcon && !_vm.isFaIcon)?_c('a-icon',{staticClass:"rightIcon",attrs:{"type":_vm.rightIcon}}):(_vm.rightIcon && _vm.isFaIcon)?_c('font-awesome-icon',{class:_vm.getFaIconColor,attrs:{"icon":[_vm.regularIcon ? 'far' : 'fas', _vm.rightIcon]}}):(_vm.rightImage)?_c('img',{staticClass:"rightImage",attrs:{"src":_vm.getRightImage,"alt":_vm.rightImage}}):_vm._e()],1)],1):_c('a-button',{staticClass:"mcma-button-content simple-button",class:[
      {
        'without-icon': !_vm.leftIcon && !_vm.leftImage && !_vm.rightIcon && !_vm.rightImage,
        'has-leftImage': _vm.leftImage,
        'leftIcon-with-name': _vm.leftIcon && _vm.name,
        'has-faIcon-alone': _vm.leftIcon && _vm.isFaIcon && !_vm.name,
        'button-bb': _vm.bbButton && !_vm.getValue,
        fullwidth: _vm.fullwidth,
      },
      `button-bb-${_vm.color}`,
    ],attrs:{"type":_vm.color,"size":_vm.size,"shape":_vm.shape,"loading":_vm.loading,"disabled":_vm.disabled},on:{"click":function($event){$event.preventDefault();return _vm.action.apply(null, arguments)}}},[(_vm.leftIcon && !_vm.isFaIcon && !_vm.loading)?_c('a-icon',{staticClass:"leftIcon",attrs:{"type":_vm.leftIcon}}):(_vm.leftIcon && _vm.isFaIcon && !_vm.loading)?_c('font-awesome-icon',{class:_vm.getFaIconColor,attrs:{"icon":[_vm.regularIcon ? 'far' : 'fas', _vm.leftIcon]}}):(_vm.leftImage)?_c('img',{staticClass:"leftImage",attrs:{"src":_vm.getLeftImage,"alt":_vm.leftImage}}):_vm._e(),_c('span',{staticClass:"name",class:{ uppercase: _vm.uppercase }},[_vm._v(" "+_vm._s(_vm.name)+" ")]),(_vm.rightIcon && !_vm.isFaIcon)?_c('a-icon',{staticClass:"rightIcon",attrs:{"type":_vm.rightIcon}}):(_vm.rightIcon && _vm.isFaIcon)?_c('font-awesome-icon',{class:_vm.getFaIconColor,attrs:{"icon":[_vm.regularIcon ? 'far' : 'fas', _vm.rightIcon]}}):(_vm.rightImage)?_c('img',{staticClass:"rightImage",attrs:{"src":_vm.getRightImage,"alt":_vm.rightImage}}):_vm._e()],1),(_vm.getValue)?_c('div',{ref:"valueBlock",staticClass:"value-block",class:_vm.color + '-color'},[_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.getValue))])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }