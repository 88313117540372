<template>
  <mcma-card title="Gestion des pièces" icon="download" class="mcma-card-header" :inline="true">
    <template #describe> Gérez ici toutes les pièces déposées par les membres. </template>
    <template #headerRightSide>
      <mcma-button
        name="Ajouter une piéce"
        color="primary"
        leftIcon="plus"
        :isFaIcon="true"
        @click="goToCreateDocumentForm()"
      />
      <a class="ninja" ref="downloadLink" />
    </template>
    <div class="align-center mt20">
      <mcma-button
        name="Télécharger toutes les pièces déposées par les membres"
        color="success"
        leftIcon="download"
        :isFaIcon="true"
        @click="alldownload()"
      />
    </div>
    <slot></slot>
  </mcma-card>
</template>

<script>
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaCard from "@/components/mcma/common/McmaCard"
import Axios from "@/util/Axios"
import { mapState } from "vuex"

export default {
  name: "DocumentsManagerHeader",
  components: {
    McmaCard,
    McmaButton,
  },
  computed: {
    ...mapState(["operation"]),
  },
  methods: {
    goToCreateDocumentForm() {
      this.$router.push({
        name: "documents-detail",
        params: {
          action: "new",
        },
      })
    },
    alldownload() {
      Axios("get", `file/pieceMembre/operation/allDownload?operationId=${this.operation.id}`, null, {
        responseType: "blob",
      })
        .then((response) => {
          const link = this.$refs.downloadLink
          link.href = window.URL.createObjectURL(new Blob([response.data]))
          link.setAttribute("download", "touteslespieces.zip")
          link.click()
        })
        .catch((error) => {
          console.error(error)
          this.$notification.error({
            message: "Erreur lors du téléchargement",
            description:
              "Une erreur est survenue lors du telechargement de la piece, veuillez contacter un administrateur.",
          })
        })
    },
  },
}
</script>

<style lang="scss"></style>
