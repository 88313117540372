<template>
  <form class="card" @submit.prevent="submit">
    <section class="card-content">
      <span class="card-title">Paramètres du site</span>
      <article class="row valign-wrapper">
        <generic-field
          v-model="fieldENR"
          getFlow="name::sites"
          getPanel="name::Données techniques (électricité)"
          getField="type::enr"
          class="col s6"
        />

        <generic-field
          v-model="eclairagePublique"
          getFlow="name::sites"
          getPanel="name::Données techniques (électricité)"
          getField="type::eclairagePublique"
        />
      </article>
      <span class="card-title">Segment, FTA & Puissances :</span>
      <blockquote v-if="segment">
        En préparation de l'opération d'optimisation de vos puissances souscrites qui sera menée par nos services, cette
        interface vous permet de bien valider les paramètres initiaux de votre contrat et de les corriger en cas
        d'inadéquation avec les caractéristiques techniques.
        <br />Toutefois, hors correction des caractéristiques techniques, toute évolution contractuelle entraînera une
        prestation complémentaire en référence aux termes du marché conclus.
      </blockquote>
      <blockquote v-else>Il faut tout d'abord selectionner un segment</blockquote>
      <article class="row">
        <div class="col s12">
          <fieldset class="col s6">
            <legend>
              <strong>Carac. tech. actuelles</strong>
            </legend>

            <div class="row">
              <label class="col s6">Segment Initial</label>
              <span class="col s6">{{ site.segmentInitial }}</span>
            </div>

            <div class="row">
              <label class="col s6">FTA Initial</label>
              <span class="col s6">{{ site.ftaInitial }}</span>
            </div>

            <div class="row">
              <div v-for="puissance in puissancesInitial" :key="puissance">
                <label class="col s6">{{ puissance }}</label>
                <span class="col s6">
                  {{ psInitial[puissance] }}
                  <span v-if="site.segmentInitial === 'C2' || site.segmentInitial === 'C3'">(kW)</span>
                  <span v-else>(kVA)</span>
                </span>
              </div>
            </div>
          </fieldset>
          <fieldset class="col s6 right">
            <legend>
              <strong>Carac. tech. qui seront mises en place</strong>
            </legend>

            <!--            <div class="row">-->
            <!--              <div class="col s12">-->

            <!--                <label for="segment">-->
            <!--                  <font-awesome-icon icon="asterisk" class="red-text"/>-->
            <!--                  Segment-->
            <!--                </label>-->
            <!--                <select-->
            <!--                  id="segment"-->
            <!--                  class="browser-default"-->
            <!--                  v-model="segment"-->
            <!--                  required-->
            <!--                  :disabled="-->
            <!--                    !site.added &&-->
            <!--                      segment !== 'C2' &&-->
            <!--                      segment !== 'C3' &&-->
            <!--                      segment !== 'C0'-->
            <!--                  "-->
            <!--                >-->
            <!--                  <option :value="null" disabled selected-->
            <!--                    >Sélectionnez un segment</option-->
            <!--                  >-->
            <!--                  <option-->
            <!--                    v-for="(seg, index) in Segments"-->
            <!--                    :key="index"-->
            <!--                    :value="seg"-->
            <!--                    :disabled="-->
            <!--                      !site.added &&-->
            <!--                        index !== 'C2' &&-->
            <!--                        index !== 'C3' &&-->
            <!--                        (segment === 'C2' || segment === 'C3')-->
            <!--                    "-->
            <!--                  >-->
            <!--                    {{ index }}-->
            <!--                  </option>-->
            <!--                </select>-->
            <!--              </div>-->
            <!--            </div>-->

            <generic-field
              v-model="segment"
              :class="{ mb12: !segment }"
              @change="onUpdateSegment"
              defaultLabel="Segment"
              defaultDisabledValue="Sélectionnez un segment"
              getFlow="name::sites"
              getPanel="name::Données techniques (électricité)"
              getField="type::segment"
              :forceRequired="true"
              :disabledOptions="!site.added && (segment === 'C2' || segment === 'C3')"
              :disabled="!site.added && segment !== 'C2' && segment !== 'C3' && segment !== 'C0'"
            />

            <div class="row" v-if="segment && ftas.length > 0">
              <div class="col s12">
                <label> <font-awesome-icon icon="asterisk" class="red-text" />Formule Tarifaire d'Acheminement </label>
                <select class="browser-default" v-model="fta" required>
                  <option :value="null" disabled selected>
                    Sélectionnez une FTA pour le segment
                    {{ segment ? segment.toUpperCase() : segment }}
                  </option>
                  <option
                    v-for="(ftaItem, index) in ftas"
                    :key="index"
                    :value="ftaItem"
                    v-if="!(eclairagePublique && ftaItem.name === 'BTINF_MU_HP_HC')"
                  >
                    {{ ftaItem.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="error-content" v-if="segment && ftas.length === 0">
              <p class="error">
                Aucune FTA n'est configuré pour le segment
                {{ segment ? segment.toUpperCase() : segment }}
              </p>
            </div>

            <div class="row" v-if="segment && fta && fta.puissances.length > 0">
              <div class="col s12">
                <ul class="red-text" v-if="invalidPs">
                  Des règles ne sont pas respectés :
                  <li v-for="error in invalidPs" :key="error">{{ error }}</li>
                </ul>
              </div>
              <div class="col s4" v-for="puissance in fta.puissances" :key="puissance">
                <div class="input-field">
                  <input
                    :id="puissance"
                    type="number"
                    class="validate"
                    :value="ps[puissance]"
                    @change="inputPs(puissance, $event.target.value)"
                    step="0.1"
                    required
                  />
                  <label :for="puissance" class="active">
                    <font-awesome-icon icon="asterisk" class="red-text" />
                    {{ puissance }}
                    <span v-if="segment === 'C2' || segment === 'C3'">(kW)</span>
                    <span v-else>(kVA)</span>
                  </label>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </article>
      <div class="row">
        <article class="input-field col s12">
          <textarea id="commentaire" class="materialize-textarea" v-model="commentaire"></textarea>
          <label for="commentaire" :class="{ active: commentaire }">Commentaires</label>
        </article>
      </div>
    </section>
    <center class="card-action">
      <button type="submit" class="waves-effect waves-light btn light-green">
        <i class="material-icons">save</i>
        Valider
      </button>

      <button type="button" class="waves-effect waves-light btn blue darken-2" @click="initialize">
        <i class="material-icons">remove</i>
        Réinitialiser
      </button>
    </center>
  </form>
</template>

<script>
import GenericField from "@/components/common/Fields/GenericField"
import Axios from "@/util/Axios"
import { getDynamiqueFields } from "@/util/Helpers/DynamiqueFields"
import Materialize from "materialize-css"
import { mapState } from "vuex"
import { Ftas, Segments } from "./Puissances"
// import _ from "lodash";
import { RA_RESULT, RA_TURPE, RV_RESULT, RV_TURPE } from "./RG_TURPE"

export default {
  name: "etape_2_elec",
  props: ["site"],
  components: {
    GenericField,
  },
  data() {
    return {
      segment: null,
      ftas: [],
      fta: {
        name: null,
        puissances: [],
      },
      ps: null,

      enr: 0,
      eclairagePublique: false,

      invalidPs: false,
      commentaire: null,
      isEnr: false,
    }
  },
  computed: {
    ...mapState(["operation"]),
    fieldENR: {
      get() {
        return this.enr || 0
      },
      set(value) {
        this.enr = value
      },
    },
    Segments() {
      return Segments
    },
    Ftas() {
      return Ftas
    },
    puissancesInitial() {
      if (this.site.ftaInitial) {
        return Ftas[this.site.ftaInitial].puissances
      } else {
        return []
      }
    },
    psInitial() {
      if (this.site.psInitial) {
        return JSON.parse(this.site.psInitial)
      } else {
        return []
      }
    },
    // displayENR() {
    //   if (this.operation && this.operation.flows) {
    //     const flow = _.find(this.operation.flows, ["name", "sites"]);
    //     if (flow && flow.panels) {
    //       const panel = _.find(flow.panels, [
    //         "name",
    //         "Données techniques (électricité)"
    //       ]);
    //       if (panel && panel.fields) {
    //         console.log("enr : %O", _.find(panel.fields, ["type", "enr"]));
    //         return _.find(panel.fields, ["type", "enr"]).displayed;
    //       }
    //     }
    //   }
    //   return true;
    // },
    // segmentField: {
    //   get() {
    //     if (this.operation && this.operation.flows) {
    //       const flow = _.find(this.operation.flows, ["name", "sites"]);
    //       if (flow && flow.panels) {
    //         const panel = _.find(flow.panels, [
    //           "name",
    //           "Données techniques (électricité)"
    //         ]);
    //         if (panel && panel.fields) {
    //           return _.find(panel.fields, ["type", "fta"]);
    //         }
    //       }
    //     }
    //     return null;
    //   },
    //   set(value) {
    //     this.fta = Ftas[value];
    //   }
    // },
    // ftaField: {
    //   get() {
    //     const dynFta = getDynamiqueFields(
    //       this.operation,
    //       "name::sites",
    //       "name::Données techniques (électricité)",
    //       "type::fta",
    //       this.fta
    //     );
    //     console.log(
    //       `%c dynFta`,
    //       'background:pink ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #000000',
    //       dynFta
    //     );
    //     return Segments[this.segment.toUpperCase()].fta[0];
    //     // if (this.operation && this.operation.flows) {
    //     //   const flow = _.find(this.operation.flows, ["name", "sites"]);
    //     //   if (flow && flow.panels) {
    //     //     const panel = _.find(flow.panels, [
    //     //       "name",
    //     //       "Données techniques (électricité)"
    //     //     ]);
    //     //     if (panel && panel.fields) {
    //     //       return _.find(panel.fields, ["type", "segment"]);
    //     //     }
    //     //   }
    //     // }
    //     // return null;
    //   },
    //   set(value) {
    //     console.log(
    //       `%c segment lib value`,
    //       'background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF',
    //       value
    //     );
    //     this.segment = Segments[value.toUpperCase()];
    //     this.fta = this.segment.fta[0]
    //   }
    // }
  },
  mounted() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.enr = this.site.enr
      this.eclairagePublique = this.site.eclairagePublique
      if (this.site.ps) {
        this.ps = JSON.parse(this.site.ps)
      } else {
        this.ps = {}
      }
      if (this.site.segment) {
        this.segment = this.site.segment
        this.initFtas(this.segment)
      } else {
        this.segment = null
      }
      if (this.site.fta && this.segment) {
        this.fta = Ftas[this.site.fta]
      }
      if (this.site.fta === "BTSUP_LU" && !this.ps["PTE/PM"]) {
        this.ps["PTE/PM"] = this.ps["HPH"]
      }
      this.commentaire = this.site.commentaire
    },
    submit() {
      let valid = true

      for (var key in this.ps) {
        if (!this.fta.puissances.includes(key)) {
          delete this.ps[key]
        }
      }

      valid = this.validatePs() ? valid : false

      if (valid) {
        Axios("patch", "api/sites/" + this.site.id, {
          ongletParametre: true,
          enr: this.enr,
          exonerationCspe: this.exonerationCspe,
          typeTcfe: this.typeTcfe,
          exonerationCee: this.exonerationCee,
          eclairagePublique: this.eclairagePublique,
          segment: this.segment,
          fta: this.fta.name,
          ps: JSON.stringify(this.ps),
          commentaire: this.commentaire,
        })
          .then((response) => {
            this.$emit("save", response.data.id)
            Materialize.toast("Les paramètres ont bien été sauvegardées", 4000, "lime")
          })
          .catch((error) => {
            console.error(error)
            Materialize.toast("Erreur serveur", 4000, "deep-orange")
          })
      } else {
        Materialize.toast("Formulaire invalide", 4000, "deep-orange")
      }
    },
    validatePs() {
      this.invalidPs = false

      const errors = []
      const derogables = []
      let valid = true

      for (const rv in RV_TURPE) {
        const result = RV_TURPE[rv](this.eclairagePublique, this.segment, this.fta, this.ps)
        if (result.state === RV_RESULT.KO) {
          errors.push(result.message)
          valid = false
        } else if (result.state === RV_RESULT.DEROGABLE) {
          derogables.push(result.message)
        }
      }

      if (valid && derogables.length) {
        let message = "Les puissances indiquées pour votre site ne respectent pas les règles suivantes :\n"
        for (const derogable of derogables) {
          message += "  - " + derogable + "\n"
        }
        message += "Voulez-vous vraiment déroger à ces règles?"

        if (!confirm(message)) {
          return false
        }
      } else if (!valid && errors.length) {
        this.invalidPs = errors
        return false
      }
      return true
    },
    inputPs(item, value) {
      let adjust = value
      for (const ra in RA_TURPE) {
        const result = RA_TURPE[ra](this.eclairagePublique, this.segment, this.fta, adjust)
        if (result.state === RA_RESULT.ADJUSTED) {
          adjust = result.value
        }
      }
      this.ps[item] = adjust
    },
    initFtas(segment) {
      const segmentObject = Segments[segment.toUpperCase()] || {}
      const ftasBySegment = segmentObject.fta || []
      const dynFta = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::fta",
        this.fta
      )
      const lockedValues = dynFta.lockedValues || []
      this.ftas = ftasBySegment.filter(function (item) {
        return lockedValues.includes(item.name)
      })
    },
    onUpdateSegment(value) {
      this.initFtas(value)
      this.fta = {
        name: null,
        puissances: [],
      }
    },
  },
  watch: {
    site() {
      this.initialize()
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style lang="scss" scoped>
fieldset {
  margin: 0;
  legend {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.row .col.s6 {
  width: 49%;
}

.error-content {
  display: flex;
  width: auto;
  height: 50px;
  background: #f15921;
  margin: 10px 0;
  border-radius: 4px;
  .error {
    margin: auto;
    color: white;
    font-weight: 900;
  }
}
</style>
