<template>
  <a-modal
    v-model="visible"
    v-if="operation"
    :maskClosable="false"
    class="mcma-admin-setting-modal"
    :title="`Paramétre général de l'opération`"
  >
    <div class="container-list">
      <div class="sentence">
        <strong>  DEVELOPPEMENT EN COURS <br /> </strong>
      </div>
      <div>
        <div class="items">
          <div class="item" v-for="(item, index) in items" :key="index">
            <div class="label">
              {{ item.label }}
            </div>
            <v-swatches
              v-model="colors[item.model]"
              :swatches="swatches"
              row-length="4"
              show-fallback
              fallback-input-type="color"
              popover-x="left"
            />
          </div>
        </div>
      </div>
      <div class="inline-buttons">
        <mcma-button name="Annuler" color="orange" class="mt24" @click="handleCancel" />
        <mcma-button name="Valider les informations" color="primary" class="mt24" @click="handleOk" />
      </div>
    </div>
  </a-modal>
</template>

<script>
/* <mcma-admin-setting-modal :visible=""
                                  :operation=""
                                  @cancel=""
                                  @save="" />
  */

import variables from "@/assets/scss/_variables.scss"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaField from "@/components/mcma/common/McmaField"
import VSwatches from "vue-swatches"

export default {
  name: "McmaAdminSettingModal",
  props: {
    visible: Boolean,
    operation: Object,
  },
  components: {
    McmaButton,
    McmaField,
    VSwatches,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      wasSubmit: false,
      items: [
        {
          label: "Couleur Principal",
          model: "--primary-color",
        },
        {
          label: "Couleur Secondaire",
          model: "--secondary-color",
        },
        {
          label: "Couleur Tertiaire",
          model: "--tertiary-color",
        },
        // {
        //   label: "Couleur de Succés",
        //   model: "--success-color"
        // },
        // {
        //   label: "Couleur de Warning",
        //   model: "--warning-color"
        // },
        // {
        //   label: "Couleur d'Erreur",
        //   model: "--error-color"
        // },
        {
          label: "Couleur d'En tête / Titre de page",
          model: "--heading-color",
        },
        {
          label: "Couleur du text général",
          model: "--text-color",
        },
        {
          label: "Couleur du text secondaire",
          model: "--text-color-secondary",
        },
      ],
      colors: {
        "--primary-color": variables.primaryColor,
        "--secondary-color": variables.secondaryColor,
        "--tertiary-color": variables.tertiaryColor,
        "--heading-color": variables.headingColor,
        "--text-color": variables.textColor,
        "--text-color-secondary": variables.textColorSecondary,
      },
      swatches: [
        variables.primaryColor,
        variables.secondaryColor,
        variables.tertiaryColor,
        // variables.successColor,
        // variables.warningColor,
        // variables.errorColor,
        variables.headingColor,
        variables.textColor,
        variables.textColorSecondary,
      ],
    }
  },
  watch: {
    visible(value) {
      if (value) {
        // this.loadColorFromOperation();
      }
    },
    colors: {
      deep: true,
      handler(value) {
        this.updateAllColor(value)
      },
    },
  },
  methods: {
    handleCancel() {
      this.updateAllColor({
        "--primary-color": variables.primaryColor,
        "--secondary-color": variables.secondaryColor,
        "--tertiary-color": variables.tertiaryColor,
        "--heading-color": variables.headingColor,
        "--text-color": variables.textColor,
        "--text-color-secondary": variables.textColorSecondary,
      })
      this.$emit("cancel", this.operation)
    },
    handleOk() {
      // alert(JSON.stringify(
      //   this.colors,
      //   null,
      //   4
      // ));
      this.$emit("cancel", this.operation)
      // this.$emit("save", this.operation);
    },
    loadColorFromOperation() {
      this.colors = this.operation && this.operation.colors ? this.operation.colors : this.colors
    },
    updateAllColor(colors) {
      const root = document.documentElement
      Object.keys(colors).forEach(async (property) => {
        root.style.setProperty(`${property}`, colors[property])
        root.style.setProperty(`${property}-h`, await this.$hexToHSL(colors[property], "hue"))
        root.style.setProperty(`${property}-s`, await this.$hexToHSL(colors[property], "saturation"))
        root.style.setProperty(`${property}-l`, await this.$hexToHSL(colors[property], "lightness"))
      })
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-admin-setting-modal {
  font-family: $font-family !important;
  width: 800px !important;
  .ant-modal-content {
    .ant-modal-footer {
      display: none;
    }
  }
  .container-list {
    display: flex;
    flex-flow: column nowrap;
    .items {
      display: flex;
      flex-flow: column nowrap;
      .item {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        margin: 5px 0;
        .label {
          font-weight: 900;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
