<template>
  <a-modal
    v-model="visible"
    v-if="site && site.fta"
    :maskClosable="false"
    class="mcma-ps-modal"
    :title="`Renseigner les puissances`"
  >
    <div class="container-list">
      <div class="sentence">
        <strong>
          Merci de renseigner les puissances pour le site
          <span class="primary-color">{{ site.nom }}</span> avec comme segment
          <span class="primary-color">{{ $getValueByKey(site.segment) }}</span>
          portant le RAE suivant :
          <span class="primary-color">{{ site.rae }}</span>
        </strong>
      </div>
      <a-form :form="form">
        <a-row type="flex" :gutter="12" class="mtb4" v-if="site.segment && puissances && puissances.length > 0">
          <div class="puissances-block">
            <label class="mcma-label"> Puissance(s) </label>
            <div>
              <mcma-field
                v-for="puissance in puissances"
                :key="puissance"
                :colSpan="12"
                type="input"
                :form="form"
                :wasSubmit="wasSubmit"
                @change="inputPs($event, puissance)"
                :fieldName="`ps-${puissance}`"
                :defaultValue="defaultValuePS(puissance)"
                :label="`${puissance} ${
                  form.getFieldValue('segment') === 'C_2' || form.getFieldValue('segment') === 'C_3' ? '(kW)' : '(kVA)'
                }`"
                :required="true"
                numberOnly="decimal"
              />
            </div>
          </div>
        </a-row>
      </a-form>

      <div class="inline-buttons">
        <!--        <mcma-button name="Annuler"-->
        <!--                     color="orange"-->
        <!--                     class="mt24"-->
        <!--                     @click="handleCancel(site)" />-->
        <mcma-button name="Valider les informations" color="primary" class="mt24" @click="handleOk" />
      </div>
    </div>
  </a-modal>
</template>

<script>
/* <mcma-ps-modal :visible=""
                    :site=""
                    @cancel=""
                    @submit="" />
  */

import Axios from "@/util/Axios"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaField from "@/components/mcma/common/McmaField"
import { Ftas } from "@/components/mcma/steps/sites/Etapes/Etape_2_Elec/Puissances"
import { RA_RESULT, RA_TURPE, RV_RESULT, RV_TURPE } from "@/components/mcma/steps/sites/Etapes/Etape_2_Elec/RG_TURPE"

export default {
  name: "McmaPsModal",
  props: {
    visible: Boolean,
    site: Object,
  },
  components: {
    McmaButton,
    McmaField,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      wasSubmit: false,
      ftas: [],
      puissances: [],
      invalidPs: false,
    }
  },
  computed: {
    psInitial() {
      return this.site.psInitial ? JSON.parse(this.site.psInitial) : {}
    },
    defaultValuePS() {
      return (puissance) => {
        if (puissance === "PTE/PM") {
          return this.site.fta === "BTSUP_LU" && !this.psInitial["PTE/PM"]
            ? this.psInitial["HPH"]
            : this.psInitial["PTE/PM"]
        }
        const sitePs = this.site.ps ? JSON.parse(this.site.ps) : {}
        return sitePs[puissance] || this.psInitial[puissance] || "0"
      }
    },
  },
  watch: {
    visible(value) {
      if (value) {
        this.initPuissances()
      }
    },
  },
  methods: {
    initPuissances() {
      this.puissances = Ftas[this.site.fta].puissances
    },
    // handleCancel(site){
    //   this.$emit("cancel", site);
    // },
    handleOk() {
      let valid = true
      this.form.validateFields(async (errors, fieldsValue) => {
        if (errors) {
          this.$notification.warning({
            message: "Puissances invalides",
            description: `Certaines puissances ne sont pas renseigné ou non-valide.`,
          })
        } else {
          const currentFta = Ftas[fieldsValue.fta] || {}
          fieldsValue.ps = this.getAllPsAvailable(fieldsValue, true)
          if (currentFta.puissances && currentFta.puissances.length > 0) {
            for (let key in fieldsValue.ps) {
              if (!currentFta.puissances.includes(key)) {
                delete fieldsValue.ps[key]
              }
            }
          }

          this.validatePs(fieldsValue).then((isValid) => {
            valid = isValid ? valid : false
            if (valid) {
              Axios("patch", "api/sites/" + this.site.id, {
                ps: JSON.stringify(fieldsValue.ps),
              })
                .then((response) => {
                  this.$emit("submit", response.data)
                  this.$notification.success({
                    message: "Puissances sauvegardées !",
                    description: `Les puissances ont bien été sauvegardées`,
                  })
                })
                .catch((error) => {
                  console.error(error)
                  this.$notification.error({
                    message: "Erreur serveur",
                    description: "Une erreur server est survenue, veuillez contacter un administrateur.",
                  })
                })
            } else {
              this.$notification.warning({
                message: "Puissances invalides",
                description: `Certaines puissances ne sont pas renseigné ou non-valide.`,
              })
            }
          })
        }
      })
    },
    validatePs(fieldsValue) {
      return new Promise((resolve) => {
        this.invalidPs = false

        const errors = []
        const derogables = []
        let valid = true

        for (const rv in RV_TURPE) {
          const result = RV_TURPE[rv](
            this.site.typeSite === "ECLAIRAGE_PUBLIC",
            this.site.segment,
            this.site.fta,
            fieldsValue.ps
          )
          if (result.state === RV_RESULT.KO) {
            errors.push(result.message)
            valid = false
          } else if (result.state === RV_RESULT.DEROGABLE) {
            derogables.push(result.message)
          }
        }

        if (valid && derogables.length) {
          let message = "Les puissances indiquées pour votre site ne respectent pas les règles suivantes :\n"
          for (const derogable of derogables) {
            message += "\n  - " + derogable + "\n"
          }
          message += "\nVoulez-vous vraiment déroger à ces règles?"

          return this.$confirm({
            title: `Dérogation nécessaire`,
            content: message,
            okText: "Oui",
            okType: "success",
            cancelText: "Non",
            onOk() {
              return resolve(true)
            },
            onCancel() {
              return resolve(false)
            },
          })
        } else if (!valid && errors.length) {
          this.invalidPs = errors
          return resolve(false)
        }
        return resolve(true)
      })
    },
    inputPs(value, item) {
      let adjust = value
      for (const ra in RA_TURPE) {
        const result = RA_TURPE[ra](this.site.typeSite === "ECLAIRAGE_PUBLIC", this.site.segment, this.site.fta, adjust)
        if (result.state === RA_RESULT.ADJUSTED) {
          adjust = result.value
        }
      }
      if (adjust !== value) {
        this.form.setFieldsValue({
          [item]: adjust,
        })
      }
    },
    getAllPsAvailable(fieldsValues, getHasObject) {
      if (!fieldsValues) {
        fieldsValues = this.form.getFieldsValue()
      }
      const fieldsValuesKeys = Object.keys(fieldsValues) || []
      const psKeys = fieldsValuesKeys.filter((item) => item.startsWith("ps-"))
      if (getHasObject) {
        const psObject = {}
        psKeys.forEach((key) => {
          const keyParse = key.replace("ps-", "")
          psObject[keyParse] = fieldsValues[key]
        })
        return psObject
      }
      return psKeys
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-ps-modal {
  font-family: $font-family !important;
  .ant-modal-content {
    .ant-modal-footer {
      display: none;
    }
  }
  .puissances-block {
    margin-top: 20px;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }
}
</style>
