<template>
  <section class="superAdminContent" :class="navbarDeployed ? 'smallContent' : ''">
    <side-nav @deploy="(value) => (navbarDeployed = value)" />

    <router-view />
  </section>
</template>

<script>
import Axios from "@/util/Axios"
import SideNav from "./SideNav"
import { mapMutations } from "vuex"

export default {
  name: "super-admin",
  components: {
    SideNav,
  },
  data() {
    return {
      navbarDeployed: false,
      currentOperation: null,
    }
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd", "setOperationEditing", "setOperation", "removeLastbreadcrumb"]),
    getOperationbyId(operationId) {
      if (!operationId) {
        return Promise.reject(new Error("no operation id"))
      }
      return Axios("get", "api/operations/" + operationId)
        .then((response) => {
          let operation = response.data
          return Axios("get", "api/operations/" + operationId + "/mails")
            .then((response) => {
              operation.mails = this.$cloneDeep(response.data._embedded.mails)
              return operation
            })
            .catch((error) => {
              this.$notification.error({
                message: `Erreur récuperation operation mail`,
                description: `Une erreur est survenue lors de la recupération de la configuration d'email de l'operation.`,
              })
              return error
            })
        })
        .catch((error) => {
          this.$notification.error({
            message: `Erreur récuperation de l'operation`,
            description: `Une erreur est survenue lors de la recupération de l'operation.`,
          })
          return error
        })
    },
    goTo(operation) {
      if (operation && operation.id) {
        this.getOperationbyId(operation.id).then((ope) => {
          this.removeLastbreadcrumb()
          this.setOperation(ope)
          this.setOperationEditing(false)
          this.$router.push({
            name: "operations-detail",
            params: {
              operationId: ope.id,
            },
          })
        })
      } else {
        this.$notification.warning({
          message: "L'operation n'existe pas",
          description: `Vous devez créer une operation avant de pouvoir vous y rendre`,
        })
      }
    },
    edit(operation) {
      if (operation && operation.id) {
        this.setOperationEditing(true)
        this.$router.push({
          name: "superadmin-operation-editing",
          params: {
            operationId: operation.id,
          },
        })
      } else {
        this.$notification.warning({
          message: "l'operation n'existe pas",
          description: `Vous ne pouvez editer une opération qui n'existe pas.`,
        })
      }
    },
    addAdmin(operation) {
      if (operation && operation.id) {
        this.$router.push({
          name: "superadmin-add-admin",
        })
      } else {
        this.$notification.warning({
          message: "l'operation n'existe pas",
          description: `Vous ne pouvez ajouter un administrateur sur une opération qui n'existe pas.`,
        })
      }
    },
    deleteOperation(operation) {
      const that = this
      that.$confirm({
        title: `Supprimer l'operation`,
        content: `Voulez-vous vraiment supprimer l'opération ${operation.name} ?
                  \nLa suppression sera irréversible.`,
        okText: "Oui",
        okType: "error",
        cancelText: "Non",
        onOk() {
          console.log(
            `%c [FAKE] Operation supprimé : `,
            "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
            `${operation.name}`
          )
        },
      })
    },
  },
  created() {
    /*
    console.log('SuperAdmin@created his.$router : %O', this.$router)
    console.log('SuperAdmin@created this.$router.history : %O', this.$router.history)
    console.log('SuperAdmin@created this.$router.history.current : %O', this.$router.history.current)
    console.log('SuperAdmin@created this.$router.history.current.fullPath : %O', this.$router.history.current.fullPath)
    */
    this.setBreadcrumbAdd({
      name: "Administration des Opérations",
      action: () => {
        this.$router.push({
          name: "superadmin-board",
          params: {
            operationId: null,
            membreId: null,
          },
        })
      },
    })
  },
}
</script>

<style lang="scss" scoped>
.superAdminContent {
  padding-left: 70px;
  transition: 0.3s;
  &:not(.smallContent) {
    transition-delay: 0.1s;
  }
  &.smallContent {
    padding-left: 220px;
  }
}
</style>
