import Axios from "axios"
import crypto from "crypto"

function cryptUrl(method, url) {
  const urlProof = encodeURI(url)
  const cipher = crypto.createCipheriv("aes-128-ecb", process.env.VUE_APP_AES_KEY, "")
  const encrypted = (cipher.update(urlProof, "ascii", "hex") + cipher.final("hex")).toUpperCase()
  if (process.env.NODE_ENV === "development") {
    console.info(method + " => " + urlProof + " => " + encrypted)
  }
  return encrypted
}

export default function (method, url, data, config, headers) {
  let baseURL = process.env.VUE_APP_API_URL
  if (window.location.origin.match(window.location.origin.match("qualif.totalenergies-collectedonnees.fr"))) {
    baseURL = "https://back-qualif.totalenergies-collectedonnees.fr"
  } else if (window.location.origin.match(window.location.origin.match("totalenergies-collectedonnees.fr"))) {
    baseURL = "https://back.totalenergies-collectedonnees.fr"
  }
  let urlNoBaseURL = url.replace(baseURL, "")
  return Axios({
    url: cryptUrl(method, urlNoBaseURL),
    method: method,
    data: data,

    baseURL: baseURL,
    headers: {
      user: window.localStorage.getItem("identifiant"),
      token: window.localStorage.getItem("token"),

      ...headers,
    },

    ...config,
  })
}
