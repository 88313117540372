import { updateObjectProperties } from "@/util/Helpers"
import Vue from "vue"
import Vuex from "vuex"
import Axios from "@/util/Axios"
import _ from "lodash"
import tutorials from "@/assets/tutorials"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    debug: false,
    connected: false,
    confOperation: null,
    operation: null,
    operations: null,
    operationEditing: false,
    user: null,
    membre: null,
    membreStats: null,
    users: [],
    membres: null,
    membresStats: [],
    emailsTracker: [],
    breadcrumb: [],
    currentTutorial: null,
    intro: null,
    tutorialIsPlaying: false,
    operationNotSaved: false,
    groupement: null,
    interlocuteur: null,
    userEditing: null,
    siteEditing: null,
    groupementEditing: null,
    appSize: {},
    showToggleMultiMembre: false,
    multiSiteColor: ["#1890ff", "#4ECB74", "#F2637B", "#ff6e47", "#FBD437", "#36CBCB", "#975FE5"],
  },
  mutations: {
    setConnected(state, connected) {
      state.connected = connected
    },
    setState(state, { stateName, value }) {
      console.log("setState@Store : %O, %O", stateName, value)
      state[stateName] = value
    },
    resetState(state, stateName) {
      state[stateName] = null
    },
    resetStateArray(state, stateName) {
      state[stateName] = []
    },
    pushInMasseToState(state, { stateName, values }) {
      state[stateName].push(...values)
      state[stateName] = _.uniqBy(state[stateName], "id")
    },
    updateStateFromList(state, { stateName, stateList }) {
      if (state[stateName]) {
        const find = stateList.find((item) => item.id === state[stateName].id)
        if (find) {
          state[stateName] = find
        }
      }
    },
    updateCurrentMembre(state, dataToUpdateAsObject) {
      console.group("updateCurrentMembre@store")
      console.log("membre before update : %O", state.membre)
      console.log("membre dataToUpdateAsObject : %O", dataToUpdateAsObject)
      updateObjectProperties(state.membre, dataToUpdateAsObject)
      console.log("membre after update : %O", state.membre)
      console.groupEnd()
    },
    setMembre(state, membre) {
      // console.group("setOperation@store");
      state.membre = membre
      // console.log("operation : %O", operation);
      // console.groupEnd();
    },
    setOperation(state, operation) {
      // console.group("setOperation@store");
      state.operation = operation
      // console.log("operation : %O", operation);
      // console.groupEnd();
    },
    setOperationEditing(state, operationEditing) {
      state.operationEditing = operationEditing
    },
    setConfOperation(state, confOperation) {
      state.confOperation = confOperation
    },
    initBreadcrumb(state, breadcrumb) {
      // console.group("setBreadcrumb@Store");
      // console.log("state.operation : %O", state.operation);
      // console.log("breadcrumb : %O", breadcrumb);
      if (breadcrumb) {
        if (breadcrumb === "goBack") {
          state.breadcrumb.pop()
        } else {
          if (state.user.role === "SUPER_ADMIN" || state.user.role === "ADMIN") {
            if (state.operation) {
              state.breadcrumb = [
                {
                  name: "Administration des Opérations",
                  action: (component) => {
                    state.membre = null
                    state.operation = null
                    if (component.$router.currentRoute.name !== "home") {
                      component.$router.push({
                        name: "superadmin-board",
                        params: {
                          operationId: null,
                          membreId: null,
                        },
                      })
                    }
                  },
                },
                {
                  name: state.operation.name,
                  action: (component) => {
                    state.membre = null
                    if (component.$router.currentRoute.name !== "operation") {
                      component.$router.push({
                        name: "operations-detail",
                        params: {
                          operationId: state.operation.id,
                        },
                      })
                    }
                  },
                },
                ...breadcrumb,
              ]
            } else {
              state.breadcrumb = [
                {
                  name: "Administration des Opérations",
                  action: (component) => {
                    state.membre = null
                    state.operation = null
                    if (component.$router.currentRoute.name !== "home") {
                      component.$router.push({
                        name: "superadmin-board",
                        params: {
                          operationId: null,
                          membreId: null,
                        },
                      })
                    }
                  },
                },
                ...breadcrumb,
              ]
            }
          } else if (state.user.role === "COORDINATEUR" || state.user.role === "COORDINATEUR_AVANCE") {
            state.breadcrumb = [
              {
                name: "Coordination",
                action: (component) => {
                  state.membre = null
                  state.operation = null
                  if (component.$router.currentRoute.name !== "home") {
                    component.$router.push({
                      name: "home",
                    })
                  }
                },
              },
              ...breadcrumb,
            ]
          } else {
            state.breadcrumb = breadcrumb
          }
        }
      } else {
        state.breadcrumb = []
      }
      // console.groupEnd();
    },
    removeLastbreadcrumb(state) {
      if (state.breadcrumb.length > 1) {
        state.breadcrumb.pop()
      }
    },
    breadcrumbGoBack(state) {
      if (state.breadcrumb.length > 1) {
        state.breadcrumb.pop()
        const lastBreadCrumb = state.breadcrumb[state.breadcrumb.length - 1]
        lastBreadCrumb.action()
      }
    },
    setBreadcrumbAdd(state, breadcrumb) {
      // console.group("setBreadcrumbAdd@Store");
      // console.log("state.operation : %O", state.operation);
      // console.log("state.breadcrumb : %O", state.breadcrumb);
      // console.log("breadcrumb : %O", breadcrumb);
      if (breadcrumb) {
        // Recherche l'index dans le state.breadcrumb du
        // breadcrumb.name si déjà présent
        let indexfind = -1
        state.breadcrumb.forEach((value, index) => {
          if (value && value.name === breadcrumb.name) {
            // console.log("> trouvé : %O at %O", breadcrumb.name, index)
            indexfind = index
          }
        })
        if (indexfind === -1) {
          state.breadcrumb.push(breadcrumb)
        } else {
          // console.log("breadcrumb slice de %O à  %O", indexfind + 1, state.breadcrumb.length - (indexfind + 1));
          state.breadcrumb.splice(indexfind + 1, state.breadcrumb.length - (indexfind + 1))
        }
      }
      // console.log("state.breadcrumb : %O", state.breadcrumb);
      // console.groupEnd();
    },
    setCurrentTutorial(state, value) {
      state.currentTutorial = value
    },
    setIntro(state, value) {
      state.intro = value
    },
    setTutorialIsPlaying(state, value) {
      state.tutorialIsPlaying = value
    },
    setOperationNotSaved(state, value) {
      // console.group("setOperationNotSaved@store");
      // console.log("> State : %O", state);
      // console.log("> Value : %O", value);
      state.operationNotSaved = value
      // console.groupEnd();
    },
  },
  getters: {
    getFlow(state) {
      // console.group("getFlow@State");
      // console.log("state.operation : %O", state.operation);
      let myflow = []
      if (state.operation && state.operation !== null && state.operation.flows) {
        // console.log("state.operation.flows : %O", state.operation.flows);
        let flows = state.operation.flows.sort((a, b) => {
          // // console.log(a.ordre + " " + b.ordre)
          return parseFloat(a.ordre) - parseFloat(b.ordre)
        })
        // // console.log("state.operation.flows : %O", flows);
        // state.operation.flows.forEach((item, index) => {
        flows.forEach((item, index) => {
          // // console.log("> %O : %O / %O", index, item.active, item.name);
          if (item.active) {
            if (item.name === "documents-download" || item.name === "piecetelechargement") {
              myflow.push("DOWNLOAD")
            }
            if (item.name === "sites") {
              myflow.push("PERIMETRE")
            }
            if (item.name === "groupements") {
              myflow.push("FACTURATION")
            }
            if (item.name === "services") {
              myflow.push("SERVICES")
            }
            if (item.name === "documents") {
              myflow.push("DOCUMENTS")
            }
            if (item.name === "documents-upload" || item.name === "piecedepot") {
              myflow.push("UPLOAD")
            }
          }
        })
      }
      // console.log("myflow : %O", myflow);
      // console.groupEnd();
      return myflow
    },
  },
  actions: {
    disconnect({ state }) {
      state.connected = false
      state.confOperation = null
      state.operation = null
      state.operationEditing = false
      state.user = null
      state.membre = null
      state.membreStats = null
      state.users = []
      state.membres = null
      state.membresStats = []
      state.emailsTracker = []
      state.breadcrumb = []
      state.tutorialIsPlaying = false
      state.operationNotSaved = false
      state.groupement = null
      state.interlocuteur = null
      state.userEditing = null
      state.showToggleMultiMembre = false

      window.localStorage.removeItem("identifiant")
      window.localStorage.removeItem("token")
    },
    requestMembreReconcil({ state, commit }) {
      if (state.operation !== null) {
        console.log("operation", state.operation.id, state.operation)
        return Axios("get", `api/membres/search/findByOperationIdEqualsAndNomEquals?operationId=${state.operation.id}&name=A_RECONCILIER`).then((response) => {
          return response.data._embedded.membres
        })
      }
    },
    requestOperations({ state, commit }) {
      if (state.user.role === "SUPER_ADMIN") {
        return Axios("get", "api/operations/?page=0&size=150").then((response) => {
          console.log("> requestOperations")
          console.log("> response : %O", response)
          commit("setState", {
            stateName: "operations",
            value: response.data._embedded.operations,
          })
          return response.data._embedded.operations
        })
      } else {
        return Axios("get", `api/operations/search/findByOperationsEquals?user=${state.user.id}`).then((response) => {
          commit("setState", {
            stateName: "operations",
            value: response.data._embedded.operations,
          })
          return response.data._embedded.operations
        })
      }
    },
    getUserById({ commit }, userId) {
      return Axios("get", `api/users/${userId}`)
        .then((response) => {
          console.log("response", response)
          return response.data
        })
        .catch((error) => {
          console.error(error.body.error || error.statusText)
          return error
        })
    },
    updateInterlocuteurOfGroupements({ commit, state }, updatedInterlocuteur) {
      if (!state.membre && !state.membre.id) {
        return
      }
      return Axios("get", "api/membres/" + state.membre.id + "/groupements").then(async (response) => {
        const groupements = _.filter(response.data._embedded.groupements, {
          removed: false,
        })
        const groupementWithThisInterlocuteur = groupements.filter((groupement) => {
          return groupement.interlocuteurPayeurId === updatedInterlocuteur.id
        })
        const groupementToUpdate = {
          interlocuteurPayeurId: updatedInterlocuteur.id,
          interlocuteurPayeurNom: updatedInterlocuteur.nom,
          interlocuteurPayeurPrenom: updatedInterlocuteur.prenom,
          telephonePayeur: updatedInterlocuteur.telephone,
          mobilePayeur: updatedInterlocuteur.mobile,
          emailPayeur: updatedInterlocuteur.mail,
        }
        for (let i = 0; i < groupementWithThisInterlocuteur.length; i++) {
          let groupement = groupementWithThisInterlocuteur[i]
          await Axios("patch", "api/groupements/" + groupement.id, groupementToUpdate)
        }
      })
    },
    requestMembreByMembreId({ state, commit, dispatch }, membreId) {
      console.log("requestMembreByMembreId@Store")
      return getMembreByMembreId(membreId).then((responseInit) => {
        console.log("> getMembreByMembreId@requestMembreByMembreId@Store : %O", responseInit.data)
        return dispatch("updateMembre", responseInit.data).then(() => {
          return responseInit.data
        })
      })
      function getMembreByMembreId(id) {
        return Axios("get", "api/membres/" + id + "?projection=client")
      }
    },
    requestMembresByUserId({ state }, userId) {
      // Recherche les membres rattaché aux users
      return Axios("get", `api/users/${userId}/membres?projection=client`)
        .then((response) => {
          return response.data._embedded.membres
        })
        .catch((error) => {
          console.log(
            `%c requestMembresByUserId error`,
            "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
            error
          )
          return error
        })
    },
    requestOperationByMembre({ commit }, membre) {
      // Dans le cas d'un user mono membre
      // Recherche l'operation rattaché au membre
      console.log("requestOperationByMembre href : %O", membre._links.operation.href)
      let href = membre._links.operation.href.replace(
        "http://waika.back.mcma-solutions.com",
        process.env.VUE_APP_API_URL
      )
      href = href.replace("http://back.totalenergies-collectedonnees.fr", process.env.VUE_APP_API_URL)
      console.log("requestOperationByMembre href : %O", href)
      return Axios("get", href)
        .then((response) => {
          commit("setOperation", response.data)
          return response.data
        })
        .catch((error) => {
          console.log(
            `%c requestOperationByMembre error`,
            "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
            error
          )
          return error
        })
    },
    updateMembre({ state, commit, dispatch }, membre) {
      console.log("updateMembre@Store")
      console.log("updateMembre@Store > membre : %O", membre)
      commit("setMembre", membre)
      if (membre === null) {
        commit("setState", {
          stateName: "membreStats",
          value: null,
        })
        return null
      } else {
        return dispatch("requestMembreStats", membre.id)
      }
    },
    requestMembreStats({ state, commit }, membreId) {
      console.log("requestMembreStats@Store")
      // commit("resetStateArray", "membresStats");
      return getMembreStatByMembreId(membreId).then((responseInit) => {
        console.log("requestMembreStats@Store response : %O", responseInit.data)
        commit("setState", {
          stateName: "membreStats",
          value: responseInit.data,
        })
        console.log("requestMembreStats@Store membreStats : %O", state.membreStats)
        return responseInit.data
      })
      function getMembreStatByMembreId(id) {
        return Axios("get", `api/membreStats/` + id)
      }
    },
    requestMembresStats({ state, commit }) {
      commit("resetStateArray", "membresStats")
      return getPage(0).then((responseInit) => {
        commit("pushInMasseToState", {
          stateName: "membresStats",
          values: responseInit.data._embedded.membreStats,
        })
        for (let page = 1; page < responseInit.data.page.totalPages; page++) {
          getPage(page).then((response) => {
            commit("pushInMasseToState", {
              stateName: "membresStats",
              values: response.data._embedded.membreStats,
            })
          })
        }
        commit("updateStateFromList", {
          stateName: "membreStats",
          stateList: state.membresStats,
        })
        return Promise.resolve()
      })

      function getPage(page) {
        return Axios(
          "get",
          `api/membreStats/search/findByOperationId?operationId=${state.operation.id}&size=50&page=${page}`
        )
      }
    },
    requestEmailsTracker({ state, commit }) {
      commit("resetStateArray", "emailsTracker")
      return getPage(0).then((responseInit) => {
        commit("pushInMasseToState", {
          stateName: "emailsTracker",
          values: responseInit.data._embedded.mailMailjets,
        })
        for (let page = 1; page < responseInit.data.page.totalPages; page++) {
          getPage(page).then((response) => {
            commit("pushInMasseToState", {
              stateName: "emailsTracker",
              values: response.data._embedded.mailMailjets,
            })
          })
        }
        return Promise.resolve()
      })

      function getPage(page) {
        return Axios(
          "get",
          `/api/mailMailjets/search/findByOperationId/?operationId=${state.operation.id}&page=${page}&size=50&projection=admin`
        )
      }
    },
    requestUsers({ state, commit }) {
      commit("resetStateArray", "users")
      return getPage(0).then((responseInit) => {
        commit("pushInMasseToState", {
          stateName: "users",
          values: responseInit.data._embedded.users,
        })
        for (let page = 1; page < responseInit.data.page.totalPages; page++) {
          getPage(page).then((response) => {
            commit("pushInMasseToState", {
              stateName: "users",
              values: response.data._embedded.users,
            })
          })
        }
        commit("updateStateFromList", {
          stateName: "user",
          stateList: state.users,
        })
        return Promise.resolve()
      })

      function getPage(page) {
        return Axios(
          "get",
          `api/users/search/findByOperationGroupementEquals?groupement=${state.operation.groupement}&page=${page}&size=50`
        )
      }
    },
    refreshStore({ state }, id) {
      console.group("refreshStore@Store")
      console.log("id : %O", id)
      let identifiant = window.localStorage.getItem("identifiant")
      // console.log("identifiant : %O", identifiant);
      if (id === null) {
        state.membre = null
      } else {
        if (identifiant) {
          Axios("get", "api/users/search/findByEmail?email=" + identifiant)
            .then((response) => {
              state.connected = true
              state.user = response.data
              console.log("> @refreshStore response.data :%O", response.data)
              console.log("> @refreshStore state.user.role : %O", state.user.role)
              console.log("> @refreshStore state.membre : %O", state.membre)
              /*
              if (state.membre || id) {
                console.log("> @refreshStore User not is ADMIN|SUPER_ADMIN_|COORDINATEUR");
                const requests = [];
                requests.push(
                  Axios("get", "api/membres/" + (id || state.membre.id) + "?projection=client")
                    .then((response) => {
                      state.membre = response.data;
                    })
                    .catch((response) => {
                      state.connected = false;
                      state.membre = null;
                      state.user = null;
                    })
                );
                Promise.all(requests).then((reponse) => {
                  // console.log("chargement du membre terminé : %O ", state.membre)
                });
                // } else if ((state.user.role === 'SUPER_ADMIN' || state.user.role !== 'ADMIN') && state.user.role !== 'COORDINATEUR' && !state.user.multiMembre) {
              } else if (
                state.user.role === "SUPER_ADMIN" ||
                state.user.role === "ADMIN" ||
                state.user.role === "COORDINATEUR"
              ) {
                console.log("> @refreshStore User is ADMIN|SUPER_ADMIN_|COORDINATEUR");
              }
              */
            })
            .catch((response) => {
              console.log("Error : %O", response)
              // state.connected = false;
              // state.membre = null;
              // state.user = null;
            })
        }
      }
      console.groupEnd()
    },
    tutoGoesTo({ state, commit }, nextStep) {
      if (nextStep && nextStep !== null) {
        state.intro.exit(true)
        commit("setCurrentTutorial", null)
        setTimeout(() => {
          commit("setCurrentTutorial", nextStep)
        }, 500)
      }
    },
    tutoAddSteps({ state, commit }, steps) {
      commit("setTutorialIsPlaying", true)
      state.intro
        .exit(true)
        .addSteps(tutorials[_.camelCase("get-" + steps)]())
        .start()
      commit("setTutorialIsPlaying", false)
    },
    tutoReset({ state, commit }, steps) {
      Vue.nextTick(() => {
        setTimeout(() => {
          if (state.intro && state.intro !== null) {
            commit("setTutorialIsPlaying", true)
            state.intro
              .exit(true)
              .setOptions({
                steps: tutorials[_.camelCase("get-" + steps)](),
                tooltipPosition: "top",
              })
              .start()
            commit("setTutorialIsPlaying", false)
          }
        }, 500)
      })
    },
    tutoNextStep({ state, dispatch }, tuto) {
      const previousTuto = tuto || state.currentTutorial
      setTimeout(() => {
        const part = previousTuto.match(/P\d+$/)
        let nextPart
        if (part === null) {
          nextPart = "P2"
        } else {
          nextPart = "P" + (parseInt(part[0].match(/\d+$/)[0]) + 1)
        }
        dispatch("tutoGoesTo", part ? previousTuto.replace(part[0], nextPart) : previousTuto + nextPart)
      }, 500)
    },
    setAppWindowsParams({ commit }) {
      const sizes = {
        xs: "480",
        sm: "576",
        md: "768",
        lg: "992",
        xl: "1200",
        xxl: "1600",
      }
      commit("setState", {
        stateName: "appSize",
        value: {
          height: window.innerHeight,
          width: window.innerWidth,
          layout: Object.keys(sizes).find(
            (key) =>
              sizes[key] ===
              Object.values(sizes)
                .reverse()
                .find((item) => item < window.innerWidth)
          ),
        },
      })
    },
  },
})
