<template>
  <main>
    <grid
      v-if="mails"
      name="Liste des mails"
      :rows="mails"
      :columns="columns"
      :search="true"
      :columnHeader="columnHeader"
      :columnsDated="['arrivedAt']"
      :columnsNoSearch="['actions']"
      :columnsListFilter="columnsListFilter"
      :multiSelect="false"
      @select="(value) => (selected = value)"
      class="tutorialNoBtn"
      id="adminToMemberGrid"
    >
      <center slot="actions" slot-scope="props">
        <template>
          <router-link
            :to="{ name: 'users', params: { id: props.row.userId } }"
            class="material-icons pointer blue-text tutorialNoBtn addMemberEdit"
            v-tooltip.auto="'Editer l\'utilisateur'"
          >
            <i class="material-icons blue-grey-text">edit</i>
          </router-link>
        </template>
      </center>

      <template slot="header">
        <blockquote v-if="user.role === 'SUPER_ADMIN' || user.role === 'ADMIN' || user.role === 'COORDINATEUR_AVANCE'">
          <span>
            Cette interface vous permets de voir le retour serveur des mails transmis aux utilisteurs via la plateforme.
            <br />
            Il est important de noté qu'il faut un certain délai entre l'émission du mail et son retour serveur. <br />
            Si l'ensemble des mails que vous avez envoyé tout de suite n'apparaissent merci de revenir un peu plus tard.
            <br />
          </span>
          <br />
          <span v-if="user.role === 'SUPER_ADMIN' || user.role === 'ADMIN'">
            <u><FONT size="4pt">Dans quel cas agir ?</FONT></u> <br />
            <ul id="case_list">
              <li>
                <FONT COLOR="RED"><B>BLOCKED, HARDBOUNCED</B></FONT> : l'adresse mail est erronée ou inexistante. Il est
                important pour la qualité d'envoi et la réussite de l'opération de rapidement prendre contact avec le
                coordonnateur ou l'entité membre pour corriger l'adresse mail. Une fois la bonne adresse en main vous
                pouvez cliquer sur l'action <i class="material-icons blue-grey-text">edit</i> pour modifier l'adresse de
                votre correspondant.
              </li>
              <li>
                <FONT COLOR="ORANGE"><B>SOFTBOUNCED, SPAM, UNSUB</B></FONT> : Il est probable que la boite mail de votre
                correspondant soit saturée, qu'une erreur technique soit survenue, ou que le mail ai été considéré comme
                un spam. Il est souhaitable de prendre contact avec le destinataire avant de retenter l'envoi de mail.
              </li>
            </ul>
          </span>
        </blockquote>
      </template>
    </grid>
  </main>
</template>

<script>
import Axios from "@/util/Axios"
import Materialize from "materialize-css"
import { mapMutations, mapState } from "vuex"
import Grid from "@/components/common/Grid"

export default {
  name: "mails",
  components: {
    Grid,
  },
  data() {
    return {
      selected: [],
      columnHeader: {
        userEmail: "Utilisateur de ce membre",
        nom: "Nom du membre",
        mailType: "Type de mail",
        status: "Status",
        arrivedAt: "Date d'arrivée",
        actions: "Actions",
      },
      columnListFilter: {
        mailType: {
          BIENVENU: "Bienvenu",
          RELANCE: "Relance",
          RAPPEL: "Rappel",
        },
        status: {
          PROCESSED: "Traité",
          QUEUED: "En attente",
          SENT: "Envoyé",
          OPENED: "Ouvert",
          CLICKED: "Cliqué",
          BOUNCE: "Rebondir",
          SPAM: "Spam",
          UNSUB: "Unsub",
          BLOCKED: "Bloqué",
          SOFTBOUNCED: "Rebondissement doux",
          HARDBOUNCED: "Rebondissement dur",
          DEFERRED: "Reporté",
        },
      },
      mails: null,
    }
  },
  computed: {
    ...mapState(["user", "operation"]),
    columns() {
      console.log("Mails this  : %O", this)
      if (this.user.role === "SUPER_ADMIN" || this.user.role === "ADMIN" || this.user.role === "COORDINATEUR_AVANCE") {
        return ["userEmail", "membres", "mailType", "status", "arrivedAt", "actions"]
      }
      return ["userEmail", "membres", "mailType", "status", "arrivedAt"]
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    initialize() {
      console.group("initialize@Membres")
      console.log("> Mails In !")

      this.mails = []
      console.log("Membres In : %O", this.mails)
      this.getPage(0).then((responseInit) => {
        this.computeResponse(responseInit)
        for (let page = 1; page < responseInit.data.page.totalPages; page++) {
          this.getPage(page).then((response) => {
            this.computeResponse(response)
          })
        }
      })
      console.log("Membres Out : %O", this.mails)
      console.groupEnd()
    },
    getPage(page) {
      console.group("getPage@mails")
      console.groupEnd()
      return Axios(
        "get",
        "/api/mailMailjets/search/findByOperationId/?operationId=" +
          this.operation.id +
          "&page=0&size=50&projection=admin"
      )
    },
    computeResponse(response) {
      console.log("> response : %O", response.data._embedded.mailMailjets)
      this.mails.push(...response.data._embedded.mailMailjets)
    },
    submit() {
      console.group("submit@Membres")

      if (this.membre) {
        console.log("path membre : %O", this.membre)
        Axios("post", "membre/save?membre=" + this.membre.id + "&name=" + this.nom + "&operation=" + this.operation.id)
          .then(() => {
            Materialize.toast("Membre édité", 4000, "lime white-text")
            this.membre = null
          })
          .catch((error) => {
            console.error(error)
            Materialize.toast("Erreur lors de la modification", 4000, "deep-orange white-text")
          })
      } else {
        console.log(">> Add newMembre : %O", this.membre)
        console.log("operation : %O", process.env.VUE_APP_API_URL + "/api/operations/" + this.operation.id)
        Axios("post", "membre/save?membre=" + 0 + "&name=" + this.nom + "&operation=" + this.operation.id)
          .then(() => {
            Materialize.toast("Membre ajouté", 4000, "lime white-text")

            this.initialize()
          })
          .catch((error) => {
            console.error(error)
            Materialize.toast("Erreur lors de l'ajout", 4000, "deep-orange white-text")
          })
        console.groupEnd()
      }
    },
    split(value) {
      if (value) {
        return value.split(",")
      }
      return []
    },
    edit(row) {
      this.membre = row
      if (this.currentTutorial) {
        this.intro.nextStep()
      }
    },
  },
  watch: {
    membre() {
      this.initialize()
    },
  },
  created() {
    this.setBreadcrumbAdd({
      name: "Suivi des mails",
      action: () => {
        this.$router.push({
          name: "mails",
        })
      },
    })
    this.initialize()
  },
}
</script>

<style lang="scss" scoped></style>
