<template>
  <a-form :form="form">
    <a-row type="flex" :gutter="12">
      <mcma-field
        type="input"
        :form="form"
        :wasSubmit="wasSubmit"
        fieldName="nom"
        label="Nom"
        placeholder="DUPONT"
        :defaultValue="interlocuteur.nom"
        :required="true"
        :uppercase="true"
        rule="excludeSpecial"
        :maxlength="32"
      />
      <mcma-field
        type="input"
        :form="form"
        :wasSubmit="wasSubmit"
        fieldName="prenom"
        label="Prénom"
        placeholder="John"
        :defaultValue="interlocuteur.prenom"
        :required="true"
        :capitalize="true"
        rule="excludeSpecial"
        :maxlength="16"
      />

      <mcma-field
        type="input"
        :form="form"
        :wasSubmit="wasSubmit"
        :defaultValue="interlocuteur.mail"
        fieldName="mail"
        label="Email"
        placeholder="john@gmail.com"
        :required="true"
        :requiredMessage="`Merci de renseigner l'email ${getInterName(form.getFieldValue('prenom'))}`"
        rule="isEmail"
      />

      <mcma-field
        type="select"
        :form="form"
        :wasSubmit="wasSubmit"
        getFlow="name::services"
        getPanel="name::Interlocuteurs"
        getField="type::civility"
      />
      <mcma-field
        type="input"
        :form="form"
        :wasSubmit="wasSubmit"
        :defaultValue="interlocuteur.telephone"
        fieldName="telephone"
        label="Téléphone Fixe"
        placeholder="0102030405"
        :extraValidationFields="['mobile']"
        :required="!form.getFieldValue('mobile') || form.getFieldValue('mobile').length <= 0"
        rule="isPhone"
      />
      <mcma-field
        type="input"
        :form="form"
        :wasSubmit="wasSubmit"
        :defaultValue="interlocuteur.mobile"
        fieldName="mobile"
        label="Téléphone Mobile"
        placeholder="0602030405"
        :extraValidationFields="['telephone']"
        :required="!form.getFieldValue('telephone') || form.getFieldValue('telephone').length <= 0"
        :requiredMessage="`ou le téléphone mobile`"
        rule="isMobilePhone"
      />
      <mcma-field
        type="select"
        :form="form"
        :wasSubmit="wasSubmit"
        :defaultValue="interlocuteur.fonction"
        getFlow="name::services"
        getPanel="name::Interlocuteurs"
        getField="type::fonction"
        :requiredMessage="`Merci de renseigner la fonction ${getInterName(form.getFieldValue('prenom'))}`"
        :required="true"
      />
      <mcma-field
        type="select"
        :form="form"
        :wasSubmit="wasSubmit"
        :defaultValue="interlocuteur.role"
        getFlow="name::services"
        getPanel="name::Interlocuteurs"
        getField="type::role"
        :requiredMessage="`Merci de renseigner le rôle ${getInterName(form.getFieldValue('prenom'))}`"
        :required="true"
        :disabled="from === 'site' || from === 'groupement'"
      />
      <mcma-field
        :colSpan="24"
        type="input"
        :form="form"
        :wasSubmit="wasSubmit"
        fieldName="refInterlocuteur"
        placeholder="Référence"
        getFlow="name::services"
        getPanel="name::Interlocuteurs"
        getField="type::refInterlocutor"
        :defaultValue="interlocuteur.refInterlocuteur"
      />
    </a-row>
    <a-row type="flex" :gutter="12" v-if="$route.name === 'interlocuteurs-detail'">
      <a-col :span="24">
        <div class="button-group-inline mt24">
          <mcma-button name="Réinitialiser" color="primary" leftIcon="undo-alt" :isFaIcon="true" @click="initialize" />
          <mcma-button
            name="Valider les informations"
            class="ml12"
            color="success"
            leftIcon="save"
            :isFaIcon="true"
            @click="submit"
          />
        </div>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
import Axios from "@/util/Axios"
import McmaField from "@/components/mcma/common/McmaField"
import McmaButton from "@/components/mcma/common/McmaButton"
import { mapState, mapActions } from "vuex"
import { pick } from "@/util/Helpers"

export default {
  name: "InterlocuteursEdit",
  props: {
    interlocuteur: Object,
    from: String,
  },
  components: {
    McmaField,
    McmaButton,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      wasSubmit: false,
    }
  },
  computed: {
    ...mapState(["membre", "operation"]),
    getInterName() {
      return (name) => {
        return name ? `de ${this.$capitalize(name)}` : ""
      }
    },
  },
  methods: {
    ...mapActions(["updateInterlocuteurOfGroupements", "refreshStore"]),
    initialize() {
      if (this.$route.params.action === "new") {
        this.form.setFieldsValue({
          nom: null,
          prenom: null,
          civility: null,
          mail: null,
          telephone: null,
          mobile: null,
          fonction: null,
          role: null,
          refInterlocuteur: null,
        })
      } else if (this.$route.params.interlocuteurId) {
        Axios("get", "api/interlocuteurs/" + this.$route.params.interlocuteurId).then((response) => {
          this.form.setFieldsValue(
            pick(response.data, [
              "nom",
              "prenom",
              "civility",
              "mail",
              "telephone",
              "mobile",
              "fonction",
              "role",
              "refInterlocuteur",
            ])
          )
        })
      }
      if (this.from === "site") {
        this.$nextTick(() => {
          this.form.setFieldsValue({
            role: "TECHNIQUE",
          })
        })
      } else if (this.from === "groupement") {
        this.$nextTick(() => {
          this.form.setFieldsValue({
            role: "FACTURATION",
          })
        })
      }
    },
    submit() {
      this.form.validateFields((errors, fieldsValue) => {
        this.wasSubmit = true
        if (errors) {
         /* this.$notification.warning({
            message: "Formulaire invalide",
            description: `Veuillez-vous assurez de remplir tous les champs requis.`,
          })*/
          this.$alert("Vous n'avez pas complété tous les champs obligatoires marqués par une astérisque rouge. Veuillez tous les remplir afin de valider le formulaire.","Erreur",'error')
          this.$emit("form-error")
        } else {
          const interlocuteur = {
            membre: process.env.VUE_APP_API_URL + "/api/membres/" + this.membre.id,
            nom: fieldsValue.nom.toUpperCase(),
            prenom: this.$capitalize(fieldsValue.prenom),
            mail: fieldsValue.mail,
            civility: fieldsValue.civility,
            telephone: fieldsValue.telephone,
            mobile: fieldsValue.mobile,
            fonction: fieldsValue.fonction,
            role: fieldsValue.role,
            refInterlocuteur: fieldsValue.refInterlocuteur,
            operationId: this.operation.id,
          }
          console.log(">> MODIF/ADD Interlocuteur : %O", interlocuteur)
          if (this.interlocuteur.id) {
            if (this.$route.params.action === "duplique") {
              this.createInterlocuteur(interlocuteur)
            } else {
              this.patchInterlocuteur(interlocuteur)
              this.updateInterlocuteurOfGroupements({
                id: this.interlocuteur.id,
                ...interlocuteur,
              })
            }
          } else {
            this.createInterlocuteur(interlocuteur)
          }
        }
      })
      this.refreshStore()
    },
    patchInterlocuteur(interlocuteur) {
      Axios("patch", "api/interlocuteurs/" + this.interlocuteur.id, interlocuteur)
        .then((response) => {
          this.$notification.success({
            message: "Interlocuteur mis à jour",
            description: `L'interlocuteur ${interlocuteur.prenom} ${interlocuteur.nom} à bien été mis à jours.`,
          })
          if (this.$route.name === "interlocuteurs-detail") {
            this.$router.push({
              name: "interlocuteurs",
            })
          } else {
            this.$emit("save-interlocuteur", response.data)
          }
        })
        .catch((error) => {
          console.error(error)
          this.$emit("form-error")
          this.$notification.error({
            message: "Erreur serveur",
            description: "Une erreur server est survenue, veuillez contacter un administrateur.",
          })
        })
    },
    createInterlocuteur(interlocuteur) {
      Axios("post", "api/interlocuteurs/", interlocuteur)
        .then((response) => {
          this.$notification.success({
            message: "Nouvel Interlocuteur ajouté",
            description: `L'interlocuteur ${interlocuteur.prenom} ${interlocuteur.nom} à bien été ajouté à vos interlocuteurs.`,
          })
          if (this.$route.name === "interlocuteurs-detail") {
            this.$router.push({
              name: "interlocuteurs",
            })
          } else {
            this.$emit("save-interlocuteur", response.data)
          }
        })
        .catch((error) => {
          console.error(error)
          this.$emit("form-error")
          if (error.response.status === 409) {
            this.$notification.warning({
              message: "L'interlocuteur existe déjà",
              description: "Un interlocuteur avec cette email existe déjà.",
            })
          } else {
            this.$notification.error({
              message: "Erreur serveur",
              description: "Une erreur server est survenue, veuillez contacter un administrateur.",
            })
          }
        })
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style lang="scss"></style>
