<template>
  <section>
    <mcma-spin-box :spinning="!operations" tip="Chargement des opérations..." :size="80" :full="true">
      <div class="searchAndFilter">
        <search v-model="searchValue" @search="search" class="search" @input="search" />
        <div class="checkbox">
          <input type="checkbox" id="hide" name="hide" :checked="checked" @change="hideOperations" />
          <label for="hide" style="font-size: 15px">Cacher les opérations passées (n-1)</label>
        </div>
        <a-select class="filter" defaultValue="Sélectionner une méthode de tri ">
          <a-select-option v-for="(filter, index) in filters" :key="index" :value="filter.key"
            @click="triggerFilter(filter)">
            {{ filter.value }}
          </a-select-option>
        </a-select>
      </div>
      <div>
        <operation-card v-for="operation in displayedOperations" :key="operation.id" :operation="operation" :user="user"
          @goTo="(operation) => $parent.goTo(operation)" @edit="(operation) => $parent.edit(operation)"
          @addAdmin="(operation) => $parent.addAdmin(operation)"
          @delete="(operation) => $parent.deleteOperation(operation)" />
      </div>
    </mcma-spin-box>
  </section>
</template>

<script>
import Search from "./Search"
import OperationCard from "./OperationCard"
import { getTime } from "@/util/Helpers"
import Axios from "@/util/Axios"
import { mapActions, mapMutations, mapState } from "vuex"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import _ from "lodash"
export default {
  name: "board",
  components: {
    Search,
    OperationCard,
    McmaSpinBox,
  },
  data() {
    return {
      searchValue: "",
      operations: null,
      operationsArchives: null,
      checked: false,
      filters: [
        {
          key: "id",
          value: "Trier par date de création",
          ascOrDesc: "asc",
          type: "Number",
        },
        {
          key: "dateFin",
          value: "Tri par date de fin",
          ascOrDesc: "asc",
          type: "Number",
        },
      ],
      displayedOperations: null,
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd", "removeLastbreadcrumb"]),
    ...mapActions(["requestOperations"]),
    async hideOperations() {
      //On affiche/cache les opérations à year n-1
      this.checked = !this.checked
      let currentDate = new Date()
      let currentYear = currentDate.getFullYear()
      let nMenosUno = currentYear - 1
      if (this.checked === true) {
        console.log(">> filter operations")
        let opeFilter = _.filter(this.operations, (o) => {
          return new Date(o.dateFin).getFullYear() > nMenosUno
        })
        this.operationsArchives = this.operations
        this.operations = opeFilter
        this.displayedOperations = this.operations
      } else {
        if (this.operationsArchives) {
          console.log(">> operations archived")
          this.operations = this.operationsArchives
          this.displayedOperations = this.operations
        } else {
          console.log(">> requestOperations")
          this.operations = await this.requestOperations()
          this.displayedOperations = this.operations
        }
      }
    },
    triggerFilter(value) {
      value.ascOrDesc = value.ascOrDesc === "asc" ? "desc" : "asc"
      this.operations = _.orderBy(this.operations, [value.key], [value.ascOrDesc])
    },
    initialize() {
      console.group("initialize@BoardSuperAdmin")
      console.log("user : %O", this.user)
      if (this.user.role === "SUPER_ADMIN") {
        Axios("get", "api/operations/?page=0&size=150").then((response) => {
          console.log("Operation : %O", response.data)
          this.operations = response.data._embedded.operations
          this.operationsArchives = this.operations
          this.displayedOperations = this.operations
        })
      } else {
        Axios("get", "api/operations/search/findByOperationsEquals?user=" + this.user.id).then((response) => {
          console.log(response.data)
          this.operations = response.data._embedded.operations
          this.operationsArchives = this.operations
          this.displayedOperations = this.operations
        })
      }
      this.operations = this.requestOperations()
      this.displayedOperations = this.operations
      console.groupEnd()
    },
    search() {
      if(this.searchValue === ""){
        this.displayedOperations = this.operations
      }
      const regexp = new RegExp(`${this.searchValue}`)
      const operationsFiltered = this.operations.filter(o => o.name.match(regexp) != null ? o : null)
      this.displayedOperations = operationsFiltered
      //console.log("Search !", this.displayedOperations, this.operations)
    },
  },
  watch: {
    selected() {
      console.group("selected@BoardIndex")
      console.groupEnd()
    },
  },
  created() {
    console.group("created@BoardIndex")
    this.$scrollToTop()
    this.removeLastbreadcrumb()
    this.setBreadcrumbAdd({
      name: "Administration des Opérations",
      action: () => {
        this.$router.push({
          name: "superadmin-board",
          params: {
            operationId: null,
            membreId: null,
          },
        })
      },
    })
    this.initialize()
    console.groupEnd()
  },
}
</script>

<style lang="scss" scoped>
.searchAndFilter {
  display: flex;
}

.checkbox {
  padding-left: 12%;
  margin: 1.4%;
}

.search {
  margin: 0.5%;
}

.filter {
  padding-left: 10%;
  margin: 1.4%;
}
</style>
