var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-header",class:{ isLog: _vm.connected }},[_c('div',{staticClass:"header-background",on:{"dblclick":function($event){return _vm.$scrollToTop()}}},[_c('mcma-svg',{attrs:{"name":"header_piece"}})],1),_c('div',{staticClass:"header-container"},[_c('div',{staticClass:"left-content"},[_c('router-link',{staticClass:"brand-logo",attrs:{"to":{ name: 'home' }}},[_c('img',{class:`header-logo-scale-${_vm.$getLogoBySubDomain().scaleNumber}`,attrs:{"src":_vm.$getLogoBySubDomain().logo.header,"alt":`${_vm.$getLogoBySubDomain().name} logo on Waika`}}),(_vm.$getLogoBySubDomain().slogan)?_c('div',{staticClass:"slogan"},[_vm._v(" "+_vm._s(_vm.$getLogoBySubDomain().slogan)+" ")]):_vm._e()])],1),_c('div',{staticClass:"right-content"},[(_vm.connected)?_c('a-menu',{attrs:{"mode":"horizontal"},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}},[(_vm.membre)?_c('a-menu-item',{key:"help"},[_c('a',{attrs:{"href":_vm.pdf,"download":"Guide Utilisateur"}},[_c('mcma-icon',{attrs:{"type":"question","color":"white","faIcon":true}}),_c('span',[_vm._v(" Aide ")])],1)]):_vm._e(),(_vm.membre)?_c('a-menu-item',{key:"membre"},[_c('router-link',{attrs:{"to":{ name: 'contact-us' }}},[_c('mcma-icon',{attrs:{"type":"users","color":"white","faIcon":true}}),_c('span',[_vm._v(" Contact ")])],1)],1):_vm._e(),_c('a-sub-menu',{attrs:{"popupClassName":"header-contact-sub-menu-popup"}},[_c('span',{staticClass:"submenu-title-wrapper",attrs:{"slot":"title"},slot:"title"},[_c('mcma-icon',{attrs:{"type":"user","color":"white","faIcon":true}}),_c('span',[_vm._v(" Compte ")])],1),_c('a-menu-item',{key:"update-account",attrs:{"disabled":_vm.$route.name === 'users-detail'}},[_c('router-link',{attrs:{"to":{
                name: 'users-detail',
                params: {
                  userId: _vm.user ? _vm.user.id : null,
                  action: 'my-account',
                },
              }}},[_vm._v(" Modification du compte ")])],1),(_vm.membre && (_vm.user.role === 'ADMIN' || _vm.user.role === 'SUPER_ADMIN'))?_c('a-menu-item',{key:"control-panel"},[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.goAdmin()}}},[_vm._v(" Retour au panneau de contrôle ")])]):_vm._e(),_c('a-menu-item',{key:"disconnect"},[_c('a',{staticClass:"red-color",on:{"click":function($event){$event.preventDefault();return _vm.disconnect()}}},[_vm._v(" Deconnexion ")])])],1)],1):_vm._e()],1)]),_c('div',{staticClass:"mcma-breadcrumb-block"},[_c('a-breadcrumb',{staticClass:"mcma-breadcrumb"},_vm._l((_vm.breadcrumb),function(item,index){return _c('a-breadcrumb-item',{key:index + 1},[_c('span',{class:{ clickable: _vm.breadcrumb.length - 1 !== index },attrs:{"id":item.name === 'Périmètres de facturation' ? 'validGroupGetBack' : ''},on:{"click":function($event){return _vm.action(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])])}),1),_c('mcma-button',{class:{ show: _vm.breadcrumb.length > 1 },attrs:{"name":"Retour","color":"orange","leftIcon":"arrow-left","isFaIcon":true},on:{"click":_vm.goBack}})],1),(_vm.user && _vm.user.role === 'CLIENT')?_c('mcma-multi-membre-fixed'):_vm._e(),_c('mcma-konami-debug')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }