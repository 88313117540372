<template>
  <div class="mcmaLoading">
    <!-- <img :src="'static/img/logo_mcma_3.png'" alt="Waika logo" /> -->

    <mcma-multi-membre-modal
      :visible="showMultiMembreModal"
      @membreSelected="(membre) => setMembreAndGoToStep(membre, true)"
      @refresh="redirection"
    />
  </div>
</template>

<script>
import McmaMultiMembreModal from "@/components/mcma/common/McmaMultiMembreModal"
import { mapActions, mapMutations, mapState } from "vuex"
import moment, { now } from "moment"

export default {
  name: "Home",
  components: {
    McmaMultiMembreModal,
  },
  data() {
    return {
      showMultiMembreModal: false,
    }
  },
  computed: {
    ...mapState(["user", "membre", "operation"]),
  },
  methods: {
    ...mapMutations(["setOperation", "setMembre", "setState", "initBreadcrumb"]),
    ...mapActions(["refreshStore", "disconnect", "updateMembre", "requestMembresByUserId", "requestOperationByMembre"]),
    redirection() {
      console.log("> @redirection User role : %O", this.user.role)
      if (
        this.user.role === "SUPER_ADMIN" ||
        this.user.role === "ADMIN" ||
        this.user.role === "COORDINATEUR" ||
        this.user.role === "COORDINATEUR_AVANCE"
      ) {
        if (!this.operation) {
          this.goToSuperAdmin()
        } else {
          if (this.membre) {
            this.goToSteps()
          } else {
            this.goToOperationsDetail()
          }
        }
      } else {
        if (this.user.role === "CLIENT") {
          this.prepareClientRedirection()
        } else {
          this.disconnect()
        }
      }
    },
    async prepareClientRedirection() {
      this.initBreadcrumb(null)
      try {
        const membres = await this.requestMembresByUserId(this.user.id)
        this.setState({
          stateName: "membres",
          value: membres,
        })
        if (!membres || membres.length === 0) {
          // Not have membre
          this.showMultiMembreModal = true
        } else if (!this.membre && membres.length > 1) {
          // Multi-membre
          this.showMultiMembreModal = true
        } else {
          // Mono-membre
          const firstMembre = this.membre ? this.membre : membres[0]
          await this.setMembreAndGoToStep(firstMembre)
        }
      } catch (error) {
        console.log(
          `%c Home > redirection error`,
          "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
          error
        )
        this.disconnect()
      }
    },
    goToSuperAdmin() {
      this.$router.push({
        name: "superadmin-board",
        params: {
          operationId: null,
          membreId: null,
        },
      })
    },
    async setMembreAndGoToStep(membre, showMultiMembreFixed) {
      try {
        await this.updateMembre(membre)
        await this.requestOperationByMembre(membre)
        if(this.operation.dateFin > moment.now() && this.membre.approuved === false){
          this.setState({
          stateName: "showToggleMultiMembre",
          value: showMultiMembreFixed,
        })
        this.goToSteps()
        }
        else{
          this.showMultiMembreModal = true
        }
        
      } catch (error) {
        console.log(
          `%c setMembreAndGoToStep error`,
          "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
          error
        )
      }
    },
    goToSteps() {
      this.$router.push({
        name: "steps",
        params: {
          operationId: this.operation.id,
          membreId: this.membre.id,
        },
      })
    },
    goToOperationsDetail() {
      this.$router.push({
        name: "operations-detail",
        params: {
          operationId: this.operation.id,
        },
      })
    },
  },
  watch: {
    user() {
      this.redirection()
    },
  },
  mounted() {
    this.refreshStore()
  },
}
</script>

<style lang="scss">
.mcmaLoading {
  height: calc(100vh - 130px);
  background: #fbfbfb;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  img {
    width: 300px;
  }
  .sentence {
    span {
      font-weight: 900;
      font-size: 20px;
    }
  }
}
</style>
