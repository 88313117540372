<template>
  <a-form :form="form">
    <a-row type="flex" :gutter="12">
      <mcma-field
        type="input"
        :form="form"
        :wasSubmit="wasSubmit"
        fieldName="nom"
        label="Nom"
        placeholder="DUPONT"
        :required="true"
        :uppercase="true"
      />
      <mcma-field
        type="input"
        :form="form"
        :wasSubmit="wasSubmit"
        fieldName="prenom"
        label="Prénom"
        placeholder="John"
        :required="true"
        :capitalize="true"
      />
      <mcma-field
        type="input"
        :form="form"
        :wasSubmit="wasSubmit"
        @change="emailChanged"
        fieldName="email"
        label="Email"
        placeholder="john@gmail.com"
        :required="true"
        :requiredMessage="`Merci de renseigner l'email ${getUserName(form.getFieldValue('prenom'))}`"
        rule="isEmail"
      />
      <mcma-field
        type="input"
        :form="form"
        :wasSubmit="wasSubmit"
        fieldName="telephoneFixe"
        label="Téléphone Fixe"
        rule="isPhone"
      />
      <mcma-field
        type="input"
        :form="form"
        :wasSubmit="wasSubmit"
        fieldName="telephoneMobile"
        label="Téléphone portable"
        :numberOnly="true"
        rule="isMobilePhone"
      />
      <mcma-field
        v-if="isAdvancedCoordinator || isAdmin || isSuperAdmin"
        type="select"
        :form="form"
        :wasSubmit="wasSubmit"
        fieldName="role"
        label="Rôle"
        disableValue="Sélectionner un rôle"
        :disabled="userIsCurrent || isAdvancedCoordinator"
        :required="true"
        :requiredMessage="`Merci de renseigner le rôle ${getUserName(form.getFieldValue('prenom'))}`"
      >
        <a-select-option value="CLIENT">Client</a-select-option>
        <a-select-option value="ADMIN">Admin</a-select-option>
        <a-select-option value="SUPER_ADMIN" v-if="isSuperAdmin">Super Admin</a-select-option>
        <a-select-option value="COORDINATEUR">Coordinateur</a-select-option>
        <a-select-option value="COORDINATEUR_AVANCE">Coordinateur Avancé</a-select-option>
      </mcma-field>
      <mcma-field type="textarea" :form="form" :wasSubmit="wasSubmit" fieldName="comment" label="Commentaire" />
    </a-row>
    <div class="change-password-block" :class="{ 'show-change-password': canChangePassword }">
      <div class="change-password-header">
        <mcma-button
          v-if="user"
          :name="userIsCurrent ? 'Changer mon mot de passe' : 'Changer le mot de passe'"
          color="purple"
          :leftIcon="checkChangePassword ? 'unlock' : 'lock'"
          :isFaIcon="true"
          @click="changeCheckPassword()"
          :disabled="emailHasChange"
        />
        <span v-else class="password-title"> Mot de passe </span>
      </div>
      <div class="change-password-body">
        <mcma-field
          type="password"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="password"
          :placeholder="!user ? 'Mot de passe' : 'Nouveau mot de passe'"
          :required="canChangePassword"
          :disabled="emailHasChange"
        />
        <mcma-field
          type="password"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="passwordConfirmation"
          placeholder="Confirmation"
          :extraValidationFields="['password']"
          :required="canChangePassword"
          requiredMessage="Merci de confirmer le mot de passe"
          :disabled="emailHasChange"
          rule="isConfirmPassword"
        />
      </div>
    </div>
    <a-row type="flex" :gutter="12">
      <a-col :span="24">
        <div class="button-group-inline mt24">
          <mcma-button
            name="Annuler les modifications"
            color="primary"
            leftIcon="undo-alt"
            :isFaIcon="true"
            @click="initialize"
          />
          <mcma-button
            :name="
              userIsCurrent
                ? 'Modifier mes informations'
                : user && user.id
                ? 'Modifier l\'utilisateur'
                : 'Ajouter l\'utilisateur'
            "
            class="ml12"
            color="success"
            leftIcon="save"
            :isFaIcon="true"
            @click="submit"
          />
        </div>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
import Axios from "@/util/Axios"
import McmaField from "@/components/mcma/common/McmaField"
import McmaButton from "@/components/mcma/common/McmaButton"
import Sha1 from "sha1"
import { accorization } from "@/util/Helpers"
import { mapActions, mapState } from "vuex"

export default {
  name: "UsersEdit",
  props: {
    user: Object,
  },
  components: {
    McmaField,
    McmaButton,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      wasSubmit: false,
      checkChangePassword: false,
      checkChangePasswordPending: false,
      emailHasChange: false,
      intervalDisconnect: null,
      countDown: 6,
    }
  },
  computed: {
    ...mapState({
      currentUser: "user",
      operation: "operation",
    }),
    userIsCurrent() {
      return this.currentUser && this.user && this.currentUser.id === this.user.id
    },
    isSuperAdmin() {
      return this.currentUser && this.currentUser.role === "SUPER_ADMIN"
    },
    isAdmin() {
      return this.currentUser && this.currentUser.role === "ADMIN"
    },
    isAdvancedCoordinator() {
      return this.currentUser && this.currentUser.role === "COORDINATEUR_AVANCE"
    },
    canChangePassword() {
      return this.user ? this.checkChangePassword : true
    },
    getUserName() {
      return (name) => {
        return name ? `de ${this.$capitalize(name)}` : ""
      }
    },
  },
  methods: {
    ...mapActions(["disconnect"]),
    initialize() {
      this.form.getFieldDecorator("nom")
      this.form.getFieldDecorator("prenom")
      this.form.getFieldDecorator("email")
      this.form.getFieldDecorator("telephoneFixe")
      this.form.getFieldDecorator("telephoneMobile")
      this.form.getFieldDecorator("comment")
      this.form.getFieldDecorator("role")
      this.form.getFieldDecorator("password")
      this.form.getFieldDecorator("passwordConfirmation")
      this.form.setFieldsValue({
        nom: this.user ? this.user.nom : null,
        prenom: this.user ? this.user.prenom : null,
        email: this.user ? this.user.email : null,
        role: this.user ? this.user.role : "CLIENT",
        telephoneFixe: this.user ? this.user.telephoneFixe : null,
        telephoneMobile: this.user ? this.user.telephoneMobile : null,
        password: null,
        passwordConfirmation: null,
        comment: this.user ? this.user.comment : null,
      })
    },
    submit() {
      this.form.validateFields((errors, fieldsValue) => {
        this.wasSubmit = true
        if (errors) {
          this.$notification.warning({
            message: "Formulaire invalide",
            description: `Veuillez-vous assurez de remplir tous les champs requis.`,
          })
        } else {
          const user = {
            id: this.user && this.user.id ? this.user.id : undefined,
            nom: fieldsValue.nom.toUpperCase(),
            prenom: this.$capitalize(fieldsValue.prenom),
            email: fieldsValue.email,
            role: fieldsValue.role,
            password:
              !this.user || (this.user && this.checkChangePassword)
                ? Sha1(fieldsValue.email + ":" + fieldsValue.password.trim()).toUpperCase()
                : undefined,
            oldPassword: !this.user && fieldsValue.password ? fieldsValue.password.trim() : undefined,
            telephoneFixe: fieldsValue.telephoneFixe,
            telephoneMobile: fieldsValue.telephoneMobile,
            comment: fieldsValue.comment,
          }

          // this.$emit("submit", user);
          this.saveUser(user)
        }
      })
    },
    saveUser(user) {
      if (user.id) {
        Axios("patch", `api/users/${user.id}`, {
          ...user,
          welcomed: null,
          firstConnectOk: null,
          lastConnect: null,
          lastConnectAttempt: null,
          passwordChanged: !this.emailHasChange,
        })
          .then(() => {
            this.$notification.success({
              message: `Utilisateur modifié`,
              description: `L'utilisateur ${user.prenom} ${user.nom} à bien été modifié`,
            })
            this.changeCheckPassword(false)
            if (this.userIsCurrent && user.password) {
              this.intervalDisconnect = setInterval(this.countDownDisconnect, 1000)
            }
          })
          .catch((error) => {
            console.error(error)
            this.$notification.error({
              message: `Erreur de modification de l'utilisateur`,
              description: `Une erreur esr survenu lors de la modification de l'utilisateur ${user.prenom} ${user.nom}. Si le probléme persiste, veuillez contacter un administrateur.`,
            })
          })
      } else {
        Axios("post", "api/users", user)
          .then((response) => {
            this.$notification.success({
              message: `Utilisateur ajouté`,
              description: `L'utilisateur ${user.prenom} ${user.nom} à bien été ajouté`,
            })
            // TODO add user operation_id dans la table operation_user...
            Axios("post", "operation/addLinkUser?operation=" + this.operation.id + "&user=" + response.data.id)
              .then(() => {
                this.$notification.success({
                  message: `Liaison sauvegardée`,
                  description: `La liaison entre l'utilisateur et l'opération à bien été sauvegardée`,
                })
                this.goToUsersManager()
              })
              .catch((error) => {
                console.error(error)
                this.$notification.error({
                  message: `Erreur de liaison`,
                  description: `Une erreur est survenue lors de l'ajout du lien entre l'utilisateur et l'opération !`,
                })
                this.goToUsersManager()
              })
          })
          .catch((error) => {
            console.error(error)
            this.$notification.error({
              message: `Erreur de l'ajout de l'utilisateur`,
              description: `Une erreur est survenue lors de l'ajout de l'utilisateur ${user.prenom} ${user.nom}.`,
            })
          })
      }
    },
    goToUsersManager() {
      this.initialize()
      this.$router.push({
        name: "users-manager",
      })
    },
    countDownDisconnect() {
      if (this.countDown < 1) {
        this.disconnection()
      } else {
        this.countDown--
        this.$message.loading({
          content: `Suite à votre changement de mot de passe, vous allez être déconnecté dans ${accorization(
            this.countDown,
            "seconde",
            true
          )}`,
          key: "countDown",
          duration: 5,
        })
      }
    },
    disconnection() {
      clearInterval(this.intervalDisconnect)
      this.disconnect()
    },
    emailChanged(email) {
      this.emailHasChange = this.user && this.user.email !== email
      this.changeCheckPassword(this.checkChangePassword ? !this.emailHasChange : false)
    },
    changeCheckPassword(newValue) {
      if (!this.checkChangePasswordPending) {
        this.checkChangePasswordPending = true
        this.checkChangePassword = newValue !== undefined ? newValue : !this.checkChangePassword
        setTimeout(() => {
          if (!this.checkChangePassword) {
            this.form.setFieldsValue({
              password: null,
              passwordConfirmation: null,
            })
          }
          this.checkChangePasswordPending = false
        }, 300)
      }
    },
  },
  watch: {
    user() {
      this.initialize()
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.change-password-block {
  display: flex;
  flex-flow: column nowrap;
  border-radius: 4px;
  background: transparent;
  padding: 0;
  max-height: 40px;
  overflow: hidden;
  transition: all 0.3s ease;
  &.show-change-password {
    background: hsla(var(--tertiary-color-h), var(--tertiary-color-s), var(--tertiary-color-l), 0.05);
    max-height: 300px;
    padding: calc(var(--padding, $padding) * 2);
  }
  .change-password-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    .password-title {
      font-weight: 900;
      &::before {
        content: "*";
        color: var(--error-color, $error-color);
        font-size: 10px;
        position: relative;
        bottom: 2px;
      }
    }
  }
  .change-password-body {
    display: flex;
    flex-flow: row nowrap;
    margin-top: calc(var(--padding, $padding) * 2);
    .mcma-field {
      .ant-input {
        background-color: transparent;
      }
      &:first-child {
        margin-right: 10px;
      }
      &:last-child {
        margin-left: 10px;
      }
    }
  }
}

.change-password-enter-active {
  transition: all 0.5s ease;
}
.change-password-leave-active {
  transition: all 0s ease;
}
.change-password-leave-to,
.change-password-enter {
  color: pink;
}
</style>
