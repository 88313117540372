<template>
  <main class="container login-page logo-and-bg">
    <div class="logo-background" :style="{ backgroundImage: $getLogoBySubDomain().backgroundCss }">
      <img
        v-if="$getLogoBySubDomain().logo && $getLogoBySubDomain().logo.login"
        class="logo"
        :src="$getLogoBySubDomain().logo.login"
        :alt="`${$getLogoBySubDomain().name} logo groupement on TotalEnergies-Collecte`"
      />
    </div>
    <section class="row login-error" v-if="error">
      <div class="col s12 m8 offset-m2">
        <div class="card-panel deep-orange">
          <div>
            {{ error }}
          </div>
          <div v-if="timeOut">
            La page va se rafraichir dans {{ timeOut / 1000 }} secondes, pour tenter de mettre à jour l'application.
          </div>
        </div>
      </div>
    </section>

    <section class="row login-message" v-if="message">
      <div class="col s12 m8 offset-m2">
        <div class="card-panel cyan">
          {{ message }}
        </div>
      </div>
    </section>

    <section class="row login-container">
      <div class="col s12 m6 offset-m3">
        <form class="card" @submit.prevent="act">
          <section class="card-content">
            <span class="card-title" style="margin-bottom: 44px"> Veuillez vous connecter </span>

            <article class="input-field">
              <i class="material-icons prefix">account_circle</i>
              <input v-model="identifiant" id="identifiant" type="text" class="validate" required :disabled="askNew" />
              <label for="identifiant" class="active"> Identifiant </label>
            </article>

            <article class="input-field">
              <i class="material-icons prefix">lock</i>
              <input v-model="password" id="password" type="password" class="validate" required :disabled="askNew" />
              <label for="password" class="active"> Mot de passe </label>
            </article>
            <img
              v-if="$getLogoBySubDomain().logo.loginLogoPower"
              style="float: right"
              class="logo"
              :src="$getLogoBySubDomain().logo.loginLogoPower"
              :alt="`${$getLogoBySubDomain().name}`"
            />
            <article class="input-field" v-if="askNew">
              <i class="material-icons prefix">lock_outline</i>
              <input v-model="newPassword" id="newPassword" type="password" class="validate" required />
              <label for="newPassword" class="active">
                Nouveau mot de passe (ne pas mettre d'espace en début ou fin)
              </label>
            </article>
          </section>

          <section class="card-action">
            <div class="row valign-wrapper">
              <article v-if="askNew" class="col s6 center-align">
                <button type="submit" class="waves-effect waves-light btn blue darken-2">
                  Changer le mot de passe
                </button>
              </article>

              <template v-else>
                <article class="col s6 center-align askChangePassword">
                  <a href @click.prevent="forgot">Mot de passe oublié ?</a>
                </article>
                <article class="col s6 center-align">
                  <button type="submit" class="waves-effect waves-light btn green">Connexion</button>
                </article>
              </template>
            </div>
          </section>
        </form>
      </div>
    </section>
  </main>
</template>

<script>
import Sha1 from "sha1"
import moment from "moment"
import Axios from "@/util/Axios"
import AxiosNoCrypt from "@/util/AxiosNoCrypt"
import { mapMutations, mapActions } from "vuex"
import Materialize from "materialize-css"
import { getClientTechInfos } from "@/util/Helpers/Navigator"
import { pushLogServer } from "@/util/Helpers/DynamiqueFields"

export default {
  name: "login",
  data() {
    return {
      error: null,
      message: null,
      lastIdentifiant: null,
      identifiant: null,
      password: null,
      passwordMustBeChanged: false,

      newPassword: null,
      askNew: false,

      timeOut: null,
    }
  },
  methods: {
    ...mapMutations(["setConnected", "initBreadcrumb", "setConfOperation"]),
    ...mapActions(["disconnect", "tutoReset"]),
    okConnect() {
      console.log("okConnect")
      this.setConnected(true)
      pushLogServer("INFO", "Connexion ok pour : " + this.identifiant)
      this.$router.push({
        name: "home",
      })
    },
    act() {
      pushLogServer("INFO", "Act connexion de : " + this.identifiant)
      if (this.askNew) {
        this.change()
      } else {
        this.connexion()
      }
    },
    connexion() {
      this.identifiant = this.identifiant.toLowerCase()
      const passHash = Sha1(this.identifiant + ":" + this.password.trim()).toUpperCase()
      const token = Sha1(this.identifiant + ":" + passHash + ":" + moment().format("YYYY-MM-DD")).toUpperCase()
      window.localStorage.setItem("identifiant", this.identifiant)
      window.localStorage.setItem("token", token)
      pushLogServer("INFO", "Tentative de connexion de : " + this.identifiant)
      Axios("get", "api/login", null, null, {
        clientTechInfos: JSON.stringify(getClientTechInfos()),
      })
        .then((response) => {
          if (!response.data.passwordChanged) {
            console.log("> Besoin de changer le mot de passe: %O", this.askNew)
            this.askNew = response.data.id
            this.message = "Pour votre première connexion, veuillez changer votre mot de passe."
            pushLogServer("INFO", "Besoin de changer le mot de passe : " + this.identifiant)
            this.passwordMustBeChanged = true
          } else {
            if (this.passwordMustBeChanged) {
              console.log("> Le mot de passe vient d'être changé : %O", this.askNew)
              pushLogServer("INFO", "Le mot de passe vient d'être changé : " + this.identifiant)
              this.passwordMustBeChanged = false
              this.okConnect()
            } else {
              this.okConnect()
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            pushLogServer("WARN", "Mauvais identifiants : " + this.identifiant)
            this.error = "Mauvais identifiant ou mot de passe."
          } else {
            pushLogServer("ERROR", "Erreur de connexion au serveur : " + this.identifiant)
            this.error = "Erreur de connexion au serveur"
          }
          this.timeOut = 2000
          setTimeout(() => {
            this.startReload()
          }, this.timeOut)
        })
    },
    change() {
      const passHash = Sha1(this.identifiant + ":" + this.newPassword.trim()).toUpperCase()
      pushLogServer("INFO", "Procedure Change pour : " + this.identifiant)
      Axios("patch", "api/users/" + this.askNew, {
        password: passHash,
        passwordChanged: true,
      })
        .then((response) => {
          console.log("> Changement mot de passe par l'utilisateur")
          pushLogServer("INFO", "Mot de passe changé : " + this.identifiant)
          this.password = this.newPassword.trim()
          this.connexion()
        })
        .catch((error) => {
          console.error(error)
          pushLogServer("ERROR", "Erreur serveur lors du changement de mot de passe : " + this.identifiant)
          pushLogServer("ERROR", "Erreur serveur : " + error)
          if (error.response.status === 400) {
            this.error = "Mauvais identifiants"
          } else {
            this.error = "Erreur de connexion au serveur"
          }
          this.timeOut = 2000
          setTimeout(() => {
            this.startReload()
          }, this.timeOut)
        })
    },
    forgot() {
      pushLogServer("INFO", "Demande de mot de passe oublié : " + this.identifiant)
      if (this.identifiant) {
        if (this.identifiant !== this.lastIdentifiant) {
          AxiosNoCrypt("post", "/mail/forgot", {
            identifiant: this.identifiant.toLowerCase(),
          })
            .then((response) => {
              pushLogServer("INFO", "Envoi Ok de mot de passe oublié : " + this.identifiant)
              Materialize.toast("Un nouveau mot de passe vous a été envoyé par mail.", 4000, "lime white-text")
            })
            .catch((error) => {
              pushLogServer("INFO", "Envoi Ko de mot de passe oublié : " + this.identifiant)
              console.error(error)
              if (error.response.status === 400) {
                this.error = "Mauvais identifiants"
              } else {
                this.error = "Erreur de connexion au serveur"
              }
              this.timeOut = 2000
              setTimeout(() => {
                this.startReload()
              }, this.timeOut)
            })
          this.lastIdentifiant = this.identifiant
        } else {
          alert("Un mail contenant votre nouveau mot de passe temporaire vous a déjà été envoyé.")
          Materialize.toast("Un nouveau mot de passe vous a déjà été envoyé.", 4000, "amber white-text")
        }
      } else {
        Materialize.toast("Vous devez d'abord renseigner un identifiant valide.", 4000, "amber white-text")
      }
    },
    startReload() {
      this.timeOut = 3000
      setTimeout(() => {
        window.location.reload(true)
      }, this.timeOut)
    },
  },
  created() {
    this.disconnect()
    this.tutoReset()
    this.initBreadcrumb(null)
    this.setConfOperation("SUPER_WAIKA")
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.login-page {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  &.logo-and-bg {
    .logo-background {
      img.logo {
        margin-left: auto;
        margin-right: auto;
        width: 400px;
        margin-top: -450px;
        margin-bottom: 50px;
      }
    }
    .login-container {
      margin-top: 150px;
    }
  }
  .login-error,
  .login-message {
    position: relative;
    z-index: 10;
  }
  .logo-background {
    position: absolute;
    z-index: 0;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    img.logo {
      width: 100vh;
    }
  }
  .login-container {
    .card {
      background: rgba($white, 0.9);
      .card-action {
        background: transparent;
        .askChangePassword {
          font-weight: 600;
        }
      }
    }
  }
}
</style>
