import Axios from "axios"
// import crypto from "crypto";

/*
function cryptUrl(method, url) {
  const urlProof = encodeURI(url);
  const cipher = crypto.createCipheriv("aes-128-ecb", process.env.VUE_APP_AES_KEY, "");
  const encrypted = (
    cipher.update(urlProof, "ascii", "hex") + cipher.final("hex")
  ).toUpperCase();
  if (process.env.NODE_ENV === "development") {
    console.info(method + " => " + urlProof + " => " + encrypted);
  }
  return encrypted;
}
*/

export default function (method, url, data, config, headers) {
  let baseURL = process.env.VUE_APP_API_URL
  if (window.location.origin.match("integration")) {
    baseURL = "https://ci-back.waika.integration.mcma-solutions.com"
  }
  // console.log('> %O | %O | %O | %O | %O', method, url, data, config, headers)
  /* if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    // baseURL = 'http://localhost:9292/'
    baseURL = process.env.VUE_APP_API_URL;
  } else {
    if (window.location.origin.match("collectivite")) {
      baseURL = "https://collectivite.back.edf.energie-collecte.fr";
    } else {
      baseURL = window.location.origin.replace(
        window.location.origin.match(/\.[^.]*\.[^.]*\.[^.]*$/)[0],
        ".back" + window.location.origin.match(/\.[^.]*\.[^.]*\.[^.]*$/)[0]
      );
    }
  } */
  // console.log("URL : " + url);
  let urlNoBaseURL = url.replace(baseURL, "")

  return Axios({
    url: urlNoBaseURL,
    method: method,
    data: data,

    baseURL: baseURL,
    headers: {
      user: window.localStorage.getItem("identifiant"),
      token: window.localStorage.getItem("token"),

      ...headers,
    },

    ...config,
  })
}
