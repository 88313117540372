<template>
  <mcma-card title="Collecte de données contractuelles" icon="collect_of_data" class="mcma-card-steps">
    <template #describe>
      <div
        v-if="collectDescribe"
        class="html-render-content"
        v-html="collectDescribe"
        :class="{ 'can-edit': $isAdmin() }"
        @click="setEditorModal('general', 'collectDescribe')"
      />
    </template>
    <mcma-spin-box :spinning="loading">
      <mcma-card class="mcma-card-steps-infos">
        <h3>Cette plateforme vous propose de :</h3>
        <div class="instruction-content">
          <div class="left-block">
            <ul>
              <li>
                <div class="number">
                  <span> 1 </span>
                </div>
                <p class="instruction">
                  <span class="preline html-render-content">
                    <p>Éditer vos données générales</p>
                  </span>
                </p>
              </li>
              <li v-for="(flow, index) in getActiveFlows" :key="index">
                <div class="number">
                  <span>
                    {{ index + 2 }}
                  </span>
                </div>
                <p class="instruction">
                  <span
                    v-html="flow.instruction"
                    class="preline html-render-content"
                    :class="{ 'can-edit': $isAdmin() }"
                    @click="setEditorModal(flow.name, 'instruction')"
                  />
                </p>
              </li>
            </ul>
          </div>
          <div class="right-block">
            <div class="sentence">
              <div class="shaped"></div>
              <div
                v-if="getGeneralFlow && objectifDescribe"
                class="html-render-content"
                v-html="objectifDescribe"
                :class="{ 'can-edit': $isAdmin() }"
                @click="setEditorModal('general', 'objectifDescribe')"
              />
            </div>
          </div>
        </div>
      </mcma-card>
    </mcma-spin-box>
    <mcma-editor-modal
      v-if="$isAdmin()"
      :visible="showEditor"
      :flowName="flowName"
      :flowProp="flowProp"
      @onCancel="setEditorModal"
      @onSave="editorHasMakeChange"
    />
  </mcma-card>
</template>

<script>
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaIcon from "@/components/mcma/common/McmaIcon"
import CountDown from "@/components/common/CountDown"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import { accorization, getTime } from "@/util/Helpers"
import McmaEditorModal from "@/components/mcma/common/McmaEditorModal"
import { mapGetters, mapState } from "vuex"

export default {
  name: "explain",
  components: {
    McmaCard,
    McmaIcon,
    McmaButton,
    CountDown,
    McmaSpinBox,
    McmaEditorModal,
  },
  data() {
    return {
      date: process.env.VUE_APP_DEADLINE,
      collectDescribe: null,
      objectifDescribe: null,
      loading: true,
      showEditor: false,
      flowName: null,
      flowProp: null,
      dateValues: {
        seconds: null,
        minutes: null,
        hours: null,
        days: null,
      },
      timer: null,
      resizeTimer: null,
      isFinish: false,
    }
  },
  computed: {
    ...mapState(["user", "confOperation", "operation"]),
    ...mapGetters(["getFlow"]),
    countDownParse() {
      return this.isFinish
        ? "Collecte terminée !"
        : `${accorization(this.dateValues.days, "jour", true)} et ${this.dateValues.hours}h${
            this.dateValues.minutes
          } restantes`
    },
    dateF() {
      return this.operation.dateFin
    },
    getGeneralFlow() {
      return this.operation.flows.find((flow) => flow.name === "general")
    },
    getActiveFlows() {
      return [...this.operation.flows].filter((flow) => flow.active && flow.name !== "general")
    },
  },
  mounted() {
    this.prepareSentences()
    this.checkTimeIsOver()
    this.setDates()
    this.killInterval()
    this.timer = setInterval(() => {
      this.checkTimeIsOver()
      this.setDates()
    }, 60)
    this.resizeInstructionContent()
    window.addEventListener("resize", this.resizeInstructionContentWithTimout)
  },
  destroyed() {
    this.killInterval()
    window.removeEventListener("resize", this.resizeInstructionContentWithTimout)
  },
  methods: {
    setEditorModal(flowName, flowProp) {
      this.showEditor = !!(flowName && flowProp)
      if (flowName && flowProp) {
        this.flowName = flowName
        this.flowProp = flowProp
      } else {
        setTimeout(() => {
          this.flowName = flowName
          this.flowProp = flowProp
        }, 300)
      }
    },
    editorHasMakeChange() {
      this.prepareSentences()
      if (this.flowProp === "objectifDescribe") {
        this.resizeInstructionContentWithTimout()
      }
      this.setEditorModal()
    },
    resizeInstructionContentWithTimout() {
      if (this.resizeTimer) {
        clearInterval(this.resizeTimer)
      }
      const instructionBlockLeft = document.querySelector(".instruction-content .left-block")
      const instructionBlockRight = document.querySelector(".instruction-content .right-block")
      instructionBlockLeft.style.opacity = 0.05
      instructionBlockRight.style.opacity = 0.05
      this.loading = true

      this.resizeTimer = setTimeout(() => {
        instructionBlockLeft.style.width = `auto`
        instructionBlockRight.style.width = `auto`
        this.resizeInstructionContent()
      }, 500)
    },
    resizeInstructionContent(leftHeight, rightWidth, index, bigIndex) {
      setTimeout(() => {
        if (!index) {
          index = 0
        }
        if (!bigIndex) {
          bigIndex = 0
        }
        bigIndex++
        const instructionBlockLeft = document.querySelector(".instruction-content .left-block")
        const instructionsBlockLeftUl = document.querySelectorAll(".instruction-content .left-block ul")[0]
        const instructionBlockRight = document.querySelector(".instruction-content .right-block")
        const instructionBlockRightSentence = document.querySelector(".instruction-content .right-block .sentence")

        if (bigIndex > 300) {
          instructionBlockLeft.style.opacity = 1
          instructionBlockRight.style.opacity = 1
          this.loading = false
          console.log(
            `%c resizeInstructionContent finish with big index`,
            "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
          )
          return
        }

        if (instructionBlockLeft && instructionsBlockLeftUl && instructionBlockRight && instructionBlockRightSentence) {
          if (
            (instructionBlockLeft.offsetHeight - 50 <= instructionsBlockLeftUl.offsetHeight &&
              instructionBlockRight.offsetHeight - 50 <= instructionBlockRightSentence.offsetHeight) ||
            (instructionBlockLeft.offsetHeight === leftHeight && instructionBlockRight.offsetHeight === rightWidth)
          ) {
            index++
          }
          if (instructionBlockLeft.offsetHeight - 50 > instructionsBlockLeftUl.offsetHeight) {
            instructionBlockLeft.style.width = `${instructionBlockLeft.offsetWidth - 50}px`
            instructionBlockRight.style.width = `${instructionBlockRight.offsetWidth + 50}px`
          } else if (instructionBlockRight.offsetHeight - 50 > instructionBlockRightSentence.offsetHeight) {
            instructionBlockLeft.style.width = `${instructionBlockLeft.offsetWidth + 50}px`
            instructionBlockRight.style.width = `${instructionBlockRight.offsetWidth - 50}px`
          }
          if (index < 1) {
            this.resizeInstructionContent(
              instructionBlockLeft.offsetHeight,
              instructionBlockRight.offsetWidth,
              index,
              bigIndex
            )
          } else {
            instructionBlockLeft.style.opacity = 1
            instructionBlockRight.style.opacity = 1
            this.loading = false
            console.log(
              `%c resizeInstructionContent finish`,
              "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
            )
          }
        }
      })
    },
    prepareSentences() {
      this.collectDescribe = this.getGeneralFlow ? this.$evalSentence(this.getGeneralFlow.collectDescribe, this) : null
      this.objectifDescribe = this.getGeneralFlow
        ? this.$evalSentence(this.getGeneralFlow.objectifDescribe, this)
        : null
    },
    killInterval() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    setDates() {
      this.dateValues = {
        seconds: getTime(this.dateF, "seconds"),
        minutes: getTime(this.dateF, "minutes"),
        hours: getTime(this.dateF, "hours"),
        days: getTime(this.dateF, "days"),
      }
    },
    checkTimeIsOver() {
      if (!this.isFinish) {
        this.isFinish =
          getTime(this.dateF, "seconds", true) ||
          getTime(this.dateF, "minutes", true) ||
          getTime(this.dateF, "hours", true) ||
          getTime(this.dateF, "days", true)
        if (this.isFinish) {
          this.$emit("timeIsOver", this.isFinish)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-card-steps {
  .card-title {
    .describe {
      white-space: pre-line;
    }
    .collect-date-box {
      .mcma-button-content {
        padding: 0 12px;
      }
      .value-block {
        height: 33px;
        padding: 0 12px;
        .value {
          margin: 7px auto;
        }
      }
      // position: absolute;
      // z-index: 1;
      // top: 38px;
      // right: -5px;
      // background: rgba($border-color-base, .1);
      // padding: 4px 9px 2px 9px;
      // border-radius: 5px;
      .date-title {
        /*font-style: italic;*/
        font-size: 14px;
        width: 100%;
        text-align: right;
        padding-right: 5px;
      }
    }
  }
  .mcma-card-steps-infos {
    margin-top: 10px;
    .card-body {
      padding-top: 0 !important;
      h3 {
        font-size: 30px;
        font-weight: 200;
        color: $blueLagon;
        margin-bottom: 20px;
      }
      .instruction-content {
        display: flex;
        flex-flow: row nowrap;
        .left-block {
          opacity: 0;
          transition: opacity 0.3s ease;
          max-width: 70%;
          min-width: 200px;
          display: flex;
          margin-right: 40px;
          ul {
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-start;
            height: 100%;
            li {
              display: flex;
              flex-flow: row nowrap;
              justify-content: flex-start;
              align-items: center;
              margin-top: 15px;
              .number {
                display: flex;
                min-width: 30px;
                width: 30px;
                height: 30px;
                margin: 0 15px;
                border-radius: 5px;
                box-shadow: $box-shadow;
                span {
                  margin: auto;
                  font-weight: 900;
                  color: $blueLagon;
                  font-size: 15px;
                  line-height: 15px;
                }
              }
              .instruction {
                .html-render-content {
                  display: inline-block;
                }
                span {
                  color: var(--text-color, $text-color);
                }
              }
            }
          }
        }
        .right-block {
          opacity: 0;
          transition: opacity 0.3s ease;
          max-width: 75%;
          min-width: 50%;
          display: flex;
          flex-flow: column nowrap;
          justify-content: flex-start;
          .sentence {
            margin-top: 15px;
            color: var(--text-color, $text-color);
            .shaped {
              height: 180px;
              width: 180px;
              shape-outside: polygon(0 0, 19% 25%, 45% 49%, 76% 67%, 100% 75%, 100% 0%);
              clip-path: polygon(0 0, 19% 25%, 45% 49%, 76% 67%, 100% 75%, 100% 0%);
              /*background: rgba(red, .05);*/
              float: right;
            }
          }
          .legendes-block {
            position: absolute;
            top: 0;
            right: 0;
            height: 190px;
            width: 150px;
            .oval-content {
              position: absolute;
              z-index: 0;
              height: 100%;
              width: 100%;
              overflow: hidden;
              .oval {
                position: relative;
                top: -90px;
                width: 200px;
                height: 280px;
                background: hsla(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l), 0.03);
                border-radius: 140px / 200px;
              }
            }
            .legende-content {
              position: relative;
              z-index: 5;
              .legende-title {
                display: flex;
                padding: 14px 0 7px 0;
                span {
                  font-size: 16px;
                  font-weight: 900;
                  color: var(--text-color, $text-color);
                  margin: auto;
                }
              }
              ul {
                display: flex;
                flex-flow: column nowrap;
                align-items: flex-end;
                margin-right: -4px;
                li {
                  position: relative;
                  display: flex;
                  flex-flow: row nowrap;
                  padding: 4px 12px 2px 4px;
                  border-radius: 4px;
                  margin-top: 10px;
                  &:before {
                    content: "";
                    position: absolute;
                    bottom: 1px;
                    right: -4px;
                    width: 13px;
                    height: 4px;
                    transform: skew(-30deg) rotate(60deg);
                    border-top-right-radius: 4px;
                  }
                  .mcma-icon {
                    .content {
                      .icon-background {
                        margin-right: 4px;
                        margin-bottom: 2px;
                      }
                      span {
                        color: $white;
                        font-weight: 900;
                        text-transform: capitalize;
                      }
                    }
                  }
                  &.to_complete {
                    background: var(--error-color, $error-color);
                    &:before {
                      background: var(--error-color, $error-color);
                    }
                    img {
                      width: 16px;
                      margin-top: 1px;
                    }
                  }
                  &.to_certify {
                    background: var(--warning-color, $warning-color);
                    &:before {
                      background: var(--warning-color, $warning-color);
                    }
                    img {
                      width: 14px;
                    }
                  }
                  &.certified {
                    background: var(--success-color, $success-color);
                    &:before {
                      background: var(--success-color, $success-color);
                    }
                    img {
                      width: 16.5px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
