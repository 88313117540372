<template>
  <mcma-card class="mcma-card-timer" style="height: 100%">
    <div class="collect-date-box">
      <!--        <div class="date-title">-->
      <!--          Date de fin de collecte-->
      <!--        </div>-->
      <span>À compléter avant le</span>
      <a-tooltip placement="left">
        <template slot="title">
          <span>Date de fin de collecte</span>
        </template>
        <div>
          <mcma-button :name="countDownParse" :color="isFinish ? 'red' : 'blueLagon'" :value="$d(dateF)" />
        </div>
      </a-tooltip>
    </div>
  </mcma-card>
</template>

<script>
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaCard from "@/components/mcma/common/McmaCard"
import { accorization, getTime } from "@/util/Helpers"
import { mapState } from "vuex"

export default {
  name: "timer",

  components: {
    McmaButton,
    McmaCard,
  },

  data() {
    return {
      //   date: process.env.VUE_APP_DEADLINE,
      //   collectDescribe: null,
      //   objectifDescribe: null,
      //   loading: true,
      //   showEditor: false,
      //   flowName: null,
      //   flowProp: null,
      dateValues: {
        seconds: null,
        minutes: null,
        hours: null,
        days: null,
      },
      //   timer: null,
      //   resizeTimer: null,
      isFinish: false,
    }
  },

  computed: {
    ...mapState(["user", "confOperation", "operation"]),
    countDownParse() {
      return this.isFinish
        ? "Collecte terminée !"
        : `${accorization(this.dateValues.days, "jour", true)} et ${this.dateValues.hours}h${
            this.dateValues.minutes
          } restantes`
    },
    dateF() {
      return this.operation.dateFin
    },
  },

  created() {
    // this.prepareSentences();
    this.checkTimeIsOver()
    this.setDates()
    // this.killInterval();
    // this.timer = setInterval(() => {
    //   this.checkTimeIsOver();
    //   this.setDates();
    // }, 60);
    // this.resizeInstructionContent();
    // window.addEventListener("resize", this.resizeInstructionContentWithTimout);
  },
  destroyed() {
    this.killInterval()
    window.removeEventListener("resize", this.resizeInstructionContentWithTimout)
  },
  methods: {
    setEditorModal(flowName, flowProp) {
      this.showEditor = !!(flowName && flowProp)
      if (flowName && flowProp) {
        this.flowName = flowName
        this.flowProp = flowProp
      } else {
        setTimeout(() => {
          this.flowName = flowName
          this.flowProp = flowProp
        }, 300)
      }
    },
    editorHasMakeChange() {
      this.prepareSentences()
      if (this.flowProp === "objectifDescribe") {
        this.resizeInstructionContentWithTimout()
      }
      this.setEditorModal()
    },
    resizeInstructionContentWithTimout() {
      if (this.resizeTimer) {
        clearInterval(this.resizeTimer)
      }
      const instructionBlockLeft = document.querySelector(".instruction-content .left-block")
      const instructionBlockRight = document.querySelector(".instruction-content .right-block")
      instructionBlockLeft.style.opacity = 0.05
      instructionBlockRight.style.opacity = 0.05
      this.loading = true

      this.resizeTimer = setTimeout(() => {
        instructionBlockLeft.style.width = `auto`
        instructionBlockRight.style.width = `auto`
        this.resizeInstructionContent()
      }, 500)
    },
    resizeInstructionContent(leftHeight, rightWidth, index, bigIndex) {
      setTimeout(() => {
        if (!index) {
          index = 0
        }
        if (!bigIndex) {
          bigIndex = 0
        }
        bigIndex++
        const instructionBlockLeft = document.querySelector(".instruction-content .left-block")
        const instructionsBlockLeftUl = document.querySelectorAll(".instruction-content .left-block ul")[0]
        const instructionBlockRight = document.querySelector(".instruction-content .right-block")
        const instructionBlockRightSentence = document.querySelector(".instruction-content .right-block .sentence")

        if (bigIndex > 300) {
          instructionBlockLeft.style.opacity = 1
          instructionBlockRight.style.opacity = 1
          this.loading = false
          console.log(
            `%c resizeInstructionContent finish with big index`,
            "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
          )
          return
        }

        if (instructionBlockLeft && instructionsBlockLeftUl && instructionBlockRight && instructionBlockRightSentence) {
          if (
            (instructionBlockLeft.offsetHeight - 50 <= instructionsBlockLeftUl.offsetHeight &&
              instructionBlockRight.offsetHeight - 50 <= instructionBlockRightSentence.offsetHeight) ||
            (instructionBlockLeft.offsetHeight === leftHeight && instructionBlockRight.offsetHeight === rightWidth)
          ) {
            index++
          }
          if (instructionBlockLeft.offsetHeight - 50 > instructionsBlockLeftUl.offsetHeight) {
            instructionBlockLeft.style.width = `${instructionBlockLeft.offsetWidth - 50}px`
            instructionBlockRight.style.width = `${instructionBlockRight.offsetWidth + 50}px`
          } else if (instructionBlockRight.offsetHeight - 50 > instructionBlockRightSentence.offsetHeight) {
            instructionBlockLeft.style.width = `${instructionBlockLeft.offsetWidth + 50}px`
            instructionBlockRight.style.width = `${instructionBlockRight.offsetWidth - 50}px`
          }
          if (index < 1) {
            this.resizeInstructionContent(
              instructionBlockLeft.offsetHeight,
              instructionBlockRight.offsetWidth,
              index,
              bigIndex
            )
          } else {
            instructionBlockLeft.style.opacity = 1
            instructionBlockRight.style.opacity = 1
            this.loading = false
            console.log(
              `%c resizeInstructionContent finish`,
              "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
            )
          }
        }
      })
    },
    prepareSentences() {
      this.collectDescribe = this.getGeneralFlow ? this.$evalSentence(this.getGeneralFlow.collectDescribe, this) : null
      this.objectifDescribe = this.getGeneralFlow
        ? this.$evalSentence(this.getGeneralFlow.objectifDescribe, this)
        : null
    },
    killInterval() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    setDates() {
      this.dateValues = {
        seconds: getTime(this.dateF, "seconds"),
        minutes: getTime(this.dateF, "minutes"),
        hours: getTime(this.dateF, "hours"),
        days: getTime(this.dateF, "days"),
      }
    },
    checkTimeIsOver() {
      if (!this.isFinish) {
        this.isFinish =
          getTime(this.dateF, "seconds", true) ||
          getTime(this.dateF, "minutes", true) ||
          getTime(this.dateF, "hours", true) ||
          getTime(this.dateF, "days", true)
        if (this.isFinish) {
          this.$emit("timeIsOver", this.isFinish)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-card-timer {
  .card-body {
    padding-top: 0 !important;
  }

  .collect-date-box {
    // text-align: center;

    .mcma-button-content {
      padding: 0 12px !important;
    }

    .value-block {
      height: 33px;
      padding: 0 12px;
      .value {
        margin: 7px auto;
      }
    }
    // position: absolute;
    // z-index: 1;
    // top: 38px;
    // right: -5px;
    // background: rgba($border-color-base, .1);
    // padding: 4px 9px 2px 9px;
    // border-radius: 5px;
    .date-title {
      /*font-style: italic;*/
      font-size: 14px;
      width: 100%;
      text-align: right;
      padding-right: 5px;
    }
  }
}
</style>
