import { render, staticRenderFns } from "./AdministrationInformations.vue?vue&type=template&id=4979b7f2"
import script from "./AdministrationInformations.vue?vue&type=script&lang=js"
export * from "./AdministrationInformations.vue?vue&type=script&lang=js"
import style0 from "./AdministrationInformations.vue?vue&type=style&index=0&id=4979b7f2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports