<template>
  <a-form :form="form">
    <mcma-card title="Type de périmètre de facturation" icon="administration" class="mcma-card-in-tab" :inline="true">
      <a-row type="flex" :gutter="12">
        <mcma-field :colSpan="12" type="input" :form="form" :wasSubmit="wasSubmit" fieldName="nom"
          getFlow="name::groupements" getPanel="name::groupements" getField="type::groupementName"
          placeholder="Mon périmètre n°5" :defaultValue="groupement.nom" rule="excludeSpecial" :required="true"
          :maxlength="35" @change="updateGroupementEditing(form.getFieldsValue())" />
      </a-row>
      <a-row type="flex" :gutter="12">
        <mcma-field :colSpan="12" type="select" :form="form" :wasSubmit="wasSubmit" fieldName="typologieSegment"
          getFlow="name::groupements" getPanel="name::groupements" getField="type::typologieSegment"
          placeholder="Typologie segment" :defaultValue="groupement.typologieSegment"
          @change="onTypoSegmentChange($event)" disableValue="Sélectionner le type de segment"
          extraLabel="Le type de segment à sélectionner doit être identique au segment renseigné dans les données de sites que vous souhaitez lier à ce périmètre de facturation" />
      </a-row>
      <a-row type="flex" :gutter="12">
        <mcma-field :colSpan="12" type="select" :form="form" :wasSubmit="wasSubmit" fieldName="typeFacturation"
          getFlow="name::groupements" getPanel="name::groupements" getField="type::typeFacturation" />
      </a-row>
      <template #describe> </template>
    </mcma-card>
    <mcma-card title="Modalité de facturation" icon="administration" class="mcma-card-in-tab" :inline="true">
      <a-row type="flex" :gutter="24">
        <mcma-field :colSpan="12" type="select" :form="form" :wasSubmit="wasSubmit"
          :defaultValue="membre.moyenPaiement | groupement.moyenPaiement" fieldName="moyenPaiement"
          disableValue="Sélectionnez un mode de paiement" getFlow="name::groupements" getPanel="name::groupements"
          getField="type::moyenPaiement" @change="moyenPayementChange;" :required="true" />
        <mcma-field :colSpan=" 12 " type="select" :form=" form " :wasSubmit=" wasSubmit "
          :defaultValue=" groupement.rythmeFacturation " fieldName="rythmeFacturation"
          disableValue="Sélectionnez un rythme de facturation" label="Rythme de facturation" getFlow="name::groupements"
          getPanel="name::groupements" getField="type::rythmeFacturation" :required=" true "
          :disabled=" disableRythmeFacturation "
          extraLabel="ne s'appliquera qu'aux sites à relève semestrielle du présent périmètre de facturation" />
      </a-row>
      <a-row type="flex" :gutter=" 24 ">
        <mcma-field :colSpan=" 12 " type="select" :form=" form " :wasSubmit=" wasSubmit "
          :defaultValue=" groupement.delaiPaiement " fieldName="delaiPaiement"
          disableValue="Sélectionnez un délai de paiement" label="Délai de paiement" getFlow="name::groupements"
          getPanel="name::groupements" getField="type::delaiPaiement" :required=" true " />
        <mcma-field :colSpan=" 12 " type="select" :form=" form " :wasSubmit=" wasSubmit " :defaultValue=" groupement.modeEnvoi "
          fieldName="modeEnvoi" getFlow="name::groupements" getPanel="name::groupements" getField="type::modeEnvoi"
          @change="onChangeModeEnvoi($event)" />
      </a-row>
      <a-row type="flex" :gutter=" 24 ">
        <mcma-field :colSpan=" 12 " v-if=" ibanIsRequired(form.getFieldValue('moyenPaiement')) " type="input" :form=" form "
          :wasSubmit=" wasSubmit " :defaultValue=" membre.titulaireIban | groupement.titulaireIban " fieldName="titulaireIban"
          label="Titulaire IBAN" getFlow="name::groupements" getPanel="name::groupements" getField="type::iban"
          :required=" ibanIsRequired(form.getFieldValue('moyenPaiement')) " />
        <mcma-field :colSpan=" 12 " v-if=" ibanIsRequired(form.getFieldValue('moyenPaiement')) " type="input" :form=" form "
          :wasSubmit=" wasSubmit " :defaultValue=" membre.codeIban | groupement.iban " fieldName="iban" label="Code IBAN"
          getFlow="name::groupements" getPanel="name::groupements" getField="type::iban" rule="isIban" @change="
            resetIbanUpload()
            isIbanTresorerie(form.getFieldValue('moyenPaiement'))
          " :required=" ibanIsRequired(form.getFieldValue('moyenPaiement')) "
          extraLabel="Si vous renseignez l'iban d'une trésorerie la modalité de paiement sera de type Prélevement trésorerie" />

        <div>
          <div v-if=" ibanIsRequired(form.getFieldValue('moyenPaiement')) " class="upload-box"
            :class=" { 'has-file': ibanUploadedFile } ">
            <mcma-button :colSpan=" 12 " name="Téléversement de l'iban"
              :color=" this.checkIban || ibanUploadedFile ? 'success' : 'purple' "
              :leftIcon=" this.checkIban || ibanUploadedFile ? 'check' : 'upload' " :isFaIcon=" true "
              @click="$refs.documentUploaded.click()" :required=" ibanIsRequired(form.getFieldValue('moyenPaiement')) " />
            <div class="file-name">
              {{ ibanUploadedFile ? ibanUploadedFile.name : "" }}
            </div>
            <input class="ninja" type="file" ref="documentUploaded" @change=" processFile " />
          </div>
          <legend v-if=" !(this.checkIban || ibanUploadedFile) && ibanIsRequired(form.getFieldValue('moyenPaiement')) ">
            Veuillez téléverser votre iban au format PDF.
          </legend>
        </div>
      </a-row>
    </mcma-card>
    <mcma-card title="Entité facturée (adresse de facturation)" icon="administration" class="mcma-card-in-tab"
      :inline=" true ">
      <a-row type="flex" :gutter=" 12 ">
        <mcma-field type="input" :form=" form " :wasSubmit=" wasSubmit " fieldName="budget" getFlow="name::groupements"
          getPanel="name::groupements" getField="type::groupementBudget" placeholder="Mon budget n°2"
          :defaultValue=" groupement.budget " @change="updateGroupementEditing(form.getFieldsValue())"
          :extraLabel=" 'n\'apparaîtra pas sur les factures' " />
      </a-row>
      <a-row type="flex" :gutter=" 24 ">
        <mcma-field type="input" :form=" form " :wasSubmit=" wasSubmit " :required=" true " :maxlength=" 31 "
          getFlow="name::groupements" getPanel="name::groupements" getField="type::raisonSocialPayeur"
          fieldName="raisonSocialePayeur" :defaultValue=" groupement.raisonSocialPayeur || this.membre.businessName "
          :requiredMessage=" `Merci de renseigner la raison sociale du destinataire de la facture` " />
      </a-row>

      <a-row type="flex" :gutter=" 24 ">
        <mcma-field type="input" :form=" form " :wasSubmit=" wasSubmit " getFlow="name::groupements"
          getPanel="name::groupements" getField="type::voieNumber" fieldName="numeroVoie"
          :defaultValue=" groupement.numeroVoie || membre.numeroVoie " />
        <mcma-field type="input" :form=" form " :wasSubmit=" wasSubmit " getFlow="name::groupements"
          getPanel="name::groupements" getField="type::voieName" :required=" true " fieldName="nomVoie"
          :defaultValue=" groupement.nomVoie || membre.nomVoie " :maxlength=" 32 "
          :requiredMessage=" `Merci de renseigner l'adresse du destinataire de la facture` " />
        <mcma-field type="input" :form=" form " :wasSubmit=" wasSubmit " :maxlength=" 32 " getFlow="name::groupements"
          getPanel="name::groupements" getField="type::addressCompleted" fieldName="complementAdresse"
          :defaultValue=" groupement.complementAdresse || membre.complementAdresse " />

        <mcma-field type="input" :form=" form " :wasSubmit=" wasSubmit " getFlow="name::groupements"
          getPanel="name::groupements" getField="type::boitePostale" fieldName="boitePostale"
          placeholder="Boite postal destinataire facture"
          :defaultValue=" groupement.boitePostale || membre.boitePostale " />
      </a-row>

      <a-row type="flex" :gutter=" 24 ">
        <mcma-field type="input" :form=" form " :wasSubmit=" wasSubmit " :numberOnly=" true " rule="isZipCode"
          getFlow="name::groupements" getPanel="name::groupements" getField="type::codePostal" fieldName="codePostale"
          :defaultValue=" groupement.codePostal || membre.postalCode " />

        <mcma-field type="input" :form=" form " :wasSubmit=" wasSubmit " :required=" true " label="Commune"
          getFlow="name::groupements" getPanel="name::groupements" getField="type::town" fieldName="commune"
          :defaultValue=" groupement.commune || membre.city " :uppercase=" true "
          :requiredMessage=" `Merci de renseigner la commune du destinataire de la facture` " />
      </a-row>
    </mcma-card>
    <mcma-card v-if=" showTresorerie(form.getFieldValue('moyenPaiement')) " title="Trésorerie en charge du paiement "
      icon="administration" class="mcma-card-in-tab" :inline=" true ">
      <template #describe>
        Merci d’indiquer les coordonnées de la trésorerie exécutant le paiement. A renseigner uniquement en cas de
        paiement sans mandatement préalable.
        <br />
        {{ msgNotaBnEntiteExecutant }}
      </template>
      <a-row type="flex" :gutter=" 12 ">
        <mcma-field type="input" :form=" form " :wasSubmit=" wasSubmit " fieldName="designationTresorerie"
          :defaultValue=" groupement.designationTresorerie " getFlow="name::groupements" getPanel="name::groupements"
          getField="type::designationTresorerie" placeholder="Ma raison sociale payeur" :required=" true "
          :requiredMessage=" `Merci de renseigner la raison sociale du gestionnaire de la facture` "
          @change="updateGroupementEditing(form.getFieldsValue())" />

        <mcma-field type="input" :form=" form " :wasSubmit=" wasSubmit " fieldName="adresseFacturationTresorerie"
          :defaultValue=" groupement.adresseFacturationTresorerie " getFlow="name::groupements" getPanel="name::groupements"
          getField="type::adresseFacturationTresorerie" placeholder="5 rue de la tour effel" :required=" true "
          :requiredMessage=" `Merci de renseigner l'adresse d'envoie de la facture` "
          @change="updateGroupementEditing(form.getFieldsValue())" />

        <mcma-field type="input" :form=" form " :wasSubmit=" wasSubmit " fieldName="codePostaleTresorerie"
          :defaultValue=" groupement.codePostaleTresorerie " getFlow="name::groupements" getPanel="name::groupements"
          getField="type::codePostaleTresorerie" placeholder="92000" :required=" true " rule="isZipCode"
          @change="updateGroupementEditing(form.getFieldsValue())" />

        <mcma-field type="input" :form=" form " :wasSubmit=" wasSubmit " fieldName="communeTresorerie"
          :defaultValue=" groupement.communeTresorerie " getFlow="name::groupements" getPanel="name::groupements"
          getField="type::townTresorerie" placeholder="PARIS" :required=" true "
          :requiredMessage=" `Merci de renseigner la commune` " :uppercase=" true "
          @change="updateGroupementEditing(form.getFieldsValue())" />
      </a-row>
    </mcma-card>
  </a-form>
</template>

<script>
import { mapState } from "vuex"
import moment from "moment"
import Axios from "@/util/Axios"
import { pick } from "@/util/Helpers"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaField from "@/components/mcma/common/McmaField"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import Constants from "@/util/Constants"

export default {
  name: "GroupementsEdit",
  data() {
    return {
      form: this.$form.createForm(this),
      wasSubmit: false,
      msgTypeFacturation: "",
      msgNotaBnEntiteExecutant: "",
      ibanUploadedFile: null,
      checkIban: this.groupement.ibanPieceUploaded, // obligé pour la duplication du field bouton
      disableRythmeFacturation: false,
      typoValueFromSite: "",
    }
  },
  components: {
    McmaCard,
    McmaField,
    McmaButton,
    McmaSpinBox,
  },
  props: {
    groupement: Object,
  },
  computed: {
    ...mapState(["membre", "operation"]),
    msgTypeFacturation2() {
      console.log(this.form)
      const a = this.form.getFieldValue("typeFacturation")
      console.log("> a : %O", a)
      if (a === "FACTURE_GROUPEE") {
        return "Tous les sites de ce périmètre de facturation seront facturés sur une facture groupée, le nom de périmètre personalisée ci-dessous apparaitra sur la facture."
      } else {
        return "Chacun de vos sites sera facturé séparément sur une facture individuelle."
      }
    },
    ibanIsRequired() {
      return (moyenPaiement) => {
        return moyenPaiement === "PRELEVEMENT" || moyenPaiement === "PRELEVEMENT_TRESORERIE" || this.membre.moyenPaiement === "PRELEVEMENT"
      }
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    //Set a default value taking the segment of first site found to typoSeg
    getTypoSegmentFromSites() {
      if (!this.groupement.typologieSegment) {
        Axios("get", "api/membres/" + this.membre.id + "/sites").then((response) => {
          let dataEmbbeded = response.data._embedded
          let siteElecs = dataEmbbeded.siteElecs
          let siteGaz = dataEmbbeded.siteGaz
          if (siteElecs !== null) {
            this.typoValueFromSite = siteElecs[0].segment
            if (
              this.typoValueFromSite === "C_1" ||
              this.typoValueFromSite === "C_2" ||
              this.typoValueFromSite === "C_3" ||
              this.typoValueFromSite === "C_4"
            ) {
              this.typoValueFromSite = "C_1,C_2,C_3,C_4"
            }
            this.form.setFieldsValue({
              typologieSegment: this.typoValueFromSite,
            })
          } else if (siteGaz !== null) {
            this.typoValueFromSite = siteGaz[0].segment
            if (this.typoValueFromSite === "T_1" || this.typoValueFromSite === "T_2") {
              this.typoValueFromSite = "T_1,T_2"
            }
            this.form.setFieldsValue({
              typologieSegment: this.typoValueFromSite,
            })
          } else {
            this.typoValueFromSite = ""
            this.form.setFieldsValue({
              typologieSegment: this.typoValueFromSite,
            })
          }
        })
      }
    },
    onTypoSegmentChange(value) {
      console.log("value", value)
      if (value === "C_1,C_2,C_3,C_4" || value === "T_3") {
        this.$nextTick(() => {
          this.form.setFieldsValue({ rythmeFacturation: "MENSUEL" })
        })
        this.disableRythmeFacturation = true
      } else {
        this.disableRythmeFacturation = false
      }
    },
    moment,
    updateGroupementEditing(groupement) {
      console.log("updateGroupementEditing")
      this.$root.$emit("ROOT_updateGroupementEditing", groupement)
    },
    updateMsgTypeFacturation(factureGroupe) {
      if (factureGroupe) {
        this.msgTypeFacturation =
          "Tous les sites de ce périmètre de facturation seront facturés sur une facture groupée, le nom de périmètre personalisée ci-dessous apparaitra sur la facture."
      } else {
        this.msgTypeFacturation =
          "Tous les sites rattachés à ce lot de facturation seront facturés de manière individuelle et partageront les mêmes attributs de facturation à renseigner dans cette page."
      }
    },
    isTypeFacturationFactureGroupee() {
      // console.log('> event : %O', event)
      const a = this.form.getFieldValue("typeFacturation")
      // console.log('> a : %O', a)
      this.updateMsgTypeFacturation(a === "FACTURE_GROUPEE")
      console.log("> a : %O", a === "FACTURE_GROUPEE")
      return a === "FACTURE_GROUPEE"
    },
    onSepaUpload(check, file) {
      this.form.setFieldsValue({
        sepaCheck: check,
        sepaFile: file,
      })
    },
    moyenPayementChange(value) {
      if (
        value === "PRELEVEMENT_BANQUE_DE_FRANCE" ||
        value === "PRELEVEMENT" ||
        value === "VIREMENT_SANS_MANDATEMENT" ||
        value === "PRELEVEMENT_TRESORERIE"
      ) {
        this.form.setFieldsValue({
          codeIdentificationIndividuelle: this.form.getFieldValue("siret"),
        })
        this.msgNotaBnEntiteExecutant =
          "N.B : Bien indiquer les coordonnées de la trésorerie en charge du paiement lors d'un virement sans mandatement ou d'un prélèvement trésorerie"
      } else {
        this.msgNotaBnEntiteExecutant = ""
      }
    },
    isIbanTresorerie(value) {
      const iban = this.form.getFieldValue("iban")
      if (iban.substring(0, 2) === "FR" && iban.substring(4, 10) === "300001") {
        this.form.setFieldsValue({ moyenPaiement: "PRELEVEMENT_TRESORERIE" })
      }
    },
    showTresorerie(moyenPaiement) {
      return moyenPaiement === "PRELEVEMENT_TRESORERIE" || moyenPaiement === "VIREMENT_SANS_MANDATEMENT"
    },
    initialize() {
      this.getTypoSegmentFromSites()
      let editGroupement = pick(this.groupement, [
        "typeFacturation",
        "nom",
        "budget",
        "designationTresorerie",
        "adresseFacturationTresorerie",
        "codePostaleTresorerie",
        "communeTresorerie",
        "moyenPaiement",
        "rythmeFacturation",
        "modeEnvoi",
        "delaiPaiement",
        "iban",
      ])
      editGroupement = this.$mergeObject(this.form.getFieldsValue(), editGroupement)
      this.isTypeFacturationFactureGroupee()
      this.$nextTick(() => {
        this.form.setFieldsValue(editGroupement)
      })

      if (this.groupements === null) {
        let initEntiteFacture = pick(
          {
            raisonSocialePayeur: this.membre.businessName,
            nomVoie: this.membre.nomVoie,
            numeroVoie: this.membre.numeroVoie,
            complementAdresse: this.membre.complementAdresse,
            codePostale: this.membre.postalCode,
            commune: this.membre.city,
          },
          ["raisonSocialePayeur", "nomVoie", "numeroVoie", "complementAdresse", "codePostale", "commune"]
        )

        initEntiteFacture = this.$mergeObject(this.form.getFieldsValue(), initEntiteFacture)
        this.$nextTick(() => {
          this.form.setFieldsValue(initEntiteFacture)
        })
      }
      this.onTypoSegmentChange(this.groupement.typologieSegment)
      this.$emit("changeModeEnvoi", this.form.getFieldValue("modeEnvoi"))
    },
    save() {
      this.$emit("save")
    },
    processFile(event) {
      const sizeMax = process.env.VUE_APP_UPLOAD_SIZE * 1024 * 1024
      const fileIban = event.target.files[0]

      if (fileIban.size > sizeMax) {
        this.$message.warning(`Le fichier dépasse la taille autorisé qui est de ${process.env.VUE_APP_UPLOAD_SIZE}Mo.`)
      } else if (fileIban.type !== "application/pdf") {
        this.$message.error(`Le fichier n'est pas au format PDF`)
      } else {
        this.ibanUploadedFile = fileIban
        this.checkIban = true
      }
    },
    addIban() {
      if (this.ibanIsRequired && this.checkIban && this.ibanUploadedFile) {
        const formData = new FormData()
        formData.append("file", this.ibanUploadedFile)
        Axios(
          "post",
          "/file/operation/" + this.operation.id + "/membre/" + this.membre.id + "/perimetrefacturation/piece",
          formData,
          null,
          {
            "Content-Type": "multipart/form-data",
          }
        ).catch((error) => {
          console.error(error)
          this.$notification.error({
            message: `Erreur lors du téléversement de l'iban`,
            description: `Une erreur s'est produite lors du téléversement de la pièce, veuillez contacter un administrateur.`,
          })
        })
      }
    },
    resetIbanUpload() {
      if (this.checkIban !== null || this.ibanUploadedFile !== null) {
        this.checkIban = null
        this.ibanUploadedFile = null
        const input = this.$refs.documentUploaded
        if (input && input.type) {
          input.type = "text"
          input.type = "file"
        }
      }
    },
    onChangeModeEnvoi(value) {
      this.$emit("changeModeEnvoi", value)
    },
    submit(interlocuteurId, interlocuteurFieldsValue) {
      console.log(
        `%c prepare saving Groupement`,
        "background:purple ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
      )
      this.form.validateFields((errors, fieldsValue) => {
        console.log(
          `%c fieldsValue`,
          "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
          fieldsValue
        )
        this.wasSubmit = true
        if (errors) {
          this.$alert(
            "Vous n'avez pas complété tous les champs obligatoires marqués par une astérisque rouge. Veuillez tous les remplir afin de valider le formulaire.",
            "Erreur",
            "error"
          )
          /*this.$notification.error({
            message: "Vous avez des erreurs",
            description: `Veuillez remplir tous les champs comprenant des erreurs`,
          })*/
          this.$scrollToTop()
        } else {
          const groupement = {
            membre: process.env.VUE_APP_API_URL + "/api/membres/" + this.membre.id,
            // Type de Facturation
            nom: fieldsValue.nom,
            typologieSegment: fieldsValue.typologieSegment,
            typeFacturation: fieldsValue.typeFacturation,
            // Entité
            budget: fieldsValue.budget,
            raisonSocialPayeur: fieldsValue.raisonSocialePayeur,
            numeroVoie: fieldsValue.numeroVoie,
            nomVoie: fieldsValue.nomVoie,
            complementAdresse: fieldsValue.complementAdresse,
            codePostal: fieldsValue.codePostale,
            boitePostale: fieldsValue.boitePostale,
            commune: fieldsValue.commune,
            // Moyen de paiment
            moyenPaiement: fieldsValue.moyenPaiement,
            delaiPaiement: fieldsValue.delaiPaiement,
            rythmeFacturation: fieldsValue.rythmeFacturation,
            titulaireIban: fieldsValue.titulaireIban,
            iban: fieldsValue.iban,
            ibanPieceUploaded: this.checkIban,
            // Tresorerie
            designationTresorerie: fieldsValue.designationTresorerie,
            adresseFacturationTresorerie: fieldsValue.adresseFacturationTresorerie,
            codePostaleTresorerie: fieldsValue.codePostaleTresorerie,
            communeTresorerie: fieldsValue.communeTresorerie,
            // Chorus
            siret: interlocuteurFieldsValue.siret,
            codeIdentificationIndividuelle: interlocuteurFieldsValue.codeIdentificationIndividuelle,
            codeSiretExecutant: interlocuteurFieldsValue.codeSiretExecutant,
            engagementJuridique: interlocuteurFieldsValue.engagementJuridique,
            codeServiceExecutant: interlocuteurFieldsValue.codeServiceExecutant,
            numConventionTripartite: interlocuteurFieldsValue.numConventionTripartite,
            chorusConfirmation: interlocuteurFieldsValue.chorusConfirmation,
            chorusCodeServiceExecutant: interlocuteurFieldsValue.chorusCodeServiceExecutant,
            chorusCodeEngagementJuridique: interlocuteurFieldsValue.chorusCodeEngagementJuridique,
            chorusNomServiceExecutant: interlocuteurFieldsValue.chorusNomServiceExecutant,
            chorusNomStructureExecutant: interlocuteurFieldsValue.chorusNomStructureExecutant,
            refContrat: interlocuteurFieldsValue.refContrat,
            // Option
            papier: interlocuteurFieldsValue.papier,
            optionExcel: interlocuteurFieldsValue.optionExcel,
            dematXml: interlocuteurFieldsValue.dematXml,
            modeEnvoi: fieldsValue.modeEnvoi,
            // Etat
            state: Constants.STATUS.COMPLETED.value,
            operationId: this.operation.id,
          }
          console.log(">> Modif/Add groupement : %O", groupement)
          console.log(">> this.groupement.id : %O", this.groupement.id)
          if (!groupement.nom) {
            this.$notification.error({
              message: "Erreur lors de l'enregistement du Groupement",
              description: "Désolé nous n'avons pas pu réccurpérer les valeurs saisies dans le formulaire...",
            })
          } else {
            this.$emit("loading", true)
            let request = null
            if (this.$route.params.action !== "duplique" && this.groupement.id) {
              request = Axios("patch", "api/groupements/" + this.groupement.id, groupement)
              console.log(">> Patch groupement : %O", groupement)
            } else {
              request = Axios("post", "api/groupements", groupement)
              console.log(">> Post groupement : %O", groupement)
            }
            request
              .then(() => {
                if (this.$route.params.action !== "duplique") {
                  this.$notification.success({
                    message: "Périmètre sauvegardé",
                    description: "Le périmètre de facturation a bien été sauvegardé.",
                  })
                } else {
                  this.$notification.success({
                    message: "Périmètre dupliqué",
                    description: "Le périmètre de facturation a bien été dupliqué.",
                  })
                }
                const typeFacturation = this.form.getFieldValue("typeFacturation")
                if (typeFacturation === "FACTURE_GROUPEE") {
                  alert(
                    "Vous avez défini une facture groupée. Vous allez maintenant pouvoir choisir les sites rattachés à cette facture groupée à l’aide du bouton « Attacher des sites »"
                  )
                } else {
                  alert(
                    "Vous avez défini un lot de factures individuelles. Vous allez maintenant pouvoir choisir les sites qui constituent ce lot à l’aide du bouton « Attacher des sites »"
                  )
                }

                if (this.sepaCheck && this.sepaFile) {
                  const formData = new FormData()
                  formData.append("file", this.sepaFile)
                  formData.append("membreId", this.membre.id)
                  formData.append("groupement", groupement.nom)
                  Axios("post", "/file/sepa/membre", formData, null, {
                    "Content-Type": "multipart/form-data",
                  })
                    .then(() => {
                      this.$notification.success({
                        message: "SEPA signé !",
                        description: "Le SEPA à bien été signé.",
                      })
                    })
                    .catch((error) => {
                      console.error(error)
                      this.$notification.error({
                        message: "Erreur SEPA",
                        description: "Erreur lors de la signature du SEPA.",
                      })
                    })
                } else if (!this.sepaCheck && this.groupement.sepa) {
                  Axios("delete", "file/sepa?membreId=" + this.membre.id + "&groupement=" + this.groupement.nom)
                    .then(() => {
                      this.$notification.success({
                        message: "SEPA supprimé !",
                        description: "Le SEPA à bien été supprimé.",
                      })
                    })
                    .catch((error) => {
                      console.error(error)
                      this.$notification.error({
                        message: "Erreur SEPA !",
                        description: "Erreur lors de la suppression du SEPA.",
                      })
                    })
                }
                this.addIban()
                this.$emit("loading", false)
                this.$router.push({
                  name: "groupements",
                })
              })
              .catch((error) => {
                console.error(error)
                this.$notification.error({
                  message: "Erreur serveur",
                  description: "Une erreur server est survenue, veuillez contacter un administrateur.",
                })
              })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.row-inline {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.upload-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 45vw;
  margin: 20px 0;
  padding-right: 10px;
  border-radius: 4px;
  background: rgba($grey, 0.1);
  border: 1px solid rgba($grey, 0.3);
  transition: all 0.3s ease;

  .file-name {
    color: $grey;
  }

  &.has-file {
    background: hsla(var(--success-color-h), var(--success-color-s), var(--success-color-l), 0.1);

    .file-name {
      color: var(--text-color, $text-color);
    }
  }
}
</style>
