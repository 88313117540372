<template>
  <mcma-spin-box :spinning="loading">
    <a-card class="mcma-card-mutli-progress">
      <div class="line" v-for="(value, key) of lines" :from="key" :key="value.name">
        <div
          class="cell"
          v-for="index in value.length"
          :key="index"
          :class="{
            offset: getName(value[index - 1]) === 'offset',
            ml0: index === 1,
            mr0: index === value.length,
          }"
        >
          <div class="inline" v-if="getName(value[index - 1])">
            <div class="icon" v-if="getIcon(value[index - 1])">
              <img :src="getIcon(value[index - 1])" :alt="getName(value[index - 1])" />
            </div>
            <span class="name">
              {{ getName(value[index - 1]) }}
            </span>
          </div>
          <span v-if="getDescribeCount(value[index - 1])" class="describe">
            {{ getDescribeCount(value[index - 1]) }}
          </span>
          <div
            class="progression"
            v-if="
              totalByValues &&
              (getAndCalculPourcentage(value, index - 1, true) || getAndCalculPourcentage(value, index - 1, true) === 0)
            "
          >
            <a-progress
              :strokeColor="getColor(value[index - 1])"
              :percent="getAndCalculPourcentage(value, index - 1)"
              :format="() => getAndCalculPourcentage(value, index - 1, true)"
              :strokeWidth="11"
              size="small"
            />
          </div>
        </div>
      </div>
    </a-card>
  </mcma-spin-box>
</template>

<script>
import _ from "lodash"
import slugify from "slugify"
import { accorization } from "@/util/Helpers"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"

export default {
  name: "mcma-card-multi-progress",
  props: {
    lines: Object,
    percentInColumn: Boolean,
    loading: Boolean,
  },
  components: {
    McmaSpinBox,
  },
  data() {
    return {
      totalByValues: {},
    }
  },
  computed: {
    getName() {
      return (item) => {
        if (item && item.name && item.uppercase) {
          return item.name.toUpperCase()
        }
        return item && item.name ? item.name : null
      }
    },
    getDescribeCount() {
      return (item) => {
        let message = ""
        if (item && item.name && item.singularWordInfosCount) {
          const nameSlug = slugify(item.name, "_")
          message = accorization(this.totalByValues[nameSlug], item.singularWordInfosCount, true)
          return message
        }
        return null
      }
    },
    getIcon() {
      return (item) => {
        return item && item.icon ? require(`@/assets/images/${item.icon}.svg`) : null
      }
    },
    getAndCalculPourcentage() {
      return (items, index, getValue) => {
        if (items[index] && (items[index].value || items[index].value === 0)) {
          let total = 0
          const heading = this.lines && this.lines.heading ? this.lines.heading : {}
          const headingName = heading && heading[index] && heading[index].name ? heading[index].name : null
          if (this.percentInColumn && headingName) {
            total = this.totalByValues[slugify(headingName, "_")]
          } else {
            total = _.sumBy(items, "value")
          }
          const pourcentage = (100 * items[index].value) / total
          return getValue ? items[index].value : pourcentage
        }
        return null
      }
    },
    getColor() {
      return (item) => {
        return item && item.color ? item.color : null
      }
    },
  },
  mounted() {
    this.totalByValues = this.getTotalValue(this.lines)
  },
  watch: {
    lines: {
      deep: true,
      handler(value) {
        this.totalByValues = this.getTotalValue(this.lines)
      },
    },
  },
  methods: {
    getTotalValue(lines) {
      const result = {}
      if (lines && lines.heading) {
        lines.heading.forEach((line, index) => {
          const name = line.name
          if (name && name !== "offset" && index !== 0) {
            let total = 0
            Object.entries(lines).forEach((item) => {
              const key = item[0]
              const values = item[1]
              if (key !== "heading") {
                if (values[index] && values[index].value) {
                  total += values[index].value
                }
              }
            })
            result[slugify(name, "_")] = total
          }
        })
      }
      return result
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-card-mutli-progress {
  font-family: $font-family;
  box-shadow: $box-shadow;
  &.ant-card-bordered {
    border: none;
  }
  > .ant-card-body {
    display: flex;
    flex-flow: column nowrap;
    .line {
      display: flex;
      flex-flow: row nowrap;
      .cell {
        font-family: $font-roboto;
        flex: 1 1 0px;
        margin: 8px 10px;
        &.offset {
          opacity: 0;
        }
        .inline {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          .icon {
            margin-right: 10px;
            img {
              width: 26px;
              height: auto;
            }
          }
          .name {
            color: var(--text-color-secondary, $text-color-secondary);
            text-transform: capitalize;
            font-size: 16px;
            font-weight: 900;
          }
        }
        .describe {
          color: var(--text-color-secondary, $text-color-secondary);
          text-transform: capitalize;
          font-size: 14px;
          font-weight: 300;
          font-style: italic;
          position: relative;
          top: -2px;
          left: -2px;
        }
        .progression {
          > .ant-progress {
            > div {
              display: flex;
              flex-flow: row nowrap;
              .ant-progress-text {
                font-size: 16px;
                font-weight: 300;
                line-height: 18px;
                color: var(--heading-color, $heading-color) !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>
