<template>
    <mcma-import-work-flow :steps="steps" :operationId="getOperationId" :membreId="getMembreId"></mcma-import-work-flow>
</template>

<script>
import McmaImportWorkFlow from "@/components/mcma/common/McmaImportWorkflow.vue"
import { mapMutations } from "vuex"
export default {
    name: "SitesImportWorkflow",
    components: {
        McmaImportWorkFlow,
    },
    data() {
        return {
            // Steps qui composent notre wkflow d'import, pour un ajout, il suffit d'ajouter une ligne avec un pipename existant côté mage..
            // Généraliser le composant, puis passer cette var en props pour appeler le composant où on veut dans l'app avec diff workflow ;)
            steps: [
                { key: 'insertPdl', label: 'Insérer des sites', order: 0, pipename: 'wp_insert_sites' },
                { key: 'insertDTElec', label: 'Insérer des données techniques ELEC', order: 1, pipename: 'wp_insert_sites_elec_dt' },
                { key: 'insertDTGaz', label: 'Insérer des données techniques GAZ', order: 2, pipename: 'wp_insert_sites_gaz_dt' },
                { key: 'linkPdlInterloc', label: 'Lier des PDL à leur interlocuteur technique', order: 3, pipename: 'wp_link_pdl_interlocuteur' }, 
            ]
        }
    },
    created() {
        console.log("operationId / membreId", this.getOperationId, " / ", this.getMembreId)
        this.initialize()
    },
    computed: {
        getOperationId() {
            if(Object.keys(this.$route.params).includes('operationId')){
                return this.$route.params.operationId
            }
            return null
        },
        getMembreId() {
            if(Object.keys(this.$route.params).includes('membreId')){
                return this.$route.params.membreId
            }
            return null
        },

    },

    methods: {
        ...mapMutations(["setBreadcrumbAdd"]),

        initialize() {
            this.setBreadcrumbAdd({
                name: "Import en masse de sites",
                action: () => {
                    this.$router.push({
                        name: "sites-generic-import",
                    })
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped></style>